import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { push } from "connected-react-router";

import "./css/SideNavBar.css";
import "./css/BADashboard.css";
import Avatar from "@mui/material/Avatar";

//Material UI
import MenuIcon from "@mui/icons-material/Menu";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Box, useMediaQuery, useTheme, Typography } from "@mui/material";
import { keyframes } from "@mui/system";

//Custom Components
import LogoutMenu from "./SharedComponents/Logout";
import HomeBookingAgent from "./HomeBookingAgent";
import ArtistsBookingAgent from "./ArtistsBookingAgent";
import SettingsBookingAgent from "./SettingsBookingAgent";
import BASchedule from "./BaSchedule";
import IncomeBookingAgent from "./IncomeBookingAgent";
import logo from "../../Visuals/Teder_Logo_Transparent.png";
import artists from "./assets/artists.svg";
import home from "./assets/home.svg";
import income from "./assets/income.svg";
import settings from "./assets/settings.svg";
import schedule from "./assets/schedule.svg";

//Contstants
import { getTodayDate } from "../../util/helpers";
import { BA_DASHBOARD_ROUTES } from "../../util/constants";
import { UserType } from "../../util/constants";

//API
import {
  get_booking_agent_details,
  get_ba_schedule_user_list,
} from "../../Common/data";
import {
  bookingAgentDetailsAction,
  setGlobalRouteAction,
} from "../../redux/actions";
// import { ConstructionOutlined } from "@mui/icons-material";

export default function BADashboard(props) {
  //Redux State
  const currentState = useSelector((state) => state.TederReducerState);
  const dispatch = useDispatch();

  //Local State
  const [route, setRoute] = useState(BA_DASHBOARD_ROUTES.Home);
  const [showSideBar, setShowSideBar] = useState(false);
  const [artistList, setArtistList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  //Responsive Mui Tools
  const theme = useTheme();
  const SMmatches = useMediaQuery(theme.breakpoints.down("sm"));
  const BAmediaQueryPhone = theme.breakpoints.down("sm");

  const slideOpen = keyframes`
  100% {
  left: 0;
}
`;

  const styles = {
    greeting: {
      fontFamily: "titleFont",
      fontWeight: "bold",
      [BAmediaQueryPhone]: {
        display: "none",
      },
    },
    //Todo: restyle referral code font/color per new design
    referralCode: {
      fontFamily: "titleFont",
      fontSize: ".87em",
      color: "red",
      [BAmediaQueryPhone]: {
        paddingLeft: "12%",
      },
    },

    sideBarItem: {
      display: "flex",
      flexDirection: "row",
      marginBottom: "70px",
      cursor: "pointer",
      paddingLeft: "35px",
      [BAmediaQueryPhone]: {
        paddingLeft: "12%",
      },
    },

    links: {
      fontFamily: "inherit",
      color: "#FFFFFF",
      paddingLeft: "10%",
      [BAmediaQueryPhone]: {
        display: "none",
      },
    },

    BAtopBar: {
      height: "81px",
      width: "calc(100% - 17%)",
      backgroundColor: "#ffffff",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "2%",
      filter: "drop-shadow(0px 4px 10px rgba(219, 53, 84, 0.08))",
      position: "fixed",
      top: "0",
      right: "0",
      zIndex: "5",
      [BAmediaQueryPhone]: {
        height: "6%",
        width: "100%",
        padding: "3%",
        alignItems: "center",
        justifyContent: "space-between",
      },
    },
    sideBarWrapper: {
      position: "fixed",
      top: "0",
      bottom: "0",
      height: "auto",
      width: "17%",
      overflow: "hidden",
      [BAmediaQueryPhone]: {
        display: "none",
      },
    },
    sideBarWrapperHidden: {
      height: "102vh",
      paddingLeft: "20px",
      color: "white",
      background: "linear-gradient(180deg, #101035 39.58%, #ce3450 100%)",
      width: "100px",
      maxWidth: "18vw",
      position: "fixed",
      zIndex: "200",
      top: "0",
      left: "-100%",
      animation: `${slideOpen} 0.75s forwards`,
      boxShadow: "0 0 5px 10px rgba(0, 0, 0, 0.3)",
    },

    BASideBar: {
      background: "#191842",
      height: "100%",
      width: "100%",
      flexDirection: "column",
      position: "relative",
      zIndex: "1",
    },

    BArightSide: {
      display: "flex",
      flexDirection: "column",
      width: "88%",
      marginLeft: "12%",
      height: "100%",
      [BAmediaQueryPhone]: {
        width: "100%",
        margin: "0 auto",
      },
    },

    linkWrapper: {
      display: "flex",
      textAlign: "center",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "25vh",
      padding: "10px",
      marginTop: "45%",
    },

    logo: {
      width: "100%",
      height: "100%",
      paddingLeft: "15%",
      [BAmediaQueryPhone]: {
        paddingTop: "5%",
      },
    },

    logoWrapper: {
      display: "flex",
      justifyContent: "center",
      paddingRight: "35%",
    },

    showLessIcon: {
      margin: "5%",
      fontSize: "large",
    },

    BAtopBarTitle: {
      color: "#1a1a4a",
      fontFamily: "titleFont, sans-serif",
      fontSize: "1 em",
      marginRight: "5%",
      [BAmediaQueryPhone]: {
        fontSize: ".75em",
      },
    },

    BAtopBarText: {
      color: "#1a1a4a",
      fontFamily: "titleFont, sans-serif",
      fontSize: ".85em",
      fontWeight: "light",
      marginRight: "5%",
      [BAmediaQueryPhone]: {
        display: "none",
      },
    },
    logout: {
      display: "flex",
      flexDirection: "row",
      position: "relative",
      alignItems: "justify",
      width: "15%",
      justifyContent: "space-evenly",
      [BAmediaQueryPhone]: {
        paddingLeft: "10%",
      },
    },

    arrow: {
      color: "black",
      fontFamily: "medium",
      alignSelf: "right",
    },

    avatar: {
      position: "absolute",
      left: "-20%",
      top: "-3px",
      width: 24,
      height: 24,
      [BAmediaQueryPhone]: {
        display: "none",
      },
    },

    showLessWrapper: {
      display: "flex",
      width: "100%",
      height: "auto",
      justifyContent: "flex-end",
    },
    svgs: {
      color: "#F9F9FA",
    },
  };

  //Methods
  const selectRoute = (selection) => setRoute(selection);
  const toggleSidebar = () => setShowSideBar(!showSideBar);

  //Margin of footer will change based on render of this component
  useEffect(() => {
    if (currentState.globalRoute === BA_DASHBOARD_ROUTES.BA_Schedule) {
      selectRoute(BA_DASHBOARD_ROUTES.BA_Schedule);
    }
    dispatch(setGlobalRouteAction(props.location.pathname));
    return () => dispatch(setGlobalRouteAction(""));
  }, []);

  useEffect(() => {
    get_booking_agent_details()
      .then((res) => {
        const details = res.booking_agent_settings[0];
        //For token refresh
        localStorage.setItem("AuthProviderId_ba", details.AuthProviderId);

        const formattedAgentDetails = {
          ...currentState.bookingAgentDetails,
          firstName: details.FirstName,
          lastName: details.LastName,
          city: details.City,
          address: details.Address,
          state: details.State,
          email: details.Email,
          zipeCode: details.ZipCode,
          password: details.PassWord,
          paymentMethod: details.PaymentMethod,
          phone: details.PhoneNumber,
          userType: UserType.BookingAgent,
          referralCode: details.ReferralCode,
          dateJoined: details.DateJoined,
          photoPath: details.PhotoPath,
        };

        dispatch(bookingAgentDetailsAction(formattedAgentDetails));
        return formattedAgentDetails;
      })
      .catch((error) => {
        setError(true);
        console.error(error);
      });

    get_ba_schedule_user_list()
      .then((res) => {
        const userList = JSON.parse(res.ba_assigned_artists);
        const formattedUserList = userList.map((option, index) => ({
          id: option.id,
          name: option.name,
          genres: option.Genre,
          photo: option.photo,
          timeOnTeder: option.timeOnTeder,
          totalEarnings: option.totalEarnings,
          totalShows: option.totalShows,
          totalTicketSales: option.totalTicketSales,
          key: index + 1,
        }));
        //Todo: convert to redux store, subscribe to state in each iteration
        setArtistList(formattedUserList);
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
  }, []);

  if (!currentState.baContext) return null;

  const { firstName, lastName } = currentState.baContext;

  const renderNames = (...args) => {
    return args && args.join(" ");
  };

  const renderInitials = (first, last) => {
    return (
      first && `${first.charAt(0).toUpperCase()}${last.charAt(0).toUpperCase()}`
    );
  };

  if (currentState.userContext.userType === UserType.Artist) {
    dispatch(push("/artist"));
  }
  if (!currentState.userContext.userLoggedIn) {
    dispatch(push("/login"));
  }

  const { Home, Artists, Income, Settings, BA_Schedule } = BA_DASHBOARD_ROUTES;

  const renderPage = () => {
    switch (route) {
      case Home:
        return (
          <HomeBookingAgent
            agentDetails={currentState.baContext}
            selectRoute={selectRoute}
            artistList={artistList}
            setArtistList={setArtistList}
          />
        );
      case Artists:
        return (
          <ArtistsBookingAgent
            artistList={artistList}
            setArtistList={setArtistList}
            isLoading={isLoading}
          />
        );
      case Income:
        return <IncomeBookingAgent />;
      case Settings:
        return (
          <SettingsBookingAgent
            agentDetails={currentState.baContext}
            renderInitials={renderInitials}
          />
        );
      case BA_Schedule:
        return <BASchedule selectRoute={selectRoute} />;
      default:
        return (
          <HomeBookingAgent
            agentDetails={currentState.baContext}
            selectRoute={selectRoute}
            artistList={artistList}
            setArtistList={setArtistList}
          />
        );
    }
  };

  return (
    <div className="dashboard-container">
      <div className="dashboard-content">
        <Box
          component="div"
          sx={showSideBar ? styles.sideBarWrapperHidden : styles.sideBarWrapper}
        >
          <Box component="div" sx={styles.BASideBar} className="left-sidebar">
            <Box component="div" sx={styles.logoWrapper}>
              <Link to="/">
                <Box
                  component="img"
                  src={logo}
                  alt="Teder"
                  loading="lazy"
                  style={styles.logo}
                />
              </Link>
            </Box>
            <div className="agent-greeting side-bar-item">
              <Typography variant="p" sx={styles.greeting}>
                {`Hello, ${renderNames(firstName, lastName)}`}
              </Typography>
            </div>
            <Box
              onClick={(e) => {
                setRoute(BA_DASHBOARD_ROUTES.Home);
                setShowSideBar(false);
              }}
              sx={styles.sideBarItem}
            >
              <Box component="img" src={home} alt="Home" style={styles.svgs} />
              <Typography variant="p" sx={styles.links}>
                Home
              </Typography>
            </Box>
            <Box
              onClick={(e) => {
                setRoute(BA_DASHBOARD_ROUTES.Artists);
                setShowSideBar(false);
              }}
              sx={styles.sideBarItem}
            >
              <Box component="img" src={artists} alt="Artists" />
              <Typography variant="p" sx={styles.links}>
                Artists
              </Typography>
            </Box>
            <Box
              onClick={(e) => {
                setRoute(BA_DASHBOARD_ROUTES.Income);
                setShowSideBar(false);
              }}
              sx={styles.sideBarItem}
            >
              <Box component="img" src={income} alt="Income" />
              <Typography variant="p" sx={styles.links}>
                Income
              </Typography>
            </Box>
            <Box
              onClick={(e) => {
                setRoute(BA_DASHBOARD_ROUTES.Settings);
                setShowSideBar(false);
              }}
              sx={styles.sideBarItem}
            >
              <Box component="img" src={settings} alt="Settings" />

              <Typography variant="p" sx={styles.links}>
                Settings
              </Typography>
            </Box>
            <Box
              onClick={(e) => {
                setRoute(BA_DASHBOARD_ROUTES.BA_Schedule);
                setShowSideBar(false);
              }}
              sx={styles.sideBarItem}
            >
              <Box component="img" src={schedule} alt="BaSchedForm" />
              <Typography variant="p" sx={styles.links}>
                Schedule
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box component="div" sx={styles.BArightSide}>
          <Box component="div" sx={styles.BAtopBar}>
            {SMmatches && (
              <MenuIcon style={{ color: "black" }} onClick={toggleSidebar} />
            )}
            <Typography variant="p" sx={styles.BAtopBarTitle}>
              Booking Agent Dashboard
            </Typography>

            <Typography variant="p" sx={styles.BAtopBarText}>
              {getTodayDate()}
            </Typography>
            <Box component="div" sx={styles.logout}>
              <Avatar sx={styles.avatar}>
                {renderInitials(firstName, lastName)}
              </Avatar>
              <Typography variant="p" sx={styles.BAtopBarText}>
                {renderNames(firstName, lastName)}
              </Typography>
              <LogoutMenu>
                <ArrowDropDownIcon style={styles.arrow} />
              </LogoutMenu>
            </Box>
          </Box>
          {renderPage()}
        </Box>
      </div>
    </div>
  );
}
