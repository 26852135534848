import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { reset_password } from "../Common/data";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

import "../Styling/forgotpasswordpage.css";
import { Link } from "react-router-dom";

function ForgotPasswordConfirmPage() {
  const routerState = useSelector((state) => state.router);

  const [codeOne, setCodeOne] = useState("");
  const [codeTwo, setCodeTwo] = useState("");
  const [codeThree, setCodeThree] = useState("");
  const [codeFour, setCodeFour] = useState("");
  const [codeFive, setCodeFive] = useState("");
  const [codeSix, setCodeSix] = useState("");
  // const [codeArray, setCodeArray] = useState([]);
  // const [code, setCode] = useState("");

  const [codeOneFocus, setCodeOneFocus] = useState(true);
  const [codeTwoFocus, setCodeTwoFocus] = useState(false);
  const [codeThreeFocus] = useState(false);
  const [codeFourFocus] = useState(false);
  const [codeFiveFocus] = useState(false);
  const [codeSixFocus] = useState(false);

  const [btnClass, setBtnClass] = useState("forgot-password-unconfirm-btn");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const email = routerState.location.query.email;
  const [errorMsg, setErrorMsg] = useState("");

  const dispatch = useDispatch();

  const handlePaste = (e) => {
    const paste = e.clipboardData.getData("text").split("");
    setCodeOne(paste[0]);
    setCodeTwo(paste[1]);
    setCodeThree(paste[2]);
    setCodeFour(paste[3]);
    setCodeFive(paste[4]);
    setCodeSix(paste[5]);
  };

  const handleCodeOne = (e) => {
    setCodeOne(e.target.value);
    setCodeOneFocus(false);
    setCodeTwoFocus(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    var code = codeOne + codeTwo + codeThree + codeFour + codeFive + codeSix;

    let data = {
      code: code,
      password: password,
      username: email,
    };

    reset_password(data)
      .then((res) => {
        dispatch(push("/login"));
      })
      .catch((err) => {
        setErrorMsg(err.responseJSON.message);
        console.log(err.responseJSON.messge);
      });
  };

  useEffect(() => {
    let code = codeOne + codeTwo + codeThree + codeFour + codeFive + codeSix;
    code = code.split("");
    if (
      password.length > 0 &&
      confirmPassword.length > 0 &&
      password === confirmPassword &&
      code.length === 6
    ) {
      setBtnClass("forgot-password-confirm-btn");
    } else {
      setBtnClass("forgot-password-unconfirm-btn");
    }
  }, [
    password,
    confirmPassword,
    codeFive,
    codeFour,
    codeOne,
    codeSix,
    codeThree,
    codeTwo,
  ]);

  return (
    <div className="forgot-password-container">
      <form>
        <div className="confirm-password-content">
          <div className="forgot-password-form">
            <p className="forgot-password-title">CREATE NEW PASSWORD</p>
          </div>

          <p
            className="account-wrong-creds"
            style={errorMsg === "" ? { display: "none" } : { display: "block" }}
          >
            {errorMsg}
          </p>

          <div className="forgot-password-code-form">
            <p className="forgot-password-text">
              For security purposes, we just sent you a temporary code. Please
              check your inbox and enter the 6 digit code below.
            </p>
            <div className="forgot-password-code">
              <input
                type="text"
                autoFocus={codeOneFocus}
                id="code-1"
                value={codeOne}
                onPaste={handlePaste}
                onChange={handleCodeOne}
                maxLength="1"
              ></input>
              <input
                type="text"
                autoFocus={codeTwoFocus}
                id="code-2"
                value={codeTwo}
                maxLength="1"
                onChange={(e) => setCodeTwo(e.target.value)}
              ></input>
              <input
                type="text"
                autoFocus={codeThreeFocus}
                id="code-3"
                value={codeThree}
                maxLength="1"
                onChange={(e) => setCodeThree(e.target.value)}
              ></input>
              <input
                type="text"
                autoFocus={codeFourFocus}
                id="code-4"
                value={codeFour}
                maxLength="1"
                onChange={(e) => setCodeFour(e.target.value)}
              ></input>
              <input
                type="text"
                autoFocus={codeFiveFocus}
                id="code-5"
                value={codeFive}
                maxLength="1"
                onChange={(e) => setCodeFive(e.target.value)}
              ></input>
              <input
                type="text"
                autoFocus={codeSixFocus}
                id="code-6"
                value={codeSix}
                maxLength="1"
                onChange={(e) => setCodeSix(e.target.value)}
              ></input>
            </div>
          </div>
          <br />

          <div className="forgot-password-form">
            <p className="forgot-password-text">
              Your new password must be different from previous passwords.
            </p>
            <label className="forgot-password-label">New Password</label>
            <input
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              className="change-password-input"
            />
            <p className="password-rules-text">
              Must have at least 6 characters including 1 uppercase letter and 1
              special character
            </p>
            <label className="forgot-password-label">
              Confirm New Password
            </label>
            <input
              onChange={(e) => setConfirmPassword(e.target.value)}
              type="password"
              className="confirm-password-input"
            />
          </div>
          <button onClick={handleSubmit} className={btnClass}>
            SUBMIT
          </button>
          <div className="resend-code">
            <p>Didn't get a code? </p>
            <Link to="/forgot-password" className="resend-code-link">
              Click here to resend{" "}
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ForgotPasswordConfirmPage;
