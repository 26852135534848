import { checkIf24hoursPassed } from "../../../util/helpers";

export default function ValidateShowForm(values, isBA) {
  let errors = {};

  // errors.showTimezone = "";
  errors.showTicketPrice = "";
  errors.showTicketMax = "";
  errors.showDuration = "";

  const notEnoughTime = checkIf24hoursPassed(values.showDate, values.showTime);

  if (isBA) {
    if (!values.Artist_ID || values.Artist_ID === undefined) {
      errors.Artist_ID = "*Please Select Artist";
    }
  }
  errors.Artist_ID = "";
  if (!values.showDate) {
    errors.showDate = "*Date is required.";
  } else errors.showDate = "";

  if (!values.showTime || values.showTime === ":") {
    errors.showTime = "*Time is required.";
  } else if (notEnoughTime) {
    errors.showTime =
      "Please schedule a show at least 24 hours from now. This will give you enough time to tell your fans.";
  } else errors.showTime = "";

  if (!values.showName) {
    errors.showName = "*Required field.";
  } else errors.showName = "";

  if (!values.photoPath) {
    errors.photoPath = "*Photo is required.";
  } else errors.photoPath = "";

  if (!values.genres || values.genres === "") {
    errors.genres = "*You must select at least one genre.";
  } else if (values.genres) {
    if (values.genres.split(",").length > 2) {
      errors.genres = "*You can only select up to 2 genres.";
    } else errors.genres = "";
  } else errors.genres = "";
  if (!values.showTicketPrice) {
    errors.showTicketPrice = "*Set a Price";
  }

  if (!values.showDescription) {
    errors.showDescription = "*Description is required.";
  } else errors.showDescription = "";

  return errors;
}
