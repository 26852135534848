import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { push } from "react-router-redux";

//MUI Components
import { Box, Fade, Typography, useTheme } from "@mui/material";

//Custom Components
import { GreenTederButton } from "../Components/BookingAgentPages/SharedComponents/Buttons";

//Assets
import logo from "../Visuals/Teder_Logo_Transparent.png";

const ConfirmationComplete = () => {
  //MUI Styles
  const theme = useTheme();
  const mediaQuery = theme.breakpoints.down("sm");
  const { green01, darkBlue } = theme.palette.tederColors;
  const { tederLogoSizing } = theme.palette.icons;
  const { purpleGradientToPink } = theme.palette.layout.background;

  const styles = {
    wrapper: {
      margin: "0 auto",
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      background: purpleGradientToPink,
      backdropFilter: "opacity(.3)",
      height: "100vh",
      width: "100vw",
    },

    logo: {
      ...tederLogoSizing,
    },

    welcomeText: {
      fontFamily: "titlefont",
      alignSelf: "center",
      marginTop: "2%",
      color: green01,
      [mediaQuery]: {
        marginTop: "12%",
      },
    },

    inset: {
      alignSelf: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      width: "65%",
      height: "75%",
      background: "rgba(255, 255, 255, 0.4)",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      backdropFilter: "opacity(.1)",
      borderRadius: "10px",
      [mediaQuery]: {
        width: "100%",
        height: "50%",
      },
    },

    column: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignContent: "center",
      margin: "5% 5% 0 0 ",
      width: "100%",
      height: "15%",
    },

    button: {
      fontFamily: "titlefont",
      width: "240px",
      height: "60px",
      textTransform: "uppercase",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      "&hover": {
        background: "white",
      },
    },
  };

  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const handleClick = () => dispatch(push("/login"));

  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <Fade in={show} timeout={2670}>
      <Box component="div" sx={styles.wrapper}>
        <Box component="div" sx={styles.inset}>
          <Box component="div" sx={styles.column}>
            <Box component="img" src={logo} sx={styles.logo} />
            <Typography variant="h3" sx={styles.welcomeText}>
              Welcome!
            </Typography>
          </Box>
          <Box component="div" sx={styles.column}>
            <Typography
              variant={"p"}
              sx={{ ...styles.welcomeText, color: darkBlue, fontSize: "1.8em" }}
            >
              Your Account Is Now Confirmed
            </Typography>
          </Box>
          <Box component="div" sx={styles.column}>
            <GreenTederButton style={styles.button} onClick={handleClick}>
              Log In
            </GreenTederButton>
          </Box>
        </Box>
      </Box>
    </Fade>
  );
};

export default ConfirmationComplete;
