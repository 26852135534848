export const ActionTypes = {
  SET_USER_REGISTRATION_1: "SET_USER_REGISTRATION_1",
  SET_USER_REGISTRATION_STEP1: "SET_USER_REGISTRATION_STEP1",
  SET_USER_REGISTRATION_STEP2: "SET_USER_REGISTRATION_STEP2",
  SET_USER_REGISTRATION_STEP3: "SET_USER_REGISTRATION_STEP3",
  SET_USER_REGISTRATION_ERRORS_STEP1: "SET_USER_REGISTRATION_ERRORS_STEP1",
  SET_USER_REGISTRATION_ERRORS_STEP2: "SET_USER_REGISTRATION_ERRORS_STEP2",
  SET_USER_REGISTRATION_ERRORS_STEP3: "SET_USER_REGISTRATION_ERRORS_STEP3",
  UPDATE_USER_NAVIGATION: "UPDATE_USER_NAVIGATION",
  USER_LOGIN: "USER_LOGIN",
  FB_USER_LOGIN: "FB_USER_LOGIN",
  USER_LOGOUT: "USER_LOGOUT",
  ARTIST_DETAILS: "ARTIST_DETAILS",
  BOOKING_AGENT_DETAILS: "BOOKING_AGENT_DETAILS",
  BOOKING_AGENT_STATS: "BOOKING_AGENT_STATS",
  BOOKING_AGENT_SHOWS: "BOOKING_AGENT_SHOWS",
  BOOKING_AGENT_LIST: "BOOKING_AGENT_LIST",
  MEMBER_DETAILS: "MEMBER_DETAILS",
  ARTIST_DETAILS_ERRORS: "ARTIST_DETAILS_ERRORS",
  BOOKING_AGENT_INCOME: "BOOKING_AGENT_INCOME",
  BOOKING_AGENT_GOALS: "BOOKING_AGENT_GOALS",
  LOAD_COUNTRIES: "LOAD_COUNTRIES",
  LOAD_STATES: "LOAD_STATES",
  ADMIN_LOAD_TICKETS_PER_SHOW: "ADMIN_LOAD_TICKETS_PER_SHOW",
  ADMIN_LOAD_SHOWS: "ADMIN_LOAD_SHOWS",
  CREATE_SHOW: "CREATE_SHOW",
  CREATE_SHOW_ERRORS: "CREATE_SHOW_ERRORS",
  RESET_SHOW: "RESET_SHOW",
  UPDATE_SHOW_DETAILS: "UPDATE_SHOW_DETAILS",
  UPDATE_SHOW_ERRORS: "UPDATE_SHOW_ERRORS",
  UPDATE_USER_SHOWS: "UPDATE_USER_SHOWS",
  UPDATE_HOMEPAGE_SHOWS: "UPDATE_HOMEPAGE_SHOWS",
  PURCHASE_TICKET: "PURCHASE_TICKET",
  PURCHASE_TICKET_ERRORS: "PURCHASE_TICKET_ERRORS",
  BA_SCHEDULE_EVENTS: "BA_SCHEDULE_EVENTS",
  SET_GLOBAL_ROUTE: "SET_GLOBAL_ROUTE",
};

export const setGlobalRouteAction = (globalRoute) => ({
  type: ActionTypes.SET_GLOBAL_ROUTE,
  globalRoute,
});

export const setUserRegistration1 = (registrationDetails) => ({
  type: ActionTypes.SET_USER_REGISTRATION_1,
  registrationDetails,
});

export const setRegistrationStep1Action = (registrationDetails) => ({
  type: ActionTypes.SET_USER_REGISTRATION_STEP1,
  registrationDetails,
});

export const setRegistrationStep2Action = (registrationDetails) => ({
  type: ActionTypes.SET_USER_REGISTRATION_STEP2,
  registrationDetails,
});

export const setRegistrationStep3Action = (registrationDetails) => ({
  type: ActionTypes.SET_USER_REGISTRATION_STEP3,
  registrationDetails,
});

export const setRegistrationErrorsStep1Action = (errorDetails) => ({
  type: ActionTypes.SET_USER_REGISTRATION_ERRORS_STEP1,
  errorDetails,
});

export const setRegistrationErrorsStep2Action = (errorDetails) => ({
  type: ActionTypes.SET_USER_REGISTRATION_ERRORS_STEP2,
  errorDetails,
});

export const setRegistrationErrorsStep3Action = (errorDetails) => ({
  type: ActionTypes.SET_USER_REGISTRATION_ERRORS_STEP3,
  errorDetails,
});

export const updateUserNavigationAction = (navigationDetails) => ({
  type: ActionTypes.UPDATE_USER_NAVIGATION,
  navigationDetails,
});

export const loginUserAction = (loginDetails) => ({
  type: ActionTypes.USER_LOGIN,
  loginDetails,
});

export const FBloginUserAction = (loginDetails) => ({
  type: ActionTypes.FB_USER_LOGIN,
  loginDetails,
});

export const logoutUserAction = (logoutDetails) => ({
  type: ActionTypes.USER_LOGOUT,
  logoutDetails,
});

export const artistDetailsAction = (artistDetails) => ({
  type: ActionTypes.ARTIST_DETAILS,
  artistDetails,
});

export const bookingAgentDetailsAction = (bookingAgentDetails) => ({
  type: ActionTypes.BOOKING_AGENT_DETAILS,
  bookingAgentDetails,
});
export const bookingAgentStatsAction = (bookingAgentStats) => ({
  type: ActionTypes.BOOKING_AGENT_STATS,
  bookingAgentStats,
});

export const bookingAgentShowsAction = (bookingAgentShows) => ({
  type: ActionTypes.BOOKING_AGENT_SHOWS,
  bookingAgentShows,
});

export const baGoalsAction = (baGoals) => ({
  type: ActionTypes.BOOKING_AGENT_GOALS,
  baGoals,
});

export const baIncomeAction = (baIncome) => ({
  type: ActionTypes.BOOKING_AGENT_INCOME,
  baIncome,
});

export const baListAction = (baList) => ({
  type: ActionTypes.BOOKING_AGENT_LIST,
  baList,
});

export const memberDetailsAction = (memberDetails) => ({
  type: ActionTypes.MEMBER_DETAILS,
  memberDetails,
});

export const updateArtistErrorsAction = (errorDetails) => ({
  type: ActionTypes.ARTIST_DETAILS_ERRORS,
  errorDetails,
});

export const createShowAction = (showDetails) => ({
  type: ActionTypes.CREATE_SHOW,
  showDetails,
});

export const resetShowAction = (showDetails = "") => ({
  type: ActionTypes.RESET_SHOW,
  showDetails,
});

export const createShowErrorsAction = (errorDetails) => ({
  type: ActionTypes.CREATE_SHOW_ERRORS,
  errorDetails,
});

export const updateShowAction = (showDetails) => ({
  type: ActionTypes.UPDATE_SHOW_DETAILS,
  showDetails,
});

export const updateShowErrorsAction = (errorDetails) => ({
  type: ActionTypes.UPDATE_SHOW_ERRORS,
  errorDetails,
});

export const updateUserShowsAction = (userShowsList) => ({
  type: ActionTypes.UPDATE_USER_SHOWS,
  userShowsList,
});
export const updatHomepageShowsAction = (homepageShowsList) => ({
  type: ActionTypes.UPDATE_HOMEPAGE_SHOWS,
  homepageShowsList,
});

export const loadContriesAction = (countriesList) => ({
  type: ActionTypes.LOAD_COUNTRIES,
  countriesList,
});

export const loadStatesAction = (statesList) => ({
  type: ActionTypes.LOAD_STATES,
  statesList,
});

export const ticketDetailsAction = (ticketDetails) => ({
  type: ActionTypes.PURCHASE_TICKET,
  ticketDetails,
});

export const ticketDetailsErrorsAction = (errorDetails) => ({
  type: ActionTypes.PURCHASE_TICKET_ERRORS,
  errorDetails,
});

export const baScheduleEventsAction = (scheduleeventsDetails) => ({
  type: ActionTypes.BA_SCHEDULE_EVENTS,
  scheduleeventsDetails,
});

export const adminLoadTicketsPerShowAction = (shows) => ({
  type: ActionTypes.ADMIN_LOAD_TICKETS_PER_SHOW,
  shows,
});
