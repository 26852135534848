import React from "react";
import { Card, CardContent, Typography, useTheme } from "@mui/material";

const SummaryCard = ({ children, value, category, alreadyHappened }) => {
  const theme = useTheme();
  const { pinkShadow } = theme.palette.layout.boxShadows;
  const styles = {
    wrapper: {
      height: "103px",
      borderRadius: "10px",
      marginBottom: "5%",
      opacity: alreadyHappened ? ".45" : "1",
      boxShadow: pinkShadow,
    },
    content: {
      padding: 0,
      margin: 0,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
    },
    value: {
      fontSize: "24px",
      fontFamily: "titleFont, sans-serif",
      color: "#1A1A4A",
      textAlign: "center",
    },
    upcoming: {
      fontSize: "18px",
      fontFamily: "titleFont, sans-serif",
      color: "blue",
      textAlign: "left",
    },
    children: {
      fontSize: "20px",
      fontFamily: "titleFont, sans-serif",
      color: "#1A1A4A",
      textAlign: "center",
    },
    category: {
      fontSize: "14px",
      fontWeight: "700",
      fontFamily: "bodyFont, sans-serif",
      color: "#1A1A4A",
      textAlign: "center",
    },
  };

  return (
    <Card elevation={2} sx={styles.wrapper}>
      <CardContent style={styles.content}>
        <Typography variant="h3" sx={styles.value}>
          {value}
        </Typography>
        <Typography variant="h5" sx={styles.children}>
          {children}
        </Typography>
        <Typography variant="subtitle1" sx={styles.category}>
          {category}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default SummaryCard;
