import React from 'react'

const AboutUs = () => {
    return (
        <div className="container">
            <h2>About Us</h2>
            <p>
                Teder was created in the midst of the covid-19 pandemic by a group of passionate artists and industry veterans who saw the suffering of the music industry and decided to do something about it.
            </p>            
            <p>
                We came together for a purpose of helping the artists and the industry professionals who were hit hard with the complete stop of music festivals, music venues, concerts and tours. We believe music is what brings us together, what makes us human and connect to one another even in times of social distancing and quarantine. 
            </p>            
            <p>
                We know music has value and as musicians ourselves we believe artists should be able to support themselves and get paid for doing what they love. That’s why Teder is here, to make a change in how music is consumed, to reinvent home entertainment, and assist with the recovery of the music industry. 
            </p>            
            <p>
                A complete ticketed live streaming platform that give the artists full control to schedule their shows, promote, perform, get discovered and make a living in today’s world.
            </p>            
            <p>
                A real online venue that has no geographical borders and no ticket capacity</p>
            <p>
                We hope you will find this tool helpful and connect to our vision during and post covid-19. We made it for you, and for us all.
            </p>
            <p>
                The Teder team.
            </p>
        </div>
    )
}

export default AboutUs
