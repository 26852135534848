import React from "react";
import { Link } from "react-router-dom";
const Story = () => {
  return (
    <div className="story-container">
      <div className="row">
        <div className="col-lg-7 story-left"></div>
        <div className="col-lg story-right">
          <div className="story-right-text">

            <div className="story-header">How it works</div><br/>
              <div className="story-list-section">
                <ul className="story-list">
                  <li className="story-list-item">Create a profile</li>
                  <li className="story-list-item">Schedule a show with dates, times and details</li>
                  <li className="story-list-item">Set ticket prices-starting at $3</li>
                  <li className="story-list-item">Perform and get paid </li>
                </ul>
              </div>
              <br/><br/>
              <div className="story-header">Artists are in control</div><br/>
              <div className="story-list-section">
                <ul className="story-list">
                  <li className="story-list-item">You set the ticket price</li>
                  <li className="story-list-item">You get 70% of your show's net profits. Teder gets 30%</li>
                  <li className="story-list-item">You get paid within 5 business days via direct deposit</li>
                </ul>
              </div>
              <br/>
              <div className="story-subtitle">With Teder, the more you promote your shows, <br/> the bigger the opportunity!</div>
            <div className="story-btn-placement">
                <Link to="/signup/1" className="story-btn">
                  <span className="story-btn-text">artist sign-up</span>
                </Link>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Story;
