import React from "react";
import { Container, Box, useTheme, Typography } from "@mui/material";
import noArtists from "../assets/noArtists.svg";

export const ArtistsNoDataPage = ({ message }) => {
  const theme = useTheme();
  const { darkRed } = theme.palette.tederColors;
  const mediaQuery = theme.breakpoints.down("sm");
  const styles = {
    wrapper: {
      position: "relative",
      height: "100vh",
      overflow: "hidden",
      width: "100vw",
    },
    image: {
      position: "absolute",
      left: "-5%",
      top: "5%",
      zIndex: 3,
      [mediaQuery]: {
        top: "25%",
        left: "-65%",
      },
    },
    title: {
      fontFamily: "titlefont",
      color: darkRed,
      marginLeft: "5%",
      [mediaQuery]: {
        marginTop: "10%",
      },
    },
  };
  return (
    <Container sx={styles.wrapper}>
      <Typography variant="h5" sx={styles.title}>
        {message}
      </Typography>
      <Box component="img" src={noArtists} sx={styles.image} />
    </Container>
  );
};
