import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { loadContriesAction } from "../../redux/actions";
import { show_country_list } from "../../Common/data";
import { push } from "connected-react-router";
import emailjs from 'emailjs-com';
import ReCAPTCHA from "react-google-recaptcha";


const Affiliate = () => {
    let Scroll = require('react-scroll');
    let scroller = Scroll.scroller;
    let Element = Scroll.Element;
    const recaptchaRef = React.createRef();

    const currentState = useSelector((state) => state.TederReducerState);
    const dispatch = useDispatch(); //to update our redux state

    const inputClassName = "form-control teder-form-input-field form-input";
    const [showApply, setShowApply] = useState(false);
    const [showErrors, setShowErrors] = useState(false);
    const [applyForm, setApplyForm] = useState({
        firstName: "",
        lastName: "",
        // address: "",
        // city: "",
        // state: "",
        // zipCode: "",
        country: "United States of America",
        email: "",
        phoneNo: "",
        profession: "",
        message: "",
    });

    // const stateError = () => {
    //     if ((applyForm.state === "Select State" || applyForm.state === "") && applyForm.country === "United States of America"){
    //         return <small className="small-green-text">*Please select state</small>;
    //     } else {
    //         return ""
    //     }
    // }

    const emailError = () => {
        if (!applyForm.email) {
            return <small className="small-green-text">*Required field.</small>;
        } else if (!/\S+@\S+\.\S+/.test(applyForm.email)) {
            return <small className="small-green-text">*Email address is invalid</small>;
        } else {
            return ""
        }
    }

    if (currentState.countries.length === 0) {
        console.log("loading countries to state");
        const get_country_list = async () => {
            const response = await show_country_list.then((result) => {
                return JSON.parse(result.countryList);
            });
            dispatch(loadContriesAction(response));
        };
        get_country_list();
    }

    // const handlePhone = value => {
    //     const registrationDetails = { ...applyForm, 'phoneNo': value.formattedValue };
    //     setApplyForm(registrationDetails);
    // }

    const formatPhoneNumber = (e) => {
        let formattedNumber;

        const { name, value } = e.target;
        const { length } = value;

        // Filter non numbers
        const regex = () => value.replace(/[^0-9\.]+/g, ""); /* eslint-disable-line */
        // Set area code with parenthesis around it
        const areaCode = () => `(${regex().slice(0, 3)})`;
        // Set formatting for first six digits
        const firstSix = () => `${areaCode()} ${regex().slice(3, 6)}`;
        // Dynamic trail as user types
        const trailer = (start) => `${regex().slice(start, regex().length)}`;

        if (length < 3) {
            // First 3 digits
            formattedNumber = regex();
        } else if (length === 4) {
            // After area code
            formattedNumber = `${areaCode()} ${trailer(3)}`;
        } else if (length === 5) {
            // When deleting digits inside parenthesis
            formattedNumber = `${areaCode().replace(")", "")}`;
        } else if (length > 5 && length < 9) {
            // Before dash
            formattedNumber = `${areaCode()} ${trailer(3)}`;
        } else if (length >= 10) {
            // After dash
            formattedNumber = `${firstSix()}-${trailer(6)}`;
        }

        const formattedObject = {
            target: { name: name, value: formattedNumber },
        };

        handleChange(formattedObject);
    };

    const phoneError = () => {
        if (!applyForm.phoneNo || applyForm.phoneNo.length === 0) {
            return <small className="teder-form-error">*Required field</small>;
        } else if (applyForm.phoneNo && applyForm.phoneNo.replace(/[^0-9\.]+/g, "").length < 10) { /* eslint-disable-line */
            return <small className="teder-form-error">*Enter a valid phone number</small>;
        } else {
            return ""
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        const registrationDetails = {
            ...applyForm,
            [name]: value,
        };

        setApplyForm(registrationDetails);
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        const recaptchaValue = recaptchaRef.current.getValue();
        if (recaptchaValue === undefined || recaptchaValue === "") {
            console.log("Error")
            alert("Please Validate you are not a robot with Google Captcha.")
        }
        else {
            if (
                applyForm.firstName === '' ||
                applyForm.lastName === '' ||
                // applyForm.address === '' ||
                // applyForm.city === '' ||
                // applyForm.state === '' ||
                // applyForm.zipCode === '' ||
                applyForm.country === '' ||
                emailError() !== '' ||
                applyForm.profession === '' ||
                applyForm.message === ''
            ) {
                setShowErrors(true);
                scroller.scrollTo('apply', { smooth: true, })
            } else {
                console.log(applyForm);
                sendEmail(e);
            }
        }
    };

    const sendEmail = (e) => {
        emailjs.sendForm('service_afeb34f', 'template_32wcc84', e.target, 'user_H45uHmiuGyyL79t5gcmsa')
            .then((result) => {
                console.log(result.text);
                console.log("yessss");
                // console.log(e.target)
                alert("Thank you, we'll be in touch with you shortly!")
                dispatch(push('/'))
            }, (error) => {
                console.log(error.text);
            });
    }

    const renderApplyForm = () => (
        <Element name="apply">
            <br /><br />
            <div className="container teder-form-contatiner">
                <form className="form" onSubmit={handleSubmit}>
                    <h2>Become an Affiliate</h2>
                    <div className="form-row">
                        <div className="form-group col-lg">
                            <label htmlFor="firstName" className="teder-form-label">FIRST NAME</label>
                            <input
                                id="firstName"
                                type="text"
                                name="firstName"
                                className={inputClassName}
                                placeholder="First Name"
                                value={applyForm.firstName}
                                onChange={handleChange}
                            />
                            {showErrors && applyForm.firstName === "" && <small className="small-green-text">*Required field</small>}
                        </div>
                        <div className="form-group col-lg">
                            <label htmlFor="lastName" className="teder-form-label">LAST NAME</label>
                            <input
                                id="lastName"
                                type="text"
                                name="lastName"
                                className={inputClassName}
                                placeholder="Last Name"
                                value={applyForm.lastName}
                                onChange={handleChange}
                            />
                            {showErrors && applyForm.lastName === "" && <small className="small-green-text">*Required field</small>}
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="address" className="teder-form-label">Profession</label>
                        <input
                            id="profession"
                            type="text"
                            name="profession"
                            className={inputClassName}
                            placeholder="Profession"
                            value={applyForm.profession}
                            onChange={handleChange}
                        />
                        {showErrors && applyForm.profession === "" && <small className="small-green-text">*Required field</small>}
                    </div>
                    <div className="form-group form-inputs">
                        <label htmlFor="email" className="teder-form-label">EMAIL</label>
                        <input
                            id="email"
                            type="email"
                            name="email"
                            className={inputClassName}
                            placeholder="Email"
                            value={applyForm.email}
                            onChange={handleChange}
                        />
                        {showErrors && emailError()}
                    </div>
                    <div className="form-group">
                        <label htmlFor="phoneNo" className="teder-form-label">PHONE NUMBER</label>
                        <input
                            id="phoneNo"
                            type="tel"
                            name="phoneNo"
                            className={inputClassName}
                            placeholder="Phone Number"
                            value={applyForm.phoneNo}
                            maxLength={14}
                            required
                            onChange={formatPhoneNumber}
                        />
                        {showErrors && phoneError()}
                    </div>
                    {/* <div className="form-group">
                        <label htmlFor="phoneNo" className="teder-form-label">PHONE NUMBER</label>
                        <NumberFormat type="tel"  id="phoneNo" name="phoneNo"  format="(###) ###-####" mask="_" className={inputClassName} placeholder="Phone Number"  onValueChange={handlePhone} required />
                    </div> */}
                    {/* <div className="form-group">
                        <label htmlFor="address" className="teder-form-label">ADDRESS</label>
                        <input
                            id="address"
                            type="text"
                            name="address"
                            className={inputClassName}
                            placeholder="Address"
                            value={applyForm.address}
                            onChange={handleChange}
                        />
                        {showErrors && applyForm.address === "" && <small className="small-green-text">*Required field</small>}
                    </div>
                    <div className="form-row">
                        <div className="form-group col-lg">
                            <label htmlFor="city" className="teder-form-label">CITY</label>
                            <input
                                id="city"
                                type="text"
                                name="city"
                                className={inputClassName}
                                placeholder="City"
                                value={applyForm.city}
                                onChange={handleChange}
                            />
                            {showErrors && applyForm.city === "" && <small className="small-green-text">*Required field</small>}
                        </div>
                        <div className="form-group col-lg-3">
                            <label
                                htmlFor="state"
                                className={ applyForm.country !== "United States of America" ? "state-label-disabled": "teder-form-label" }
                            >
                                STATE
                            </label>
                            {applyForm.country !==
                            "United States of America" ? (
                                <input
                                    id="state"
                                    className="form-control artist-textbox-style teder-select"
                                    name="state"
                                    type="text"
                                    disabled
                                    value="Select State"
                                />
                            ) : (
                                <select
                                    id={ applyForm.state === "Select State" || applyForm.state === "" ? "state-placeholder" : "state" }
                                    className="form-control artist-textbox-style teder-select"
                                    name="state"
                                    value={applyForm.state}
                                    onChange={handleChange}
                                >
                                    <option>Select State</option>
                                    <option>AK</option>
                                    <option>AL</option>
                                    <option>AR</option>
                                    <option>AZ</option>
                                    <option>CA</option>
                                    <option>CO</option>
                                    <option>CT</option>
                                    <option>DE</option>
                                    <option>FL</option>
                                    <option>GA</option>
                                    <option>HI</option>
                                    <option>IA</option>
                                    <option>ID</option>
                                    <option>IL</option>
                                    <option>IN</option>
                                    <option>KS</option>
                                    <option>KY</option>
                                    <option>LA</option>
                                    <option>MA</option>
                                    <option>MD</option>
                                    <option>ME</option>
                                    <option>MI</option>
                                    <option>MN</option>
                                    <option>MO</option>
                                    <option>MS</option>
                                    <option>MT</option>
                                    <option>NC</option>
                                    <option>ND</option>
                                    <option>NE</option>
                                    <option>NH</option>
                                    <option>NJ</option>
                                    <option>NM</option>
                                    <option>NV</option>
                                    <option>NY</option>
                                    <option>OH</option>
                                    <option>OK</option>
                                    <option>OR</option>
                                    <option>PA</option>
                                    <option>RI</option>
                                    <option>SC</option>
                                    <option>SD</option>
                                    <option>TN</option>
                                    <option>TX</option>
                                    <option>UT</option>
                                    <option>VA</option>
                                    <option>VT</option>
                                    <option>WA</option>
                                    <option>WI</option>
                                    <option>WV</option>
                                    <option>WY</option>
                                </select>
                            )}
                            {showErrors && stateError()}
                        </div>
                        <div className="form-group col-lg-3">
                            <label htmlFor="zipCode" className="teder-form-label">ZIP CODE</label>
                            <input
                                id="zipCode"
                                type="tel"
                                name="zipCode"
                                maxLength={5}
                                className={inputClassName}
                                placeholder="Zip Code"
                                value={applyForm.zipCode}
                                onChange={handleChange}
                            />
                            {showErrors && applyForm.zipCode === "" && <small className="small-green-text">*Required field</small>}
                        </div>
                    </div> */}
                    <div className="form-group">
                        <label className="teder-form-label">COUNTRY</label>
                        <select
                            id="country"
                            className={"form-control artist-textbox-style teder-select"}
                            name="country"
                            onChange={handleChange}
                            value={applyForm.country}
                        >
                            {currentState.countries.map((element) => (
                                <option key={element.ID}> {element.CountryName} </option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="message" className="teder-form-label">Message</label><br />
                        <textarea
                            id="message"
                            type="text"
                            name="message"
                            className={inputClassName}
                            placeholder="Tell us about yourself and music background."
                            value={applyForm.message}
                            onChange={handleChange}
                        />
                        {showErrors && applyForm.message === "" && <small className="small-green-text">*Required field</small>}
                    </div>
                    <div className="d-flex justify-content-center">
                        <ReCAPTCHA ref={recaptchaRef} sitekey="6LdnocIZAAAAAOmOkiSnG5kq2fEMJ1b-1Wbj3AKu" />
                    </div>
                    <br />
                    <div className="text-center">
                        <button className="red-teder-btn" type="submit">Submit</button>
                    </div>
                    <br /><br /><br />
                </form>
            </div>
        </Element>
    )

    return (
        <>
            <div className="container">
                <h2>Affiliate program</h2>
                <p>
                    As part of our mission to help not just artists but also the industry professionals who were heavily affected by covid-19, we created the Teder Affiliate Program.
                </p>
                <p>
                    Our goal is to a provide a window of opportunity to all the industry people who worked for years in the music and entertainment industry, and to assist them to monetize the vast network of connections they’ve built over the years.
                </p>
                <p>
                    We are offering 3% on all net tickets sales for any artist who was referred to the platform by an approved affiliate for a period of 6 months. The funds will be paid at the same time the artists get paid within 5 business days. The more artists the affiliate bring, the more shows they perform, the more tickets they sell, the more money the affiliate will make.
                </p>
                <p>
                    We believe this will help the sound people, the production personal, the lighting teams, and so on to pivot into an online booking agent role and to utilize their existing network to connect artists to Teder and make an extra income from home in these tough times.
                </p><br />
                <div className="text-center">
                    <button className="green-teder-btn" onClick={() => setShowApply(true)}>Click to apply</button>
                </div>
            </div>
            {showApply && renderApplyForm()}
        </>
    )
}

export default Affiliate
