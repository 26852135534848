import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./css/SideNavBar.css";
import "./css/BADashboard.css";

import { useDispatch, useSelector } from "react-redux";
import { artistDetailsAction } from "../../redux/actions";
import { get_artist_details } from "../../Common/data";

//Material UI Components
import { Typography, Box, useMediaQuery, useTheme, Fade } from "@mui/material";
import { keyframes } from "@mui/system";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import logo from "../../Visuals/Teder_Logo_Transparent.png";

//Custom Components
import ArtistPage from "../../Containers/ArtistPage";
import SchedShowForm from "../ShowPages/Schedule/SchedShowForm";
import BillingPage from "../../Containers/BillingPage";
import EditProfile from "../ArtistPages/EditProfile/EditProfile";
import LogoutMenu from "./SharedComponents/Logout";

//Constants
import { ARTIST_DASHBOARD_ROUTES } from "../../util/constants";
import { ARTIST_DASHBOARD_LINKS } from "../../util/constants";

const ArtistProfileDashboard = () => {
  //State

  const currentState = useSelector((state) => state.TederReducerState);
  const dispatch = useDispatch();
  const [showSideBar, setShowSideBar] = useState(false);
  const [route, setRoute] = useState(ARTIST_DASHBOARD_ROUTES.Home);
  const [selected, setSelected] = useState(0);
  const [artistDetails, setArtistDetails] = useState([]);

  //Route Definitions
  const { Home, ScheduleShow, TicketSales, EditAccount } =
    ARTIST_DASHBOARD_ROUTES;

  //MUI Styling
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const mediaQueryPhone = theme.breakpoints.down("sm");
  const { darkPurple, darkRed } = theme.palette.tederColors;

  const slideOpen = keyframes`
    100% {
    left: 0;
  }
`;

  const styles = {
    links: {
      fontFamily: "inherit",
      color: "#FFFFFF",
      padding: "10px 15px",
      minWidth: "174px",
      minHeight: "43px",
      margin: "6%",
      borderRadius: "15px",
      cursor: "pointer",
    },
    topBar: {
      height: "8.5%",
      width: "100%",
      background: darkPurple,
      display: "flex",
      padding: "25px",
      textAlign: "left",
      filter: "drop-shadow(0px 4px 10px rgba(219, 53, 84, 0.08))",
      position: "fixed",
      top: "0",
      right: "0",
      zIndex: "10",
      transition: ".5s ease-out",
      [mediaQueryPhone]: {
        height: "5%",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
      },
    },
    topBarText: {
      color: "white",
      fontFamily: "titleFont, sans-serif",
      fontSize: "16px",
      margin: "0",
      [mediaQueryPhone]: {
        marginRight: "30%",
      },
    },
    sidebarWrapper: {
      position: "fixed",
      top: "0",
      height: "100vh",
      width: "17%",
      [mediaQueryPhone]: {
        display: "none",
      },
    },
    sideBarWrapperHidden: {
      height: "102vh",
      paddingLeft: "20px",
      color: "white",
      width: "400px",
      maxWidth: "90vw",
      position: "fixed",
      zIndex: "200",
      top: "0",
      left: "-100%",
      animation: `${slideOpen} 0.75s forwards`,
      boxShadow: "0 0 5px 10px rgba(0, 0, 0, 0.3)",
    },
    sideBar: {
      background: darkPurple,
      display: "flex",
      height: "100%",
      width: "100%",
      flexWrap: "wrap",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    rightSide: {
      display: "flex",
      flexDirection: "column",
      width: "100vw",
      [mediaQueryPhone]: {
        margin: "0 auto",
      },
    },
    linkWrapper: {
      display: "flex",
      textAlign: "center",

      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-around",
      width: "100%",
    },
    sideLinkWrapper: {
      boxSizing: "border-box",
      margin: "0 auto",
      display: "flex",
      textAlign: "center",
      alignItems: "center",
      flexDirection: "column",
      paddingRight: "8%",
      justifyContent: "space-between",
    },

    logo: {
      width: "98px",
      height: "98px",
    },
    showLessIcon: {
      margin: "5%",
      fontSize: "large",
    },
    showLessWrapper: {
      display: "flex",
      width: "100%",
      height: "auto",
      justifyContent: "flex-end",
    },
    linkButton: {
      cursor: "pointer",
    },
  };

  // fetching artist details
  useEffect(() => {
    get_artist_details()
      .then((res) => {
        localStorage.setItem(
          "AuthProviderId_artist",
          res.artist_details[0].AuthProviderId
        );
        const artistDetails = {
          ...currentState.artistDetails,
          authProviderId: res.artist_details[0].AuthProviderId,
          authProvider: res.artist_details[0].AuthProvider,
          authID: res.artist_details[0].AuthID,
          artistId: res.artist_details[0].ArtistID,
          firstName: res.artist_details[0].FirstName,
          lastName: res.artist_details[0].LastName,
          photoPath: res.artist_details[0].PhotoPath,
          address: res.artist_details[0].Address,
          city: res.artist_details[0].City,
          email: res.artist_details[0].Email,
          state: res.artist_details[0].State,
          country: res.artist_details[0].Country,
          zipCode: res.artist_details[0].ZipCode,
          phoneNo: res.artist_details[0].PhoneNo,
          bankName: res.artist_details[0].BankName,
          routingNo: res.artist_details[0].RoutingNo,
          accID: res.artist_details[0].AccID,
          accNo: res.artist_details[0].AccNo,
          stageName: res.artist_details[0].StageName,
          genres: res.artist_details[0].Genres,
          bio: res.artist_details[0].DescriptionBIO,
          website: res.artist_details[0].Website,
          SChandle: res.artist_details[0].SoundcloudHandle,
          IGhandle: res.artist_details[0].InstagramHandle,
          YThandle: res.artist_details[0].YoutubeHandle,
          TThandle: res.artist_details[0].TikTokHandle,
          referralCode: res.artist_details[0].ReferralCode,
          userType: res.artist_details[0].UserType,
        };

        dispatch(artistDetailsAction(artistDetails));

        setArtistDetails(artistDetails);
        return artistDetails;
      })
      .catch((err) => console.error(err));
    dispatch({ type: "CLEAR_SHOW_DETAILS" });
  }, []);

  const toggleSidebar = () => setShowSideBar(!showSideBar);

  const renderPage = () => {
    switch (route) {
      case Home:
        return <ArtistPage artistDetails={artistDetails} />;
      case ScheduleShow:
        return <SchedShowForm />;
      case TicketSales:
        return <BillingPage />;
      case EditAccount:
        return <EditProfile />;
      default:
        return <ArtistPage />;
    }
  };

  const renderTopLinks = () =>
    ARTIST_DASHBOARD_LINKS.map((link, i) => {
      return (
        <Box
          key={link.id}
          component="div"
          style={styles.linkButton}
          onClick={() => {
            setRoute(link.name);
            setSelected(link.id);
          }}
        >
          <Typography
            style={{
              ...styles.links,
              background: selected === i ? darkRed : "none",
              border: selected === i ? "none" : "solid 1px white",
            }}
            children={link.page}
          />
        </Box>
      );
    });

  const renderSideLinks = () =>
    ARTIST_DASHBOARD_LINKS.map((link, i) => {
      return (
        <Box
          key={link.id}
          component="div"
          style={styles.linkButton}
          onClick={() => {
            setRoute(link.name);
            setSelected(link.id);
            setShowSideBar(false);
          }}
        >
          <Typography
            style={{
              ...styles.links,
              width: "280px",
              textAlign: "center",
              background: selected === i ? darkRed : "none",
              border: selected === i ? "none" : "solid 1px white",
            }}
            children={link.page}
          />
        </Box>
      );
    });

  return (
    <div className="dashboard-container">
      <div className="dashboard-content artist">
        <Box
          component="div"
          sx={showSideBar ? styles.sideBarWrapperHidden : styles.sidebarWrapper}
        >
          <Box
            component="div"
            sx={{ ...styles.sideBar, display: showSideBar ? "block" : "none" }}
          >
            {matches && (
              <Box
                component="div"
                onClick={toggleSidebar}
                sx={styles.showLessWrapper}
              >
                <ExpandLessIcon fontSize="large" />
              </Box>
            )}
            <Box component="div" style={styles.sideLinkWrapper}>
              <Link to="/">
                <Box
                  component="img"
                  src={logo}
                  alt="Teder"
                  loading="lazy"
                  style={{ ...styles.logo, marginLeft: "10%" }}
                />
              </Link>
              {renderSideLinks()}
            </Box>
          </Box>
        </Box>
        <Box component="div" sx={styles.rightSide}>
          <Box component="div" sx={styles.topBar}>
            <Box onClick={toggleSidebar}>
              <MenuIcon
                style={{
                  color: "white",
                  display: matches ? "block" : "none",
                }}
              />
            </Box>

            <Fade in={!matches} timeout={2000}>
              <Box component="div" style={styles.linkWrapper}>
                {!matches && (
                  <Link to="/">
                    <Box
                      component="img"
                      src={logo}
                      alt="Teder"
                      loading="lazy"
                      style={{ ...styles.logo, order: "1" }}
                    />
                  </Link>
                )}
                {renderTopLinks()}
                <LogoutMenu artist>
                  <Typography
                    sx={{
                      ...styles.links,
                      width: "170px",
                      textAlign: "center",
                      border: "solid 1px white",
                    }}
                  >
                    Log out
                  </Typography>
                </LogoutMenu>
              </Box>
            </Fade>
          </Box>
          {renderPage()}
        </Box>
      </div>
    </div>
  );
};

export default ArtistProfileDashboard;
