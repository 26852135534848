import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import ScrollToTop from "./Context/ScrollToTop";
import { TederThemeProvider } from "./Theme.js";
import { history } from "./redux/store";
import { store, persistor } from "./redux/store";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { PersistGate } from "redux-persist/integration/react";

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <ScrollToTop />
        <TederThemeProvider>
          <App history={history} />
        </TederThemeProvider>
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
