import React, { useEffect } from "react";
import "../Components/HomePage/homepage.css";
import Landing from "../Components/HomePage/Landing";
import Story from "../Components/HomePage/Story";
import Shows from "../Components/HomePage/Shows";
import TopNavBar from "../Components/Navigation/TopNavBar";
import { Typography, Box, useTheme } from "@mui/material";
import { Link } from "react-router-dom";

const HomePage = () => {
  let Scroll = require("react-scroll");
  let Element = Scroll.Element;
  const theme = useTheme();
  const { green01 } = theme.palette.tederColors;

  const styles = {
    textWrapper: {
      marginTop: "7%",
      display: "flex",
      justifyContent: "center",
    },
    shows: {
      fontSize: "1.4em",
      color: green01,
      textTransform: "uppercase",
      fontFamily: "titleFont",
      letterSpacing: "2px",
      alignSelf: "center",
      "&:hover": {
        color: "white",
      },
    },
  };

  return (
    <div id="home-page">
      <TopNavBar />
      <div id="landing">
        <Landing />
      </div>
      <Element name="shows">
        <div id="shows">
          <Shows />
          <Box sx={styles.textWrapper}>
            {/* <Link to="explore-shows" style={{ textDecoration: "none" }}>
              <Typography variant="p" sx={styles.shows}>
                Explore Shows
              </Typography>
            </Link> */}
          </Box>
        </div>
      </Element>
      <Element name="story">
        <div id="story">
          <Story />
        </div>
      </Element>
    </div>
  );
};

export default HomePage;
