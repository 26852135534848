import { createStore, applyMiddleware } from "redux";
import { createHashHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import createRootReducer from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension";
import storage from "redux-persist/lib/storage";
import {
  persistStore,
  persistReducer,
} from 'redux-persist';
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import logger from "redux-logger";

export const history = createHashHistory({
  hashType: "slash",
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["TederReducerState"],
  debug: true,
  stateReconciler: autoMergeLevel2
};

const persistedReducer = persistReducer(
  persistConfig,
  createRootReducer(history)
);

const connectedRouterMiddleware = routerMiddleware(history);
const middlewares = [connectedRouterMiddleware, logger];

export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(...middlewares))
);
export const persistor = persistStore(store);

const ex = { store, persistor };

export default ex;
