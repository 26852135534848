import React from "react";
import { Card, CardContent, Typography, useTheme, Box } from "@mui/material";

const DisplayAdminTickets = ({
  children,
  value,
  category,
  alreadyHappened,
  handleShowMore,
  handleAddToTicketDisplay,
  handleRef,
  show,
  index,
}) => {
  const theme = useTheme();
  const { pinkShadow } = theme.palette.layout.boxShadows;
  const { darkPurple } = theme.palette.tederColors;
  const styles = {
    wrapper: {
      position: "relative",
      minHeight: "200px",
      textAlign: "left",
      width: "auto",
      borderRadius: "10px",
      marginBottom: "5%",
      boxShadow: pinkShadow,
      border: "solid 1px grey",
      // background: alreadyHappened ? "#D0D0D0" : "white",
      background: "none",
    },
    content: {
      textAlign: "justify",
      padding: 0,
      margin: "3%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      height: "100%",
    },
    value: {
      fontSize: "24px",
      fontFamily: "titleFont, sans-serif",
      color: "#1A1A4A",
      textAlign: "center",
    },
    upcoming: {
      fontSize: "18px",
      fontFamily: "titleFont, sans-serif",
      color: "blue",
      alignSelf: "flex-start",
    },
    children: {
      fontSize: "20px",
      fontFamily: "titleFont, sans-serif",
      // color: "#1A1A4A",
      color: "white",
      textAlign: "center",
    },
    category: {
      fontSize: "14px",
      fontWeight: "700",
      fontFamily: "bodyFont, sans-serif",
      color: "#1A1A4A",
      textAlign: "center",
    },
    button: {
      position: "absolute",
      right: 10,
      top: -35,
      width: "130px",
      alignSelf: "flex-end",
      background: darkPurple,
      color: "white",
    },
  };

  return (
    <Card elevation={3} sx={styles.wrapper}>
      <CardContent style={styles.content}>
        {!alreadyHappened && (
          <Typography variant="p" sx={styles.upcoming}>
            Upcoming
          </Typography>
        )}
        <button
          style={styles.button}
          onClick={(e) => {
            handleShowMore(show.showID);
            handleRef(index, show.showID, show.showID);
          }}
        >
          {`SHOW ${show.showMore ? "LESS" : "MORE"}`}
        </button>

        <Box component="div" sx={styles.children}>
          {children}
        </Box>
        <Typography variant="subtitle1" sx={styles.category}>
          {category}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default DisplayAdminTickets;
