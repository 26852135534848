import { React, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Route, useHistory } from "react-router-dom";
import { logoutUserAction } from "./redux/actions";
import { refresh_access_token, artist_logout } from "./Common/data.js";
import { AuthRoles } from "./Common/global";
import { DateTime } from "luxon";
import { UserType } from "./util/constants";

const ProtectedRoute = (props) => {
  const history = useHistory();
  const currentState = useSelector((state) => state.TederReducerState);
  const [tokenIsExpired, setTokenIsExpired] = useState(false);
  const dispatch = useDispatch();

  function checkIfTokenExpired(date) {
    const HOUR = 3600;
    const anHourAgo = Date.now() - HOUR;
    return date >= anHourAgo;
  }

  useEffect(() => {
    const controller = new AbortController();
    //Get Refresh Token
    let tokenRefreshPayload;

    if (currentState.userContext.userType === UserType.Artist) {
      tokenRefreshPayload = {
        username: localStorage.getItem("AuthProviderId_artist"),
        refresh_token: localStorage.getItem("refresh_token_artist"),
      };
    } else if (currentState.userContext.userType === UserType.BookingAgent) {
      tokenRefreshPayload = {
        username: localStorage.getItem("AuthProviderId_ba"),
        refresh_token: localStorage.getItem("refresh_token_ba"),
      };
    }
    setTokenIsExpired(
      checkIfTokenExpired(currentState.userContext.timeLoggedIn)
    );

    // if (tokenIsExpired) {
    refresh_access_token(tokenRefreshPayload)
      .then((res) => {
        localStorage.setItem("id_token", res.id_token);
        localStorage.setItem("expires", res.expires_in);
      })
      .catch((error) => {
        console.error(error);
      });
    // }
    return () => controller.abort();
  }, []);

  const handleLogout = () => {
    artist_logout(currentState.userContext.userID);
    let tederLogoutDetails = {
      userID: "",
      userFirstName: "",
      userLastName: "",
      userEmail: "",
      pendingShows: 0,
      userLoggedIn: false,
      userType: AuthRoles.ANONYMOUS,
    };
    dispatch(logoutUserAction(tederLogoutDetails));
  };

  let authenticated = true;
  // if (checkIfTokenExpired()) {
  //   authenticated = false;
  // }
  if (!currentState.userContext.userLoggedIn) authenticated = false;
  // if user not authenticated
  // log out & redirect to login page
  if (!authenticated) {
    return window.setTimeout(() => {
      handleLogout();
      history.push(`/login?goto=${props.location.pathname}`);
    }, 1);
  }

  return <Route {...props} />;
};

export default ProtectedRoute;
