export default function validateStepTwo(values) {
    let errors = {}

    if(!values.bankName){
        errors.bankName = "*Required field."
    }else
        errors.bankName = ""

    if(!values.routingNo){
        errors.routingNo = "*Required field."
    }else
        errors.routingNo = ""

    if(!values.accountNo){
        errors.accountNo = "*Required field."
    }else
        errors.accountNo = ""
    
    if(!values.accNo){
        errors.accNo = "*Required field."
    }else
        errors.accNo = ""

    if((!values.accNo && values.accNo === '') || 
        (!values.confirmAccountNo && values.confirmAccountNo === '')){
        errors.confirmAccountNo = "*Please confirm account number."
    } else if (values.confirmAccountNo !== (values.accNo || values.accountNo)){
        errors.confirmAccountNo = "*Account numbers do not match."
    }else
        errors.confirmAccountNo = ""

    return errors;
}