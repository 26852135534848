import React, { useEffect, useMemo } from "react";
import { TableRow, TableCell, Typography } from "@mui/material";

import ArtistBlock from "./ArtistBlock";

import { HEADER_POSITION } from "../../../util/constants";
import Config from "../../../Common/config.js";
import NumberFormat from "react-number-format";
import { getMonthAndYearDifference, getTodayDate } from "../../../util/helpers";
import { addSpace } from "../../../util/helpers";

const RowItem = ({ data, headers, rank }) => {
  const styles = {
    tableValue: {
      fontSize: "14px",
      fontFamily: "proximaNova2",
      color: "#1a1a4a",
    },
    tableValueNull: {
      fontFamily: "proximaNova",
      fontSize: "12px",
      color: "#ccc",
    },
    allArtistsText: {
      width: "100%",
      textAlign: "left",
      paddingLeft: "80px",
      fontFamily: "titleFont",
      fontSize: "16px",
      color: "#1a1a4a",
    },
  };

  const getLastShowStyle = (data) => {
    return data.lastShow === "(no passed shows yet)"
      ? styles.tableValueNull
      : styles.tableValue;
  };

  const { ArtistPhotoUrl } = Config();

  //Memoize Expensive Calculation of Date List
  const expensiveCalc = useMemo(
    () => getMonthAndYearDifference(getTodayDate(), data.timeOnTeder),
    [data.timeOnTeder]
  );

  useEffect(() => {
    console.log(
      "get month and year",
      getMonthAndYearDifference(getTodayDate(), data.timeOnTeder)
    );
  }, []);

  return (
    <TableRow key={data.id} style={{ border: "2px solid transparent" }}>
      {headers.map((header, index) => (
        <TableCell
          sx={index === 0 && { paddingTop: 0 }}
          key={index}
          align={HEADER_POSITION[header]}
        >
          {header === "name" ? (
            <ArtistBlock
              rank={rank}
              image={`${ArtistPhotoUrl}${data.photo}`}
              name={data.name}
              genre={addSpace(data.genres)}
              location={data.location}
            />
          ) : header === "totalEarnings" ? (
            <NumberFormat
              value={data.totalEarnings}
              style={styles.tableValue}
              thousandSeparator
              displayType="text"
              prefix="$"
            />
          ) : header === "timeOnTeder" ? (
            <Typography sx={styles.tableValue}>{expensiveCalc}</Typography>
          ) : (
            <Typography
              sx={
                header === "lastShow"
                  ? getLastShowStyle(data)
                  : styles.tableValue
              }
            >
              {data[header]}
            </Typography>
          )}
        </TableCell>
      ))}
    </TableRow>
  );
};

export default RowItem;
