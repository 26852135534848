import React, { useState, useEffect } from "react";
import "./viewShowPage.css";
import { useSelector } from "react-redux";
import { get_stream_info } from "../../../Common/data";
import { useParams } from "react-router-dom";
import { GET_IP_API } from "../../../util/constants";
const ViewShowPage = () => {
  // var player_width = 800
  var player_height = 600;

  var currentState = useSelector((state) => {
    return state.TederReducerState;
  });

  const showId = useParams();
  const [ticket, setTicket] = useState();
  const [videoUrl, setVideoUrl] = useState();
  const [showPlayer, setShowPlayer] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [IPAddress, setIPAddress] = useState();

  useEffect(() => {
    const getIPAddress = async () => {
      const result = await fetch(GET_IP_API);
      const address = await result.json();
      setIPAddress(address.IPv4);
    };
    try {
      getIPAddress();
    } catch (error) {
      console.error("IP error", error);
    }
  }, []);

  // const handleTicket =(e) => {
  //     let { name, value } = e.target;
  //     setTicket(value)
  // }

  const handleJoin = () => {
    // temp if condition with master ticket number, 2nd part is the permanent one
    const get_stream_params = {
      ticket: ticket,
      show_id: showId.id,
      ip_address: IPAddress,
    };

    get_stream_info(get_stream_params)
      .then((result) => {
        if (result.code === "Success") {
          setVideoUrl(result.StreamInfo[0]);
          setShowPlayer(true);
          setErrorMessage("");
        } else if (result.code === "Error") {
          console.log("Error-message");
          setErrorMessage(result.message);
        } else if (!result.code) {
          setErrorMessage("Stream has not started yet!");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const get_video_iframe = () => {
    console.log(videoUrl.split("/")[2]);
    const video_id = videoUrl.split("/")[2];
    return {
      __html:
        '<iframe  title="Teder Live" src="https://player.vimeo.com/video/' +
        video_id +
        '" width="100%" height="' +
        player_height +
        '" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>',
      // '<iframe title="dfsdf" src="https://player.vimeo.com/video/510772444" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen width="600" height="500"></iframe> '
    };
  };

  const getPlayer = () => {
    if (showPlayer) {
      // let video_url = "https://player.vimeo.com/video/474845457"
      // let video_url = "https://player.vimeo.com/external/474845457.hd.mp4?s=6e7c6e2ee6cb7e59080703817aacdaec3d6d0d18&profile_id=174"
      return (
        <>
          <div className="row">
            <h3>Now Showing {currentState.viewShowDetails.showName}</h3>
          </div>
          <div className="clearfix"></div>
          <div className="row" dangerouslySetInnerHTML={get_video_iframe()}>
            {/* <video width="800" height="600" controls>
                        <source src={video_url} type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video> */}
            {/* <iframe title="tederShow" src={videoUrl} width={player_width} height={player_height} frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe> */}

            {/* <iframe title="tederShow" src={video_url} width={player_width} height={player_height} frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe> */}
          </div>
        </>
      );
    } else return <></>;
  };
  const styles = {
    container: {
      margin: "0 auto",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      flexGap: "3%",
      height: "100vh",
    },
  };

  return (
    <div style={styles.container}>
      <h1>Join the concert</h1>
      <br />
      <p>Enter Your Ticket number below</p>

      <input
        className="watch-show-input"
        onBlur={(e) => setTicket(e.target.value)}
        id="ticketNumber"
        type="text"
        placeholder="Ticket number"
      />
      <button onClick={handleJoin} className="watch-show-btn">
        {" "}
        Enter{" "}
      </button>

      <div className="row">
        <small className="small-green-text">{errorMessage}</small>
      </div>
      <div className="row">
        {/* <iframe title="dfsdf" src="https://player.vimeo.com/video/510772444" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen width="600" height="500"></iframe> */}
      </div>
      {getPlayer()}
      <script src="https://player.vimeo.com/api/player.js"></script>
      <script></script>
    </div>
  );
};

export default ViewShowPage;
