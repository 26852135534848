import { DateTime } from "luxon";
import { ValueAxis } from "@devexpress/dx-react-chart-material-ui";
import { WEEKDAYS, THIS_OR_NEXT, TIME_METRICS } from "./constants";

export const trimUnderscores = (str) => str.replace(/_|_/g, " ");

export const capitalize = (str) => {
  return str[0].toUpperCase() + str.slice(1);
};

export const parseURL = (url) => {
  const brokenURL = url.split("/");
  return brokenURL[brokenURL.length - 1].replace(".jpeg", "");
};

export const titleCase = (str) => {
  const splitStr = str.split(" ");
  let output = "";
  splitStr.forEach((s, i) => {
    if (s.length) {
      output += s[0].toUpperCase() + s.slice(1);
    }
    if (i !== splitStr.length - 1) {
      output += " ";
    }
  });
  return output;
};

export const getTodayDate = () => {
  const date = DateTime.local();
  const { weekdayLong, monthLong, day, weekYear } = date;
  return `${weekdayLong}, ${monthLong} ${day}, ${weekYear}`;
};

export const getMonth = () => {
  const date = DateTime.local();
  return date.monthShort;
};

export const displayLastFewMonths = (state = []) => {
  let todayMonth = getMonth();
  const foundMonthMatch = state.find((value) => {
    let monthNameParsed = value.date.slice(0, 3);
    return monthNameParsed === todayMonth;
  });
  const index = state.indexOf(foundMonthMatch);
  return state.slice(index - 3, index + 1);
};

export const roundFloat = (num) => {
  return Number(num.toFixed(1));
};

export const buildDateDisplay = (luxonInstance) => {
  const weekDay = WEEKDAYS[luxonInstance.weekday - 1];
  const differenceInDays = luxonInstance.diff(DateTime.now(), ["days"]);
  const daysAway = differenceInDays.values.days;

  const weekNumber = luxonInstance.weekNumber;
  const currentWeekNumber = DateTime.now().weekNumber;
  const weeksAway = weekNumber - currentWeekNumber;

  const time = luxonInstance.toFormat("HH:mm");
  const date = luxonInstance.toFormat("MM/dd");
  const relativeToToday = DateTime.now()
    .plus(differenceInDays)
    .toRelativeCalendar();

  let dateDescription = "";
  if (weeksAway < 2 && daysAway >= 2) {
    dateDescription += `${THIS_OR_NEXT[weeksAway]} ${weekDay}`;
  } else if (daysAway < 2) {
    dateDescription += `${capitalize(relativeToToday)}`;
  } else {
    dateDescription += `${weekDay}, ${date}`;
  }

  if (weeksAway < 0 || daysAway <= 0) return dateDescription;
  return dateDescription + ` at ${timeFormatter(time)}`;
};

export const timeFormatter = (timeStr) => {
  const splitTime = timeStr.split(":");
  let [hour, minutes] = [parseInt(splitTime[0]), splitTime[1]];
  let AM_OR_PM = null;
  if (hour < 12 && hour !== 0) {
    AM_OR_PM = "am";
  } else if (hour === 0) {
    hour = 12;
    AM_OR_PM = "am";
  } else if (hour === 12) {
    AM_OR_PM = "pm";
  } else {
    hour -= 12;
    AM_OR_PM = "pm";
  }

  if (minutes !== "00") {
    return `${hour}:${minutes} ${AM_OR_PM}`;
  }
  return `${hour} ${AM_OR_PM}`;
};

export const ascendingByTime = (a, b) => {
  const timeA = DateTime.fromISO(a.show_time);
  const timeB = DateTime.fromISO(b.show_time);
  return timeA - timeB;
};

export const descendingByTime = (a, b) => {
  const timeA = DateTime.fromISO(a.show_time);
  const timeB = DateTime.fromISO(b.show_time);
  return timeB - timeA;
};
export const convertDateString = (date) =>
  new Date(date).toLocaleString("en-us", {
    month: "short",
    year: "numeric",
  });

export const convertDateStringWithDay = (date) =>
  new Date(date).toLocaleString("en-us", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });

export const addSpace = (str) => {
  const addedSpace = str.replace(/,/g, ", ");
  return addedSpace;
};

export const happenedThisMonth = (iso) => {
  const rightNow = DateTime.now();
  const currentYear = rightNow.year;
  const currentMonth = rightNow.month;

  const time = DateTime.fromISO(iso);
  const [year, month] = [time.year, time.month];
  return currentYear === year && currentMonth === month;
};

export const camelCaseToStr = (str) => {
  let output = str[0].toUpperCase();

  for (let i = 1; i < str.length; i++) {
    if (str[i] === str[i].toUpperCase()) {
      output += ` ${str[i]}`;
    } else {
      output += str[i];
    }
  }
  return output;
};

export const getTotalTicketSales = (listOfShows) =>
  listOfShows.reduce((totalTix, show) => totalTix + show.tickets_sold, 0);

export const getLastShow = (listOfShows) => {
  let lastShow = "(no passed shows yet)";
  const sortedPassedShows = listOfShows
    .filter((show) => show.already_passed)
    .sort((a, b) => descendingByTime(a, b));

  if (sortedPassedShows.length) {
    const luxonInstance = DateTime.fromISO(sortedPassedShows[0].show_time);
    lastShow = buildDateDisplay(luxonInstance);
  }
  return lastShow;
};

export const convertToStandardTime = (militaryTime) => {
  const [hours, minutes, seconds] = militaryTime.split(":");
  return `${hours > 12 ? hours - 12 : hours}:${minutes}${
    seconds ? `:${seconds}` : ""
  } ${hours >= 12 ? "PM" : "AM"}`;
};

export const getTotalEarnings = (listOfShows) => {
  const totalEarnings = listOfShows.reduce(
    (earnings, show) => earnings + show.tickets_sold * show.price,
    0
  );
  return totalEarnings;
};

export const getTimeOnTeder = (artist) => {
  let timeOnTeder;
  const timeSinceJoined = DateTime.now().diff(artist.timeOnTeder, [
    ...TIME_METRICS,
  ]);

  for (let i = 0; i < TIME_METRICS.length; i++) {
    if (timeSinceJoined.values[TIME_METRICS[i]] > 0) {
      let metric = TIME_METRICS[i];
      const time = timeSinceJoined.values[metric];

      if (time === 1) {
        metric = metric.slice(0, metric.length - 1);
      }
      timeOnTeder = `${time} ${metric}`;
      break;
    }
  }
  return timeOnTeder;
};

export const truncateWithEllipses = (text, max) =>
  text && text.substr(0, max - 1) + (text.length > max ? "... " : "");

export const getIncomeChartLabel = (props) => {
  const { text } = props;
  return <ValueAxis.Label {...props} text={`$${text}`} />;
};

export const checkIf24hoursPassed = (showDate, showTime) => {
  const attemptedDate = new Date(`${showDate}T${showTime}:00`);
  const now = new Date();
  const msBetweenDates = Math.abs(attemptedDate.getTime() - now.getTime());
  const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);

  return hoursBetweenDates < 24;
};

export const getMonthAndYearDifference = (startDate, endDate) => {
  let humanFriendlyTimeStatement;

  //Reformatting Dates to JS Date Objects
  startDate = new Date(startDate);

  //Some Regex for Safari compatibility
  endDate = new Date(endDate.replace(/-/g, "/"));

  //Find difference, result will be float
  const calcDifference =
    endDate.getMonth() -
    startDate.getMonth() +
    12 * (endDate.getFullYear() - startDate.getFullYear());

  //Unit conversions
  let distance = calcDifference / 12;
  let roundedDistance = roundFloat(distance);
  let convertedPositive = Math.abs(roundedDistance);
  let integerYear = Math.floor(convertedPositive);
  let decimalMonth = convertedPositive - integerYear;
  let decimalMonthWhole = decimalMonth * 10;
  decimalMonthWhole = roundFloat(decimalMonthWhole);
  //Determine display message
  if (integerYear === 1)
    humanFriendlyTimeStatement = `${integerYear} year, ${decimalMonthWhole} month${
      decimalMonthWhole === 1 ? "" : "s"
    }`;
  else if (integerYear > 1)
    humanFriendlyTimeStatement = `${integerYear} years, ${decimalMonthWhole} month${
      decimalMonthWhole === 1 ? "" : "s"
    }`;
  else if (integerYear < 1 && decimalMonthWhole === 1)
    humanFriendlyTimeStatement = `${decimalMonthWhole} month`;
  else if (decimalMonthWhole <= 0)
    humanFriendlyTimeStatement = `less than a month`;
  else humanFriendlyTimeStatement = `${decimalMonthWhole} months`;

  return humanFriendlyTimeStatement;
};

export const calculateAge = (date) => {
  if (date === "None") return date;
  const dateOfBirth = new Date(date);
  const rightNow = new Date();
  const justAge = getMonthAndYearDifference(dateOfBirth, rightNow).substring(
    0,
    3
  );
  return justAge;
};

export const convertTime12to24 = (timeObject) => {
  let newValue;
  let newObj;
  const militaryTime = {
    "01": "13",
    "02": "14",
    "03": "15",
    "04": "16",
    "05": "17",
    "06": "18",
    "07": "19",
    "08": "20",
    "09": "21",
    10: "22",
    11: "23",
    12: "24",
  };
  if (timeObject.ofDay === "PM" && timeObject.hour !== "12") {
    for (let property in militaryTime) {
      if (timeObject.hour == property) newValue = militaryTime[property];
    }
    newObj = {
      ...timeObject,
      hour: newValue,
    };
    return newObj;
  } else if (timeObject.ofDay === "AM" && timeObject.hour === "12") {
    newObj = {
      ...timeObject,
      hour: "24",
    };
    return newObj;
  }
  return timeObject;
};
