import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createShowAction, createShowErrorsAction } from "../../redux/actions";
import { convertTime12to24 } from "../../util/helpers";
import ValidateShowForm from "../ShowPages/Schedule/ValidateShowForm";

const TederTimePicker = ({ primary }) => {
  const currentState = useSelector((state) => state.TederReducerState);
  const dispatch = useDispatch();
  const BA_STYLE = "timeForm";
  const ARTIST_STYLE = "timeFormArtist";

  const getClass = () => {
    return primary ? ARTIST_STYLE : BA_STYLE;
  };

  const hours = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  const minutes = ["00", "30"];
  const amOrPM = ["AM", "PM"];

  const [time, setTime] = useState({
    hour: "01",
    min: "00",
    ofDay: "AM",
  });
  useEffect(() => {
    const convertedTo24Hours = convertTime12to24(time);
    const state = {
      ...currentState.createShowState,
      showTime: `${convertedTo24Hours.hour}:${convertedTo24Hours.min}`,
    };
    dispatch(createShowAction(state));
    dispatch(createShowErrorsAction(ValidateShowForm(state)));
  }, [time]);

  const handleTime = (e) => {
    const { name, value } = e.target;
    setTime((prevTime) => {
      return {
        ...prevTime,
        [name]: value,
      };
    });
  };
  return (
    <>
      <select
        className={getClass()}
        name="hour"
        onChange={handleTime}
        value={time.hour}
      >
        {hours.map((hour, i) => (
          <option key={i + 1}>{hour}</option>
        ))}
      </select>
      <select
        className={getClass()}
        name="min"
        value={time.min}
        onChange={handleTime}
      >
        {minutes.map((min, i) => (
          <option key={i + 1}>{min}</option>
        ))}
      </select>
      <select
        className={getClass()}
        name="ofDay"
        onChange={handleTime}
        value={time.ofDay}
      >
        {amOrPM.map((option, i) => (
          <option key={i + 1}>{option}</option>
        ))}
      </select>
      <br />
    </>
  );
};

export default TederTimePicker;
