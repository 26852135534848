import React, { useState, useRef, Suspense, useEffect, lazy } from "react";
import { Input } from "@mui/material";
import {
  admin_get_artist_ticket_info,
  admin_get_customers,
} from "../../Common/data";
import "./css/input.css";
// import RenderTimeZone from "../../HelperFunctions/RenderTimeZone.js";
// import NumberFormat from "react-number-format";

import {
  convertDateStringWithDay,
  convertToStandardTime,
} from "../../util/helpers";
import { Typeahead } from "react-bootstrap-typeahead";
import DisplayAdminTickets from "./DisplayAdminTickets";

const AdminTicketsInfo = () => {
  // const currentState = useSelector((state) => state.TederReducerState);
  //Todo: convert to redux state
  const TicketTable = lazy(() => import("./TicketTable"));
  const [allUsers, setAllUsers] = useState([]);
  const [artistTicketDisplay, setArtistTicketDisplay] = useState([]);
  const [filterByArtist, setFilterByArtist] = useState("");
  const [refNumber, setRefNumber] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const scrollRef = useRef([]);
  const searchRef = useRef();

  useEffect(() => {
    //Todo: figure out how to make this work. Should auto scroll to ref after state update
    scrollRef[0]?.current.scrollIntoView({ behavior: "smooth" });
  }, [refNumber]);

  useEffect(() => {
    admin_get_customers({ user_type: "artist" })
      .then((res) => {
        const formatRes = res.ticket_per_show.map((show) => {
          return {
            ...show,
            StageName: show.FirstName + show.LastName,
          };
        });
        setAllUsers(formatRes);
        console.log("response", formatRes);
      })
      .catch((error) => console.error(error));
  }, []);

  const handleSelectArtist = (id) => {
    const artistID = {
      artist_id: id,
    };
    admin_get_artist_ticket_info(artistID)
      .then((res) => {
        setArtistTicketDisplay(res.all_artist_shows[0].shows);
      })
      .catch((error) => console.error("new tickets error", error));
  };

  const handleShowMore = (id) => {
    setArtistTicketDisplay((prevState) =>
      prevState.map((show) => {
        if (show.showID === id) {
          return { ...show, showMore: !show.showMore };
        }
        return show;
      })
    );
  };

  const handleRef = (index, id, showID) => {
    if (showID === id) {
      setRefNumber(index);
      scrollRef[refNumber]?.current.scrollIntoView({ behavior: "smooth" });
    }
    return;
  };

  const filteredShows = artistTicketDisplay.filter((show) => {
    if (searchTerm !== "") {
      return show.showTitle.toLowerCase().includes(searchTerm.toLowerCase());
    }
    return true;
  });

  const displayShows = filteredShows.map((show, i) => (
    //TODO: convert to MUI components
    <div ref={(element) => (scrollRef.current[i] = element)}>
      <DisplayAdminTickets
        key={i}
        alreadyHappened={show.alreadyHappened}
        handleShowMore={handleShowMore}
        scrollRef={scrollRef}
        handleRef={handleRef}
        show={show}
      >
        <div style={{ textAlign: "center" }}>
          <h3>{show.showTitle}</h3>
          <p> {convertDateStringWithDay(show.dateOfShow)}</p>
          <p> {`GMT ${show.timeZone}`}</p>
          <p> {convertToStandardTime(show.showTime)}</p>
          <p>Revenue: {show.totalRevenue}</p>
          <p>Total Tickets Sold: {show.numberOfTicketsSold}</p>
        </div>
        <div
          style={{
            display: show.showMore ? "block" : "none",
            textAlign: "left",
          }}
        >
          <p>Ticket Price: {show.ticketPrice}</p>
          <p>Ticket Number: {show.ticketNumber}</p>
          <p>
            Booked by: {show.bookingAgent ? show.bookingAgent : show.artistName}
          </p>
          <Suspense fallback={<div>Loading...</div>}>
            <TicketTable show={show} />
          </Suspense>
        </div>
      </DisplayAdminTickets>
    </div>
  ));

  return (
    <div className="container">
      <h3>Show Tickets Info</h3>
      <Typeahead
        id="allUsers"
        name="allUsers"
        labelKey={"StageName"}
        placeholder="Search Artist Name"
        options={allUsers}
        onChange={(selected) => {
          if (selected[0]) {
            setFilterByArtist(selected[0].StageName);
            handleSelectArtist(selected[0].ArtistID);
          } else {
            setFilterByArtist("");
            return true;
          }
        }}
      />
      <input
        type="text"
        className="input-search-show-name"
        ref={searchRef}
        id="searchShowName"
        name="searchShowName"
        placeholder="Search Show Name"
        onChange={() => {
          setSearchTerm(searchRef.current.value);
        }}
      />
      {filterByArtist && (
        <div style={{ margin: "5%", padding: "3%" }}>
          <div>Artist: {filterByArtist}</div>
          <div>
            Shows On Teder:{" "}
            {artistTicketDisplay.length
              ? artistTicketDisplay.length
              : "Loading..."}
          </div>
          {displayShows}
        </div>
      )}
    </div>
  );
};

export default AdminTicketsInfo;
