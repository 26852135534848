import React, { useState, useEffect } from "react";

//Custom Components & Assets
import TopNavBar from "../../Navigation/TopNavBar";
import ShowGrid from "../../HomePage/ShowGrid";
import Arrow from "./Arrow.svg";

//MUI
import {
  Container,
  Typography,
  Input,
  Box,
  Tooltip,
  useTheme,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";

const ExploreShows = () => {
  //Styles
  const theme = useTheme();
  const mediaQuery = theme.breakpoints.down("sm");

  const styles = {
    wrapper: {
      marginTop: "3%",
      height: "auto",
    },

    inputContainer: {
      marginTop: "5%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      height: "30%",
      width: "120vw",
      position: "relative",
      [mediaQuery]: {
        flexDirection: "column",
        height: "15vh",
        justifyContent: "space-between",
        alignItems: "flex-start",
      },
    },

    searchField: {
      background: "white",
      fontFamily: "titleFont",
      textDecoration: "none",
      borderRadius: "30px",
      minWidth: "60%",
      minHeight: "38px",
      border: "none",
      padding: ".3% 0 .3% 2%",
      alignSelf: "center",
      [mediaQuery]: {
        marginRight: "17%",
      },
    },

    exploreText: {
      fontFamily: "titleFont",
      fontSize: "1.3em",
      marginRight: "10%",
    },

    exploreTextWrapper: {
      position: "absolute",
      left: "0",
      alignSelf: "flex-start",
      display: "flex",
      width: "15%",
      justifyContent: "space-evenly",
      [mediaQuery]: {
        position: "static",
      },
    },

    arrow: {
      height: "1.3em",
      cursor: "pointer",
    },
  };

  //State
  const currentState = useSelector((state) => state.TederReducerState);
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");

  //Search Logic
  const handleChange = (e) => setSearchTerm(e.target.value);

  const filteredShows = currentState.homepageShows.filter((show) => {
    const validTerms = [show?.EventName, show?.Genres, show?.StageName];

    const matchedValue = validTerms.some((value) =>
      value.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return searchTerm !== "" ? matchedValue : true;
  });

  const handleClick = () => dispatch(push("/"));

  useEffect(() => dispatch({ type: "CLEAR_SHOW_DETAILS" }), []);

  return (
    <Box>
      <TopNavBar />
      <Container sx={styles.inputContainer}>
        <Box sx={styles.exploreTextWrapper}>
          <Tooltip title="Home">
            <Box
              component="img"
              src={Arrow}
              sx={styles.arrow}
              onClick={handleClick}
            />
          </Tooltip>
          <Typography
            variant="p"
            sx={{ ...styles.exploreText, fontSize: "1em" }}
          >
            Explore Shows
          </Typography>
        </Box>
        <Tooltip title="Search artist's name, genre, or show title">
          <Input
            sx={styles.searchField}
            placeholder="SEARCH"
            onChange={handleChange}
            value={searchTerm}
            autoFocus={true}
            label="Search"
            type="search"
            disableUnderline={true}
          />
        </Tooltip>
      </Container>
      <Container sx={styles.wrapper}>
        <Typography
          variant="p"
          sx={{
            ...styles.exploreText,
            position: "static",
            textTransform: "uppercase",
          }}
        >
          Upcoming
        </Typography>
        <ShowGrid showsList={filteredShows} />
        <Typography
          variant="p"
          sx={{
            ...styles.exploreText,
            position: "static",
            textTransform: "uppercase",
          }}
        >
          Popular
        </Typography>
      </Container>
    </Box>
  );
};

export default ExploreShows;
