import { createTheme, ThemeProvider } from "@mui/material/styles";

//Custom Teder UI Theme
const tederTheme = createTheme({
  palette: {
    tederColors: {
      darkRed: "#DB3554",
      darkBlue: "#1A1A4A",
      darkPurple: "#2B026D",
      green01: "#87DFB2",
    },
    icons: {
      editIconSizing: { width: "30px", cursor: "pointer" },
      tederLogoSizing: { width: "98px", height: "98px" },
    },
    layout: {
      background: {
        offWhite001: "#E5E5E5",
        offWhite002: "#f9f9fa",
        white001: "#ffffff",
        gradientToPink: "linear-gradient(180deg, #1A1A4A 0%, #D77083 100%)",
        purpleGradientToPink:
          "linear-gradient(180deg, #582EFF 0%, rgba(219, 53, 84, 0.82) 100%)",
      },
      boxShadows: {
        pinkShadow: "0px 4px 10px rgba(219, 53, 84, 0.08)",
      },
    },
  },
});

function TederThemeProvider({ children }) {
  return <ThemeProvider theme={tederTheme}>{children}</ThemeProvider>;
}

export { tederTheme, TederThemeProvider };
