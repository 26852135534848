import $ from "jquery";
import { store } from "../redux/store";

class ApiHandler {
  _api_ep;
  _headers = []; // use to save the headers for the request - pass the user_auth teder token - there
  _resources = []; // the resources of the RESTFUL API - /artist /login /....
  _teder_token;
  constructor(api_ep, resources) {
    this._api_ep = api_ep;
    this._resources = resources;

    // get the user token
    const state = store.getState();
    const user_token = state.TederReducerState.userContext.userID;
    this._teder_token = user_token;

    // set the teder token as header
    this.setDefaultHeaders();
    this.setDefaultHeaders1();
  }

  setDefaultHeaders = () => {
    const state = store.getState();
    const user_token = state.TederReducerState.userContext.userID;
    this.setHeaders([
      {
        key: "Accept",
        value: "application/json",
      },
      {
        key: "Content-Type",
        value: "application/json",
      },
      {
        key: "token",
        value: user_token,
      },
    ]);
  };

  setDefaultHeaders1 = () => {
    // const state = store.getState();
    // const user_token = state.TederReducerState.userContext.userID;
    const user_token = localStorage.getItem("id_token");
    this._headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${user_token}`,
    };
  };

  getResourceUrl = (resource_name) => {
    return this._api_ep + "/" + resource_name;
  };

  setHeaders = (headers) => {
    for (const i in headers) {
      // this._headers.push([headers[i].key, headers[i].value]);
      this._headers.push(`${headers[i].key}: ${headers[i].value}`);
    }
  };

  toUrlParams = (url_params) => {
    var result = "";
    url_params.forEach((param) => {
      result += param.key + "=" + param.value + "&";
    });
    return result.substring(0, result.length - 1);
  };

  get_resource = (
    resource_name,
    api_method,
    body_params,
    url_params,
    resource_params = ""
  ) => {
    // console.log("Inside get_resource");
    var url = this.getResourceUrl(resource_name);

    if (!url_params === null) {
      url = url + "?" + this.toUrlParams(url_params);
    }

    if (resource_params.length > 0) {
      url = url + "/" + resource_params;
    }
    // console.log(url);
    try {
      return new Promise((resolve, reject) => {
        const controller = new AbortController();
        const { signal } = controller;
        // console.log("Inside get_resource tryyy");
        // console.log(body_params);
        $.ajax({
          url: url,
          headers: this._headers,
          type: api_method, // can be GET/POST/PUT/etc..
          data: JSON.stringify(body_params),
          signal: signal,
          beforeSend: function () {
            document.getElementById("spinner").style.display = "inline-block";
            document.getElementById("root").classList.add("overlay");
          },
          success: function (result) {
            console.log("ajax call success");
            // console.log("enable this only on your local please!")
            resolve(result);
          },
          error: function (xhr, status, error) {
            console.log("ajax call error");
            resolve(xhr);
          },
          complete: function () {
            document.getElementById("spinner").style.display = "none";
            document.getElementById("root").classList.remove("overlay");
          },
        });
      });
    } catch (e) {
      //   console.log("Inside get_resource catch");

      //   console.log(e);
      throw new Error("Error making API Request " + e);
    }
  };
}

export default ApiHandler;
