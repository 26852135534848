import React, {useState} from 'react';
// import { useSelector } from "react-redux";
import { admin_get_artist_bank } from '../../Common/data';
import './css/artistBankInfo.css'

const AdminArtistBankInfo = ({allUsers}) => {
    // const currentState = useSelector((state) => state.TederReducerState)

    const [artistID, setArtistID] = useState('')
    const [loadBank, setLoadBank] = useState(false)
    const [showBank, setShowBank] = useState('')
  
    const fetchBankInfo = () => {
        let data = {
            Artist_ID: parseInt(artistID, 0)
        }
        admin_get_artist_bank(data).then((result) => {
            setShowBank(result.Show_Details[0])
        }).catch((error) => {
            console.log("Error caught in update api")
            console.error(error)
            return false
        })
    
        setLoadBank(true)
    }

    const renderBankInfo = () =>(
        <div className="admin-bank-info">
            <div className="form-row">
                <div className="form-group col">
                    <label className="teder-form-label">First Name:</label>
                </div>
                <div className="form-group col">{showBank.FirstName}</div>
            </div>
            <div className="form-row">
                <div className="form-group col">
                    <label className="teder-form-label">Last Name:</label>
                </div>
                <div className="form-group col">{showBank.LastName}</div>
            </div>
            <div className="form-row">
                <div className="form-group col">
                    <label className="teder-form-label">Bank Name:</label>
                </div>
                <div className="form-group col">{showBank.BankName}</div>
            </div>
            <div className="form-row">
                <div className="form-group col">
                    <label className="teder-form-label">Routing Number:</label>
                </div>
                <div className="form-group col">{showBank.RoutingNo}</div>
            </div>
            <div className="form-row">
                <div className="form-group col">
                    <label className="teder-form-label">Account Number:</label>
                </div>
                <div className="form-group col">{showBank.AccNo}</div>
            </div>
        </div>
    )

    const checkID = () => {
        const id = parseInt(artistID, 0)
        let allArtistIDs = []
        
        allUsers.forEach(user => allArtistIDs.push(user.ArtistID))
        return allArtistIDs.includes(id)
    }

    const handleSubmit = () => {
        checkID() ? fetchBankInfo() : 
        <> 
            {alert('Please enter a valid Artist ID')} 
            {setLoadBank(false)}
        </>
        setArtistID('')
    }

    return (
        <div className="container content-padding ">
            <h3>Artist Bank Info Search</h3>
            <br /><br />
            <div className="text-center">
                <label htmlFor="artistID" className="teder-form-label">Enter artist ID:</label><br />
                <input
                    id="artistID"
                    type="number"
                    name="artistID"
                    className="watch-show-input"
                    placeholder="Artist ID"
                    value={artistID}
                    onChange={e => setArtistID(e.target.value)}
                />
                <button className="red-teder-btn" onClick={handleSubmit}>Search</button>
            </div>
            <br />
            <div className="d-flex justify-content-center">
                {loadBank && renderBankInfo()}
            </div>
        </div>
    )
}

export default AdminArtistBankInfo
