export default function validateTicket(values) {
    let errors = {};

    if (!values.cardNo){
        errors.cardNo = "*Required field."
    } else {
        errors.cardNo= ""
    }

    if (!values.cardName){
        errors.cardName = "*Required field."
    } else {
        errors.cardName= ""
    }

    if (values.expMonth === 'Month' || values.expMonth === '' ){
        errors.expMonth = "*Please select month."
    } else {
        errors.expMonth= ""
    }

    if (values.expYear === 'Year' || values.expYear === ''){
        errors.expYear = "*Please select year."
    } else {
        errors.expYear= ""
    }

    if (!values.CVV || values.CVV.length === 0){
        errors.CVV = "*Required field."
    } else {
        errors.CVV= ""
    }

    return errors;
}