import React from "react";
import { ArtistsNoDataPage } from "./ArtistsNoDataPage";
import { useMediaQuery, useTheme, Box, Typography } from "@mui/material";
import { CardHeading, ArtistTable } from "../SharedComponents";

const BookingAgentArtists = ({ artistList, isLoading }) => {
  const theme = useTheme();
  const mediaQueryPhone = theme.breakpoints.down("sm");

  const { offWhite002 } = theme.palette.layout.background;

  const styles = {
    wrapper: {
      boxSizing: "border-box",
      display: "flex",
      background: offWhite002,
      flexDirection: "column",
      paddingTop: "12vh",
      alignItems: "center",
      width: "100%",
      height: "100vh",

      [mediaQueryPhone]: {
        width: "100vw",
        alignItems: "left",
        overflow: "hidden",
        // padding: "0px",
      },
    },
    heading: {
      width: "800px",
      paddingLeft: "60px",
    },
  };

  return (
    <Box component="div" sx={styles.wrapper}>
      <CardHeading title="All Artists" style={styles.heading} />
      {!artistList.length && (
        <ArtistsNoDataPage message="You Have No Artists To Book" />
      )}
      {isLoading ? (
        <Typography variant="p" style={styles.heading}>
          Loading Artists...
        </Typography>
      ) : (
        <ArtistTable
          artistList={artistList}
          title="All Artists"
          headers={[
            "name",
            "totalShows",
            "totalTicketSales",
            "lastShow",
            "timeOnTeder",
          ]}
          rankedByEarnings={false}
        />
      )}
    </Box>
  );
};

export default BookingAgentArtists;
