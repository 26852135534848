import React, { useState, useEffect } from "react";
import "./editProfile.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import UploadPhoto from "../../../HelperFunctions/UploadPhoto";
import GenreOptions from "../../../HelperFunctions/GenreOptions";
import validateStepThree from "../../ArtistSignUpPage/ArtistSignupForms/validateStepThree";
import validateStepTwo from "../../ArtistSignUpPage/ArtistSignupForms/validateStepTwo";
import validateStepOne from "../../ArtistSignUpPage/ArtistSignupForms/validateStepOne";
import {
  show_country_list,
  show_state_list,
  update_artist_details,
} from "../../../Common/data";
import {
  artistDetailsAction,
  updateArtistErrorsAction,
  loadContriesAction,
  loadStatesAction,
} from "../../../redux/actions";
import soundCloud from "../../../Visuals/ArtistProfileAssets/soundcloud.svg";
import youTube from "../../../Visuals/ArtistProfileAssets/youtube.svg";
import instagram from "../../../Visuals/ArtistProfileAssets/instagram.svg";
import tiktok from "../../../Visuals/ArtistProfileAssets/tiktok.svg";
import website from "../../../Visuals/ArtistProfileAssets/globe.svg";
import Modal from "react-modal";
import { Box } from "@mui/material";
import { get_artist_details } from "../../../Common/data";

const EditProfile = ({ history }) => {
  const dispatch = useDispatch(); //to update our redux state
  const currentState = useSelector((state) => state.TederReducerState);

  const [artistDetail, setArtistDetails] = useState("");

  const styles = {
    wrapper: {
      margin: "3% 0 0 0",
      padding: "3% 3% 8% 1%",
      minHeight: "inherit",
    },
  };

  const {
    SChandle,
    IGhandle,
    YThandle,
    TThandle,
    stageName,
    bio,
    genres,
    photoPath,
    referralCode,
    firstName,
    lastName,
    address,
    city,
    state,
    zipCode,
    country,
    email,
    phoneNo,
  } = currentState.artistContext;
  console.log("currentState.artistContext: ", currentState.artistContext);

  // fetching artist details
  useEffect(() => {
    get_artist_details()
      .then((res) => {
        let artistDetails = {
          ...currentState.artistDetails,
          authProviderId: res.artist_details[0].AuthProviderId,
          authProvider: res.artist_details[0].AuthProvider,
          authID: res.artist_details[0].AuthID,
          artistId: res.artist_details[0].ArtistID,
          firstName: res.artist_details[0].FirstName,
          lastName: res.artist_details[0].LastName,
          photoPath: res.artist_details[0].PhotoPath,
          address: res.artist_details[0].Address,
          city: res.artist_details[0].City,
          email: res.artist_details[0].Email,
          state: res.artist_details[0].State,
          country: res.artist_details[0].Country,
          zipCode: res.artist_details[0].ZipCode,
          phoneNo: res.artist_details[0].PhoneNo,
          bankName: res.artist_details[0].BankName,
          routingNo: res.artist_details[0].RoutingNo,
          accID: res.artist_details[0].AccID,
          accNo: res.artist_details[0].AccNo,
          stageName: res.artist_details[0].StageName,
          genres: res.artist_details[0].Genres,
          bio: res.artist_details[0].DescriptionBIO,
          website: res.artist_details[0].Website,
          SChandle: res.artist_details[0].SoundcloudHandle,
          IGhandle: res.artist_details[0].InstagramHandle,
          YThandle: res.artist_details[0].YoutubeHandle,
          TThandle: res.artist_details[0].TikTokHandle,
          referralCode: res.artist_details[0].ReferralCode,
          userType: res.artist_details[0].UserType,
          StripeAcct: res.artist_details[0].StripeAcct,
        };
        setArtistDetails(artistDetails);
        dispatch(artistDetailsAction(artistDetails));

        return artistDetails;
      })
      .catch((err) => console.error(err));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log("artistDetail", artistDetail, artistDetail.StripeAcct);

  // const inputClassName = "form-control artist-textbox-style form-input";
  const inputClassName = "input-control-artist-edit";

  const [editProfile, setEditProfile] = useState(true);
  const [editGeneral, setEditGeneral] = useState(false);
  const [editBank, setEditBank] = useState(false);

  // const [bankInfoUpdate, setBankInfoUpdate] = useState(false);
  // const [confAccNo] = useState('');
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const [updateSaved, setUpdateSaved] = useState({
    firstName: false,
    lastName: false,
    photoPath: false,
    address: false,
    city: false,
    email: false,
    state: false,
    country: false,
    zipCode: false,
    phoneNo: false,
    bankName: false,
    routingNo: false,
    accNo: false,
    stageName: false,
    genres: false,
    bio: false,
    website: false,
    SChandle: false,
    IGhandle: false,
    YThandle: false,
    TThandle: false,
  });

  // const handlePhone = value => {
  //   const updateArtistDetails = { ...currentState.artistContext, 'phoneNo': value.formattedValue};
  //   setUpdateSaved({...updateSaved, phoneNo: true })
  //   dispatch(artistDetailsAction(updateArtistDetails));
  // }

  const handleChange = (e) => {
    let { name, value } = e.target;

    let updateArtistDetails = {
      ...currentState.artistContext,
      [name]: value,
    };
    console.log("name", value);
    setUpdateSaved({ ...updateSaved, [name]: true });

    // update the redux state
    dispatch(artistDetailsAction(updateArtistDetails));

    // validate before calling API
    const error1 = validateStepOne(updateArtistDetails);
    const error2 = validateStepTwo(updateArtistDetails);
    const error3 = validateStepThree(updateArtistDetails);
    // merge the three objects
    let allErrors = Object.assign({}, error1, error2, error3);

    // remove keys from object
    delete allErrors["checkedPrivacy"];
    delete allErrors["checkedTerms"];
    delete allErrors["accountNo"];
    delete allErrors["confirmAccountNo"];
    // console.log(allErrors)

    //udpate the error state in redux
    dispatch(updateArtistErrorsAction(allErrors));
  };

  const handleSave = () => {
    const artistInfo = currentState.artistContext;

    if (
      artistInfo.bankName !== "" &&
      artistInfo.routingNo !== "" &&
      artistInfo.accountNo !== ""
    ) {
      // if (currentState.updateArtistErrors.isValid) {
      // console.log("okay...", artistInfo)
      for (const [name, value] of Object.entries(artistInfo)) {
        if (updateSaved[name] === true) {
          handleBlur(name, value);
          // console.log("weird...", name, value)
        }
      }
      setUpdateSaved({
        firstName: false,
        lastName: false,
        photoPath: false,
        address: false,
        city: false,
        email: false,
        state: false,
        country: false,
        zipCode: false,
        phoneNo: false,
        bankName: false,
        routingNo: false,
        accNo: false,
        stageName: false,
        genres: false,
        bio: false,
        website: false,
        SChandle: false,
        IGhandle: false,
        YThandle: false,
        TThandle: false,
      });
      setIsOpen(true);
    } else {
      showError();
      setIsOpen(false);
    }
  };

  const showError = () => {
    alert("Please complete form");
  };

  const handleBlur = (name, value) => {
    // calling the API - check validation first before calling the API
    // if(currentState.updateArtistErrors.isValid) {
    console.log("check karo", currentState.artistContext);
    if (
      currentState.artistContext.bankName !== "" &&
      currentState.artistContext.routingNo !== "" &&
      currentState.artistContext.accountNo !== ""
    ) {
      let updatedInfo = {
        update_field: name,
        update_value: value,
        teder_token: currentState.userContext.userID,
      };

      // console.log("inside handleChange", updatedInfo);

      update_artist_details(updatedInfo)
        .then((data) => {
          if (data.code === "Success") {
            // alert("Field Updated")
            name === "stripeCTA" && window.open(data.message, "_blank");
          } else {
            // alert("Error happened")
            console.log("Something went wrong upon update...");
          }
          return true;
        })
        .catch((error) => {
          setIsOpen(false);
          console.log("Error caught in update api");
          console.error(error);
          return false;
        });
    }
  };

  if (currentState.countries.length === 0) {
    console.log("loading countries to state");
    const get_country_list = async () => {
      const response = await show_country_list.then((result) => {
        return JSON.parse(result.countryList);
      });
      dispatch(loadContriesAction(response));
    };
    get_country_list();
  }

  if (currentState.states.length === 0) {
    console.log("loading states to state");
    const get_state_list = async () => {
      const response = await show_state_list.then((result) => {
        return JSON.parse(result.stateList);
      });
      dispatch(loadStatesAction(response));
    };
    get_state_list();
  }

  const profileForm = () => (
    <Box component="div">
      <h2>ARTIST PROFILE</h2>
      <div className="row">
        <div className="col-md">
          <form>
            <div className="form-group">
              <UploadPhoto photo={photoPath} handleChange={handleChange} />
              <small className="small-green-text">
                {currentState.updateArtistErrors.photoPath}
              </small>
            </div>
            <div className="text-center">
              <small>Please do not include the "@" on your handles.</small>
            </div>
            <div className="form-row">
              <div className="d-flex align-items-center">
                <div className="form-group col-1">
                  <img
                    src={soundCloud}
                    alt="Sound Cloud"
                    className="socials-icon"
                  />
                </div>
              </div>
              <div className="form-group col">
                <input
                  type="text"
                  name="SChandle"
                  className={inputClassName}
                  placeholder="Soundcloud Handle"
                  value={SChandle}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="d-flex align-items-center">
                <div className="form-group col-1">
                  <img
                    src={instagram}
                    alt="Instagram"
                    className="socials-icon"
                  />
                </div>
              </div>
              <div className="form-group col">
                <input
                  type="text"
                  name="IGhandle"
                  className={inputClassName}
                  placeholder="Instagram Handle"
                  value={IGhandle}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="d-flex align-items-center">
                <div className="form-group col-1">
                  <img src={youTube} alt="YouTube" className="socials-icon" />
                </div>
              </div>
              <div className="form-group col">
                <input
                  type="text"
                  name="YThandle"
                  className={inputClassName}
                  placeholder="YouTube Handle"
                  value={YThandle}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="d-flex align-items-center">
                <div className="form-group col-1">
                  <img src={tiktok} alt="TikTok" className="socials-icon" />
                </div>
              </div>
              <div className="form-group col">
                <input
                  type="text"
                  name="TThandle"
                  className={inputClassName}
                  placeholder="TikTok Handle"
                  value={TThandle}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="d-flex align-items-center">
                <div className="form-group col-1">
                  <img src={website} alt="Website" className="socials-icon" />
                </div>
              </div>
              <div className="form-group col">
                <input
                  type="url"
                  name="website"
                  className={inputClassName}
                  placeholder="Website URL"
                  value={currentState.artistContext.website}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="text-center">
              <small>Please do not include "https://www." on your URL.</small>
            </div>
          </form>
          <br />
        </div>
        <div className="col-md">
          <form>
            <div className="form-group">
              <label htmlFor="stageName" className="teder-form-label">
                STAGE NAME
              </label>
              <br />
              <input
                id="stageName"
                type="text"
                name="stageName"
                className={inputClassName}
                placeholder="Stage Name"
                value={stageName}
                onChange={handleChange}
              />
              <small className="small-green-text">
                {currentState.updateArtistErrors.stageName}
              </small>
            </div>
            <div className="form-group">
              {<GenreOptions genres={genres} handleChange={handleChange} />}
              <small className="small-green-text">
                {currentState.updateArtistErrors.genres}
              </small>
            </div>
            <div className="form-group">
              <label htmlFor="bio" className="teder-form-label">
                BIO
              </label>
              <textarea
                id="bio"
                type="text"
                name="bio"
                className={inputClassName}
                placeholder="Tell us about yourself."
                value={bio}
                onChange={handleChange}
              />
              <small className="small-green-text">
                {currentState.updateArtistErrors.bio}
              </small>
            </div>
            {referralCode && (
              <>
                <label htmlFor="referralCode" className="teder-form-label">
                  Referral Code Used:
                </label>
                <i> {referralCode}</i>
              </>
            )}
          </form>
        </div>
      </div>
      <br />
      <div className="d-flex justify-content-center">
        <span className="red-teder-btn" onClick={handleSave}>
          Save
        </span>
      </div>
    </Box>
  );
  const formatPhoneNumber = (e) => {
    let formattedNumber;

    const { name, value } = e.target;
    const { length } = value;

    // Filter non numbers
    const regex = () =>
      value.replace(/[^0-9\.]+/g, ""); /* eslint-disable-line */
    // Set area code with parenthesis around it
    const areaCode = () => `(${regex().slice(0, 3)})`;
    // Set formatting for first six digits
    const firstSix = () => `${areaCode()} ${regex().slice(3, 6)}`;
    // Dynamic trail as user types
    const trailer = (start) => `${regex().slice(start, regex().length)}`;

    if (length < 3) {
      // First 3 digits
      formattedNumber = regex();
    } else if (length === 4) {
      // After area code
      formattedNumber = `${areaCode()} ${trailer(3)}`;
    } else if (length === 5) {
      // When deleting digits inside parenthesis
      formattedNumber = `${areaCode().replace(")", "")}`;
    } else if (length > 5 && length < 9) {
      // Before dash
      formattedNumber = `${areaCode()} ${trailer(3)}`;
    } else if (length >= 10) {
      // After dash
      formattedNumber = `${firstSix()}-${trailer(6)}`;
    }

    const formattedObject = {
      target: {
        name: name,
        value: formattedNumber,
      },
    };

    handleChange(formattedObject);
  };
  console.log("check list", currentState.countries);
  if (currentState.countries.length === 0) {
    console.log("loading countries to state");
    const get_country_list = async () => {
      const response = await show_country_list.then((result) => {
        return JSON.parse(result.countryList);
      });
      dispatch(loadContriesAction(response));
    };
    get_country_list();
  }

  if (currentState.states.length === 0) {
    console.log("loading states to state");
    const get_state_list = async () => {
      const response = await show_state_list.then((result) => {
        return JSON.parse(result.stateList);
      });
      dispatch(loadStatesAction(response));
    };
    get_state_list();
  }
  const generalForm = () => (
    <div className="container teder-form-contatiner">
      <form className="form">
        <h2>GENERAL INFO</h2>
        <div className="form-row">
          <div className="form-group col-lg">
            <label htmlFor="firstName" className="teder-form-label">
              FIRST NAME
            </label>
            <input
              id="firstName"
              type="text"
              name="firstName"
              className={inputClassName}
              placeholder="First Name"
              value={firstName}
              onChange={handleChange}
            />
            <small className="small-green-text">
              {currentState.updateArtistErrors.firstName}
            </small>
          </div>
          <div className="form-group col-lg">
            <label htmlFor="lastName" className="teder-form-label">
              LAST NAME
            </label>
            <input
              id="lastName"
              type="text"
              name="lastName"
              className={inputClassName}
              placeholder="Last Name"
              value={lastName}
              onChange={handleChange}
            />
            <small className="small-green-text">
              {currentState.updateArtistErrors.lastName}
            </small>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="address" className="teder-form-label">
            ADDRESS
          </label>
          <input
            id="address"
            type="text"
            name="address"
            className={inputClassName}
            placeholder="Address"
            value={address}
            onChange={handleChange}
          />
          <small className="small-green-text">
            {currentState.updateArtistErrors.address}
          </small>
        </div>
        <div className="form-row">
          <div className="form-group col-lg">
            <label htmlFor="city" className="teder-form-label">
              CITY
            </label>
            <input
              id="city"
              type="text"
              name="city"
              className={inputClassName}
              placeholder="City Name"
              value={city}
              onChange={handleChange}
            />
            <small className="small-green-text">
              {currentState.updateArtistErrors.city}
            </small>
          </div>
          <div className="form-group col-lg-3">
            <label
              htmlFor="State"
              className={
                country !== "United States of America"
                  ? "state-label-disabled"
                  : "teder-form-label"
              }
            >
              STATE
            </label>
            {country !== "United States of America" ? (
              <input
                id="state"
                className="form-control artist-textbox-style teder-select"
                name="state"
                type="text"
                disabled
                value="Select State"
              />
            ) : (
              <select
                id={
                  state === "Select State" || state === ""
                    ? "state-placeholder"
                    : "state"
                }
                className="form-control artist-textbox-style teder-select"
                name="state"
                value={state}
                onChange={handleChange}
              >
                <option>Select State</option>
                <option>AK</option>
                <option>AL</option>
                <option>AR</option>
                <option>AZ</option>
                <option>CA</option>
                <option>CO</option>
                <option>CT</option>
                <option>DE</option>
                <option>FL</option>
                <option>GA</option>
                <option>HI</option>
                <option>IA</option>
                <option>ID</option>
                <option>IL</option>
                <option>IN</option>
                <option>KS</option>
                <option>KY</option>
                <option>LA</option>
                <option>MA</option>
                <option>MD</option>
                <option>ME</option>
                <option>MI</option>
                <option>MN</option>
                <option>MO</option>
                <option>MS</option>
                <option>MT</option>
                <option>NC</option>
                <option>ND</option>
                <option>NE</option>
                <option>NH</option>
                <option>NJ</option>
                <option>NM</option>
                <option>NV</option>
                <option>NY</option>
                <option>OH</option>
                <option>OK</option>
                <option>OR</option>
                <option>PA</option>
                <option>RI</option>
                <option>SC</option>
                <option>SD</option>
                <option>TN</option>
                <option>TX</option>
                <option>UT</option>
                <option>VA</option>
                <option>VT</option>
                <option>WA</option>
                <option>WI</option>
                <option>WV</option>
                <option>WY</option>
              </select>
            )}
            <small className="small-green-text">
              {currentState.updateArtistErrors.state}
            </small>
          </div>
          <div className="form-group col-lg-3">
            <label htmlFor="zipCode" className="teder-form-label">
              ZIP CODE
            </label>
            <input
              id="zipCode"
              type="text"
              name="zipCode"
              className={inputClassName}
              placeholder="Zip Code"
              value={zipCode}
              onChange={handleChange}
              maxLength={5}
            />
            <small className="small-green-text">
              {currentState.updateArtistErrors.zipCode}
            </small>
          </div>
        </div>
        <div className="form-group">
          <label className="teder-form-label">COUNTRY</label>
          <select
            id="country"
            className={"form-control artist-textbox-style teder-select"}
            name="country"
            value={country}
            onChange={handleChange}
          >
            {currentState.countries.map((element) => (
              <option key={element.ID}> {element.CountryName} </option>
            ))}
          </select>
          <small className="small-green-text">
            {currentState.updateArtistErrors.country}
          </small>
        </div>
        <div className="form-group form-inputs">
          <label htmlFor="email" className="teder-form-label">
            EMAIL
          </label>
          <input
            id="email"
            type="email"
            name="email"
            className={inputClassName}
            placeholder="Email"
            value={email}
            disabled="true"
            onChange={handleChange}
          />
          <small className="small-green-text">
            {currentState.updateArtistErrors.email}
          </small>
        </div>
        <div className="form-group">
          <label htmlFor="phoneNo" className="teder-form-label">
            PHONE NUMBER
          </label>
          <input
            id="phoneNo"
            type="tel"
            name="phoneNo"
            className={inputClassName}
            placeholder="Phone Number"
            value={phoneNo}
            maxLength={14}
            required
            onChange={formatPhoneNumber}
          />
          <small className="small-green-text">
            {currentState.updateArtistErrors.phoneNo}
          </small>
        </div>
        {/* <div className="form-group">
                <label htmlFor="phoneNo" className="teder-form-label">PHONE NUMBER</label>
                <NumberFormat type="tel"  id="phoneNo" name="phoneNo" value={artistDetails.phoneNo} format="(###) ###-####" mask="_" className={inputClassName} placeholder="Phone Number"  onValueChange={handlePhone} required />
            </div> */}
      </form>
      <br />
      <div className="d-flex justify-content-center">
        <span className="red-teder-btn" onClick={handleSave}>
          Save
        </span>
      </div>
      <br />
      <br />
      <br />
    </div>
  );

  // const confirmAccNo = () => {
  //   console.log("inside confirmAccNo", confAccNo)
  //   let error
  //   if (!confAccNo || confAccNo === '') {
  //     error = "*Please confirm account number."
  //   } else if (confAccNo !== artistDetails.accNo) {
  //     error = "*Account numbers do not match."
  //   } else {
  //     setBankInfoUpdate(false);
  //   }
  //   return error
  // }

  const bankForm = () =>
    !artistDetail.StripeAcct ? (
      <div className="container teder-form-contatiner" id="edit-bank">
        <form>
          <h2>BANK ACCOUNT</h2>
          <div className="d-flex justify-content-center">
            <button
              id="stripeCTA"
              type="button"
              className="green-teder-btn"
              name="stripeCTA"
              onClick={() => handleBlur("stripeCTA", "")}
            >
              LOGIN TO STRIPE
            </button>
          </div>
          <div className="text-center">
            <small className="small-green-text">
              *In order to get paid, please set up a Stripe account.
            </small>
          </div>
          {/* <br />

          <small>
            NOTE: Artists will get 70% of their show’s net profits. Teder will
            get 30%. You'll receive payment within 5 business days via direct
            deposit.
          </small>
          <br /> */}
        </form>
      </div>
    ) : (
      <div className="container teder-form-contatiner" id="edit-bank">
        <form>
          <h2>Bank Account</h2>

          <div className="d-flex justify-content-center">
            <button
              id="stripeCTA"
              type="button"
              className="green-teder-btn"
              name="stripeCTA"
              onClick={() => handleBlur("stripeCTA", "")}
            >
              LOGIN TO STRIPE
            </button>
          </div>
          <div className="text-center">
            <small className="small-green-text">
              *In order to get paid, please set up a Stripe account.
            </small>
          </div>
        </form>
      </div>
    );

  const onlyProfile = () => {
    setEditProfile(true);
    setEditGeneral(false);
    setEditBank(false);
  };

  const onlyGeneral = () => {
    setEditProfile(false);
    setEditGeneral(true);
    setEditBank(false);
  };

  const onlyBank = () => {
    setEditProfile(false);
    setEditGeneral(false);
    setEditBank(true);
  };

  const handleBack = () => history.goBack();

  const myAccount = () => (
    <Box component="div" sx={styles.wrapper}>
      <br />
      <div className="edit-profile-buttons-container">
        {/* <button className="green-teder-btn" onClick={handleBack}>
          Back
        </button> */}
        <button className="red-teder-btn" onClick={onlyProfile}>
          Profile
        </button>
        <button className="red-teder-btn" onClick={onlyGeneral}>
          General
        </button>
        <button className="red-teder-btn" onClick={onlyBank}>
          Bank Account
        </button>
        <br />
      </div>
      <div className="text-center">
        {/* {!artistDetails.bankName && <small className="small-green-text">*Please set up your bank account.</small>} */}
      </div>

      <br />

      {editProfile && profileForm()}
      {editGeneral && generalForm()}
      {editBank && bankForm()}

      <Modal
        isOpen={modalIsOpen}
        ariaHideApp={false}
        className="Modal"
        overlayClassName="Overlay"
        contentLabel="Schedule Modal"
      >
        <div className="teder-modal-container">
          <div className="modal-body">
            <div className="teder-modal-subtitle text-center">
              Your changes have been saved!
            </div>
            <br />
            <br />
            <div className="text-center">
              <button
                className="green-teder-btn"
                onClick={() => setIsOpen(false)}
              >
                close
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </Box>
  );

  const redirectUser = () => {
    console.log("user not authenticated");
    dispatch(push("/login"));
    return "Please login";
  };

  return currentState.userContext.userLoggedIn ? myAccount() : redirectUser();
};

export default EditProfile;
