import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridToolbarExport,
  GridToolbarContainer,
  selectedGridRowsCountSelector,
} from "@mui/x-data-grid";
import { SignalCellularNull } from "@mui/icons-material";
import { admin_get_customers } from "../../Common/data";
import { calculateAge } from "../../util/helpers.js";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export default function AdminUsersChildTab(props) {
  const [rows, setRows] = useState([]);
  let columns;
  if (props.user === "artist") {
    columns = [
      { field: "ArtistID", headerName: "Artist Id", width: 80 },
      {
        field: "EmailID",
        headerName: "Email",
        width: 160,
      },
      {
        field: "FirstName",
        headerName: "First Name",
        width: 160,
      },
      {
        field: "GenderDesc",
        headerName: "Gender",
        width: 160,
      },
      {
        field: "DateOfBirth",
        headerName: "Age",
        width: 160,
      },
      {
        field: "LastName",
        headerName: "Last Name",
        width: 200,
      },
      {
        field: "StageName",
        headerName: "Stage Name",
        width: 230,
      },
      {
        field: "DateCreated",
        headerName: "Date Joined",
        width: 160,
      },
      {
        field: "LastLogin",
        headerName: "Last Login",
        width: 160,
      },
      {
        field: "ReferringBookingAgent",
        headerName: "Referring Booking Agent",
        width: 230,
      },
      {
        field: "Address",
        headerName: "Address",
        width: 200,
      },
      {
        field: "City",
        headerName: "City ",
        width: 160,
      },
      {
        field: "State",
        headerName: "State",
        width: 160,
      },
      {
        field: "Country",
        headerName: "Country ",
        width: 160,
      },
      {
        field: "PhoneNo",
        headerName: "Phone ",
        width: 160,
      },
    ];
  } else if (props.user === "booking_agent") {
    columns = [
      { field: "ID", headerName: "Id", width: 80 },
      {
        field: "EmailID",
        headerName: "Email",
        width: 230,
      },
      {
        field: "FirstName",
        headerName: "First Name",
        width: 210,
      },
      {
        field: "LastName",
        headerName: "Last Name",
        width: 180,
      },
      {
        field: "GenderDesc",
        headerName: "Gender",
        width: 160,
      },
      {
        field: "DateOfBirth",
        headerName: "Age",
        width: 160,
      },
      {
        field: "DateCreated",
        headerName: "Date Joined",
        width: 160,
      },
      {
        field: "LastLogin",
        headerName: "Last Login",
        width: 160,
      },
      {
        field: "Address",
        headerName: "Address",
        width: 220,
      },
      {
        field: "City",
        headerName: "City ",
        width: 200,
      },
      {
        field: "State",
        headerName: "State",
        width: 160,
      },
      {
        field: "Country",
        headerName: "Country ",
        width: 220,
      },
      {
        field: "PhoneNo",
        headerName: "Phone ",
        width: 160,
      },
      {
        field: "ReferralCode",
        headerName: "Referral Code",
        width: 160,
      },
    ];
  } else if (props.user === "user") {
    columns = [
      { field: "ID", headerName: "Id", width: 80 },
      {
        field: "EmailID",
        headerName: "Email",
        width: 230,
      },
      {
        field: "FirstName",
        headerName: "First Name",
        width: 210,
      },
      {
        field: "LastName",
        headerName: "Last Name",
        width: 180,
      },
      {
        field: "Address",
        headerName: "Address",
        width: 220,
      },
      {
        field: "City",
        headerName: "City ",
        width: 160,
      },
      {
        field: "State",
        headerName: "State",
        width: 160,
      },
      {
        field: "Country",
        headerName: "Country ",
        width: 200,
      },
      {
        field: "PhoneNo",
        headerName: "Phone ",
        width: 160,
      },
      {
        field: "NumberOfPurchedTickets",
        headerName: "Number of Purchased Tickets",
        width: 200,
      },
      {
        field: "LatestPurchaseDate",
        headerName: "Latest Purchase Date",
        width: 160,
      },
    ];
  }

  useEffect(() => {
    admin_get_customers(props.Payload)
      .then((res) => {
        setRows(res.ticket_per_show);
      })
      .catch((error) => console.error(error));
  }, [props.Payload]);

  //Todo: add to response to calculate age when age value returned from backend
  // const listWithFormattedAgeOfUser = res.ticket_per_show.map((user) => {
  //   return { ...user, DateOfBirth: calculateAge(user.DateOfBirth) };
  // });

  return (
    <Box className="admin-data-grid" sx={{ height: 630 }}>
      <DataGrid
        rows={rows}
        getRowId={(rows) =>
          rows.ArtistID ? rows.ArtistID : rows.ID ? rows.ID : null
        }
        columns={columns}
        pageSize={20}
        rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
    </Box>
  );
}
