import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Card, List, Divider, Box, Button, useTheme } from "@mui/material";
import { CardHeading } from "../SharedComponents";
import { RedTederButton } from "../SharedComponents/Buttons";
import UpcomingShowListItem from "./UpcomingShowListItem";

import { get_booking_agent_associated_shows } from "../../../Common/data";

import { bookingAgentShowsAction } from "../../../redux/actions";
import { BA_DASHBOARD_ROUTES } from "../../../util/constants";

const UpcomingShowsCard = ({ selectRoute }) => {
  //State
  const currentState = useSelector((state) => state.TederReducerState);
  const dispatch = useDispatch();

  //Design
  const theme = useTheme();
  const { pinkShadow } = theme.palette.layout.boxShadows;

  const styles = {
    wrapper: {
      minHeight: "245px",
      padding: "0 11px",
      boxShadow: pinkShadow,
    },
    noShowsWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  };

  useEffect(() => {
    // Load Shows
    const loadShows = () => {
      get_booking_agent_associated_shows()
        .then(({ shows }) => {
          const formattedShows = shows.map((show) => {
            return {
              ...currentState.baShows,
              artistName: show.artist_name,
              artistImage: show.artist_image_link,
              startTime: show.StartTime,
              timeZone: show.TimeZone,
              startDate: show.StartDate,
            };
          });
          dispatch(bookingAgentShowsAction(formattedShows));
        })
        .catch((error) => console.error(error));

      // const baShowsThisMonth = shows.filter((show) =>
      //   happenedThisMonth(show.show_time)
      // );

      // const tixSoldThisMonth = baShowsThisMonth.reduce(
      //   (totalTix, show) => totalTix + show.tickets_sold,
      //   0
      // );
      // const revenueThisMonth = baShowsThisMonth.reduce(
      //   (totalRev, show) => totalRev + show.tickets_sold * show.price,
      //   0
      // );

      // setShowsThisMonth(baShowsThisMonth);
      // setTixSoldThisMonth(tixSoldThisMonth);
      // setRevenueThisMonth(revenueThisMonth);
    };
    loadShows();
  }, []);

  if (!currentState.baShows) return null;

  const renderShowData = () => {
    const data = currentState.baShows;
    if (!data.length) {
      return (
        <Box component="div" style={styles.noShowsWrapper}>
          <RedTederButton
            onClick={() => selectRoute(BA_DASHBOARD_ROUTES.BA_Schedule)}
          >
            Book a show
          </RedTederButton>
        </Box>
      );
    }
    if (data.length >= 3) {
      return data
        .slice(0, 3)
        .map((showData, index) => (
          <UpcomingShowListItem key={index} showData={showData} />
        ));
    }
    return data.map((showData, index) => (
      <UpcomingShowListItem key={index} showData={showData} />
    ));
  };
  return (
    <Card elevation={2} style={styles.wrapper}>
      <CardHeading
        title="UPCOMING SHOWS"
        subTitle="SEE ALL"
        selectRoute={selectRoute}
        routeSelection={BA_DASHBOARD_ROUTES.Artists}
      />
      <Divider />
      <List>{renderShowData()}</List>
    </Card>
  );
};

export default UpcomingShowsCard;
