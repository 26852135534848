import React, { useState } from "react";
import { Link } from "react-router-dom";
import ReactLogo from "../../Visuals/logo.svg";
import FBicon from "./img/fb-icon.svg";
import IGicon from "./img/ig-icon.svg";
import YTicon from "./img/yt-icon.svg";
import { useSelector } from "react-redux";
import { animateScroll as scroll } from "react-scroll";

const Footer = () => {
  const currentState = useSelector((state) => {
    return state.TederReducerState;
  });

  const [subscriberEmail, setSubscriberEmail] = useState("");

  const submitHandler = (e) => {
    e.preventDefault();
    setSubscriberEmail("");
  };

  return (
    <>
      <div className="footer-container">
        <div className="row">
          <div className="col-lg-2 footer-logo">
            <Link to="/" onClick={() => scroll.scrollTo(0)}>
              <img
                src={ReactLogo}
                className="footer-link-tag"
                width="100"
                height="100"
                alt="Teder"
                loading="lazy"
              />
            </Link>
          </div>
          <div className="col-lg-3 footer-links">
            <ul className="link-list">
              <li>
                <Link to="/about-us" className="footer-link-tag">
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/signup/1" className="footer-link-tag">
                  Artist Sign-up
                </Link>
              </li>
              <li>
                <Link to="/contact" className="footer-link-tag">
                  Contact Us
                </Link>
              </li>
              <li>
                <Link to="/faq" className="footer-link-tag">
                  {currentState.userContext.userLoggedIn
                    ? "Artist Support & FAQs"
                    : "FAQs"}
                </Link>
              </li>
              <li>
                <Link to="/terms" className="footer-link-tag">
                  Terms & Conditions
                </Link>
              </li>
              <li>
                <Link to="/privacy-policy" className="footer-link-tag">
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-lg footer-email">
            <form onSubmit={submitHandler}>
              <label htmlFor="footerEmail" className="footer-email-text">
                Get the latest shows sent straight to your inbox.
              </label>
              <br />
              <input
                id="footerEmail"
                type="email"
                className="footer-email-input"
                placeholder="Email"
                onChange={(e) => setSubscriberEmail(e.target.value)}
                value={subscriberEmail}
              />
              <div className="invalid-feedback">
                Please enter a valid email.
              </div>
              <button
                type="button"
                className="footer-email-btn"
                onClick={submitHandler}
              >
                Subscribe
              </button>

              <div className="clear"></div>
              <div className="outputMessage"></div>
              <div className="row recaptcha-wrap justify-content-end">
                <div
                  className="g-recaptcha"
                  data-theme="dark"
                  data-size="compact"
                  data-sitekey="6LdnocIZAAAAAOmOkiSnG5kq2fEMJ1b-1Wbj3AKu"
                ></div>
              </div>
            </form>
          </div>
          <div className="col-lg-3 footer-socials">
            <a href="http://facebook.com/ontederlive" target="blank">
              <img src={FBicon} alt="Facebook" className="footer-social-icon" />
            </a>
            <a href="http://instagram.com/tederlive" target="blank">
              <img
                src={IGicon}
                alt="Instagram"
                className="footer-social-icon"
              />
            </a>
            <a
              href="https://www.youtube.com/channel/UCv3TGQsTnBmQhMkhw6dDspw"
              target="blank"
            >
              <img src={YTicon} alt="Youtube" className="footer-social-icon" />
            </a>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        All Rights Reserved © Teder, Inc 2021 - 2022
      </div>
    </>
  );
};

export default Footer;
