import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./topnavbar.css";
import SideBar from "./SideBar";
import ReactLogo from "../../Visuals/logo.svg";
import MenuIcon from "./img/menu.svg";
import ProfileIcon from "./img/profile.svg";
import { useSelector, useDispatch } from "react-redux";
import { UserType } from "../../util/constants";
// import SearchIcon from './img/search.svg';

const TopNavBar = () => {
  const currentState = useSelector((state) => state.TederReducerState);
  const dispatch = useDispatch();
  const [showSideBar, setShowSideBar] = useState(false);
  const toggleSideBar = () =>
    showSideBar ? setShowSideBar(false) : setShowSideBar(true);

  const getCorrectRoute = () => {
    if (currentState.userContext.isSignupCompleted === 0)
      return currentState.userContext.userType === UserType.Artist
        ? "/signup/1"
        : "/basignup/1";
    if (!currentState.userContext.userType) return "/login";
    if (currentState.userContext.userType === UserType.Artist) return "/artist";
    if (currentState.userContext.userType === UserType.BookingAgent)
      return "/agent-dashboard";
  };

  return (
    <>
      {/* <div className="beta-header">beta</div> */}
      {showSideBar && (
        <SideBar
          toggleSideBar={toggleSideBar}
          setShowSideBar={setShowSideBar}
          sideBarStyle={showSideBar ? "sidebar" : "sidebar hide"}
          getCorrectRoute={getCorrectRoute}
        />
      )}
      <div className="header-container d-flex flex-row bd-highlight">
        <div className="header-menu">
          <span onClick={() => toggleSideBar()}>
            <img
              src={MenuIcon}
              className="menu"
              width="15"
              height="15"
              alt=""
              loading="lazy"
            />
          </span>
        </div>
        <div className="header-logo">
          <Link
            to="/"
            className="navbar-brand"
            onClick={() => setShowSideBar(false)}
          >
            <img
              src={ReactLogo}
              width="85"
              height="85"
              alt="Teder"
              loading="lazy"
            />
          </Link>
        </div>
        <div className="header-shortcuts">
          <Link to={getCorrectRoute()} onClick={() => setShowSideBar(false)}>
            <img
              src={ProfileIcon}
              className="profile"
              width="15"
              height="15"
              alt=""
              loading="lazy"
            />
          </Link>
          {/* <Link to="/" onClick={() => setShowSideBar(false)}>
                        <img src={SearchIcon} width="15" height="15" alt="" loading="lazy"/>
                    </Link> */}
        </div>
      </div>
    </>
  );
};

export default TopNavBar;
