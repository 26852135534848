import React, { useState, useEffect } from "react";
import { show_genre_list } from "../Common/data";
import MultiSelect from "react-multi-select-component";
import "../Styling/genreOptions.css";

const GenreOptions = ({ genres, handleChange }) => {
  const [genreList, setGenreList] = useState([]);

  useEffect(() => {
    show_genre_list()
      .then((result) => {
        const parsedList = JSON.parse(result.genreList);
        setGenreList(
          parsedList.map((option, index) => ({
            label: option.GenreName,
            value: option.GenreName,
            key: index + 1,
          }))
        );
      })
      .catch((error) => console.error(error));
  }, []);

  const makeGenreSelections = (genreArr) => {
    var genre_list = [];
    for (const genre of genreArr) {
      genre_list.push(genre.value);
    }
    const genresObj = {
      target: {
        name: "genres",
        value: genre_list?.join(","),
      },
    };

    handleChange(genresObj);
  };

  const getSelectedItems = () => {
    var selectedArray = genres?.split(",");
    var selectedReduxGenre = [];
    if (selectedArray?.length > 0) {
      for (const genre of genreList) {
        for (const selectedGenre of selectedArray) {
          if (genre.value === selectedGenre) selectedReduxGenre.push(genre);
        }
      }
    }
    return selectedReduxGenre;
  };

  return (
    <div className="form-group genre-dropdown">
      <label className="music-genre-label">MUSIC GENRES</label>
      <MultiSelect
        options={genreList}
        focusSearchOnOpen={true}
        hasSelectAll={false}
        shouldToggleOnHover={false}
        name="genres"
        value={getSelectedItems()}
        onChange={makeGenreSelections}
        labelledBy={"Selected"}
      />
      {/* <select
      className={"form-control artist-textbox-style"}
      onChange={this.setGenresList}
    >
      <option
        key="default-genre-message"
        value=""
        disabled
        selected="Search here.."
      >
        Choose Genres
      </option>
      {this.state.genre_list.map((element, i) => (
        <option key={element.ID} value={i + 1}>
          {" "}
          {element.GenreName}{" "}
        </option>
      ))}
    </select> */}
    </div>
  );
};

export default GenreOptions;
