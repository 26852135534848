export default function validateStepThree(values) {
    let errors = {}

    errors.SChandle = ""
    errors.IGhandle = ""
    errors.YThandle = ""
    errors.website = ""
    
    if(!values.stageName){
        errors.stageName = "*Required field."
    }
    else
        errors.stageName = ""

    if(!values.photoPath && values.photoPath === ''){
        errors.photoPath = "*Photo is required."
    }else
        errors.photoPath = ""
    
    if(!values.genres){
        errors.genres = "*You must select at least 1 genre."
    } else if (values.genres){
        if(values.genres.split(',').length > 2) {
            errors.genres = "*You can only select up to 2 genres."
        }
        else
        errors.genres = ""
    }
    else
        errors.genres = ""

    if(!values.checkedTerms){
        errors.checkedTerms = "*Must check and agree to Teder's Terms and Conditions"
    }else
        errors.checkedTerms = ""

    if(!values.bio && values.bio === ''){
        errors.bio = "*Please provide a short bio"
    }else
        errors.bio = ""

    if(!values.checkedPrivacy){
        errors.checkedPrivacy = "*Must check  and agree to Teder's Privacy Policy"
    }else
        errors.checkedPrivacy = ""

    return errors;
}