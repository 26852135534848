import React from "react";
import { Card, CardContent, Typography, useTheme } from "@mui/material";

const ReferralCard = ({ children = null, value, category }) => {
  const theme = useTheme();
  const { darkRed } = theme.palette.tederColors;
  const { pinkShadow } = theme.palette.layout.boxShadows;
  const styles = {
    wrapper: {
      minHeight: "5%",
      width: "auto",
      borderRadius: "10px",
      marginBottom: "5%",
      boxShadow: pinkShadow,
    },
    content: {
      padding: "3%",
      margin: 0,
      display: "flex",
      // flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
    },
    value: {
      fontSize: "1.35em",
      fontFamily: "titleFont, sans-serif",
      paddingLeft: "3%",
      color: darkRed,
      textAlign: "center",
    },
    category: {
      // fontSize: "1.35em",
      // fontWeight: "700",
      fontFamily: "bodyFont, sans-serif",
      color: darkRed,
      textAlign: "center",
    },
  };

  return (
    <Card elevation={2} sx={styles.wrapper}>
      <CardContent style={styles.content}>
        <Typography variant="subtitle1" sx={styles.category}>
          {category}
        </Typography>
        <Typography variant="h3" sx={styles.value}>
          {children ? children : value}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ReferralCard;
