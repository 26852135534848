import React, { useState, useEffect } from "react";
import "../Styling/forgotpasswordpage.css";
import { forgot_password } from "../Common/data";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

function ForgotPasswordPage() {
  const [btnClass, setBtnClass] = useState("forgot-password-unconfirm-btn");
  const [email, setEmail] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    if (email.length > 0) {
      setBtnClass("forgot-password-confirm-btn");
    } else {
      setBtnClass("forgot-password-unconfirm-btn");
    }
  }, [email]);

  const handleSubmit = (event) => {
    event.preventDefault();
    let data = {
      username: email,
    };

    forgot_password(data)
      .then((res) => {
        dispatch(push("/forgot-password/confirmation?email=" + email));
      })
      .catch((err) => console.log(err));
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
  };
  return (
    <div className="forgot-password-container">
      <div className="forgot-password-content">
        <form className="forgot-password-form" onSubmit={handleSubmit}>
          <p className="forgot-password-title">FORGOT PASSWORD</p>
          <p className="forgot-password-text">
            Please enter your email below and we will send you a code that you
            can use to reset your password.
          </p>
          <label className="forgot-password-label">Email</label>
          <input onChange={handleChange} className="forgot-password-input" />
          <button className={btnClass} disabled={email.length === 0}>
            CONFIRM
          </button>
        </form>
      </div>
    </div>
  );
}

export default ForgotPasswordPage;
