import React, { useState, useEffect } from "react";
import { DateTime } from "luxon";
import {
  Card,
  Grid,
  Link,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";

import { ArtistTable, IncomeChart, CardHeading } from "../SharedComponents";
import { useSelector } from "react-redux";

import {
  get_booking_agent_shows,
  get_ba_schedule_user_list,
} from "../../../Common/data";
import { descendingByTime } from "../../../util/helpers";

//API

const IncomeBookingAgent = () => {
  //State
  const currentState = useSelector((state) => state.TederReducerState);
  const [artistList, setArtistList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  //MUI Theme, Media Queries
  const theme = useTheme();
  const { pinkShadow } = theme.palette.layout.boxShadows;

  const mediaQueryPhone = theme.breakpoints.down("sm");
  const matches = useMediaQuery(mediaQueryPhone);

  //Styles
  const styles = {
    wrapper: {
      boxSizing: "border-box",
      margin: "0 auto",
      paddingTop: "10vh",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      background: "#f9f9fa",
      minHeight: "100vh",
      [mediaQueryPhone]: {
        overflow: "hidden",
        alignItems: "flex-start",
        paddingRight: "10%",
        width: "100vw",
      },
    },
    incomeWrapper: {
      boxSizing: "border-box",
      padding: "10px 56px",
      boxShadow: pinkShadow,
      [mediaQueryPhone]: {
        padding: "8%",
        width: "88vw",
        overflow: "scroll",
        marginBottom: "5%",
      },
    },
    highestEarning: {
      boxShadow: pinkShadow,
    },
    allArtistsText: {
      width: "100%",
      textAlign: "left",
      paddingBottom: "3vh",
      paddingLeft: "40px",
      fontFamily: "titleFont",
      fontSize: "16px",
      color: "#1a1a4a",
    },
    headerWrapper: {
      display: "flex",
      height: "44px",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
    },
    headerTitle: {
      fontFamily: "titleFont, sans-serif",
      fontSize: "15px",
      marginTop: "5px",
      color: "#1A1A4A",
    },
    headerSubTitle: {
      fontSize: "12px",
      fontFamily: "proximaNova",
      textDecoration: "underline",
      paddingLeft: "25px",
    },
  };

  const [shows, setShows] = useState([]);
  const [yearlyOrMonthly, setYearlyOrMonthly] = useState("yearly");
  const [incomeData, setIncomeData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const headers = ["name", "totalShows", "totalTicketSales", "totalEarnings"];

  useEffect(() => {
    get_booking_agent_shows()
      .then((res) => {
        console.log("ba shows", res.ba_shows);
        setShows(res.ba_shows);
      })
      .catch((error) => console.error(error));

    let baShows;
    if (yearlyOrMonthly === "yearly") {
      baShows = shows.filter((show) => show.BookingAgentID === show.ArtistID);
    } else {
      baShows = shows.filter((show) => {
        const showDateTime = DateTime.fromISO(show.show_time);
        const currentDateTime = DateTime.now();
        const { year, month } = currentDateTime;
        const [showMonth, showYear] = [showDateTime.Month, showDateTime.Year];
        return (
          // show.BookingAgentID === agent.id &&
          showMonth === month && showYear === year
        );
      });
    }
    setShows(baShows);

    if (yearlyOrMonthly === "yearly") {
      setIncomeData(currentState.baIncome[0]);
      setChartData(
        incomeData.map((month) => ({
          date: month.date?.split(0, 3),
          grossed: month?.grossed,
        }))
      );
      // shows.forEach((show) => {
      //   const monthIndex = DateTime.fromISO(show.show_time).month - 1;
      //   const grossedThisShow = show.tickets_sold * show.price;
      //   chartData[monthIndex].grossed += grossedThisShow;
      // });
    } else if (yearlyOrMonthly === "monthly") {
      const currentDateTime = DateTime.now();
      const { year, month } = currentDateTime;
      let thisMonth = {
        date: null,
        grossed: 0,
      };

      shows
        .sort((a, b) => descendingByTime(b, a))
        .forEach((show) => {
          const [showMonth, showYear] = [show.Month, show.Year];
          if (month == showMonth && year == showYear) {
            let grossedThisShow = show.TotalIncome;
            thisMonth.date = DateTime.fromISO(show.Month).toFormat("LLL dd");
            thisMonth.grossed += grossedThisShow;
          }
        });
      setIncomeData([thisMonth]);
    }
    // setIncomeData(chartData);
    //Show high eran data 19-08-2020
    get_ba_schedule_user_list()
      .then((res) => {
        const userList = JSON.parse(res.ba_assigned_artists);

        const formattedResponse = userList.map((option, index) => ({
          id: option.id,
          name: option.name,
          genres: option.Genre,
          photo: option.photo,
          timeOnTeder: option.timeOnTeder,
          totalEarnings: parseInt(option.totalEarnings),
          totalShows: option.totalShows,
          totalTicketSales: option.totalTicketSales,
          key: index + 1,
        }));
        //Todo: convert to redux store, subscribe to state in each iteration
        setArtistList(
          formattedResponse.sort((a, b) => b.totalEarnings - a.totalEarnings)
        );
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
  }, [yearlyOrMonthly]);

  const handleDataScope = (scope) => {
    setYearlyOrMonthly(scope);
  };

  const YearlyAndMonthlyLinks = () => (
    <div>
      <Link
        href="#/agent-dashboard"
        onClick={() => {
          handleDataScope("yearly");
          console.log("scope", yearlyOrMonthly);
        }}
        color="#1A1A4A"
        sx={styles.headerSubTitle}
      >
        Yearly
      </Link>
      <Link
        href="#/agent-dashboard"
        onClick={() => {
          handleDataScope("monthly");
          console.log("scope", yearlyOrMonthly);
        }}
        color="#1A1A4A"
        sx={styles.headerSubTitle}
      >
        Monthly
      </Link>
    </div>
  );
  if (!currentState.baIncome[0].length) return null;
  return (
    <Grid container spacing={matches ? 3 : 4} sx={styles.wrapper}>
      <Grid item>
        <Typography sx={styles.allArtistsText}>Income</Typography>
        <Card elevation={2} sx={styles.incomeWrapper}>
          <CardHeading
            title="YOUR TOTAL INCOME"
            component={<YearlyAndMonthlyLinks />}
          />
          <IncomeChart width={715} height={280} data={incomeData} />
        </Card>
      </Grid>
      <Grid item>
        <Card
          elevation={2}
          sx={matches ? styles.incomeWrapper : styles.highestEarning}
        >
          <CardHeading
            title="HIGHEST EARNING ARTISTS"
            style={{
              margin: "10px 10px 10px",
              // width: "88vw",
            }}
          />
          {/*<ArtistsNoDataPage message="Coming Soon" />  */}
          <div className="highest-earning-artists">
            {isLoading ? (
              <Typography variant="p" className="">
                Loading Artists...
              </Typography>
            ) : (
              <ArtistTable
                headers={headers}
                rankedByEarnings={false}
                artistList={artistList}
              />
            )}
          </div>
        </Card>
      </Grid>
    </Grid>
  );
};

export default IncomeBookingAgent;
