import React from "react";
import { Paper } from "@mui/material";
import { EventTracker } from "@devexpress/dx-react-chart";
import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  LineSeries,
  Tooltip as ChartTip,
} from "@devexpress/dx-react-chart-material-ui";

import { getIncomeChartLabel } from "../../../util/helpers";

const IncomeChart = ({ width, height, data }) => {
  const styles = {
    wrapper: {
      border: "1px solid transparent",
      boxShadow: "none",
    },
  };
  return (
    <Paper style={styles.wrapper}>
      <Chart width={width} height={height} data={data}>
        <ArgumentAxis tickSize={0} />
        <ValueAxis
          tickFormat={() => (tick) => tick > 999 ? `${tick / 1000}k` : tick}
          labelComponent={getIncomeChartLabel}
        />
        <LineSeries valueField="grossed" argumentField="date" color="#DB3554" />
        <EventTracker />
        <ChartTip contentComponent={getIncomeChartLabel} />
      </Chart>
    </Paper>
  );
};

export default IncomeChart;
