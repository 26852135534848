import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { display_show_list } from "../../Common/data";
import { updatHomepageShowsAction } from "../../redux/actions";
import { Box } from "@mui/system";
import ShowGrid from "./ShowGrid";

const Shows = () => {
  const [loadShows, setLoadShows] = useState(false);

  const dispatch = useDispatch();
  var currentState = useSelector((state) => {
    return state.TederReducerState;
  });
  useState(() => {
    if (!loadShows) {
      display_show_list()
        .then((result) => {
          dispatch(updatHomepageShowsAction(result.Recent_Show_list));
        })
        .catch((error) => {
          console.log("Error caught in update api");
          console.log(error);
          return false;
        });

      setLoadShows(true);
    }
  }, []);

  return (
    <Box component="div" sx={{ overflow: "scroll" }}>
      <span className="shows-header">This week on teder</span>
      <br />
      <ShowGrid showsList={currentState.homepageShows} />
    </Box>
  );
};

export default Shows;
