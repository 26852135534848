import React, { useState, useEffect } from "react";
import "../Styling/artistpage.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { display_my_shows } from "../Common/data";
import { updateUserShowsAction } from "../redux/actions";
import ShowCard from "../Components/HomePage/ShowCard";
import { Typography, Container, Box } from "@mui/material";
import { Link } from "react-router-dom";
import logo from "../Visuals/Teder_Logo_Transparent.png";

const MyShows = () => {
  const styles = {
    logo: {
      width: "98px",
      height: "98px",
    },
  };

  const [loadMyShows, setLoadMyShows] = useState(false);

  const dispatch = useDispatch(); //to update our redux state
  const currentState = useSelector((state) => state.TederReducerState);

  useEffect(() => {
    if (!loadMyShows) {
      let data = {};
      display_my_shows(data)
        .then((result) => {
          dispatch(updateUserShowsAction(result.My_Show_List));
        })
        .catch((error) => {
          console.log("Error caught in update api");
          console.log(error);
          return false;
        });

      setLoadMyShows(true);
    }
  }, []);

  const myShowsPage = () => {
    return (
      <div className="card-grid">
        {currentState.userShows.length &&
          currentState.userShows.map((showListing) => (
            <ShowCard key={showListing.ID} showListing={showListing} />
          ))}
      </div>
    );
  };

  const redirectUser = () => {
    dispatch(push("/login"));
    return "Please login";
  };
  if (!currentState.userShows || !currentState.userShows.length)
    return (
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h2"
          style={{ fontFamily: "titleFont", margin: "5%" }}
        >
          You Have No Upcoming Shows
        </Typography>
        <Link to="/">
          <Box
            component="img"
            src={logo}
            alt="Teder"
            loading="lazy"
            style={{ ...styles.logo }}
          />
        </Link>
      </Container>
    );
  return currentState.userContext.userLoggedIn ? myShowsPage() : redirectUser();
};

export default MyShows;
