import artists from "./JSON/artists.json"
import shows from "./JSON/shows.json"
import goals from "./JSON/goals.json"
import genres from "./JSON/genres.json"
import bookingAgents from "./JSON/bookingAgents.json"

export const allArtists = artists;
export const allShows = shows;
export const allGoals = goals;
export const allGenres = genres;
export const allBookingAgents = bookingAgents;
