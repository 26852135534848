import React from "react";
import { Card, Container, Typography } from "@mui/material";

const GoalListItem = ({ title, target, current }) => {
  const percentage = (current / target) * 100;
  const percentageOffset = `${100 - percentage}%`;

  const styles = {
    wrapper: {
      display: "flex",
      border: "1px solid transparent",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "Calc(50% - 22.5px)",
      width: "100%",
    },
    barContainer: {
      width: "80%",
      height: "7px",
      borderRadius: "2rem",
      position: "relative",
      overflow: "hidden",
      transition: "all 0.5s",
      willChange: "transform",
      background: "#FCEFF1",
      boxShadow: "0 0 1px #e76f51",
    },
    bar: {
      position: "absolute",
      height: "100%",
      width: "100%",
      content: "",
      background: "#DB3554",
      top: 0,
      bottom: 0,
      right: percentageOffset,
      borderRadius: "inherit",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    title: {
      fontSize: "12px",
      fontFamily: "titleFont",
      color: "#1A1A4A",
      marginBottom: "5px",
      textAlign: "center",
    },
    progressReached: {
      fontSize: "12px",
      fontFamily: "proximaNova",
      marginTop: "6px",
      color: "#1A1A4A",
      textAlign: "center",
    },
  };

  return (
    <Card elevation={0} style={styles.wrapper}>
      <Typography sx={styles.title}>{title}</Typography>
      <Container style={styles.barContainer}>
        <div style={styles.bar}></div>
      </Container>
      <Typography sx={styles.progressReached}>{`${Math.trunc(
        percentage
      )}% REACHED`}</Typography>
    </Card>
  );
};

export default GoalListItem;
