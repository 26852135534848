import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
// import { AuthRoles } from "../../Common/global";

var initialState = {
  // initial Sign Up
  userSignUp: {
    username: "",
    password: "",
    usertype: "",
    password_error: "",
    username_error: "",
    verification_error: "",
  },
  userContext: {
    pendingShows: 0,
    FBuserFirstName: "",
    FBuserLastName: "",
    FBuserEmail: "",
    FBUserID: "",
    FBuserToken: "",
    userID: "",
    tederAccessToken: "",
    tederRefreshToken: "",
    userLoggedIn: false,
    userType: "",
    authProviderId: "",
    authProvider: "",
    authID: "",
    isSignupCompleted: "",
  },
  userNavigation: {
    sourceUrl: "",
    redirectUrl: "",
  },
  artistContext: {
    artistID: "",
    firstName: "",
    lastName: "",
    photoPath: "",
    address: "",
    city: "",
    email: "",
    state: "",
    country: "",
    zipCode: "",
    phoneNo: "",
    bankName: "",
    routingNo: "",
    accID: "",
    accNo: "",
    stageName: "",
    genres: "",
    bio: "",
    website: "",
    SChandle: "",
    IGhandle: "",
    YThandle: "",
    TThandle: "",
    referralCode: "",
    userType: "",
  },
  baContext: {
    baId: "",
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    email: "",
    state: "",
    country: "",
    zipCode: "",
    phoneNo: "",
    photoPath: "",
    bankName: "",
    routingNo: "",
    accID: "",
    accNo: "",
    referralCode: "",
    userType: "",
    dateJoined: "",
  },

  memberContext: {
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    email: "",
    state: "",
    country: "",
    zipCode: "",
    phoneNo: "",
    referralCode: "",
    userType: "",
    perRate: "",
  },
  updateArtistErrors: {
    firstName: "",
    lastName: "",
    photoPath: "",
    address: "",
    city: "",
    email: "",
    state: "",
    country: "",
    zipCode: "",
    phoneNo: "",
    bankName: "",
    routingNo: "",
    accNo: "",
    stageName: "",
    genreList: "",
    bio: "",
    SChandle: "",
    IGhandle: "",
    YThandle: "",
    TThandle: "",
    website: "",
    isValid: true,
  },
  userRegistrationStep1: {
    userID: "",
    userType: "",
    firstName: "",
    lastName: "",
    gender: "",
    dateOfBirth: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
    country: "United States of America",
    email: "",
    phoneNo: "",
    referralCode: "",
  },
  userRegistrationStep1Errors: {
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
    email: "",
    phoneNo: "",
    isValid: false,
  },
  userRegistrationStep2: {
    bankName: "",
    routingNo: "",
    accountNo: "",
    confirmAccountNo: "",
  },
  userRegistrationStep2Errors: {
    bankName: "",
    routingNo: "",
    accountNo: "",
    confirmAccountNo: "",
    isValid: false,
  },
  userRegistrationStep3: {
    photoPath: "",
    SChandle: "",
    IGhandle: "",
    YThandle: "",
    TThandle: "",
    website: "",
    stageName: "",
    bio: "",
    checkedTerms: false,
    checkedPrivacy: false,
    genres: "",
  },
  userRegistrationStep3Errors: {
    photoPath: "",
    SChandle: "",
    IGhandle: "",
    YThandle: "",
    TThandle: "",
    website: "",
    stageName: "",
    bio: "",
    checkedTerms: "",
    checkedPrivacy: "",
    genres: "",
    isValid: false,
  },
  userShows: [],
  homepageShows: [],
  createShowState: {
    showName: "",
    genres: "",
    showDate: "",
    showTime: "",
    showTimezone: "-05:00",
    showTicketPrice: 3,
    showDuration: 60,
    showTicketMax: "",
    showDescription: "",
    photoPath: "",
    Artist_ID: "",
  },
  createShowStateErrors: {
    showName: "",
    genres: "",
    showDate: "",
    showTime: "",
    showTimezone: "",
    showTicketPrice: "",
    showDuration: "",
    showTicketMax: "",
    showDescription: "",
    photoPath: "",
    Artist_ID: "",
    isValid: false,
  },
  viewShowDetails: {
    showID: "",
    artistID: "",
    artistStageName: "",
    artistPhotoPath: "",
    artistSC: "",
    artistIG: "",
    artistYT: "",
    artistTT: "",
    artistWebsite: "",
    createdDate: "",
    showStreamKey: "",
    showPageLink: "",
    showName: "",
    genres: "",
    genreIDList: "",
    showDate: "",
    showTime: "",
    showTimezone: "",
    showTicketPrice: "",
    showDuration: "",
    showTicketMax: "",
    showDescription: "",
    photoPath: "",
    featured: "",
    status: "",
    ticketsSold: "",
  },
  editShowErrors: {
    // showStreamKey: '',
    // showPageLink: '',
    showName: "",
    genres: "",
    genreIDList: "",
    showDate: "",
    showTime: "",
    showTimezone: "",
    showDuration: "",
    showDescription: "",
    photoPath: "",
    // featured: '',
    isValid: true,
  },
  deleteShowState: {},
  countries: [],
  states: [],
  purchaseTicket: {
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    state: "",
    country: "United States of America",
    zipCode: "",
    email: "",
    phoneNo: "",
    ticketCount: 1,
    cardNo: "",
    cardName: "",
    expMonth: "",
    expYear: "",
    CVV: "",
  },
  purchaseTicketErrors: {
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    state: "",
    country: "",
    zipCode: "",
    email: "",
    phoneNo: "",
    cardNo: "",
    cardName: "",
    expMonth: "",
    expYear: "",
    CVV: "",
    isValid: false,
  },
};

export const TederReducer = (state = initialState, action) => {
  //here you can add artifacts to the state according to specific action
  switch (action.type) {
    case "SET_USER_REGISTRATION_1": {
      return {
        ...state,
        userSignUp: {
          username: action.registrationDetails.username,
          password: action.registrationDetails.password,
          userType: action.registrationDetails.usertype,
        },
      };
    }
    case "SET_USER_REGISTRATION_STEP1": {
      return {
        ...state,
        userRegistrationStep1: {
          userID: action.registrationDetails.userID,
          firstName: action.registrationDetails.firstName,
          lastName: action.registrationDetails.lastName,
          gender: action.registrationDetails.gender,
          dateOfBirth: action.registrationDetails.dateOfBirth,
          address: action.registrationDetails.address,
          city: action.registrationDetails.city,
          state: action.registrationDetails.state,
          zipCode: action.registrationDetails.zipCode,
          country: action.registrationDetails.country,
          email: action.registrationDetails.email,
          phoneNo: action.registrationDetails.phoneNo,
          referralCode: action.registrationDetails.referralCode,
          userType: action.registrationDetails.userType,
        },
      };
    }
    case "SET_USER_REGISTRATION_STEP2": {
      return {
        ...state,
        userRegistrationStep2: {
          bankName: action.registrationDetails.bankName,
          routingNo: action.registrationDetails.routingNo,
          accountNo: action.registrationDetails.accountNo,
          confirmAccountNo: action.registrationDetails.confirmAccountNo,
        },
      };
    }
    case "SET_USER_REGISTRATION_STEP3": {
      return {
        ...state,
        userRegistrationStep3: {
          photoPath: action.registrationDetails.photoPath,
          SChandle: action.registrationDetails.SChandle,
          IGhandle: action.registrationDetails.IGhandle,
          YThandle: action.registrationDetails.YThandle,
          TThandle: action.registrationDetails.TThandle,
          website: action.registrationDetails.website,
          stageName: action.registrationDetails.stageName,
          bio: action.registrationDetails.bio,
          checkedTerms: action.registrationDetails.checkedTerms,
          checkedPrivacy: action.registrationDetails.checkedPrivacy,
          genres: action.registrationDetails.genres,
        },
      };
    }
    case "SET_USER_REGISTRATION_ERRORS_STEP1": {
      var allValidStep1 =
        action.errorDetails.firstName === "" &&
        action.errorDetails.lastName === "" &&
        action.errorDetails.address === "" &&
        action.errorDetails.city === "" &&
        action.errorDetails.state === "" &&
        action.errorDetails.zipCode === "" &&
        action.errorDetails.email === "" &&
        action.errorDetails.phoneNo === ""
          ? true
          : false;

      return {
        ...state,
        userRegistrationStep1Errors: {
          firstName: action.errorDetails.firstName,
          lastName: action.errorDetails.lastName,
          address: action.errorDetails.address,
          city: action.errorDetails.city,
          state: action.errorDetails.state,
          zipCode: action.errorDetails.zipCode,
          country: action.errorDetails.country,
          email: action.errorDetails.email,
          phoneNo: action.errorDetails.phoneNo,
          isValid: allValidStep1,
        },
      };
    }
    case "SET_USER_REGISTRATION_ERRORS_STEP2": {
      var allValidStep2 =
        action.errorDetails.bankName === "" &&
        action.errorDetails.routingNo === "" &&
        action.errorDetails.accountNo === "" &&
        action.errorDetails.confirmAccountNo === ""
          ? true
          : false;
      return {
        ...state,
        userRegistrationStep2Errors: {
          bankName: action.errorDetails.bankName,
          routingNo: action.errorDetails.routingNo,
          accountNo: action.errorDetails.accountNo,
          confirmAccountNo: action.errorDetails.confirmAccountNo,
          isValid: allValidStep2,
        },
      };
    }
    case "SET_USER_REGISTRATION_ERRORS_STEP3": {
      var allValidStep3 =
        action.errorDetails.stageName === "" &&
        action.errorDetails.checkedTerms === "" &&
        action.errorDetails.checkedPrivacy === "" &&
        action.errorDetails.photoPath === "" &&
        action.errorDetails.genres === ""
          ? true
          : false;

      return {
        ...state,
        userRegistrationStep3Errors: {
          photoPath: action.errorDetails.photoPath,
          SChandle: action.errorDetails.SChandle,
          IGhandle: action.errorDetails.IGhandle,
          YThandle: action.errorDetails.YThandle,
          TThandle: action.errorDetails.TThandle,
          website: action.errorDetails.website,
          stageName: action.errorDetails.stageName,
          bio: action.errorDetails.bio,
          checkedTerms: action.errorDetails.checkedTerms,
          checkedPrivacy: action.errorDetails.checkedPrivacy,
          genres: action.errorDetails.genres,
          isValid: allValidStep3,
        },
      };
    }
    case "USER_LOGIN": {
      return {
        ...state,
        userContext: {
          ...state.userContext,
          userLoggedIn: true,
          userID: action.loginDetails.userID,
          userType: action.loginDetails.userType,
          userRole: action.loginDetails.userRole,
          tederAccessToken: action.loginDetails.tederAccessToken,
          tederRefreshToken: action.loginDetails.tederRefreshToken,
          timeLoggedIn: action.loginDetails.timeLoggedIn,
          isSignupCompleted: action.loginDetails.isSignupCompleted,
        },
      };
    }
    case "FB_USER_LOGIN": {
      return {
        ...state,
        userContext: {
          ...state.userContext,
          FBuserFirstName: action.loginDetails.FBuserFirstName,
          FBuserLastName: action.loginDetails.FBuserLastName,
          FBuserEmail: action.loginDetails.FBuserEmail,
          FBUserID: action.loginDetails.FBUserID,
          FBuserToken: action.loginDetails.FBuserToken,
        },
        userRegistrationStep1: {
          ...state.userRegistrationStep1,
          firstName:
            state.userRegistrationStep1.firstName === ""
              ? action.loginDetails.FBuserFirstName
              : state.userRegistrationStep1.firstName,
          lastName:
            state.userRegistrationStep1.lastName === ""
              ? action.loginDetails.FBuserLastName
              : state.userRegistrationStep1.lastName,
          email:
            state.userRegistrationStep1.email === ""
              ? action.loginDetails.FBuserEmail
              : state.userRegistrationStep1.email,
        },
      };
    }
    case "USER_LOGOUT": {
      return {
        ...state,
        userContext: { ...initialState.userContext },
        userRegistrationStep1: { ...initialState.userRegistrationStep1 },
        userRegistrationStep2: { ...initialState.userRegistrationStep2 },
        userRegistrationStep3: { ...initialState.userRegistrationStep3 },
        userRegistrationStep4: { ...initialState.userRegistrationStep4 },
        artistContext: { ...initialState.artistContent },
        memberContext: { ...initialState.memberContext },
      };
    }
    case "ARTIST_DETAILS": {
      return {
        ...state,
        artistContent: {
          ...state.artistContext,
          authProviderId: action.artistDetails.authProviderId,
          authProvider: action.artistDetails.authProvider,
          authID: action.artistDetails.authID,
        },
        artistContext: {
          artistId: action.artistDetails.artistID,
          firstName: action.artistDetails.firstName,
          lastName: action.artistDetails.lastName,
          photoPath: action.artistDetails.photoPath,
          address: action.artistDetails.address,
          city: action.artistDetails.city,
          email: action.artistDetails.email,
          state: action.artistDetails.state,
          country: action.artistDetails.country,
          zipCode: action.artistDetails.zipCode,
          phoneNo: action.artistDetails.phoneNo,
          bankName: action.artistDetails.bankName,
          routingNo: action.artistDetails.routingNo,
          accID: action.artistDetails.accID,
          accNo: action.artistDetails.accNo,
          stageName: action.artistDetails.stageName,
          genres: action.artistDetails.genres,
          bio: action.artistDetails.bio,
          website: action.artistDetails.website,
          SChandle: action.artistDetails.SChandle,
          IGhandle: action.artistDetails.IGhandle,
          YThandle: action.artistDetails.YThandle,
          TThandle: action.artistDetails.TThandle,
          referralCode: action.artistDetails.referralCode,
          userType: action.artistDetails.userType,
        },
      };
    }

    case "BOOKING_AGENT_DETAILS": {
      return {
        ...state,
        baContent: {
          ...state.bookingAgentDetails,
          authProviderId: action.bookingAgentDetails.authProviderId,
          authProvider: action.bookingAgentDetails.authProvider,
          authID: action.bookingAgentDetails.authID,
        },
        baContext: {
          baId: action.bookingAgentDetails.artistID,
          firstName: action.bookingAgentDetails.firstName,
          lastName: action.bookingAgentDetails.lastName,
          address: action.bookingAgentDetails.address,
          city: action.bookingAgentDetails.city,
          email: action.bookingAgentDetails.email,
          state: action.bookingAgentDetails.state,
          country: action.bookingAgentDetails.country,
          zipCode: action.bookingAgentDetails.zipCode,
          phoneNo: action.bookingAgentDetails.phoneNo,
          photoPath: action.bookingAgentDetails.photoPath,
          bankName: action.bookingAgentDetails.bankName,
          routingNo: action.bookingAgentDetails.routingNo,
          accID: action.bookingAgentDetails.accID,
          accNo: action.bookingAgentDetails.accNo,
          referralCode: action.bookingAgentDetails.referralCode,
          userType: action.bookingAgentDetails.userType,
          dateJoined: action.bookingAgentDetails.dateJoined,
        },
      };
    }

    case "BOOKING_AGENT_STATS": {
      return {
        ...state,
        baStats: {
          ...state.bookingAgentStats,
          newArtists: action.bookingAgentStats.newArtists,
          numberOfShows: action.bookingAgentStats.numberOfShows,
          revenue: action.bookingAgentStats.revenue,
          ticketsSold: action.bookingAgentStats.ticketsSold,
        },
      };
    }
    case "BOOKING_AGENT_SHOWS": {
      const relatedShows = [...action.bookingAgentShows];
      return {
        ...state,
        baShows: relatedShows,
      };
    }

    case "BOOKING_AGENT_GOALS": {
      const relatedGoals = [...action.baGoals];
      return {
        ...state,
        baGoals: [relatedGoals],
      };
    }
    case "BOOKING_AGENT_LIST": {
      const baList = [...action.baList];
      return {
        ...state,
        baList: [baList],
      };
    }
    case "BOOKING_AGENT_INCOME": {
      const baIncome = [...action.baIncome];
      return {
        ...state,
        baIncome: [baIncome],
      };
    }

    case "MEMBER_DETAILS": {
      return {
        ...state,
        memberContext: {
          firstName: action.memberDetails.firstName,
          lastName: action.memberDetails.lastName,
          address: action.memberDetails.address,
          city: action.memberDetails.city,
          email: action.memberDetails.email,
          state: action.memberDetails.state,
          country: action.memberDetails.country,
          zipCode: action.memberDetails.zipCode,
          phoneNo: action.memberDetails.phoneNo,
          referralCode: action.memberDetails.referralCode,
          userType: action.memberDetails.userType,
          perRate: action.memberDetails.perRate,
        },
      };
    }
    case "ARTIST_DETAILS_ERRORS": {
      var allArtistDetails =
        action.errorDetails.firstName === "" &&
        action.errorDetails.lastName === "" &&
        action.errorDetails.address === "" &&
        action.errorDetails.city === "" &&
        action.errorDetails.zipCode === "" &&
        action.errorDetails.email === "" &&
        action.errorDetails.phoneNo === "" &&
        action.errorDetails.bankName === "" &&
        action.errorDetails.routingNo === "" &&
        action.errorDetails.accNo === "" &&
        action.errorDetails.stageName === "" &&
        action.errorDetails.photoPath === "" &&
        action.errorDetails.bio === "" &&
        action.errorDetails.genres === ""
          ? true
          : false;

      return {
        ...state,
        updateArtistErrors: {
          firstName: action.errorDetails.firstName,
          lastName: action.errorDetails.lastName,
          address: action.errorDetails.address,
          city: action.errorDetails.city,
          state: action.errorDetails.state,
          zipCode: action.errorDetails.zipCode,
          country: action.errorDetails.country,
          email: action.errorDetails.email,
          phoneNo: action.errorDetails.phoneNo,
          bankName: action.errorDetails.bankName,
          routingNo: action.errorDetails.routingNo,
          accNo: action.errorDetails.accNo,
          photoPath: action.errorDetails.photoPath,
          SChandle: action.errorDetails.SChandle,
          IGhandle: action.errorDetails.IGhandle,
          YThandle: action.errorDetails.YThandle,
          TThandle: action.errorDetails.TThandle,
          website: action.errorDetails.website,
          stageName: action.errorDetails.stageName,
          bio: action.errorDetails.bio,
          checkedTerms: action.errorDetails.checkedTerms,
          checkedPrivacy: action.errorDetails.checkedPrivacy,
          genres: action.errorDetails.genres,
          isValid: allArtistDetails,
        },
      };
    }
    case "CREATE_SHOW": {
      return {
        ...state,
        createShowState: {
          showName: action.showDetails.showName,
          genres: action.showDetails.genres,
          showDate: action.showDetails.showDate,
          showTime: action.showDetails.showTime,
          showDuration: action.showDetails.showDuration,
          showTimeZone: action.showDetails.showTimeZone,
          showTicketPrice: action.showDetails.showTicketPrice,
          showTicketMax: action.showDetails.showTicketMax,
          showDescription: action.showDetails.showDescription,
          photoPath: action.showDetails.photoPath,
          Artist_ID: action.showDetails.Artist_ID,
        },
      };
    }
    case "RESET_SHOW": {
      return {
        ...state,
        createShowState: {
          showName: "",
          genres: "",
          showDate: "",
          showTime: "",
          showTimezone: "-05:00",
          showTicketPrice: 3,
          showDuration: 60,
          showTicketMax: "",
          showDescription: "",
          photoPath: "",
        },
      };
    }
    case "CREATE_SHOW_ERRORS": {
      var allValidCreateShow =
        !action.errorDetails.Artist_ID &&
        action.errorDetails.showName === "" &&
        action.errorDetails.genres === "" &&
        action.errorDetails.showDescription === "" &&
        action.errorDetails.photoPath === "" &&
        action.errorDetails.showDate === "" &&
        action.errorDetails.showTime === ""
          ? true
          : false;

      return {
        ...state,
        createShowStateErrors: {
          showName: action.errorDetails.showName,
          genres: action.errorDetails.genres,
          showDate: action.errorDetails.showDate,
          showTime: action.errorDetails.showTime,
          showDuration: action.errorDetails.showDuration,
          showTimezone: action.errorDetails.showTimezone,
          showTicketPrice: action.errorDetails.showTicketPrice,
          showTicketMax: action.errorDetails.showTicketMax,
          showDescription: action.errorDetails.showDescription,
          photoPath: action.errorDetails.photoPath,
          Artist_ID: action.errorDetails.Artist_ID,
          isValid: allValidCreateShow,
        },
      };
    }
    case "UPDATE_SHOW_DETAILS": {
      return {
        ...state,
        viewShowDetails: {
          showID: action.showDetails.showID,
          artistID: action.showDetails.artistID,
          artistStageName: action.showDetails.artistStageName,
          artistPhotoPath: action.showDetails.artistPhotoPath,
          artistSC: action.showDetails.artistSC,
          artistIG: action.showDetails.artistIG,
          artistYT: action.showDetails.artistYT,
          artistTT: action.showDetails.artistTT,
          artistWebsite: action.showDetails.artistWebsite,
          createdDate: action.showDetails.createdDate,
          showStreamKey: action.showDetails.showStreamKey,
          showPageLink: action.showDetails.showPageLink,
          showName: action.showDetails.showName,
          genres: action.showDetails.genres,
          genreIDList: action.showDetails.genreIDList,
          showDate: action.showDetails.showDate,
          showTime: action.showDetails.showTime,
          showTimezone: action.showDetails.showTimezone,
          showTicketPrice: action.showDetails.showTicketPrice,
          showDuration: action.showDetails.showDuration,
          showTicketMax: action.showDetails.showTicketMax,
          showDescription: action.showDetails.showDescription,
          photoPath: action.showDetails.photoPath,
          featured: action.showDetails.featured,
          status: action.showDetails.status,
          ticketsSold: action.showDetails.ticketsSold,
        },
      };
    }
    case "UPDATE_SHOW_ERRORS": {
      var allValidUpdateShow =
        action.errorDetails.showName === "" &&
        action.errorDetails.genres === "" &&
        action.errorDetails.showDate === "" &&
        action.errorDetails.showTime === "" &&
        !action.errorDetails.showTimezone &&
        action.errorDetails.showDuration === "" &&
        action.errorDetails.showDescription === "" &&
        action.errorDetails.photoPath === ""
          ? true
          : false;

      return {
        ...state,
        editShowErrors: {
          // showStreamKey: '',
          // showPageLink: '',
          showName: action.errorDetails.showName,
          genres: action.errorDetails.genres,
          showDate: action.errorDetails.showDate,
          showTime: action.errorDetails.showTime,
          showTimezone: action.errorDetails.showTimezone,
          showDuration: action.errorDetails.showDuration,
          showDescription: action.errorDetails.showDescription,
          photoPath: action.errorDetails.photoPath,
          // featured: '',
          isValid: allValidUpdateShow,
        },
      };
    }
    case "UPDATE_USER_SHOWS": {
      return {
        ...state,
        userShows: action.userShowsList,
      };
    }
    case "UPDATE_HOMEPAGE_SHOWS": {
      return {
        ...state,
        homepageShows: action.homepageShowsList,
      };
    }
    case "UPDATE_USER_NAVIGATION": {
      return {
        ...state,
        userNavigation: action.navigationDetails,
      };
    }
    case "LOAD_COUNTRIES": {
      return {
        ...state,
        countries: action.countriesList,
      };
    }
    case "LOAD_STATES": {
      return {
        ...state,
        states: action.statesList,
      };
    }
    case "PURCHASE_TICKET": {
      return {
        ...state,
        purchaseTicket: {
          firstName: action.ticketDetails.firstName,
          lastName: action.ticketDetails.lastName,
          address: action.ticketDetails.address,
          city: action.ticketDetails.city,
          state: action.ticketDetails.state,
          country: action.ticketDetails.country,
          zipCode: action.ticketDetails.zipCode,
          email: action.ticketDetails.email,
          phoneNo: action.ticketDetails.phoneNo,
          ticketCount: action.ticketDetails.ticketCount,
          cardNo: action.ticketDetails.cardNo,
          cardName: action.ticketDetails.cardName,
          expMonth: action.ticketDetails.expMonth,
          expYear: action.ticketDetails.expYear,
          CVV: action.ticketDetails.CVV,
        },
      };
    }
    case "PURCHASE_TICKET_ERRORS": {
      var ticketDetailsErrors =
        action.errorDetails.firstName === "" &&
        action.errorDetails.lastName === "" &&
        action.errorDetails.address === "" &&
        action.errorDetails.city === "" &&
        action.errorDetails.state === "" &&
        action.errorDetails.zipCode === "" &&
        action.errorDetails.email === "" &&
        // action.errorDetails.phoneNo === "" &&
        action.errorDetails.cardNo === "" &&
        action.errorDetails.cardName === "" &&
        action.errorDetails.expMonth === "" &&
        action.errorDetails.expYear === "" &&
        action.errorDetails.CVV === ""
          ? true
          : false;

      return {
        ...state,
        purchaseTicketErrors: {
          firstName: action.errorDetails.firstName,
          lastName: action.errorDetails.lastName,
          address: action.errorDetails.address,
          city: action.errorDetails.city,
          state: action.errorDetails.state,
          zipCode: action.errorDetails.zipCode,
          country: action.errorDetails.country,
          email: action.errorDetails.email,
          phoneNo: action.errorDetails.phoneNo,
          cardNo: action.errorDetails.cardNo,
          cardName: action.errorDetails.cardName,
          expMonth: action.errorDetails.expMonth,
          expYear: action.errorDetails.expYear,
          CVV: action.errorDetails.CVV,
          isValid: ticketDetailsErrors,
        },
      };
    }
    case "ADMIN_LOAD_TICKETS_PER_SHOW":
      const ticketsPerShow = [...action.shows];
      return { ...state, adminTicketsPerShow: ticketsPerShow };
    //  case "ADMIN_LOAD_TICKETS_PER_SHOW"
    case "CLEAR_DATA":
      return { ...initialState, homepageShows: state.homepageShows };

    case "CLEAR_SHOW_DETAILS":
      return { ...state, viewShowDetails: initialState.viewShowDetails };
    case "CLEAR_CREATE_SHOW_STATE":
      return { ...state, createShowState: initialState.createShowState };
    case "CLEAR_SHOW_ERRORS":
      return { ...state, createShowStateErrors: initialState.createShowState };
    case "SET_GLOBAL_ROUTE":
      return { ...state, globalRoute: action.globalRoute };

    default: {
      return state;
    }
  }
};

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    TederReducerState: TederReducer,
  });

export default createRootReducer;
