import React, { useMemo } from "react";
import { CardContent, Container, Typography, Link } from "@mui/material";
import { convertDateString } from "../../../util/helpers";
import { ArtistBlock } from "../SharedComponents";
import { addSpace } from "../../../util/helpers";

const ArtistsToBookListItem = ({ artist, index, dismiss }) => {
  const styles = {
    wrapper: {
      height: "100%",
      padding: "0",
      borderBottom: "1px solid #dddddd",
    },
    content: {
      display: "flex",
      alignItems: "center",
      padding: "0 5px",
      height: "70px",
    },
    avatar: {
      height: "48px",
      width: "48px",
      objectFit: "cover",
      borderRadius: "0%",
    },
    artistName: {
      fontSize: "14px",
      fontFamily: "titleFont",
      color: "#1A1A4A",
    },
    artistInfo: {
      fontSize: "12px",
      fontFamily: "proximaNova",
      color: "#1A1A4A",
    },
    dismissButton: {
      padding: 0,
      width: "34px",
      fontSize: "10px",
      textAlign: "left",
      fontFamily: "proximaNova",
    },
  };

  return (
    <CardContent key={index} style={styles.wrapper}>
      <ArtistBlock
        name={artist.name}
        genre={addSpace(artist.genres)}
        photo={artist.photo}
      />
      <Container style={{ padding: 0 }}>
        <Typography sx={styles.artistInfo}>
          Joined: {convertDateString(artist.timeOnTeder.replace(/-/g, "/"))}
        </Typography>
        <Link
          underline="hover"
          href="#/agent-dashboard"
          onClick={() => dismiss(artist.id)}
          color="#1A1A4A"
          sx={styles.dismissButton}
        >
          Dismiss
        </Link>
      </Container>
    </CardContent>
  );
};

export default ArtistsToBookListItem;
