import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { AuthRoles } from "../Common/global";
import { push } from "connected-react-router";
import AdminAllShows from "../Components/Admin/AdminAllShows";
import AdminAllUsers from "../Components/Admin/AdminAllUsers";
import AdminGeneral from "../Components/Admin/AdminGeneral";
import { admin_get_shows, admin_get_users } from "../Common/data";
import AdminTicketsInfo from "../Components/Admin/AdminTicketsInfo";
import AdminArtistBankInfo from "../Components/Admin/AdminArtistBankInfo";
import AdminUsersChildTab from "../Components/Admin/AdminUsersChildTab";
import Grid from "@mui/material/Grid";
import logo from "../Visuals/Teder_Logo_Transparent.png";
const AdminPage = () => {
  const currentState = useSelector((state) => state.TederReducerState);

  const dispatch = useDispatch(); //to update our redux state
  if (
    !currentState.userContext.userLoggedIn &&
    !currentState.userContext.userRole === AuthRoles.ADMIN
  ) {
    dispatch(push("/login"));
  }

  const [loadAllShows, setLoadAllShows] = useState(false);
  const [loadAllUsers, setloadAllUsers] = useState(false);
  const [allShows, setAllShows] = useState([]);
  const [allUsers, setAllUsers] = useState([]);

  useEffect(() => {
    if (!loadAllShows) {
      let data = {
        artist_id: "",
        start_date: "",
        show_name: "",
      };

      admin_get_shows(data)
        .then((result) => {
          const reversedShowList = result.Show_List.reverse();
          setAllShows(reversedShowList);
        })
        .catch((error) => {
          return false;
        });

      setLoadAllShows(true);
    }

    if (!loadAllUsers) {
      let data = {};

      admin_get_users(data)
        .then((result) => {
          const reversedUsersList = result.users_List.reverse();
          setAllUsers(reversedUsersList);
        })
        .catch((error) => {
          console.error(error);
        });

      setloadAllUsers(true);
    }
  }, []);

  const stateUpdateForAdminShows = (data) => {
    setAllShows(data);
  };

  const adminPage = () => (
    <div className="container-fluid content-padding adminWrapBlock">
      <Grid
        container
        spacing={0}
        direction="row"
        justifyContent="left"
        alignItems="center"
      >
        <Grid item xs={1}>
          <img src={logo} alt="Teder" width="120" height="120" />
        </Grid>
        <Grid item xs={11} textAlign="left">
          <h2 className="mb-0 left-text">Admin Page</h2>
        </Grid>
      </Grid>
      <Grid container spacing={0} direction="row">
        <Grid item xs={12}>
          <div className="adminRow">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  id="general-tab"
                  data-toggle="tab"
                  href="#generalContent"
                  role="tab"
                  aria-controls="general-tab"
                  aria-selected="true"
                >
                  General
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="users-tab"
                  data-toggle="tab"
                  href="#users"
                  role="tab"
                  aria-controls="users-tab"
                  aria-selected="false"
                >
                  Users
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="shows-tab"
                  data-toggle="tab"
                  href="#showsContent"
                  role="tab"
                  aria-controls="shows-tab"
                  aria-selected="false"
                >
                  Shows
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="tickets-tab"
                  data-toggle="tab"
                  href="#tickets"
                  role="tab"
                  aria-controls="tickets-tab"
                  aria-selected="false"
                >
                  Tickets
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="artist-bank-info-tab"
                  data-toggle="tab"
                  href="#artist-bank-info"
                  role="tab"
                  aria-controls="artist-bank-info-tab"
                  aria-selected="false"
                >
                  Artist Bank Info
                </a>
              </li>
              {/* <li className="nav-item">
                    <a className="nav-link" id="transactions-tab" data-toggle="tab" href="#transactions" role="tab" aria-controls="transactions-tab" aria-selected="false">Transactions</a>
                </li> */}
            </ul>
            <div className="tab-content" id="user-tab-content">
              <div
                className="tab-pane fade show active"
                id="generalContent"
                role="tabpanel"
                aria-labelledby="general-tab"
              >
                <AdminGeneral />
              </div>
              <div
                className="tab-pane admin-child-users fade"
                id="users"
                role="tabpanel"
                aria-labelledby="users-tab"
              >
                {/* <AdminAllUsers allUsers={allUsers} /> */}
                <ul
                  className="nav nav-tabs mb-3"
                  id="userchildtab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link active"
                      id="userchild-tab-1"
                      data-toggle="tab"
                      href="#userchild-tabs-1"
                      role="tab"
                      aria-controls="ex1-tabs-1"
                      aria-selected="true"
                    >
                      Artists
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      id="userchild-tab-2"
                      data-toggle="tab"
                      href="#userchild-tabs-2"
                      role="tab"
                      aria-controls="userchild-tabs-2"
                      aria-selected="false"
                    >
                      Booking Agent
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      id="userchild-tab-3"
                      data-toggle="tab"
                      href="#userchild-tabs-3"
                      role="tab"
                      aria-controls="userchild-tabs-3"
                      aria-selected="false"
                    >
                      Users
                    </a>
                  </li>
                </ul>
                <div className="tab-content" id="ex1-content">
                  <div
                    className="tab-pane fade show active"
                    id="userchild-tabs-1"
                    role="tabpanel"
                    aria-labelledby="userchild-tab-1"
                  >
                    <AdminUsersChildTab
                      Payload={{ user_type: "artist" }}
                      user={"artist"}
                    />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="userchild-tabs-2"
                    role="tabpanel"
                    aria-labelledby="userchild-tab-2"
                  >
                    <AdminUsersChildTab
                      Payload={{ user_type: "booking_agent" }}
                      user={"booking_agent"}
                    />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="userchild-tabs-3"
                    role="tabpanel"
                    aria-labelledby="userchild-tab-3"
                  >
                    <AdminUsersChildTab
                      Payload={{ user_type: "user" }}
                      user={"user"}
                    />
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="showsContent"
                role="tabpanel"
                aria-labelledby="shows-tab"
              >
                <AdminAllShows
                  allShows={allShows}
                  stateUpdateForAdminShows={stateUpdateForAdminShows}
                />
              </div>
              <div
                className="tab-pane fade"
                id="tickets"
                role="tabpanel"
                aria-labelledby="tickets-tab"
              >
                <div className="row adminRow">
                  <AdminTicketsInfo allUsers={allUsers} />
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="artist-bank-info"
                role="tabpanel"
                aria-labelledby="artist-bank-info-tab"
              >
                <div className="row adminRow">
                  <AdminArtistBankInfo allUsers={allUsers} />
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
  const redirectUser = () => {
    dispatch(push("/"));
    return "Nice try";
  };
  return currentState.userContext.userRole === AuthRoles.ADMIN
    ? adminPage()
    : redirectUser();
};

export default AdminPage;
