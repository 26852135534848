async function customHubspotAPI(properties = []) {
  //Todo: convert to env variables
  const PORTAL_ID = "22555143";
  const GUI_ID = "abac74ec-d0a5-43f0-963f-e655b60ae300";
  const API = `https://api.hsforms.com/submissions/v3/integration/submit/${PORTAL_ID}/${GUI_ID}`;

  const timeStamp = Date.now();

  /* Hubspot expects custom properties to be an array of objects shaped like:
   [{ name: String , value: String }] */
  const formattedProperties = properties.map((propertyName) => ({
    ...propertyName,
    objectTypeId: "0-1",
  }));
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      submittedAt: timeStamp,
      fields: formattedProperties,
    }),
  };
  const response = await fetch(API, options);
  const data = await response.json();

  if (!data) return "No data found";
  return data;
}

export default customHubspotAPI;
