import React, { useEffect } from "react";
import { animateScroll as scroll } from "react-scroll";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Box, useTheme } from "@mui/system";
const Landing = () => {
  const theme = useTheme();
  const mediaQuery = theme.breakpoints.down("sm");
  const dispatch = useDispatch();

  const styles = {
    wrapper: {
      display: "flex",
      [mediaQuery]: {
        flexDirection: "column",
      },
    },
  };

  useEffect(() => dispatch({ type: "CLEAR_SHOW_DETAILS" }), []);

  return (
    <div className="headline-container">
      <div>
        <h1 className="headline-title" onClick={() => scroll.scrollTo(1800)}>
          YOUR GLOBAL LIVE STAGE
        </h1>
        <p>
          Teder is a live streaming platform designed to ensure artists get paid
          for doing <br />
          what they love, while offering a full live concert experience for
          their fans.
        </p>
        <br />
        <Box component="div" sx={styles.wrapper}>
          <Link
            to="/"
            onClick={() => scroll.scrollTo(650)}
            type="button"
            className="landing-btn"
          >
            EXPLORE SHOWS
          </Link>
          <Link to="/signup/3" type="button" className="landing-btn">
            SIGN UP
          </Link>
        </Box>
      </div>
    </div>
  );
};

export default Landing;
