import React from "react";
import {
  Card,
  List,
  Divider,
  CircularProgress,
  useTheme,
  Typography,
} from "@mui/material";
import { CardHeading } from "../SharedComponents";
import ArtistsToBookListItem from "./ArtistsToBookListItem";
import { BA_DASHBOARD_ROUTES } from "../../../util/constants";

const ArtistsToBookCard = ({ data, handleDismiss, loading, selectRoute }) => {
  const theme = useTheme();
  const { pinkShadow } = theme.palette.layout.boxShadows;
  const { darkRed } = theme.palette.tederColors;
  const styles = {
    wrapper: {
      height: "390px",
      padding: "0 11px",
      marginBottom: "5%",
      boxShadow: pinkShadow,
    },
    noData: {
      padding: "10%",
      color: darkRed,
      textAlign: "center",
      fontSize: "1em",
      fontFamily: "titlefont",
    },
  };

  return (
    <Card elevation={2} style={styles.wrapper}>
      <CardHeading
        title="ARTISTS TO BOOK"
        subTitle="SEE ALL"
        selectRoute={selectRoute}
        routeSelection={BA_DASHBOARD_ROUTES.Artists}
      />
      <Divider />
      {!data.length && (
        <Typography variant="h5" sx={styles.noData}>
          You Have No Artists to Book
        </Typography>
      )}
      <List>
        {data.length &&
          data
            .slice(0, 3)
            .map((artist, index) =>
              loading ? (
                <CircularProgress key={index} style={styles.loading} />
              ) : (
                <ArtistsToBookListItem
                  key={index}
                  artist={artist}
                  index={index}
                  dismiss={handleDismiss}
                />
              )
            )}
      </List>
    </Card>
  );
};

export default ArtistsToBookCard;
