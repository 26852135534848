import React, { useState } from 'react'
import "../../Styling/artistsignup.css";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from 'emailjs-com';
import NumberFormat from 'react-number-format';
// import  { useState, useEffect } from "react";
// import {submit_contact_message} from '../../Common/data'


const ContactUsPage = ({ history }) => {
    const recaptchaRef = React.createRef();
    // const [state,setState]=useState(false);
    // const [errorMessage, setErrorMessage] = useState()
    // const [firstName, setFirstName] = useState()

    // // const generateLink = () => {

    // //     var link= "mailto:"
    // //     // var email = document.getElementById('email').value
    // //     // var name=  document.getElementById('fname').value
    // //     // var phone = document.getElementById('phone').value
    // //     // var help = document.getElementById('help').value
    // //     // var body=  help + "<br/>" +  name + "<br/>" + phone

    // //     // link=link + email + "?subject=Contact%20Teder&body=" + body
    // //     return link
    // // }
    // const onChange=(value) =>{
    //console.log("Captcha value:", value);
    // }

    // const HandleOnBlur=(e) => {
    // console.log(e.target.id)
    // setFirstName(e.target.value)
    // }

    // const handleSubmit = (item) => {
    //     const recaptchaValue = recaptchaRef.current.getValue();
    //     if (recaptchaValue===undefined || recaptchaValue==="")
    //     {
    //         console.log("Error")
    //         setErrorMessage("Please Validate you are not a robot with Google Captcha.")
    //     }
    //     else
    //     {
    //         setErrorMessage("")
    //     }
    //     // validate the fields

    //     //if everythign is good - we submit a call to the api
    //     const contact_info = {
    //         "firstName": firstName,
    //         "lastName": "lastNameGoesHere"
    //     }
    //     console.log(contact_info)

    //     submit_contact_message(contact_info).then((result) => {
    //         //if server respond http 200 - OK message then we can see what came back.
    //         console.log(result)

    //         setErrorMessage("Your Message has been submited.")
    //     }).catch((error) =>{
    //         //if api call failed - we catch it here
    //         setErrorMessage("There was an error submitting your request.")
    //         console.log(error)
    //     })
    // }


    // return (
    //     <div className="container teder-form-contatiner">
    //         <br/>
    //         <h2 className="text-center">Contact Us</h2><br/>
    //             <form>
    //                 <div className="form-group">
    //                     <label className="teder-form-label" for="fname">Name</label>
    //                     <input type="text" onBlur={HandleOnBlur} className="form-control artist-textbox-style" id="fname" placeholder="Name" />
    //                 </div>
    //                 <div className="form-group">
    //                     <label className="teder-form-label" for="email">Email address</label>
    //                     <input type="email" className="form-control artist-textbox-style" id="email" placeholder="Email" />
    //                 </div>
    //                 <div className="form-group">
    //                     <label className="teder-form-label" for="phone">Phone number</label>
    //                     <input type="text" className="form-control artist-textbox-style" id="phone" placeholder="Phone"/>
    //                 </div>
    //                 <div className="form-group">
    //                     <label className="teder-form-label" for="help">What can we help you with?</label>
    //                     <textarea className="form-control artist-textbox-style" id="help" placeholder="Type your message here." rows="3"></textarea>
    //                 </div>
    //                 <br/>
    //                 <div className="d-flex justify-content-center">
    //                     <ReCAPTCHA
    //                         ref={recaptchaRef}
    //                         sitekey="6LdnocIZAAAAAOmOkiSnG5kq2fEMJ1b-1Wbj3AKu"
    //                         onChange={onChange}
    //                     />
    //                 </div>
    //                 <br/>
    //                 <div className="text-center">

    //                     <button type="button" className="story-btn" onClick={handleSubmit}>Submit</button>
    //                     {/* <small>{errorMessage}</small> */}
    //                 </div>
    //             </form>

    //         <div>
    //     <br/><br/><br/>
    //     </div>
    //     </div>
    // )

    const [contactForm, setContactForm] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });
    const [showErrors, setShowErrors] = useState(false);

    // const formatPhoneNumber = (e) => {
    //     let formattedNumber;

    //     const { name, value } = e.target;
    //     const { length } = value;

    //     // Filter non numbers
    //     const regex = () => value.replace(/[^0-9.]+/g, "");
    //     // Set area code with parenthesis around it
    //     const areaCode = () => `(${regex().slice(0, 3)})`;
    //     // Set formatting for first six digits
    //     const firstSix = () => `${areaCode()} ${regex().slice(3, 6)}`;
    //     // Dynamic trail as user types
    //     const trailer = (start) => `${regex().slice(start, regex().length)}`;

    //     if (length < 3) {
    //       // First 3 digits
    //       formattedNumber = regex();
    //     } else if (length === 4) {
    //       // After area code
    //       formattedNumber = `${areaCode()} ${trailer(3)}`;
    //     } else if (length === 5) {
    //       // When deleting digits inside parenthesis
    //       formattedNumber = `${areaCode().replace(")", "")}`;
    //     } else if (length > 5 && length < 9) {
    //       // Before dash
    //       formattedNumber = `${areaCode()} ${trailer(3)}`;
    //     } else if (length >= 10) {
    //       // After dash
    //       formattedNumber = `${firstSix()}-${trailer(6)}`;
    //     }

    //     const formattedObject = {
    //       target: {
    //         name: name,
    //         value: formattedNumber,
    //       },
    //     };

    //     handleChange(formattedObject);
    // };

    const emailError = () => {
        if (!contactForm.email) {
            return <small className="small-green-text">*Required field.</small>;
        } else if (!/\S+@\S+\.\S+/.test(contactForm.email)) {
            return <small className="small-green-text">*Email address is invalid</small>;
        } else {
            return ""
        }
    }

    // const phoneError = () => {
    //     if (!contactForm.phone || contactForm.phone.length === 0) {
    //         return <small className="small-green-text">*Required field</small>;
    //     } else if (contactForm.phone && contactForm.phone.replace(/[^0-9\.]+/g, "").length < 10) {
    //         return <small className="small-green-text">*Enter a valid phone number</small>;
    //     } else {
    //         return ""
    //     }
    // }

    const handleChange = (e) => {
        const { name, value } = e.target;
        let userInput = { ...contactForm, [name]: value };
        setContactForm(userInput);
    }

    const handlePhone = value => {
        const userInput = { ...contactForm, 'phone': value.formattedValue };
        setContactForm(userInput);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const recaptchaValue = recaptchaRef.current.getValue();
        if (recaptchaValue === undefined || recaptchaValue === "") {
            alert("Please Validate you are not a robot with Google Captcha.")
        }
        else {
            if (
                contactForm.name === '' ||
                emailError() !== '' ||
                // phoneError() !== '' ||
                contactForm.message === ''
            ) {
                setShowErrors(true);
                window.scrollTo(0, 0);
            } else {
                sendEmail(e);
            }
        }

    }

    const sendEmail = (e) => {
        emailjs.sendForm('service_afeb34f', 'template_hvkqvhj', e.target, 'user_H45uHmiuGyyL79t5gcmsa')
            .then((result) => {
                alert("Thank you, we'll be in touch with you shortly!")
                history.push('/')
            }, (error) => {
                console.log(error.text);
            });
    }

    return (
        <div className="container teder-form-contatiner">
            <h2 className="text-center">Contact Us</h2><br />
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="name" className="teder-form-label">Name</label><br />
                    <input id="name" type="text" className="form-control artist-textbox-style" placeholder="Name" name="name" value={contactForm.name} onChange={handleChange} />
                    {showErrors && contactForm.name === '' && <><small className="small-green-text">*Required.</small></>}
                </div>
                <div className="form-group">
                    <label htmlFor="email" className="teder-form-label">E-mail Address</label><br />
                    <input id="email" type="text" className="form-control artist-textbox-style" placeholder="E-mail" name="email" value={contactForm.email} onChange={handleChange} />
                    {showErrors && emailError()}
                </div>
                {/* <div className="form-group">
                    <label htmlFor="phone" className="teder-form-label">Phone</label>
                    <input id="phone" type="text" className="form-control artist-textbox-style" placeholder="Phone" name="phone" value={contactForm.phone} onChange={formatPhoneNumber} maxLength={14}/>
                    {showErrors && phoneError()}
                </div> */}
                <div className="form-group">
                    <label htmlFor="phone" className="teder-form-label">Phone</label>
                    <NumberFormat type="tel" id="phoneNo" name="phoneNo" format="(###) ###-####" mask="_" className="form-control artist-textbox-style" placeholder="Phone Number" onValueChange={handlePhone} required />
                </div>
                <div className="form-group">
                    <label className="teder-form-label" htmlFor="message">What can we help you with?</label>
                    <textarea className="form-control artist-textbox-style" id="message" placeholder="Type your message here." name="message" value={contactForm.message} onChange={handleChange}></textarea>
                    {showErrors && contactForm.message === '' && <><small className="small-green-text">*Required.</small></>}
                </div>
                <br />
                <div className="d-flex justify-content-center">
                    <ReCAPTCHA ref={recaptchaRef} sitekey="6LdnocIZAAAAAOmOkiSnG5kq2fEMJ1b-1Wbj3AKu" />
                </div>
                <br />
                <div className="text-center">
                    <button type="submit" className="story-btn">Submit</button>
                </div>
            </form>
            <div>
                <br /><br /><br />
            </div>
        </div>
    )
}

export default ContactUsPage