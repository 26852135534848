import React from "react";
import ShowCard from "./ShowCard";
import { Box } from "@mui/system";

const ShowGrid = ({ showsList }) => {
  const get_show_grid = () => {
    let result;
    if (showsList !== undefined) {
      result = showsList.map((showListing) => (
        <ShowCard key={showListing.ID} showListing={showListing} />
      ));

      return result;
    }
  };
  return (
    <Box>
      <div className="card-grid">{get_show_grid()}</div>
    </Box>
  );
};

export default ShowGrid;
