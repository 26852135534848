// External Components + Libraries
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { display_my_shows } from "../Common/data";
import ShowGrid from "../Components/HomePage/ShowGrid";
import { updateUserShowsAction } from "../redux/actions";
import { Box, Container, Typography, useTheme } from "@mui/material";

//Custom Components
import ShowCard from "../Components/HomePage/ShowCard";
import ArtistSocialMediaHandles from "../Components/BookingAgentPages/SharedComponents/ArtistSocialMediaHandles";
import ArtistBanner from "../Components/BookingAgentPages/SharedComponents/ArtistBanner";

import { GreenTederButton } from "../Components/BookingAgentPages/SharedComponents/Buttons";

//SVGs
import email from "./assets/svgs/email.svg";
import phone from "./assets/svgs/phone.svg";
import edit from "./assets/svgs/edit.svg";

import "../Styling/artistpage.css";

import Modal from "react-modal";

//Data
import { UserType } from "../util/constants";

const ArtistPage = ({ artistDetails }) => {
  const currentState = useSelector((state) => state.TederReducerState);
  const dispatch = useDispatch(); //to update our redux state

  //Mui Styling
  const theme = useTheme();
  const { editIconSizing } = theme.palette.icons;
  const { gradientToPink } = theme.palette.layout.background;
  const { darkBlue, green01 } = theme.palette.tederColors;
  const mediaQuery = theme.breakpoints.down("sm");

  //Styles
  const styles = {
    wrapper: {
      display: "flex",
      justifyContent: "space-evenly",
      marginTop: "9%",
      padding: "0 10% 0 10%",
      flexDirection: "column",
      background: gradientToPink,
      color: "black",
      height: "auto",
      [mediaQuery]: {
        padding: "0 2% 0 2%",
      },
    },
    aboutHeader: {
      textAlign: "left",
      fontSize: "1.2rem",
      fontFamily: "titleFont",
      fontWeight: "bolder",
      textTransform: "uppercase",
      letterSpacing: "4px",
      marginBottom: "10px",
      marginLeft: "5px",
      color: "white",
    },

    socials: {
      display: "flex",
      width: "50%",
      color: "white",
      justifyContent: "flex-start",
      alignItems: "left",
      [mediaQuery]: {
        flexDirection: "column",
      },
    },

    socialsIcon: {
      width: "30px",
      height: "30px",
      margin: "0 5% 2% 0",
    },

    artistProfileContainer: {
      border: "1px solid #444",
      boxSizing: "border-box",
      maxWidth: "100%",
      background: darkBlue,
      overflow: "scroll",
    },

    buttonColumn: {
      textAlign: "right",
      margin: "-120px 0 20px -25px",
    },
    aboutWrapper: {
      margin: "5% 0 5% 0",
      minWidth: "30%",
      height: "auto",
      borderRadius: "15px",
      background:
        "linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.184) 100%)",
      boxShadow: "10px 11px 25px rgba(26, 26, 74, 0.1)",
    },

    line: {
      height: "1px",
      textAlign: "center",
      width: "100%",
      margin: "0 auto",
      background: "#FFFFFF",
    },

    shows: {
      marginTop: "10px",
      marginBottom: "5%",
      fontSize: "0.9rem",
      textAlign: "center",
      paddingRight: "100%",
      color: "rgba(247, 247, 247, 0.945)",
      [mediaQuery]: {
        paddingRight: "24%",
      },
    },

    paragraph: {
      textAlign: "justify",
      justifyContent: "center",
      paddingRight: "10px",
      color: "white",
      margin: "0 0 8% 13%",
    },

    bio: {
      padding: "3%",
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      justifyContent: "space-evenly",
    },
  };

  const [loadMyShows, setLoadMyShows] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);

  if (!loadMyShows) {
    let data = {};

    display_my_shows(data)
      .then((result) => {
        dispatch(updateUserShowsAction(result.My_Show_List));
      })
      .catch((error) => {
        console.log(error);
        return false;
      });

    setLoadMyShows(true);
  }

  const renderArtistContent = () => {
    if (!currentState.userContext.userLoggedIn) {
      dispatch(push("/login"));
    }
    if (currentState.userContext.userType === UserType.BookingAgent) {
      dispatch(push("/agent-dashboard"));
    }
    return (
      <>
        <Box component="div" style={styles.artistProfileContainer}>
          <br />
          <Box component="div" sx={styles.wrapper}>
            <ArtistBanner
              artistPhoto={currentState.artistContext.photoPath}
              stageName={currentState.artistContext.stageName}
              firstName={currentState.artistContext.firstName}
              lastName={currentState.artistContext.lastName}
              genre={currentState.artistContext.genres}
              city={currentState.artistContext.city}
              state={currentState.artistContext.state}
              phoneNumber={currentState.artistContext.phoneNo}
              emailAddress={currentState.artistContext.email}
              genres={currentState.artistContext.genres}
              emailIcon={email}
              phoneIcon={phone}
              editIcon={edit}
            />
            <Box component="div" sx={styles.aboutWrapper}>
              <Box
                component="div"
                style={{
                  borderBottom: "solid 1px white",
                  padding: "2%",
                  display: "flex",
                  flexDirection: "row",

                  justifyContent: "space-between",
                }}
              >
                <Typography sx={styles.aboutHeader}>About</Typography>
                {/* <Box
                  component="img"
                  src={edit}
                  alt="edit icon"
                  style={editIconSizing}
                /> */}
              </Box>
              <Box component="div" style={styles.bio}>
                <Box
                  component="div"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  <Typography
                    sx={{
                      ...styles.aboutHeader,
                      color: green01,
                      marginBottom: "5%",
                    }}
                  >
                    Bio
                  </Typography>
                  <Typography sx={styles.paragraph}>
                    {currentState.artistContext.bio}
                  </Typography>
                </Box>
                <Box component="div" sx={styles.socials}>
                  <Typography sx={{ ...styles.aboutHeader, color: green01 }}>
                    Socials
                  </Typography>
                  <ArtistSocialMediaHandles artistDetails={artistDetails} />
                </Box>
              </Box>
            </Box>
            <div className="artist-scheuleshow-detail">
              <Typography sx={{ ...styles.aboutHeader, color: green01 }}>
                NEXT SHOW
              </Typography>
              <br />
              {currentState.userShows && currentState.userShows.length > 0 ? (
                <>
                  <ShowGrid showsList={currentState.userShows} />
                </>
              ) : (
                <></>
              )}
            </div>
          </Box>
        </Box>

        <Modal
          isOpen={modalIsOpen}
          ariaHideApp={false}
          className="Modal"
          overlayClassName="Overlay"
          contentLabel="Bank Modal"
        >
          <Container>
            <Box>
              <Typography>Please enter your bank account</Typography>
              <Box>
                <br />
                Before you can schedule a show, please set up your bank account
                in{" "}
                <Link to="/artist/my-account/" className="footer-link-tag">
                  my account
                </Link>
                .
              </Box>
              <br />
              <Box>
                <GreenTederButton
                  type="button"
                  onClick={() => setIsOpen(false)}
                >
                  close
                </GreenTederButton>
              </Box>
            </Box>
          </Container>
        </Modal>
      </>
    );
  };

  return <>{artistDetails ? renderArtistContent() : null}</>;
};

export default ArtistPage;
