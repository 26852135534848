import React from "react";

const PrivacyPage = () => {
  return (
    <div className="container">
      <div>
        <br />
        <h2>Privacy Policy</h2>
      </div>
      <br />
      <div className="row justify-content-start">
        <div className="col-sm">
          <p>
            TEDER, LLC PRIVACY POLICY AGREEMENT Teder, LLC (Company) values its
            users' privacy. This Privacy Policy ("Policy") will help you
            understand how we collect and use personal information from those
            who visit our website or make use of our online services. This
            policy further explains what we will and will not do with the
            information we collect. Our Policy has been designed and created to
            ensure those affiliated with Company of our commitment and
            realization of our obligation not only to meet, but to exceed, most
            existing privacy standards.
          </p>
          <p>
            {" "}
            Company reserves the right to make changes to this Policy at any
            time. It is suggested that you visit this page frequently for the
            most up to date information. If at any point in time Company decides
            to make use of any personally identifiable information (PII) on
            file, in a manner vastly different from that which was stated when
            this information was initially collected, the user or users shall be
            promptly notified by email.{" "}
          </p>
          <p>
            This Policy applies to Company, and it governs any and all data
            collection and usage by us. Through the use of www.tederlive.com,
            you are therefore consenting to the data collection procedures
            expressed in this Policy.
          </p>
          <p>
            Company may partner with third-party suppliers in order to deliver
            its products and services to you. Please note that this Policy does
            not govern the collection and use of information by companies that
            Company does not control, nor by individuals not employed or managed
            by us. If you visit a website that we mention or link to, be sure to
            review its privacy policy before providing the site with
            information. It is highly recommended and suggested that you review
            the privacy policies and humanFriendlyTimeStatements of any website
            you choose to use or frequent to better understand the way in which
            websites collect, make use of and share the information collected.
          </p>
          <h3>Information We Collect</h3>
          <p>
            It is always up to you whether to disclose personally identifiable
            information to us, although if you elect not to do so, we reserve
            the right not to register you as a user or provide you with any
            products or services.
          </p>
          <p>
            In addition, Company may have the occasion to collect non-personal
            anonymous demographic information, such as age, gender, household
            income, political affiliation, race and religion, as well as the
            type of browser you are using, IP address, or type of operating
            system, which will assist us in providing and maintaining superior
            quality service.
          </p>
          <p>We collect the following information:</p>
          <ul>
            <li>
              Personal identifiers (such as your name, username, social media
              name, address, email address, password, and phone number)
            </li>
            <li>Device identifiers (such as your carrier identifier)</li>
            <li>
              Personal characteristics (such as your date of birth, gender, and
              the language you speak)
            </li>
            <li>
              Financial information (such as payment card information, banking
              information, and PayPal and Apple transaction information)
            </li>
            <li>
              Audio and visual information (such as account photo, other photos,
              and other materials)
            </li>
            <li>
              Geolocation data and other metadata (such as content creation
              date, formatting information, and location information (i.e.,
              geotags))
            </li>
            <li>
              Commercial information (such as viewing, download and purchase
              history for subscriptions, events, and concerts, artist
              information including name, biography and music genre)
            </li>
            <li>
              Communications between you, Company, Artists, and other users
              (such as comments on the Services (including social media pages)
              and customer service requests)
            </li>
            <li>
              Inferences from any of the above categories about your preferences
            </li>
          </ul>
          <h3>Why We Collect Information and For How Long</h3>
          <p> We are collecting your data for several reasons:</p>
          <ul>
            <li>
              To better understand your needs and provide you with the services
              you have requested;
            </li>
            <li>
              To fulfill our legitimate business interest in providing and
              improving our services and products;
            </li>
            <li>
              To send you promotional emails containing information we think you
              may like when we have your consent to do so;
            </li>
            <li>
              To contact you to fill out surveys or participate in other types
              of market research, when we have your consent to do so;
            </li>
            <li>
              To customize our website according to your online behavior and
              personal preferences.
            </li>
          </ul>
          <p>
            The data we collect from you will be stored for no longer than
            necessary. The length of time we retain said information will be
            determined based upon the following criteria: the length of time
            your personal information remains relevant; the length of time it is
            reasonable to keep records to demonstrate that we have fulfilled our
            duties and obligations; any limitation periods within which claims
            might be made; any retention periods prescribed by law or
            recommended by regulators, professional bodies or associations; the
            type of contract we have with you, the existence of your consent,
            and our legitimate interest in keeping such information as stated in
            this Policy.
          </p>
          <h3> Use of Information Collected</h3>
          <p>
            {" "}
            Company does not sell, rent or lease any of its customer lists
            and/or names to any third parties; should this policy change, we
            will provide notice to you prior to effecting such change. Company
            may collect and may make use of personal information to assist in
            the operation of our website and to ensure delivery of the services
            you need and request. At times, we may find it necessary to use
            personally identifiable information as a means to keep you informed
            of other possible products and/or services that may be available to
            you.
          </p>
          Company may also be in contact with you with regards to completing
          surveys and/or research questionnaires related to your opinion of
          current or potential future services that may be offered. Company uses
          various third-party social media software and features including but
          not limited to Facebook, Vimeo, and other interactive programs. These
          may collect your IP address and require cookies to work properly.
          These services are governed by the privacy policies of the providers
          and are not within Company’s control.
          <h3>Automatic Information Collection</h3>
          <p>
            {" "}
            When you use the Digital Services, we and service providers and
            trusted partners use cookies, log data, and similar technology to
            automatically record personal identifiers (such as your IP address
            and device identifiers), information about your device and browser
            (such as your browser type, locale preferences, and mobile carrier)
            and information concerning your online Services usage activity (such
            as favorites, playlists created, tracks played and downloaded,
            activity in the applications, date and time of listening, clicks and
            views of content and pages, product ID, time of log-in, and what
            version of the Services you use, email opens, clicks, and forwards,
            the link you used to reach them, and the content and advertising
            displayed on them and our content and advertising on other websites
            and online services). If you access the Digital Service via a
            handheld device, we also store a device identifier that uniquely
            identifies the device.
          </p>
          <p>
            We and our service providers and trusted partners use this
            information (i) to provide, tailor, and personalize the Services to
            you; (ii) to enable certain features on the Services; (iii) for
            service improvement purposes; and (iv) for marketing and advertising
            purposes. We may also use internal or third-party analytics tools to
            help us measure traffic and usage trends for the Digital Service
            that assist us in providing and improving the Digital Service.
          </p>
          <p>
            We permit service providers and trusted partners to track visitors
            to the Digital Service and the activities of those visitors on the
            Digital Service over time, and they may track those visitors across
            other websites and online services over time, if those websites and
            apps also use the same partners. In these situations, you are using
            and directing us to intentionally disclose personal information
            (including personal identifiers) to those service providers and
            trusted partners so they can perform those services.
          </p>
          <p>
            The Digital Service may use both temporary (“session”) and
            persistent cookies. The “Help” feature on most browsers will tell
            you how to prevent your browser from accepting new cookies, how to
            have the browser notify you when you receive a new cookie, or how to
            disable cookies. This may, however, impact the functionality of the
            Services.
          </p>
          <p>
            We currently use Google Analytics from Google Inc. to create
            anonymous usage statistics. You may opt-out of Google Analytics by
            installing a plug-in to your browser. A plug-in for the most common
            browsers can be found here:
            https://tools.google.com/dlpage/gaoptout.
          </p>
          <h3>Disclosure of Information</h3>
          <p>
            Company may not use or disclose the information provided by you
            except under the following circumstances:
          </p>
          <ul>
            <li>
              For a legitimate business purpose to provide services or products
              you have ordered, included third-party suppliers and trusted
              partners;
            </li>
            <li>
              Other ways described in this Policy or to which you have otherwise
              consented;
            </li>
            <li>
              In the aggregate with other information in such a way so that your
              identity cannot reasonably be determined;
            </li>
            <li>
              As required by law, or in response to a subpoena or search
              warrant;
            </li>
            <li>
              To outside auditors who have agreed to keep the information
              confidential;
            </li>
            <li>As necessary to enforce the Terms of Service;</li>
            <li>
              As necessary to maintain, safeguard and preserve all the rights
              and property of Company.
            </li>
          </ul>
          <h3>Non-Marketing Purposes</h3>
          <p>
            Company greatly respects your privacy. We do maintain and reserve
            the right to contact you if needed for non-marketing purposes (such
            as bug alerts, security breaches, account issues, and/or changes in
            Company’s products and services). In certain circumstances, we may
            use our website, newspapers, or other public means to post a notice.
          </p>
          <h3>Children under the age of 13</h3>
          <p>
            Company’s website is not directed to, and does not knowingly collect
            personal identifiable information from, children under the age of
            thirteen (13). If it is determined that such information has been
            inadvertently collected on anyone under the age of thirteen (13), we
            shall immediately take the necessary steps to ensure that such
            information is deleted from our system's database, or in the
            alternative, that verifiable parental consent is obtained for the
            use and storage of such information. Users between the age of 13 and
            18 must receive parental consent to use our website and services.
          </p>
          <h3>Unsubscribe or Opt-Out</h3>
          <p>
            All users and visitors to our website have the option to discontinue
            receiving communications from us by way of email or newsletters. To
            discontinue or unsubscribe from our website please send an email
            that you wish to unsubscribe to support@onteder.com. If you wish to
            unsubscribe or opt-out from any third-party websites, you must go to
            that specific website to unsubscribe or opt-out. Company will
            continue to adhere to this Policy with respect to any personal
            information previously collected.
          </p>
          <h3>Links to Other Websites</h3>
          <p>
            Our website does contain links to affiliate and other websites.
            Company does not claim nor accept responsibility for any privacy
            policies, practices and/or procedures of other such websites.
            Therefore, we encourage all users and visitors to be aware when they
            leave our website and to read the privacy
            humanFriendlyTimeStatements of every website that collects
            personally identifiable information. This Privacy Policy Agreement
            applies only and solely to the information collected by our website.
          </p>
          <h3>Notice to European Union Users (GDPR Policy)</h3>
          <p>
            Company’s operations are located primarily in the United States. If
            you provide information to us, the information will be transferred
            out of the European Union (EU) and sent to the United States. By
            providing personal information to us, you are consenting to its
            storage and use as described in this Policy.
          </p>
          <h4>Your Rights as a Data Subject</h4>
          <p>
            Under the regulations of the General Data Protection Regulation
            ("GDPR") of the EU you have certain rights as a Data Subject. These
            rights are as follows:
          </p>
          <ul>
            <li>
              The right to be informed: this means we must inform you of how we
              intend to use your personal data and we do this through the terms
              of this Policy.
            </li>
            <li>
              The right of access: this means you have the right to request
              access to the data we hold about you and we must respond to those
              requests within one month. You can do this by sending an email to
              support@onteder.com
            </li>
            <li>
              The right to rectification: this means that if you believe some of
              the data we hold is incorrect, you have the right to have it
              corrected. You can do this by logging into your account with us,
              or by sending us an email with your request.
            </li>
            <li>
              The right to erasure: this means you can request that the
              information we hold be deleted, and we will comply unless we have
              a compelling reason not to, in which case you will be informed of
              same. You can do this by sending an email to support@onteder.com
            </li>
            <li>
              The right to restrict processing: this means you can change your
              communication preferences or opt-out of certain communications.
              You can do this by sending an email to support@onteder.com
            </li>
            <li>
              The right of data portability: this means you can obtain and use
              the data we hold for your own purposes without explanation. If you
              wish to request a copy of your information, contact us at
              support@onteder.com
            </li>
            <li>
              The right to object: this means you can file a formal objection
              with us regarding our use of your information with regard to third
              parties, or its processing where our legal basis is our legitimate
              interest in it. To do this, please send an email to
              support@onteder.com
            </li>
          </ul>
          <p>
            In addition to the rights above, please rest assured that we will
            always aim to encrypt and anonymize your personal information
            whenever possible. We also have protocols in place in the unlikely
            event that we suffer a data breach and we will contact you if your
            personal information is ever at risk. For more details regarding our
            security protections see the section below or visit our website at
            www.onteder.com
          </p>
          <h3>California Consumer Protection Act</h3>
          <p>
            The State of California has established its own unique regulations
            that apply to California residents. If you are a California
            resident, you have the right to obtain from us, once per year and
            free of charge, information regarding what information we disclose
            to third party marketers, and the names and addresses of each third
            party to whom we disclose your data. If you are a California
            resident and would like to make such a request, please use the
            contact information listed in this policy.
          </p>
          <p>
            If you are a California resident and under the age of 18, you have
            the right to request that we remove any data that you publicly post
            on our website. To request removal of your data, please use the
            contact information listed in this policy. Note that while we will
            remove your data that is publicly posted on our website, we may not
            be able to completely remove that data from our systems.
          </p>
          <p>
            Under the CCPA, California consumers may use an authorized agent to
            make privacy rights requests. We require the authorized agent to
            provide us with proof of the California consumer’s written
            permission (for example, a power of attorney) that demonstrates
            authorization to submit a request for the California consumer. An
            authorized agent must follow the process described below to make a
            request, and we will also (a) require the authorized agent to verify
            the agent’s own identity and (b) confirm the agent’s authority with
            the California consumer about whom the request was made.
          </p>
          <h3>Security</h3>
          <p>
            Company takes precautions to protect your information. When you
            submit personal identifiable information via the website, your
            information is protected both online and offline. Wherever we
            collect sensitive information (e.g. credit card information), that
            information is encrypted and transmitted to us in a secure way. You
            can verify this by looking for a lock icon in the address bar and
            looking for "https" at the beginning of the address of the webpage.
          </p>
          <p>
            While we use encryption to protect sensitive information transmitted
            online, we also protect your information offline. Only employees who
            need the information to perform a specific job function (for
            example, billing or customer service) are granted access to
            personally identifiable information. The computers and servers in
            which we store personally identifiable information are kept in a
            secure environment. This is all done to prevent any loss, misuse,
            unauthorized access, disclosure or modification of the user's
            personal information under our control.
          </p>
          <p>
            The company also uses Secure Socket Layer (SSL) for authentication
            and private communications to build users' trust and confidence in
            the internet and website use by providing simple and secure access
            and communication of credit card and personal information.
          </p>
          <h3>Acceptance of Terms</h3>
          <p>
            {" "}
            By using this website, you are hereby accepting the terms and
            conditions stipulated within the Privacy Policy Agreement and the
            Company’s Terms of Use, which is incorporated into this policy. If
            you are not in agreement with our terms and conditions, then you
            should refrain from further use of our site or services. In
            addition, your continued use of our website following the posting of
            any updates or changes to our terms and conditions shall mean that
            you agree and acceptance of such changes.
          </p>
          <h3>How to Contact Us</h3>
          <p>
            If you have any questions or concerns regarding this Privacy Policy,
            please feel free to contact us at the following email, telephone
            number or mailing address.
          </p>
          <p>
            Teder LLC Support@onteder.com 232 7th Ave. Suite. 1602, New York, NY
            10011
          </p>
          <h2>PRIVACY POLICY CONSIDERATIONS</h2>
          <p>(Not to be included in the policy)</p>
          <h3>GDPR Disclosure:</h3>
          <p>
            in order to be fully compliant with GDPR regulations your company
            must fulfill other requirements such as: (i) doing an assessment of
            data processing activities to improve security; (ii) have a data
            processing agreement with any third party vendors; (iii) appoint a
            data protection officer for the company to monitor GDPR compliance;
            (iv) designate a representative based in the EU under certain
            circumstances; and (v) have a protocol in place to handle a
            potential data breach. For more details on how to make sure your
            company is fully compliant with GDPR, please visit the official
            website at https://gdpr.eu.
          </p>
          <h3>COPPA Compliance Disclosure:</h3>
          <p>
            This Privacy Policy presumes that your website is not directed at
            children under the age of 13 and does not knowingly collect personal
            identifiable information from them or allow others to do the same
            through your site. If this is not true for your website or online
            service and you do collect such information (or allow others to do
            so), please be aware that you must be compliant with all COPPA
            regulations and guidelines in order to avoid violations which could
            lead to law enforcement actions, including civil penalties.
          </p>
          <p>
            In order to be fully compliant with COPPA your website or online
            service must fulfill other requirements such as: (i) posting a
            privacy policy which describes not only your practices, but also the
            practices of any others collecting personal information on your site
            or service — for example, plug-ins or ad networks; (ii) include a
            prominent link to your privacy policy anywhere you collect personal
            information from children; (iii) include a description of parental
            rights (e.g. that you won't require a child to disclose more
            information than is reasonably necessary, that they can review their
            child's personal information, direct you to delete it, and refuse to
            allow any further collection or use of the child's information, and
            the procedures to exercise their rights); (iv) give parents "direct
            notice" of your information practices before collecting information
            from their children; and (v) obtain the parents' "verifiable
            consent" before collecting, using or disclosing personal information
            from a child. For more information on the definition of these terms
            and how to make sure your website or online service is fully
            compliant with COPPA please visit
            https://www.ftc.gov/tips-advice/business-
            center/guidance/childrens-online-privacy-protection-rule-six-step-compliance.{" "}
          </p>
        </div>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
};

export default PrivacyPage;
