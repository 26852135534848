import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { get_my_billing } from "../Common/data";
import { Typography, Box, useTheme } from "@mui/material";

import "../Styling/artistpage.css";

const BillingPage = ({ history }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const mediaQuery = theme.breakpoints.down("sm");
  const styles = {
    wrapper: {
      marginLeft: "10%",
      marginTop: "10%",
      [mediaQuery]: {
        marginTop: "30%",
        display: "flex",
        flexWrap: "wrap",
      },
    },
    table: {
      display: "flex",
      flexDirection: "column",
    },
    ticketSales: {
      //   marginRight: "15%",
      fontFamily: "titlefont",
    },
  };

  var currentState = useSelector((state) => {
    return state.TederReducerState;
  });

  if (!currentState.userContext.userLoggedIn) {
    dispatch(push("/login"));
  }

  const [loadMyBilling, setloadMyBilling] = useState(false);
  const [billing, setBilling] = useState([]);

  if (!loadMyBilling) {
    let data = {};

    get_my_billing(data)
      .then((result) => {
        if (result.code === "Success") {
          setBilling(result.BillingInfo);
        } else {
          console.log(result);
        }
      })
      .catch((error) => {
        console.log("Error caught in get_my_billing api");
        console.log(error);
        return false;
      });

    setloadMyBilling(true);
  }
  const backButton = () => history.goBack();

  return (
    <Box component="div" sx={styles.wrapper}>
      <Box component="div" sx={styles.text}>
        <Typography variant="h5" sx={styles.ticketSales}>
          ticket sales
        </Typography>
      </Box>
      <br />
      <br />
      <div className="row">
        <Box component="div" style={styles.table}>
          <table className="table tableWhite">
            <thead>
              <tr>
                <th scope="col-md">Date</th>
                <th scope="col-md">Show Name</th>
                <th scope="col-md">Show Status</th>
                <th scope="col-md">Tickets Sold</th>
                <th scope="col-md">Revenue</th>
                <th scope="col-md">Payout Date</th>
                <th scope="col-md">Payment Provider</th>
                <th scope="col-md">Reference #</th>
              </tr>
            </thead>
            <tbody>
              {billing.map((item) => (
                <tr key={item.EventName + item.StartDate}>
                  <td>{item.StartDate}</td>
                  <td>{item.EventName}</td>
                  <td>{item.Status}</td>
                  <td>{item.TicketsSold}</td>
                  <td>{item.Revenue ? item.Revenue : "Pending"}</td>
                  <td>{item.PayoutDate ? item.PayoutDate : "Pending"}</td>
                  <td>
                    {item.PaymentProvider ? item.PaymentProvider : "Pending"}
                  </td>
                  <td>{item.Reference ? item.Reference : "Pending"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      </div>
    </Box>
  );
};

export default BillingPage;
