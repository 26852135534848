import React from "react";
import { Container, ListItemAvatar, Avatar, Typography } from "@mui/material";

import artistImages from "../../../Visuals/tempImages";
import { titleCase } from "../../../util/helpers";
import Config from "../../../Common/config.js";

const ArtistBlock = ({ image, name, genre, location, photo, rank = null }) => {
  const styles = {
    content: {
      display: "flex",
      alignItems: "center",
      padding: "0 5px",
      height: "70px",
    },
    avatar: {
      height: "48px",
      width: "48px",
      objectFit: "cover",
      borderRadius: "0%",
    },
    artistName: {
      fontSize: "14px",
      fontFamily: "proximaNovaBold",
      color: "#1A1A4A",
    },
    artistInfo: {
      fontSize: "12px",
      fontFamily: "proximaNova",
      color: "#1A1A4A",
    },
    rank: {
      marginRight: "1rem",
    },
  };
  const { ArtistPhotoUrl } = Config();
  return (
    <Container style={styles.content}>
      {rank && <Typography style={styles.rank}>{`${rank}. `}</Typography>}
      <ListItemAvatar>
        <Avatar
          src={image || `${ArtistPhotoUrl}${photo}`}
          style={styles.avatar}
        />
      </ListItemAvatar>
      <Container style={{ padding: 0 }}>
        <Typography sx={styles.artistName}>{name}</Typography>
        <Typography sx={styles.artistInfo}>{titleCase(genre)}</Typography>
        <Typography sx={styles.artistInfo}>{location}</Typography>
      </Container>
    </Container>
  );
};

export default ArtistBlock;
