import React, { useState, useEffect } from "react";
import "./css/StepOne.css";
import { useSelector } from "react-redux";
import validateStepOne from "./validateStepOne";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import {
  setRegistrationStep1Action,
  loadContriesAction,
  loadStatesAction,
  artistDetailsAction,
  baListAction,
  loginUserAction,
} from "../../../redux/actions";
import {
  show_country_list,
  show_state_list,
  get_artist_details,
  get_booking_agent_list,
  get_genders_list,
} from "../../../Common/data";
import { useTrackingCode } from "react-hubspot-tracking-code-hook";
import { Typeahead } from "react-bootstrap-typeahead";
import moment from "moment";
import { HUBSPOT_PROPERTIES } from "../../../util/constants";
import customHubspotAPI from "../../../HelperFunctions/CustomHubspotAPI";

function SignUpFormOne() {
  const todaysDate = "1900-01-01";

  //Redux State
  const dispatch = useDispatch();
  const currentState = useSelector((state) => state.TederReducerState);

  //Local State
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [genders, setGenders] = useState([]);
  const [countryLoad, setCountryLoad] = useState(false);
  const [statesLoad, setStatesLoad] = useState(false);
  const { setIdentity, setPathPageView } = useTrackingCode();

  const {
    userType,
    firstName,
    lastName,
    address,
    city,
    state,
    zipCode,
    country,
    email,
    phoneNo,
    referralCode,
  } = currentState.userRegistrationStep1;
  // fetching artist details
  // checking state

  useEffect(() => {
    get_artist_details()
      .then((res) => {
        let artistDetails = {
          ...currentState.artistDetails,
          authProviderId: res.artist_details[0].AuthProviderId,
          authProvider: res.artist_details[0].AuthProvider,
          authID: res.artist_details[0].AuthID,
          artistId: res.artist_details[0].ArtistID,
          firstName: res.artist_details[0].FirstName,
          lastName: res.artist_details[0].LastName,
          photoPath: res.artist_details[0].PhotoPath,
          address: res.artist_details[0].Address,
          city: res.artist_details[0].City,
          email: res.artist_details[0].Email,
          state: res.artist_details[0].State,
          country: res.artist_details[0].Country,
          zipCode: res.artist_details[0].ZipCode,
          phoneNo: res.artist_details[0].PhoneNo,
          bankName: res.artist_details[0].BankName,
          routingNo: res.artist_details[0].RoutingNo,
          accID: res.artist_details[0].AccID,
          accNo: res.artist_details[0].AccNo,
          stageName: res.artist_details[0].StageName,
          genres: res.artist_details[0].Genres,
          bio: res.artist_details[0].DescriptionBIO,
          website: res.artist_details[0].Website,
          SChandle: res.artist_details[0].SoundcloudHandle,
          IGhandle: res.artist_details[0].InstagramHandle,
          YThandle: res.artist_details[0].YoutubeHandle,
          TThandle: res.artist_details[0].TikTokHandle,
          referralCode: res.artist_details[0].ReferralCode,
          userType: res.artist_details[0].UserType,
        };
        dispatch(artistDetailsAction(artistDetails));
        return artistDetails;
      })
      .catch((err) => console.error(err));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Call Data for Typeahead Refferal
  useEffect(() => {
    get_booking_agent_list()
      .then((res) => {
        const formattedList = JSON.parse(res.booking_agent_list);
        dispatch(baListAction(formattedList));
      })
      .catch((err) => console.error(err));

    //Fetch Genders
    get_genders_list()
      .then((res) => {
        setGenders(res.gendersList);
      })
      .catch((error) => console.error(error));
  }, []);

  //Fetch Countries
  const get_country_list = async () => {
    const response = await show_country_list
      .then((result) => {
        return JSON.parse(result.countryList);
      })
      .catch((err) => console.error(err));
    setCountries(response);
    dispatch(loadContriesAction(response));
  };

  const get_states_list = async () => {
    const response = await show_state_list
      .then((result) => {
        return JSON.parse(result.stateList);
      })
      .catch((err) => console.error(err));
    setStates(response);

    dispatch(loadStatesAction(response));
  };

  useEffect(() => {
    // get countries if not in state
    if (currentState.countries.length === 0) {
      get_country_list();
      setCountryLoad(true);
    } else {
      setCountries(currentState.countries);
      setCountryLoad(true);
    }

    // get states if not in state
    if (currentState.states.length === 0) {
      get_states_list();
      setStatesLoad(true);
    } else {
      setStates(currentState.states);
      setStatesLoad(true);
    }

    if (userType === 1) {
      const registrationDetails = {
        ...currentState.userRegistrationStep1,
        userType: 1,
      };

      dispatch(setRegistrationStep1Action(registrationDetails));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userType]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const registrationDetails = {
      ...currentState.userRegistrationStep1,
      [name]: value,
    };
    dispatch(setRegistrationStep1Action(registrationDetails));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = {
      ...currentState.userRegistrationStep1,
      userType: userType,
      firstName: firstName,
      lastName: lastName,
      address: address,
      city: city,
      state: state,
      zipCode: zipCode,
      country: country,
      email: email,
      phoneNo: phoneNo,
      referralCode: referralCode,
    };

    const hubspotData = [
      {
        name: HUBSPOT_PROPERTIES.EMAIL,
        value: email,
      },
      {
        name: HUBSPOT_PROPERTIES.USER_TYPE,
        value: "artist",
      },
      {
        name: HUBSPOT_PROPERTIES.REFERRED_BY,
        value: referralCode,
      },
    ];

    let details = {
      ...currentState.userContext,
      isSignupCompleted: 1,
    };

    validateStepOne(data);
    try {
      customHubspotAPI(hubspotData);
    } catch (error) {
      console.error("hubspot form api error", error);
    }

    dispatch(setRegistrationStep1Action(data));
    dispatch(loginUserAction(details));

    const customProperties = {
      name: `${data.firstName} ${data.lastName}`,
      user_type: "artist",
      referred_by: data.referralCode,
    };

    setIdentity(data.email, customProperties);

    setPathPageView("/signup/2");
    dispatch(push("/signup/2"));
  };
  const signUpForm = () => (
    <div className="sign-up-form-1-container">
      <div className="sign-up-form-1-content">
        <form className="sign-up-form-1-form" onSubmit={handleSubmit}>
          <div className="sign-up-title">
            <p>SIGN UP FORM</p>
          </div>
          <div className="first-last-input">
            <div className="name-input">
              <label>First Name</label>
              <input
                name="firstName"
                value={currentState.userRegistrationStep1.firstName}
                onChange={handleChange}
              ></input>
              <p>*Required Field</p>
            </div>
            <div className="last-input">
              <label>Last Name</label>
              <input
                name="lastName"
                value={currentState.userRegistrationStep1.lastName}
                onChange={handleChange}
              ></input>
            </div>
          </div>
          <div className="state-input">
            <label>Date of Birth</label>
            <input
              style={{ borderRadius: "3px", border: "none", height: "33px" }}
              type="date"
              min={todaysDate}
              name="dateOfBirth"
              value={currentState.userRegistrationStep1.dateOfBirth}
              onChange={handleChange}
              placeholder="mm/dd/yyyy"
            />
            <p>*Must be 18 or older</p>
            <label>Gender</label>
            <select
              labelId="select-gender"
              id="select-gender"
              name="gender"
              onChange={handleChange}
            >
              {genders &&
                genders.map((gender) => (
                  <option key={gender.DisplayOrder} value={gender.GenderDesc}>
                    {gender.GenderDesc}
                  </option>
                ))}
            </select>
          </div>
          <div className="address-input">
            <label>Address</label>
            <input
              name="address"
              value={currentState.userRegistrationStep1.address}
              onChange={handleChange}
            ></input>
          </div>
          <div className="city-state-zip-input">
            <div className="city-input">
              <label>City</label>
              <input
                name="city"
                value={currentState.userRegistrationStep1.city}
                onChange={handleChange}
              ></input>
            </div>
            <div className="state-input">
              <label>State</label>
              <select
                value={currentState.userRegistrationStep1.state}
                name="state"
                onChange={handleChange}
              >
                <option>Select State</option>
                <option>AK</option>
                <option>AL</option>
                <option>AR</option>
                <option>AZ</option>
                <option>CA</option>
                <option>CO</option>
                <option>CT</option>
                <option>DE</option>
                <option>FL</option>
                <option>GA</option>
                <option>HI</option>
                <option>IA</option>
                <option>ID</option>
                <option>IL</option>
                <option>IN</option>
                <option>KS</option>
                <option>KY</option>
                <option>LA</option>
                <option>MA</option>
                <option>MD</option>
                <option>ME</option>
                <option>MI</option>
                <option>MN</option>
                <option>MO</option>
                <option>MS</option>
                <option>MT</option>
                <option>NC</option>
                <option>ND</option>
                <option>NE</option>
                <option>NH</option>
                <option>NJ</option>
                <option>NM</option>
                <option>NV</option>
                <option>NY</option>
                <option>OH</option>
                <option>OK</option>
                <option>OR</option>
                <option>PA</option>
                <option>RI</option>
                <option>SC</option>
                <option>SD</option>
                <option>TN</option>
                <option>TX</option>
                <option>UT</option>
                <option>VA</option>
                <option>VT</option>
                <option>WA</option>
                <option>WI</option>
                <option>WV</option>
                <option>WY</option>
              </select>
            </div>
            <div className="zip-input">
              <label>Zip Code</label>
              <input
                name="zipCode"
                value={currentState.userRegistrationStep1.zipCode}
                onChange={handleChange}
              ></input>
            </div>
          </div>
          <div className="country-email-input">
            <div className="country-input">
              <label>Country</label>
              <select
                value={currentState.userRegistrationStep1.country}
                name="country"
                onChange={handleChange}
                required
              >
                <option>Select Country</option>
                {countries &&
                  countries.map((country) => (
                    <option key={country.ID}>{country.CountryName}</option>
                  ))}
              </select>
              <span style={{ color: "red", fontSize: ".65em", margin: "2%" }}>
                *Required Field
              </span>
            </div>
            <div className="email-input">
              <label>Email</label>
              <input
                name="email"
                value={currentState.userRegistrationStep1.email}
                onChange={handleChange}
              ></input>
            </div>
          </div>
          <div className="phone-referral-input">
            <div className="phone-input">
              <label>Phone Number</label>
              <input
                name="phoneNo"
                value={currentState.userRegistrationStep1.phoneNo}
                onChange={handleChange}
              ></input>
            </div>
            <div className="referral-input">
              <label>Agent Referral</label>
              <Typeahead
                id="Booking Agents"
                name="referralCode"
                labelKey={"displayName"}
                options={currentState.baList[0]}
                onChange={(selected) => {
                  if (selected[0]) {
                    const registrationDetails = {
                      ...currentState.userRegistrationStep1,
                      referralCode: selected[0].referralCode,
                    };
                    dispatch(setRegistrationStep1Action(registrationDetails));
                  }
                  return null;
                }}
              />
            </div>
          </div>
          <div className="next-button-container">
            {/* <Link to="/signup/2"> */}
            <button
              className={
                currentState.userRegistrationStep1.country === "Select Country"
                  ? "disabled"
                  : "next-button"
              }
              disabled={
                currentState.userRegistrationStep1.country === "Select Country"
              }
            >
              <p>NEXT</p>
            </button>
            {/* </Link> */}
          </div>
        </form>
      </div>
    </div>
  );
  if (!currentState.baList) return null;
  return countryLoad && statesLoad ? signUpForm() : null;
}

export default SignUpFormOne;
