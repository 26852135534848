// External Components + Libraries
import React, { useState, useEffect } from "react";
import { DateTime } from "luxon";
import { useDispatch } from "react-redux";
import { Typography, Grid, Box, useTheme } from "@mui/material";
import NumberFormat from "react-number-format";
import { baGoalsAction, baIncomeAction } from "../../../redux/actions";
import { convertDateString } from "../../../util/helpers";

// Custom Components
import UpcomingShowsCard from "./UpcomingShowsCard";
import IncomeOverviewCard from "./IncomeOverviewCard";
import ArtistsToBookCard from "./ArtistsToBookCard";
import GoalsCard from "./GoalsCard";
import SummaryCard from "./SummaryCard";
import ReferralCard from "./ReferralCard";

// Common Resources
import { ABBREVIATED_MONTHS } from "../../../util/constants";
import {
  get_booking_agent_goals,
  get_booking_agent_income,
  get_ba_schedule_user_list,
} from "../../../Common/data";
import {
  buildDateDisplay,
  ascendingByTime,
  happenedThisMonth,
  roundFloat,
} from "../../../util/helpers";

import { useSelector } from "react-redux";

//API
import {
  get_booking_agent_stats,
  get_booking_agent_associated_shows,
} from "../../../Common/data";
import { bookingAgentStatsAction } from "../../../redux/actions";
// Data
import {
  allBookingAgents,
  allArtists,
  allShows,
  allGoals,
} from "../../../seedData";

const BookingAgentHome = ({
  agentDetails,
  selectRoute,
  artistList,
  setArtistList,
}) => {
  const currentState = useSelector((state) => state.TederReducerState);
  const dispatch = useDispatch();
  const theme = useTheme();
  const mediaQueryPhone = theme.breakpoints.down("sm");

  // Local Styles
  const styles = {
    wrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingTop: "12vh",
      background: "#f9f9fa",
      minHeight: "100vh",
      padding: "10% 3% 5% 10%",
      // marginTop: "3%",

      skeleton: {
        height: "103px",
        borderRadius: "10px",
        marginBottom: "5%",
      },

      [mediaQueryPhone]: {
        width: "100%",
        marginTop: "13%",
        padding: "10% 4% 5% 10%",
      },
    },
    content: {
      maxWidth: "660px",
    },
    monthSoFar: {
      fontFamily: "titleFont",
      color: "#1A1A4A",
      marginBottom: "1rem",
      width: "100%",
      textAlign: "left",
    },
    revenue: {
      length: "auto",
    },
    referralCode: {
      background: "black",
    },
  };

  //Local State
  const [showsThisMonth, setShowsThisMonth] = useState([]);
  const [artistsThisMonth, setArtistsThisMonth] = useState([]);
  const [upcomingEventsData, setUpcomingEventsData] = useState([]);
  const [tixSoldThisMonth, setTixSoldThisMonth] = useState(0);
  const [revenueThisMonth, setRevenueThisMonth] = useState(0);
  const [loading, setLoading] = useState(false);

  // Load Data
  useEffect(() => {
    // setLoading(true);
    get_booking_agent_stats()
      .then(({ stats }) => {
        const formattedStats = {
          ...currentState.agentStats,
          newArtists: stats.NewArtists,
          numberOfShows: stats.NumOfShows,
          revenue: stats.revenue,
          ticketsSold: stats.ticketsSold,
        };
        dispatch(bookingAgentStatsAction(formattedStats));
        // setLoading(false);
      })
      .catch((error) => console.error(error));

    const agent = allBookingAgents[0];
    const artists = [];
    const unbookedArtists = [];
    const shows = allShows.filter((show) => show.booking_agent_id === agent.id);

    // Load Artists
    // const loadArtists = () => {
    //   allArtists.forEach((artist) => {
    //     artist.booking_agent_id === agent.id
    //       ? artists.push(artist)
    //       : unbookedArtists.push(artist);
    //   });

    //   const artistThisMonth = artists.filter((artist) =>
    //     happenedThisMonth(artist.date_joined)
    //   );

    //   setBookableArtists(unbookedArtists);
    //   setArtistsThisMonth(artistThisMonth);
    // };

    // Load Income
    const loadIncomeData = () => {
      get_booking_agent_income()
        .then((res) => {
          const formattedIncomeData = res.stats.map(({ month, income }) => {
            return {
              date: convertDateString(month),
              grossed: income,
            };
          });
          // const withMonths = ABBREVIATED_MONTHS.map((month) => ({}));
          // const formattedIncomeData = ABBREVIATED_MONTHS.map((month) => ({
          //   date: month,
          //   grossed: res.stats.income,
          // }));
          dispatch(baIncomeAction(formattedIncomeData));
        })
        .catch((error) => console.error(error));

      const baIncomeData = ABBREVIATED_MONTHS.map((month) => ({
        date: month,
        grossed: 0,
      }));

      shows.forEach((show) => {
        const monthIndex = DateTime.fromISO(show.show_time).month - 1;
        const grossedThisShow = show.tickets_sold * show.price;

        baIncomeData[monthIndex].grossed += grossedThisShow;
      });
    };

    // Load Goals
    const loadGoalData = () => {
      get_booking_agent_goals()
        .then(({ stats }) => {
          const formattedGoals = [
            {
              active: true,
              category: "ARTIST_SIGN_UPS",
              current: stats["Monthly Artist Sign ups"],
              target: 10,
            },
            {
              active: true,
              category: "SHOWS_BOOKED",
              current: stats["Monthly Booked Artists"],
              target: 10,
            },
          ];

          dispatch(baGoalsAction(formattedGoals));
        })
        .catch((error) => console.error(error));

      // const bookingAgentGoals = allGoals.filter(
      //   (goal) => goal.booking_agent_id === agent.id
      // );
    };

    // Load Upcoming Shows
    const loadUpcomingEvents = () => {
      const upcomingShowData = [];

      const sortedFutureShows = shows
        .filter((show) => !show.already_passed)
        .sort((a, b) => ascendingByTime(a, b));

      sortedFutureShows.forEach((show) => {
        const showArtist = artists.filter((artist) =>
          artist.shows.includes(show.id)
        )[0];

        if (showArtist) {
          const timeInstance = DateTime.fromISO(show.show_time);
          const displayDate = buildDateDisplay(timeInstance);
          const showAndArtistData = {
            artistName: showArtist.name,
            imageURL: showArtist.image_url,
            displayDate,
          };
          upcomingShowData.push(showAndArtistData);
        }
      });

      setUpcomingEventsData(upcomingShowData);
    };

    loadIncomeData();
    loadGoalData();
    loadUpcomingEvents();
    return () => null;
  }, [currentState.agentStats, dispatch, currentState.baShows]);

  // Action Methods
  const handleDismiss = (id) => {
    const filteredArtists = artistList.filter((artist) => artist.id !== id);
    setLoading(true);
    setArtistList(filteredArtists);
    setLoading(false);
  };

  if (
    !currentState.baStats ||
    !currentState.baGoals ||
    !currentState.baContext ||
    !currentState.baIncome
  )
    return null;

  const { newArtists, numberOfShows, revenue, ticketsSold } =
    currentState.baStats;

  const { referralCode } = currentState.baContext;

  return (
    <Box component="div" sx={styles.wrapper}>
      <Grid style={styles.content} container spacing={3}>
        <Typography sx={styles.monthSoFar}>Your month so far:</Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <SummaryCard category="NEW ARTISTS" value={newArtists || 0} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <SummaryCard category="SHOWS" value={numberOfShows || 0} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <SummaryCard category="TICKETS SOLD">
              <NumberFormat
                value={ticketsSold || 0}
                thousandSeparator
                displayType="text"
              />
            </SummaryCard>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <SummaryCard sx={styles.revenue} category="REVENUE">
              <NumberFormat
                value={revenue ? roundFloat(revenue) : 0}
                thousandSeparator
                displayType="text"
                prefix={"$"}
              />
            </SummaryCard>
          </Grid>
          <Grid item xs={12} md={7}>
            <GoalsCard data={currentState.baGoals[0]} />
            <IncomeOverviewCard
              data={currentState.baIncome[0]}
              selectRoute={selectRoute}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <ReferralCard
              category="Your Code:"
              value={referralCode}
              style={styles.referralCode}
            />
            <ArtistsToBookCard
              data={artistList}
              handleDismiss={handleDismiss}
              selectRoute={selectRoute}
              loading={loading}
            />
            <UpcomingShowsCard selectRoute={selectRoute} />
          </Grid>
          {/* <Grid item xs={12} md={7}></Grid>
          <Grid item xs={12} md={5}></Grid> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default BookingAgentHome;
