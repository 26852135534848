import React, { useState, useEffect } from "react";
import { Box, Container } from "@mui/material";
import youTube from "../../../Visuals/ArtistProfileAssets/youtube.svg";
import soundCloud from "../../../Visuals/ArtistProfileAssets/soundcloud.svg";
import instagram from "../../../Visuals/ArtistProfileAssets/instagram.svg";
import tiktok from "../../../Visuals/ArtistProfileAssets/tiktok.svg";
import websiteImage from "../../../Visuals/ArtistProfileAssets/globe.svg";

const ArtistSocialMediaHandles = ({ artistDetails }) => {
  const [socialMediaData, setSocialMediaData] = useState([]);

  useEffect(() => {
    const { SChandle, IGhandle, YThandle, TThandle, website } = artistDetails;
    setSocialMediaData([
      { handle: SChandle, image: soundCloud },
      { handle: IGhandle, image: instagram },
      { handle: YThandle, image: youTube },
      { handle: TThandle, image: tiktok },
      { handle: website, image: websiteImage },
    ]);
    return () => {
      setSocialMediaData("");
    };
  }, [artistDetails]);

  const styles = {
    socialsWrapper: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      margin: "2%",
      alignItems: "center",
    },
    socialsIcon: {
      width: "38px",
      height: "38px",
      margin: "0 5% 2% 0",
    },
  };

  const mappedSocials = socialMediaData.map(({ handle, image }, i) => {
    return (
      handle && (
        <Container component="div" style={styles.socialsWrapper} key={i}>
          <Box
            component="img"
            src={image}
            alt="Social Media Icon"
            sx={styles.socialsIcon}
          />
          <Box>{handle}</Box>
        </Container>
      )
    );
  });

  return mappedSocials;
};

export default ArtistSocialMediaHandles;
