import React, { useState, useEffect } from "react";
import Config from "../../../Common/config";
import { Box, Typography, Container, CircularProgress } from "@mui/material";
import { useTheme } from "@mui/material";

const ArtistBanner = (props) => {
  //MUI Styling
  const theme = useTheme();
  const mediaQuery = theme.breakpoints.down("sm");
  const { editIconSizing } = theme.palette.icons;
  const artistPhotoPath = Config().ArtistPhotoUrl;

  const {
    artistPhoto,
    stageName,
    emailIcon,
    phoneIcon,
    editIcon,
    city,
    state,
    phoneNumber,
    emailAddress,
    genres,
  } = props;

  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {
    if (artistPhoto) setisLoading(false);
  }, [artistPhoto, isLoading]);

  const styles = {
    column: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    artistProfileBanner: {
      position: "inherit",
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      minHeight: "250px",
      borderBottom: "1px solid #444",
      borderRadius: "15px",
      background: "linear-gradient(179.71deg, #BDBDD4 0.25%, #B8B8F9 140.57%)",
      [mediaQuery]: {
        flexDirection: "column",
        flexWrap: "wrap",
      },
    },
    bannerBackground: {
      background: "black",
      width: "100%",
      opacity: "0.3",
    },

    artistPhoto: {
      maxHeight: "20vh",
      maxWidth: "20vh",
      margin: "5%",
      width: "70vw",
      height: "70vw",
      borderRadius: "100%",
      objectFit: "cover",
      background: "rgb(80, 80, 80)",
      border: "5px solid #fff",
      [mediaQuery]: {
        maxHeight: "14vh",
        maxWidth: "14vh",
        margin: "2%",
      },
    },
    artistInfoWrapper: {
      boxSizing: "border-box",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      [mediaQuery]: {
        flexDirection: "column",
        alignItems: "left",
        height: "100%",
      },
    },
    title: {
      letterSpacing: "2px",
      fontFamily: "titleFont",
      marginLeft: "10%",
      color: "white",
      [mediaQuery]: {
        marginRight: "50%",
      },
    },
  };

  return (
    <>
      <Box component="div" sx={styles.artistProfileBanner}>
        <Box component="div" sx={styles.artistInfoWrapper}>
          {isLoading ? (
            <CircularProgress sx={styles.artistPhoto} />
          ) : (
            <Box
              component="img"
              src={`${artistPhotoPath}${artistPhoto}`}
              sx={styles.artistPhoto}
              alt="artist"
            />
          )}
          <Box component="div" style={styles.column}>
            <Typography variant="h5" sx={styles.title}>
              {stageName}
            </Typography>
            <Typography
              variant="p"
              style={{
                whiteSpace: "nowrap",
                marginLeft: "10%",
                color: "white",
              }}
            >
              {city}, {state}
            </Typography>
            <Typography
              variant="p"
              style={{
                whiteSpace: "nowrap",
                marginLeft: "10%",
                color: "white",
              }}
            >
              {genres}
            </Typography>
          </Box>

          <Box
            component="div"
            sx={{
              ...styles.column,
              width: "100%",
              marginLeft: "25%",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              [mediaQuery]: {
                marginLeft: "0",
              },
            }}
          >
            <Box
              component="div"
              sx={{
                ...styles.column,
                flexDirection: "row",
                width: "100%",
                height: "23%",
                justifyContent: "left",
                flexWrap: "wrap",
                alignItems: "center",
                [mediaQuery]: {
                  margin: "5%",
                },
              }}
            >
              <Box
                component="img"
                src={phoneIcon}
                alt="phone icon"
                style={{ minWidth: "30px" }}
              />
              <Typography variant="p" style={{ color: "white", margin: "3%" }}>
                {phoneNumber}
              </Typography>
            </Box>
            <Box
              component="div"
              sx={{
                ...styles.column,
                flexDirection: "row",
                width: "100%",
                height: "23%",
                alignItems: "center",
                justifyContent: "left",
              }}
            >
              <Box
                component="img"
                src={emailIcon}
                alt="email icon"
                style={{ minWidth: "30px" }}
              />
              <Typography
                variant="p"
                sx={{
                  color: "white",
                  whiteSpace: "nowrap",
                  margin: "5%",
                  [mediaQuery]: {
                    whiteSpace: "auto",
                  },
                }}
              >
                {emailAddress}
              </Typography>
            </Box>
          </Box>

          {/* <Box
            component="div"
            style={{
              ...styles.column,
              width: "100%",
              border: "solid 5px blue",
            }}
          >
            <Box
              component="img"
              src={editIcon}
              alt="edit icon"
              sx={{
                ...editIconSizing,
                margin: "14%",
                [mediaQuery]: {},
              }}
            />
          </Box> */}
        </Box>
      </Box>
    </>
  );
};

export default ArtistBanner;
