import React from 'react'
import step1 from "../Components/ShowPages/Stream/img/obs/obs_step1.png";
import step2 from "../Components/ShowPages/Stream/img/obs/obs_step2.png";
import step3 from "../Components/ShowPages/Stream/img/obs/obs_step3.png";
import step4 from "../Components/ShowPages/Stream/img/obs/obs_step4.png";
import step5 from "../Components/ShowPages/Stream/img/obs/obs_step5.png";
import step6 from "../Components/ShowPages/Stream/img/obs/obs_step6.png";
import step6_mini from "../Components/ShowPages/Stream/img/obs/obs_step6_mini.png";
import step7 from "../Components/ShowPages/Stream/img/obs/obs_step7.png";

const StreamInstructions = () => {
    return (
        <div className="container content-padding">
            <br />
            <h1 className="text-center">Start streaming with OBS</h1>
            <br />
            <ol className="OBSList">
                <li>
                    Download OBS for you desktop/laptop at - <a href="https://obsproject.com/download"> https://obsproject.com/download</a>. &nbsp;Windows, Mac and Linux supprted.
                </li>
                <li>
                    Install and open the player
                </li>
                <li>
                    Open Settings, &nbsp;Add your stream key in OBS. <br/>
                
                    <img width="75%" src={step1} alt="step1" />
                    <br/><br/>
                    <img width="75%" src={step2} alt="step2" />
                </li>
                <li>
                    Set your Audio input capture device(You may choose existing one)<br/>
                    <img width="75%" src={step3} alt="step3" />
                    <br/><br/>
                    <img width="75%" src={step4} alt="step4" />
                    <br/><br/>
                    <img width="75%" src={step5} alt="step5" />
                </li>
                <li>
                    Set your Video input capture device(You may choose existing one)<br/>
                    <img width="75%" src={step6} alt="step6" />
                    <br/><br/>
                    <img width="30%" src={step6_mini} alt="step6_mini" />
                </li>
                <li>
                    Start Your Stream!<br/>
                    <img width="75%" src={step7} alt="step7" />
                </li>
                <li>
                    When you're done. Finish your stream.<br/>
                    PLACE Holder For finish stream photo
                    {/* <img width="75%" src={step7} alt="step7" /> */}
                </li>
            </ol>
            <br /><br />
        </div>
    )
}

export default StreamInstructions
