import React from "react";
import { CardContent, Typography } from "@mui/material";

const CardHeading = ({
  title,
  subTitle,
  component = null,
  style = {},
  selectRoute,
  routeSelection,
}) => {
  const styles = {
    headerWrapper: {
      padding: "0 5px",
      display: "flex",
      height: "44px",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
    },
    headerTitle: {
      fontFamily: "titleFont, sans-serif",
      fontSize: "15px",
      marginTop: "5px",
      color: "#1A1A4A",
    },
    headerSubTitle: {
      fontSize: "10px",
      fontFamily: "proximaNova",
      textDecoration: "underline",
      cursor: "pointer",
    },
  };

  return (
    <CardContent style={{ ...styles.headerWrapper, ...style }}>
      <Typography variant="h3" sx={styles.headerTitle}>
        {title}
      </Typography>
      {component ? (
        component
      ) : (
        <Typography
          variant="caption"
          sx={styles.headerSubTitle}
          onClick={() => selectRoute(routeSelection)}
        >
          {subTitle}
        </Typography>
      )}
    </CardContent>
  );
};

export default CardHeading;
