import React, { useState } from "react";
import "../../../Styling/artistsignup.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { show_country_list, purchasing_ticket } from "../../../Common/data";
import {
  loadContriesAction,
  ticketDetailsAction,
  ticketDetailsErrorsAction,
} from "../../../redux/actions";
import validateStepOne from "../../ArtistSignUpPage/ArtistSignupForms/validateStepOne";
import validateTicket from "./validateTicket";
import Modal from "react-modal";
import NumberFormat from "react-number-format";

const PurchaseTicket = () => {
  const currentState = useSelector((state) => state.TederReducerState);
  const dispatch = useDispatch();
  const ticket = currentState.purchaseTicket;

  const [ticketsPurchased, setticketsPurchased] = useState([]);
  // const [viewConfirmation, setViewConfirmation] = useState(false)

  const [modalIsOpen, setIsOpen] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  let Scroll = require("react-scroll");
  let scroller = Scroll.scroller;
  let Element = Scroll.Element;

  const inputClassName = "form-control artist-textbox-style";

  const handleChange = (e) => {
    let { name, value } = e.target;

    let ticketDetails = {
      ...ticket,
      [name]: value,
    };

    // update the redux state
    dispatch(ticketDetailsAction(ticketDetails));
  };

  const handlePhone = (value) => {
    const ticketDetails = { ...ticket, phoneNo: value.formattedValue };
    dispatch(ticketDetailsAction(ticketDetails));
  };

  const handleBlur = () => {
    const error1 = validateStepOne(currentState.purchaseTicket);
    const error2 = validateTicket(currentState.purchaseTicket);
    let allErrors = Object.assign({}, error1, error2);
    dispatch(ticketDetailsErrorsAction(allErrors));
  };

  const handlePurchase = () => {
    if (currentState.purchaseTicketErrors.isValid) {
      const ticketInfo = {
        Event_ID: currentState.viewShowDetails.showID,
        EmailID: ticket.email,
        FirstName: ticket.firstName,
        LastName: ticket.lastName,
        PhoneNo: ticket.phoneNo,
        Address: ticket.address,
        City: ticket.city,
        State: ticket.state,
        Country: ticket.country,
        ZipCode: ticket.zipCode,
        TicketsCount: parseInt(ticket.ticketCount, 0),
        number: ticket.cardNo,
        exp_month: ticket.expMonth,
        exp_year: ticket.expYear,
        cvc: ticket.CVV,
      };
      setIsloading(true);
      purchasing_ticket(ticketInfo)
        .then((data) => {
          if (data.code === "Success") {
            setticketsPurchased(data.TicketNoList);
            // setViewConfirmation(true);
            setIsloading(false);
            setIsOpen(true);

            clearTicketForm();
          } else {
            console.log("Something went wrong upon purchase ticket...");
            setIsloading(false);
            alert("Please enter a valid credit card.");
            scroller.scrollTo("purchase", { smooth: true });
          }

          return true;
        })
        .catch((error) => {
          console.log("Error caught in update api");
          console.log(error);
          alert("Please contact our support team at support@onteder.com.");
          return false;
        });
    } else {
      handleBlur();
      alert("Please complete the form");
      scroller.scrollTo("purchase", { smooth: true });
    }
  };

  const clearTicketForm = () => {
    const blankForm = {
      firstName: "",
      lastName: "",
      address: "",
      city: "",
      state: "",
      country: "United States of America",
      zipCode: "",
      email: "",
      phoneNo: "",
      ticketCount: 1,
      cardNo: "",
      cardName: "",
      expMonth: "",
      expYear: "",
      CVV: "",
    };
    dispatch(ticketDetailsAction(blankForm));
  };

  // const formatPhoneNumber = (e) => {
  //     let formattedNumber;

  //     const { name, value } = e.target;
  //     const { length } = value;

  //     // Filter non numbers
  //     const regex = () => value.replace(/[^0-9\.]+/g, "");
  //     // Set area code with parenthesis around it
  //     const areaCode = () => `(${regex().slice(0, 3)})`;
  //     // Set formatting for first six digits
  //     const firstSix = () => `${areaCode()} ${regex().slice(3, 6)}`;
  //     // Dynamic trail as user types
  //     const trailer = (start) => `${regex().slice(start, regex().length)}`;

  //     if (length < 3) {
  //         // First 3 digits
  //         formattedNumber = regex();
  //     } else if (length === 4) {
  //         // After area code
  //         formattedNumber = `${areaCode()} ${trailer(3)}`;
  //     } else if (length === 5) {
  //         // When deleting digits inside parenthesis
  //         formattedNumber = `${areaCode().replace(")", "")}`;
  //     } else if (length > 5 && length < 9) {
  //         // Before dash
  //         formattedNumber = `${areaCode()} ${trailer(3)}`;
  //     } else if (length >= 10) {
  //         // After dash
  //         formattedNumber = `${firstSix()}-${trailer(6)}`;
  //     }

  //     const formattedObject = {
  //       target: {
  //         name: name,
  //         value: formattedNumber,
  //       },
  //     };

  //     handleChange(formattedObject);
  // };

  if (currentState.countries.length === 0) {
    const get_country_list = async () => {
      const response = await show_country_list.then((result) => {
        return JSON.parse(result.countryList);
      });
      dispatch(loadContriesAction(response));
    };
    get_country_list();
  }

  // const confirmationPanel = () => {
  //   if (viewConfirmation){
  //     return (
  //       <div id="purchaseConfirmationDiv" className="row border-danger">
  //         <div className="col-md">
  //           <h3 className="text-center">Thank you for your purchase!</h3>
  //           <p>Please save your tickets number below. A confirmation email was sent to you with the detials below.</p>
  //         </div>
  //         <div className="col-md-5">
  //           <h3 className="text-center">Your Tickets:</h3>
  //           <ul className="list-group">
  //           {
  //             ticketsPurchased.map((value, index) => {
  //             return <li className="list-group-item list-group-item-danger">{value}</li>
  //             })
  //           }
  //           </ul>
  //         </div>
  //         <div className="row"></div>
  //       </div>
  //     )
  //   }
  // }
  return (
    <Element name="purchase">
      <div className="container">
        <h3 className="text-center">Purchase a ticket</h3>
        <br />
        {/* {confirmationPanel()} */}
        <div className="row">
          <div className="col-md">
            <form className="form ticket-form">
              <div className="form-row">
                <div className="form-group col-lg">
                  <label htmlFor="firstName">FIRST NAME</label>
                  <input
                    id="firstName"
                    type="text"
                    name="firstName"
                    className={inputClassName}
                    placeholder="First Name"
                    value={ticket.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <small className="small-green-text">
                    {currentState.purchaseTicketErrors.firstName}
                  </small>
                </div>
                <div className="form-group col-lg">
                  <label htmlFor="lastName">LAST NAME</label>
                  <input
                    id="lastName"
                    type="text"
                    name="lastName"
                    className={inputClassName}
                    placeholder="Last Name"
                    value={ticket.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <small className="small-green-text">
                    {currentState.purchaseTicketErrors.lastName}
                  </small>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="address">ADDRESS</label>
                <input
                  id="address"
                  type="text"
                  name="address"
                  className={inputClassName}
                  placeholder="Address"
                  value={ticket.address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <small className="small-green-text">
                  {currentState.purchaseTicketErrors.address}
                </small>
              </div>
              <div className="form-row">
                <div className="form-group col-lg">
                  <label htmlFor="city">CITY</label>
                  <input
                    id="city"
                    type="text"
                    name="city"
                    className={inputClassName}
                    placeholder="City Name"
                    value={ticket.city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <small className="small-green-text">
                    {currentState.purchaseTicketErrors.city}
                  </small>
                </div>
                <div className="form-group col-lg-3">
                  <label
                    htmlFor="State"
                    className={
                      ticket.country !== "United States of America"
                        ? "state-label-disabled"
                        : ""
                    }
                  >
                    STATE
                  </label>
                  {ticket.country !== "United States of America" ? (
                    <input
                      id="state"
                      className="form-control artist-textbox-style teder-select"
                      name="state"
                      type="text"
                      disabled
                      value="Select State"
                    />
                  ) : (
                    <select
                      id={
                        ticket.state === "Select State" || ticket.state === ""
                          ? "state-placeholder"
                          : "state"
                      }
                      className="form-control artist-textbox-style teder-select"
                      name="state"
                      value={ticket.state}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option>Select State</option>
                      <option>AK</option>
                      <option>AL</option>
                      <option>AR</option>
                      <option>AZ</option>
                      <option>CA</option>
                      <option>CO</option>
                      <option>CT</option>
                      <option>DE</option>
                      <option>FL</option>
                      <option>GA</option>
                      <option>HI</option>
                      <option>IA</option>
                      <option>ID</option>
                      <option>IL</option>
                      <option>IN</option>
                      <option>KS</option>
                      <option>KY</option>
                      <option>LA</option>
                      <option>MA</option>
                      <option>MD</option>
                      <option>ME</option>
                      <option>MI</option>
                      <option>MN</option>
                      <option>MO</option>
                      <option>MS</option>
                      <option>MT</option>
                      <option>NC</option>
                      <option>ND</option>
                      <option>NE</option>
                      <option>NH</option>
                      <option>NJ</option>
                      <option>NM</option>
                      <option>NV</option>
                      <option>NY</option>
                      <option>OH</option>
                      <option>OK</option>
                      <option>OR</option>
                      <option>PA</option>
                      <option>RI</option>
                      <option>SC</option>
                      <option>SD</option>
                      <option>TN</option>
                      <option>TX</option>
                      <option>UT</option>
                      <option>VA</option>
                      <option>VT</option>
                      <option>WA</option>
                      <option>WI</option>
                      <option>WV</option>
                      <option>WY</option>
                    </select>
                  )}
                  <small className="small-green-text">
                    {currentState.purchaseTicketErrors.state}
                  </small>
                </div>
                <div className="form-group col-lg-3">
                  <label htmlFor="zipCode">ZIP CODE</label>
                  <input
                    id="zipCode"
                    type="text"
                    name="zipCode"
                    className={inputClassName}
                    placeholder="Zip Code"
                    value={ticket.zipCode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    maxLength={5}
                  />
                  <small className="small-green-text">
                    {currentState.purchaseTicketErrors.zipCode}
                  </small>
                </div>
              </div>
              <div className="form-group">
                <label>COUNTRY</label>
                <select
                  id="country"
                  className={"form-control artist-textbox-style teder-select"}
                  name="country"
                  value={ticket.country}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  {currentState.countries.map((element) => (
                    <option key={element.ID}> {element.CountryName} </option>
                  ))}
                </select>
                <small className="small-green-text">
                  {currentState.purchaseTicketErrors.country}
                </small>
              </div>
              <div className="form-group form-inputs">
                <label htmlFor="email">EMAIL</label>
                <input
                  id="email"
                  type="email"
                  name="email"
                  className={inputClassName}
                  placeholder="Email"
                  value={ticket.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <small className="small-green-text">
                  {currentState.purchaseTicketErrors.email}
                </small>
              </div>
              {/* <div className="form-group">
                <label htmlFor="phoneNo">PHONE NUMBER</label>
                <input
                  id="phoneNo"
                  type="tel"
                  name="phoneNo"
                  className={inputClassName}
                  placeholder="Phone Number"
                  value={ticket.phoneNo}
                  maxLength={14}
                  required
                  onChange={formatPhoneNumber}
                  onBlur={handleBlur}
                />
                <small className="small-green-text">{currentState.purchaseTicketErrors.phoneNo}</small>
              </div><br/> */}
              <div className="form-group">
                <label htmlFor="phoneNo">PHONE NUMBER</label>
                <NumberFormat
                  type="tel"
                  id="phoneNo"
                  name="phoneNo"
                  format="(###) ###-####"
                  mask="_"
                  className={inputClassName}
                  placeholder="Phone Number"
                  onValueChange={handlePhone}
                  required
                />
              </div>
              <br />
            </form>
          </div>
          <div className="col-md-5">
            <form className="form ticket-form">
              <div className="form-group">
                <label className="text-center">NUMBER OF TICKETS</label>
                <br />
                <input
                  id="ticketCount"
                  type="number"
                  name="ticketCount"
                  className={inputClassName}
                  value={ticket.ticketCount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className="form-group">
                <label htmlFor="cardName">NAME ON CARD</label>
                <input
                  id="cardName"
                  type="text"
                  name="cardName"
                  className={inputClassName}
                  placeholder="Credit Card Name"
                  value={ticket.cardName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <small className="small-green-text">
                  {currentState.purchaseTicketErrors.cardName}
                </small>
              </div>
              <div className="form-group">
                <label htmlFor="cardNo">CREDIT CARD NUMBER</label>
                <input
                  id="cardNo"
                  type="number"
                  name="cardNo"
                  className={inputClassName}
                  placeholder="Credit Card Number"
                  value={ticket.cardNo}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <small className="small-green-text">
                  {currentState.purchaseTicketErrors.cardNo}
                </small>
              </div>
              <div className="row">
                <div className="form-group col-md-4">
                  <label htmlFor="expMonth">MONTH</label>
                  <select
                    id="expMonth"
                    name="expMonth"
                    className={inputClassName}
                    value={ticket.expMonth}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option>Month</option>
                    <option>01</option>
                    <option>02</option>
                    <option>03</option>
                    <option>04</option>
                    <option>05</option>
                    <option>06</option>
                    <option>07</option>
                    <option>08</option>
                    <option>09</option>
                    <option>10</option>
                    <option>11</option>
                    <option>12</option>
                  </select>
                  <small className="small-green-text">
                    {currentState.purchaseTicketErrors.expMonth}
                  </small>
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="expYear">YEAR</label>
                  <select
                    id="expYear"
                    name="expYear"
                    className={inputClassName}
                    value={ticket.expYear}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option>Year</option>
                    <option>2021</option>
                    <option>2022</option>
                    <option>2023</option>
                    <option>2024</option>
                    <option>2025</option>
                    <option>2026</option>
                    <option>2027</option>
                    <option>2028</option>
                    <option>2029</option>
                    <option>2030</option>
                  </select>
                  <small className="small-green-text">
                    {currentState.purchaseTicketErrors.expYear}
                  </small>
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="CVV">CVV</label>
                  <input
                    id="CVV"
                    type="number"
                    name="CVV"
                    className={inputClassName}
                    placeholder="CVV"
                    value={ticket.CVV}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <small className="small-green-text">
                    {currentState.purchaseTicketErrors.CVV}
                  </small>
                </div>
              </div>
            </form>
          </div>
          <br />
          <br />
          <br />
        </div>
        <div className="d-flex justify-content-center">
          <span className="red-teder-btn" onClick={handlePurchase}>
            Purchase
          </span>
        </div>
        <Modal
          isOpen={isLoading}
          ariaHideApp={false}
          className="Modal"
          overlayClassName="Overlay"
          contentLabel="Loading Modal"
        >
          <div className="teder-modal-container">
            <div className="modal-body">
              <div className="teder-modal-title text-center">Loading...</div>
              <div className="teder-modal-body"></div>
            </div>
          </div>
        </Modal>

        <Modal
          isOpen={modalIsOpen}
          ariaHideApp={false}
          className="Modal"
          overlayClassName="Overlay"
          contentLabel="Purchase Modal"
        >
          <div className="teder-modal-container">
            <div className="modal-body">
              <div className="teder-modal-title text-center">
                THANK YOU FOR YOUR PURCHASE!
              </div>
              <div className="teder-modal-body">
                <p>
                  Please save your ticket number below. An email confirmation
                  was also sent.
                </p>
                <div className="teder-modal-subtitle">
                  Your Ticket Number(s):
                </div>
                <ul className="list-group">
                  {ticketsPurchased.map((value) => (
                    <li
                      key={value}
                      className="list-group-item list-group-item-danger"
                    >
                      {value}
                    </li>
                  ))}
                </ul>
              </div>
              <br />
              <br />
              <div className="text-center">
                <button
                  type="button"
                  className="green-teder-btn"
                  onClick={() => setIsOpen(false)}
                >
                  close
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </Element>
  );
};

export default PurchaseTicket;
