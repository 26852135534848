import {
  admin_run_vimeo_prep,
  admin_update_video_ready_to_stream,
  admin_get_video_status,
  admin_end_video,
  admin_get_video_stream_details,
  admin_reset_show_status,
} from "../../Common/data";
// import { useSelector } from "react-redux";
import React, { useState } from "react";

const AdminGeneral = () => {
  // const currentState = useSelector((state) => state.TederReducerState)
  const [showID0, setShowID0] = useState();
  const [showID1, setshowID1] = useState();
  const [showID2, setshowID2] = useState();
  const [showID3, setshowID3] = useState();
  const [showID4, setshowID4] = useState();

  const [message, setMessage] = useState();
  const [VideoReadyResult, setVideoReadyResult] = useState();
  const [VideoStatusResult, setVideoStatusResult] = useState();
  const [EndVideoResult, seEndVideoResult] = useState();
  const [ShowStreamInfo, seShowStreamInfo] = useState();

  const handleResetShowStatus = () => {
    const showId = {
      event_id: parseInt(showID0),
    };
    admin_reset_show_status(showId)
      .then((res) => console.log("reset show status", res))
      .catch((error) => console.error("reset show status error", error));
  };

  const handleEventPrep = () => {
    let data = {};

    admin_run_vimeo_prep(data)
      .then((result) => {
        setMessage(result.message);
      })
      .catch((error) => {
        console.log("Error caught in admin_run_vimeo_prep api");
        console.log(error);
        return false;
      });
  };

  const handleShowReady = () => {
    let data = {
      video_url: "/videos/" + showID1,
    };

    admin_update_video_ready_to_stream(data)
      .then((result) => {
        setVideoReadyResult(result.vimeo_response[0]);
      })
      .catch((error) => {
        console.log("Error caught in admin_run_vimeo_prep api");
        console.log(error);
        setVideoReadyResult(error);
        return false;
      });
  };

  const handleCheckShowStatus = () => {
    let data = {
      video_url: "/videos/" + showID2,
    };

    admin_get_video_status(data)
      .then((result) => {
        setVideoStatusResult(result.vimeo_response);
      })
      .catch((error) => {
        console.log("Error caught in admin_run_vimeo_prep api");
        console.log(error);
        setVideoStatusResult(error);
        return false;
      });
  };

  const handleEndShow = () => {
    let data = {
      video_url: "/videos/" + showID3,
    };

    admin_end_video(data)
      .then((result) => {
        seEndVideoResult(result.vimeo_response);
      })
      .catch((error) => {
        console.log("Error caught in admin_end_video api");
        console.log(error);
        seEndVideoResult(error);
        return false;
      });
  };

  const handleGetShowStreamDetails = () => {
    let data = {
      show_id: showID4,
    };

    admin_get_video_stream_details(data)
      .then((result) => {
        seShowStreamInfo(result.stream_info);
      })
      .catch((error) => {
        console.log("Error caught in admin_end_video api");
        console.log(error);
        seShowStreamInfo(error);
        return false;
      });
  };

  return (
    <div className="row adminRow">
      <h3>General Admin Actions</h3>
      <div className="col-md-12">
        <table className="table tableWhite">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Action</th>
              <th scope="col">Result</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Run Vimeo Event Prep</td>
              <td>
                <button onClick={handleEventPrep} className="btn-primary">
                  Execute
                </button>
              </td>
              <td colSpan="2">
                <small>{message}</small>
              </td>
            </tr>
            <tr>
              <td>Reset Event Status</td>
              <td>
                <input
                  type="text"
                  id="showID0"
                  onChange={(e) => setShowID0(e.target.value)}
                  placeholder="Enter Show ID Here"
                ></input>
                <button onClick={handleResetShowStatus} className="btn-primary">
                  Reset
                </button>
              </td>
              <td colSpan="2">
                <small>{message}</small>
              </td>
            </tr>
            <tr>
              <td>Update video - Ready to stream</td>
              <td>
                <input
                  type="text"
                  id="showID1"
                  onChange={(e) => setshowID1(e.target.value)}
                  placeholder="Enter Show ID Here"
                ></input>
                <button onClick={handleShowReady} className="btn-primary">
                  Update
                </button>
              </td>
              <td>
                <small>{VideoReadyResult}</small>
              </td>
            </tr>
            <tr>
              <td>Check video state</td>
              <td>
                <input
                  type="text"
                  id="showID2"
                  onChange={(e) => setshowID2(e.target.value)}
                  placeholder="Enter Show ID Here"
                ></input>
                <button onClick={handleCheckShowStatus} className="btn-primary">
                  Check
                </button>
              </td>
              <td>
                <small>{VideoStatusResult}</small>
              </td>
            </tr>
            <tr>
              <td>End video</td>
              <td>
                <input
                  type="text"
                  id="showID3"
                  onChange={(e) => setshowID3(e.target.value)}
                  placeholder="Enter Show ID Here"
                ></input>
                <button onClick={handleEndShow} className="btn-primary">
                  End Video Stream
                </button>
              </td>
              <td>
                <small>{EndVideoResult}</small>
              </td>
            </tr>
            <tr>
              <td>Get Teder Show Stream Info</td>
              <td>
                <input
                  type="text"
                  id="showID4"
                  onChange={(e) => setshowID4(e.target.value)}
                  placeholder="Enter Show ID Here"
                ></input>
                <button
                  onClick={handleGetShowStreamDetails}
                  className="btn-primary"
                >
                  Get Show Stream Info
                </button>
              </td>
              <td>
                <small>{ShowStreamInfo}</small>
              </td>
            </tr>
          </tbody>
        </table>
    </div>
 </div>
  );
};

export default AdminGeneral;
