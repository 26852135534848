import React, { useState } from "react";
import "../../../Styling/artistsignup.css";
import GenreOptions from "../../../HelperFunctions/GenreOptions";
import UploadPhoto from "../../../HelperFunctions/UploadPhoto";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import {
  updateShowAction,
  updateShowErrorsAction,
  // updatHomepageShowsAction
} from "../../../redux/actions";
import ValidateShowForm from "./ValidateShowForm";
import Config from "../../../Common/config";
import { update_show } from "../../../Common/data";
import RenderTimeZone from "../../../HelperFunctions/RenderTimeZone";
import moment from "moment";
import Modal from "react-modal";

const EditShowDetails = ({ match, history }) => {
  const dispatch = useDispatch();
  const currentState = useSelector((state) => state.TederReducerState);
  const editShow = currentState.viewShowDetails;

  const inputClassName = "form-control artist-textbox-style";
  const baseShowsURL = Config().ShowsPhotoUrl;
  const dayjs = require("dayjs");
  let startDate = dayjs(editShow.showDate);

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [confirmNotify, setConfirmNotify] = useState(false);
  const [savedChanges, setSavedChanges] = useState(false);
  const [updateSaved, setUpdateSaved] = useState({
    showName: false,
    genres: false,
    showDate: false,
    showTime: false,
    showTimezone: false,
    showDuration: false,
    showDescription: false,
    photoPath: false,
  });

  const handleChange = (e) => {
    let { name, value } = e.target;

    let updateShowDetails = {
      ...editShow,
      Artist_ID: currentState.artistContent.stageName,
      [name]: value,
    };

    setUpdateSaved({ ...updateSaved, [name]: true });

    dispatch(updateShowAction(updateShowDetails));
    //update the error state so that the user get real-time validation feedback
    const errors = ValidateShowForm(updateShowDetails);
    // console.log(errors)
    dispatch(updateShowErrorsAction(errors));

    // console.log("are we vaild?", currentState.editShowErrors.isValid)
    // if(currentState.editShowErrors.isValid && (name === 'photoPath' || name === 'genres')){
    //     if(value === '' || !value){
    //       return
    //     } else {
    //       handleBlur(e)
    //     }
    // }
  };

  const handleSave = () => {
    const editShow = currentState.viewShowDetails;

    if (currentState.editShowErrors.isValid) {
      for (const [name, value] of Object.entries(editShow)) {
        if (updateSaved[name] === true) {
          handleBlur(name, value);
        }
      }
      setUpdateSaved({
        showName: false,
        genres: false,
        showDate: false,
        showTime: false,
        showTimezone: false,
        showDuration: false,
        showDescription: false,
        photoPath: false,
      });
      setSavedChanges(true);
    } else {
      showError();
    }
  };

  const showError = () => {
    alert("Please complete form");
  };

  const handleBlur = (name, value) => {
    // let { name, value } = e.target;

    if (currentState.editShowErrors.isValid) {
      const updatedShow = {
        ShowID: editShow.showID,
        update_field: name,
        update_value: value,
      };

      update_show(updatedShow)
        .then((data) => {
          if (data.code === "Success") {
            // updateHomepageShows();
            setConfirmNotify(false);
          } else {
            console.log("Something went wrong upon update...");
          }
          return true;
        })
        .catch((error) => {
          console.log("Error caught in update api");
          console.log(error);
          return false;
        });
    }
  };

  // const updateHomepageShows = () => {
  //     const list = currentState.homepageShows
  //     console.log('inside updateHomepageShows', list)
  //     const updateShowsList = list.map(show => {
  //         if(show.ID === editShow.showID) {
  //             return show = {
  //                 ArtistID: editShow.artistID,
  //                 ArtistPhotoPath: editShow.artistPhotoPath,
  //                 CreatedDate: editShow.createdDate,
  //                 Duration: editShow.showDuration,
  //                 // EventBroadcastLink: editShow.showStreamKey,
  //                 EventDescription: editShow.showDescription,
  //                 EventName: editShow.showName,
  //                 EventPageLink: editShow.showPageLink,
  //                 Featured: editShow.featured,
  //                 GenreIDList: editShow.genreIDList,
  //                 Genres: editShow.genres,
  //                 ID: editShow.showID,
  //                 PhotoPath: editShow.photoPath,
  //                 StageName: editShow.artistStageName,
  //                 StartDate: editShow.showDate,
  //                 StartTime: editShow.showTime,
  //                 Status: editShow.status,
  //                 TicketPrice: editShow.showTicketPrice,
  //                 TicketsSold: editShow.ticketsSold,
  //                 TimeZone: editShow.showTimezone,
  //                 TotalTickets: editShow.showTicketMax,
  //             }
  //         } else {
  //             return show
  //         }
  //     })
  //     console.log('post update..', updateShowsList)
  //     dispatch(updatHomepageShowsAction(updateShowsList))
  // }

  const yesChange = () => {
    setIsOpen(false);
    setConfirmNotify(true);
  };

  const backButton = () =>
    currentState.editShowErrors.isValid
      ? history.goBack()
      : alert("Please complete the form");

  const sensitiveInputs = () => (
    <>
      <div className="form-row">
        <div className="form-group col-md">
          <label className="teder-form-label">DATE</label>
          <input
            type="date"
            name="showDate"
            className={inputClassName}
            value={editShow.showDate}
            onChange={handleChange}
            placeholder="mm/dd/yyy"
          />
          <small className="small-green-text">
            {currentState.editShowErrors.showDate}
          </small>
        </div>
        <div className="form-group col-md">
          <label className="teder-form-label">TIME</label>
          <input
            type="time"
            name="showTime"
            className={inputClassName}
            value={editShow.showTime}
            onChange={handleChange}
          />
          <small className="small-green-text">
            {currentState.editShowErrors.showTime}
          </small>
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md">
          <label className="teder-form-label">TIME ZONE</label>
          <select
            id="selectTimezone"
            name="showTimezone"
            className="form-control artist-textbox-style teder-select"
            value={editShow.showTimezone}
            onChange={handleChange}
          >
            <option value="-12:00">(GMT -12:00) Baker Island</option>
            <option value="-11:00">(GMT -11:00) Apia, Samoa</option>
            <option value="-10:00">(GMT -10:00) Honolulu, Hawaii</option>
            <option value="-09:50">(GMT -9:30) Marquesas Islands</option>
            <option value="-09:00">(GMT -9:00) Juneau, Alaska</option>
            <option value="-08:00">
              (GMT -8:00) Pacific Time (US &amp; Canada), Los Angeles,
              California{" "}
            </option>
            <option value="-07:00">
              (GMT -7:00) Mountain Time (US &amp; Canada), Denver, Colorado
            </option>
            <option value="-06:00">
              (GMT -6:00) Central Time (US &amp; Canada), Chicago, Illinois
            </option>
            <option value="-05:00">
              (GMT -5:00) Eastern Time (US &amp; Canada), New York, New York
            </option>
            <option value="-04:00">(GMT -4:00) Caracas, Venezuela</option>
            <option value="-03:50">(GMT -3:30) St. John's, Newfoundland</option>
            <option value="-03:00">(GMT -3:00) Sao Paulo, Brazil</option>
            <option value="-02:00">(GMT -2:00) South Georgia Island</option>
            <option value="-01:00">(GMT -1:00) Azores, Portugal</option>
            <option value="+00:00">(GMT) London, England</option>
            <option value="+01:00">(GMT +1:00) Paris, France</option>
            <option value="+02:00">(GMT +2:00) Kaliningrad, Russia</option>
            <option value="+03:00">(GMT +3:00) Tel Aviv-Yafo, Israel</option>
            <option value="+03:50">(GMT +3:30) Tehran, Iran</option>
            <option value="+04:00">(GMT +4:00) Muscat, Oman</option>
            <option value="+04:50">(GMT +4:30) Kabul, Afghanistan</option>
            <option value="+05:00">(GMT +5:00) Tashkent, Uzbekistan</option>
            <option value="+05:50">(GMT +5:30) Mumbai, India</option>
            <option value="+05:75">(GMT +5:45) Kathmandu, Nepal</option>
            <option value="+06:00">(GMT +6:00) Colombo, Sri Lanka</option>
            <option value="+06:50">(GMT +6:30) Yangon, Myanmar</option>
            <option value="+07:00">(GMT +7:00) Jakarta, Indonesia</option>
            <option value="+08:00">(GMT +8:00) Singapore, Singapore</option>
            <option value="+08:75">(GMT +8:45) Eucla, Western Australia</option>
            <option value="+09:00">(GMT +9:00) Tokyo, Japan</option>
            <option value="+09:50">(GMT +9:30) Darwin, Australia</option>
            <option value="+10:00">(GMT +10:00) Sydney, Australia</option>
            <option value="+10:50">(GMT +10:30) Lord Howe Island</option>
            <option value="+11:00">(GMT +11:00) Solomon Islands</option>
            <option value="+11:50">(GMT +11:30) Norfolk Island</option>
            <option value="+12:00">(GMT +12:00) Fiji, Oceania</option>
            <option value="+12:75">(GMT +12:45) Chatham Islands</option>
            <option value="+13:00">(GMT +13:00) Apia, Samoa</option>
            <option value="+14:00">(GMT +14:00) Line Islands</option>
          </select>
        </div>
      </div>
      <div className="form-group">
        <div className="form-row">
          <div className="form-group col-md">
            <label className="teder-form-label">DURATION</label>
            <input
              type="range"
              name="showDuration"
              className="custom-range durationRange "
              value={editShow.showDuration}
              min="30"
              max="300"
              step="30"
              id="durationRange"
              onChange={handleChange}
            />
          </div>
          <div className="form-group col-md">
            <label className="teder-form-label">MINUTES</label>
            <input
              type="text"
              name="showDuration"
              className={inputClassName}
              value={editShow.showDuration}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    </>
  );

  const sensitiveInfo = () => (
    <div className="sensitive-details">
      <div className="form-row">
        <div className="form-group col">
          <label className="teder-form-label">DATE:</label>
        </div>
        <div className="form-group col">
          <div className="sc-show-date">{startDate.format("MMM D YYYY")}</div>
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col">
          <label className="teder-form-label">TIME:</label>
        </div>
        <div className="form-group col">
          <div className="sc-show-date">
            {moment(editShow.showTime, "HH:mm").format("hh:mm a")}
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col">
          <label className="teder-form-label">TIME ZONE:</label>
        </div>
        <div className="form-group col">
          <div className="sc-show-location">
            {RenderTimeZone(editShow.showTimezone)}
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col">
          <label className="teder-form-label">Duration:</label>
        </div>
        <div className="form-group col">
          <div className="sc-show-date">{editShow.showDuration} mins</div>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <button className="img-box-button" onClick={() => setIsOpen(true)}>
          Edit
        </button>
      </div>
    </div>
  );

  const editForm = () => (
    <div className="container artist-signup3 justify-content-xl-center">
      <div className="text-center">
        <button className="green-teder-btn" onClick={backButton}>
          Back
        </button>
      </div>
      <br />
      <br />
      <h2>EDIT SHOW</h2>
      <div className="row">
        <div className="col-md">
          <UploadPhoto
            photo={`${editShow.photoPath}` || null}
            handleChange={handleChange}
            message={"Upload Event Photo"}
            showType={"show"}
          />
          <small className="small-green-text">
            {currentState.editShowErrors.photoPath}
          </small>
          {confirmNotify ? sensitiveInputs() : sensitiveInfo()}
        </div>
        <div className="col-md">
          <form>
            <div className="form-group">
              <label htmlFor="showName" className="teder-form-label">
                SHOW NAME
              </label>
              <input
                id="showName"
                type="text"
                name="showName"
                className={inputClassName}
                placeholder="Show Name"
                value={editShow.showName}
                onChange={handleChange}
              />
              <small className="small-green-text">
                {currentState.editShowErrors.showName}
              </small>
            </div>
            <div className="form-group">
              <GenreOptions
                genres={editShow.genres}
                handleChange={handleChange}
              />
              <small className="small-green-text">
                {currentState.editShowErrors.genres}
              </small>
            </div>
            <div className="form-group">
              <label className="teder-form-label">DESCRIPTION</label>
              <br />
              <small className="small-green-text">
                Character Limit: {300 - editShow.showDescription.length}
              </small>
              <textarea
                className={inputClassName}
                id="inputAbout"
                name="showDescription"
                placeholder="Tell us more about your show."
                value={editShow.showDescription}
                onChange={handleChange}
                maxLength="300"
              />
              <small className="small-green-text">
                {currentState.editShowErrors.showDescription}
              </small>
            </div>
          </form>
        </div>
      </div>
      <br />
      <br />

      <div className="d-flex justify-content-center">
        <span className="red-teder-btn" onClick={handleSave}>
          Save
        </span>
      </div>

      <Modal
        isOpen={modalIsOpen}
        ariaHideApp={false}
        className="Modal"
        overlayClassName="Overlay"
        contentLabel="Bank Modal"
      >
        <div className="teder-modal-container">
          <div className="modal-body">
            <div className="teder-modal-title text-center">Careful!</div>
            <div className="teder-modal-body">
              You're about to edit sensitive information. Any changes to these
              details will notify your audience.
              <br />
              <br />
              <br />
              <div className="text-center">Would you like to proceed?</div>
            </div>
            <div className="text-center">
              <button
                type="button"
                className="red-teder-btn"
                onClick={yesChange}
              >
                Yes
              </button>
              <button
                type="button"
                className="green-teder-btn"
                onClick={() => setIsOpen(false)}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={savedChanges}
        ariaHideApp={false}
        className="Modal"
        overlayClassName="Overlay"
        contentLabel="Bank Modal"
      >
        <div className="teder-modal-container">
          <div className="modal-body">
            <div className="teder-modal-subtitle text-center">
              Your changes have been saved!
            </div>
            <br />
            <br />
            <div className="text-center">
              <button
                className="green-teder-btn"
                onClick={() => setSavedChanges(false)}
              >
                close
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );

  const redirect = () => {
    if (!currentState.artistContext.stageName) {
      dispatch(push(`/shows/${match.params.id}`));
      return "not your show";
    } else if (
      currentState.artistContext.stageName === editShow.artistStageName
    ) {
      return editForm();
    } else {
      dispatch(push(`/shows/${match.params.id}`));
      return "not your show";
    }
  };

  return redirect();
};

export default EditShowDetails;
