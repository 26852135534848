import React from "react";
import Footer from "./Footer";
import IGFeed from "./IGFeed";
import "../../Components/Footer/footer.css";

const FooterContainer = () => {
  return (
    <>
      {/* <div id="page-break">
        <h4>
          <a
            href="http://instagram.com/tederlive"
            target="blank"
            className="follow-us"
          >
            FOLLOW US @ONTEDER
          </a>
        </h4>
      </div>
      <div id="ig-feed">
        <IGFeed />
      </div> */}
      <div id="footer">
        <Footer />
      </div>
    </>
  );
};

export default FooterContainer;
