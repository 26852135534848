import React from "react";
import { Link } from "react-router-dom";
const TermsPage = () => {
  return (
    <div className="container">
      <div>
        <br />
        <h2>Terms and Conditions</h2>
      </div>
      <br />
      <div className="row justify-content-start">
        <div className="col-sm">
          <p>
            These Terms and Conditions of Use (“Terms of Use”) set forth the
            terms and conditions under which you are authorized to use the
            websites of Teder, LLC including any subdomains and mobile versions,
            use one of our mobile applications, or interact with our other
            on-site or web-enabled technologies.
          </p>
          <h3>DEFINITIONS</h3>
          <p>“Artists” refers to Musicians/performers with Teder accounts; </p>
          <p>
            “Audience” refers to the persons who access the Teder platform to
            view livestreamed performances from the Artists they follow;
          </p>
          <p>
            “Company” refers to Teder, LLC and their subsidiaries, affiliates,
            assigns, service providers, licensors, respective officers,
            directors, employees or agents and other related entities;
          </p>
          <p>
            “Digital Platform” refers to the proprietary technology and
            integrated third-party technology used to deliver the Digital
            Service to the Users;
          </p>
          <p>
            “Digital Service” refers to the product delivered via the Digital
            Platform through various web technologies for User’s consumption;
          </p>
          <p>
            “Users” or “You/Your” refers to all persons accessing the Teder
            platform, including the Artists, the Audience, and any other persons
            or entities engaging with the Teder platform.
          </p>
          <h3>DESCRIPTION OF SERVICE</h3>
          <p>
            Our Digit al Service pairs music artists with the general public.
            Through your use of our Digital Service, you consent to the
            practices described in these Terms of Use. To the extent additional
            rules or guidelines affect your use of our Digital Service, those
            rules and guidelines (including our Privacy Policy) are hereby
            incorporated by reference into these Terms of Use. If you do not
            agree to these Terms of Use, you should immediately stop using our
            Digital Services.
          </p>
          <h3>PREREQUSITES TO USING THE DIGITAL SERVICE</h3>
          <p>
            You need access to the internet in order to use the Digital Platform
            and access the Digital Service. Any cost for accessing the internet
            shall be paid by you. This also applies when using the Digital
            Service via mobile phone, tablet, or other personal device capable
            of internet connection. If you use the Digital Service via mobile
            telephone you should familiarize yourself with applicable data
            transfer costs for your mobile carrier plan. Company does not have
            any responsibility or liability for data transfer costs you may
            incur when using the Digital Service. We cannot guarantee that the
            Digital Service will work with all devices.{" "}
          </p>
          <p>
            It is your responsibility to ensure that you are able to comply with
            the relevant system requirements described above. Company accepts no
            responsibility for any lack of functionality that is due to your
            equipment (including your device, internet connection, operating
            system or settings and software). Access to parts or all of the
            Digital Service may be restricted from time to time to allow for
            repairs, maintenance or updating.
          </p>
          <p>
            You may not use the Digital Service in any way that is fraudulent or
            illegal or is expressly prohibited by these Terms. By using the
            Service, you promise to us that you accept these Terms and that (a)
            you are 18 years of age or over; or (b) you are between the age of
            13 and 17, and have express permission from your parent or guardian
            to use the Digital Service. If you are under age 13, you are not
            permitted to use the Digital Service or to submit any personally
            identifiable information to Company. If Company reasonably believes
            that you have not told us your true age, we may suspend your use of
            the Digital Service until you have provided us with acceptable proof
            of age. If you are a parent or guardian and believe Company may have
            inadvertently collected personal information from your child, please
            notify Company immediately by sending an email to
            support@onteder.com detailing the basis of your belief and your
            request.
          </p>
          <p>
            The Digital Service requires you to register and create an account
            for validation. You agree to (a) provide accurate, current and
            complete information as prompted by any registration forms on the
            Service; (b) maintain and promptly update such information and any
            other information provided to Company, to keep it accurate, current
            and complete; (c) maintain the security of your password and
            identification; and (d) be fully responsible for all use of your
            account and for any actions that take place using your account.
          </p>
          <p>
            Company reserves the right to suspend or remove your account or
            prohibit your use of the Digital Service, without responsibility or
            liability to you, if activities occur on your account that we
            believe breach the Agreement.
          </p>
          <h3>PURCHASE, PRICE INFORMATION, PAYMENT TERMS AND REFUND POLICY</h3>
          <ol type="A">
            <li>
              Events. Digital Service will provide to you certain Events which
              may include (without limitation), concerts, studio recording
              sessions, jam sessions, etc. Company reserves the right to
              schedule, or allow to be scheduled, Events at any time. To access
              any of the Events, User must register with Digital Service.
              Digital Service will store and maintain User’s information,
              including credit card number, in a secure environment. Users must
              use that registration to access any of the Digital Services.
            </li>
            <li>
              Payment Methods. In connection with any and all Events that you
              select to purchase you must provide Company with a current, valid,
              accepted method of payment, which may change from time to time
              ("Payment Method"). All Event purchases are subject to this
              Agreement and any additional terms and conditions, including any
              fees, imposed by your financial services provider and/or mobile
              carrier, as applicable. You are solely responsible for reviewing
              the terms of use, privacy policy or any other terms governing your
              use of the services provided by your financial services provider
              and/or mobile carrier. By providing a Payment Method in connection
              with any Event payment, you hereby authorize Company to debit the
              total cost of said Event and agree to be responsible for any
              foreign transaction charges associated with your Event purchase.
              Should charges for which you are responsible fail at the time
              payment is required, you may be responsible for costs associated
              with Company’s efforts to collect amounts due in accordance with
              applicable laws. You represent and promise to us that all
              information you provide in connection with the purchase of an
              Event is true, accurate and complete. The duration and cost of
              Events are set by Company in its sole discretion. When you
              purchase access to an Event, you will initially be charged at the
              rate applicable at that time.
            </li>
            <li>
              Billing Cycle. The total Event fee is due and payable to Company
              immediately upon sale and your Payment Method will be
              automatically billed at that time.{" "}
            </li>
            <li>
              Refund Policy. We will not refund any fees paid to us, including
              any advance charge(s) or payment(s) for any Event listed on the
              Digital Service, unless otherwise required by law or the terms of
              this Agreement.
            </li>
            <li>
              Charge Backs. If you dispute Event charges with your credit card
              company or financial institution, and Company’s investigation
              determines the charges were valid, we may, on reasonable written
              notice to you, charge to your Digital Service account the
              reasonable required cost of responding to your charge back
              request. Company’s Privacy Policy details what information Company
              may share with your financial institution as part of the
              investigation into disputed Event charges.
            </li>
          </ol>
          <h3>THIRD-PARTY SERVICES AND CONTENT</h3>
          <p>
            Our Digital Service are intended for personal use and for
            entertainment purposes. We enable you to interact with and inquire
            about the Artists and their promoted or hosted events. We may also
            provide links for you to third party websites, some of which may be
            operated by a third party, not Company. Your use of these features
            and other content or services provided by third parties—including
            third-party links to other websites on the Internet—are not governed
            by these Terms of Use. You acknowledge and agree that we are not
            responsible for the availability of, or any content located on or
            through, any third-party site or service, or any use of your
            information by those third parties. You further acknowledge that any
            reliance on representations and warranties provided by any party
            other than Company will be at your own risk. You expressly agree to
            hold us harmless for any claims of damage arising from any content,
            product or service provided by any third party or use of your
            information by those third parties. Your use of those third-party
            websites and services is subject to the terms of use and privacy
            policies posted on each site or service, and we encourage you to
            review those terms of use and privacy policies.
          </p>
          <h3>MODIFICATIONS AND INTERRUPTION TO THE DIGITAL SERVICE</h3>
          <p>
            We reserve the right to modify or discontinue all or any portion of
            our Digital Service with or without notice to you. We will not be
            liable if we choose to exercise this right. You acknowledge and
            accept that we do not guarantee continuous, uninterrupted or secure
            access to our Digital Services, or that operation of our Digital
            Service will be uninterrupted or error free. You understand that
            usage of our Digital Service may be interfered with or adversely
            affected by numerous factors or circumstances outside of our
            control.
          </p>
          <h3>RESTRICTED ACTIVITIES</h3>
          <p>
            Digital Service is intended for personal use only. use of the
            Digital Service in commercial or public settings is not permitted.
            Any use of content on the Digital Service, including without
            limitation reproduction for purposes other than those noted herein,
            modification, distribution, replication, any form of data extraction
            or data mining, or other commercial exploitation of any kind,
            without prior written permission of an authorized officer of Company
            is strictly prohibited. With the exception of search engines, you
            agree that you will not use any robot, spider, or other automatic
            device, or manual process to monitor or copy our web pages or the
            content contained therein without prior written permission of an
            authorized officer of Company. You may use the content solely for
            your personal, non-commercial use, except as described herein. You
            may not make any use of content owned by any third parties which is
            available on the Digital Service without the express consent of
            those third parties. You may not engage in any of the following with
            regard to the Digital Service (including without limitation posting
            or transmitting content through the Digital Service), and you agree
            not to use the Digital Service to:
          </p>
          <ol>
            <li>
              Violate or encourage the violation of any local, state, national,
              or international law or regulation;
            </li>
            <li>
              Collect or store personal data about other users of our Digital
              Service or solicit personal information from any individual;
            </li>
            <li>
              Impersonate any person or entity or otherwise misrepresent your
              affiliation with a person or entity;
            </li>
            <li>
              Send or promote any message that is unlawful, libelous,
              defamatory, abusive, sexually explicit, threatening, vulgar,
              obscene, profane, disparaging regarding racial, gender or ethnic
              background, any humanFriendlyTimeStatement that you have reason to
              know is false or misleading, or otherwise objectionable messages,
              as determined by Company in its sole discretion;
            </li>
            <li>
              Infringe any patent, trademark, trade secret, copyright, right of
              publicity or privacy, or other right of any party, or distribute
              any content you do not have a right to make available under any
              law or under contractual or fiduciary relationships;
            </li>
            <li>
              Promote or distribute any unauthorized advertising, promotional
              materials, or material which can be characterized as “junk mail,”
              “spam,” “chain letters,” “pyramid schemes,” or similar material,
              any request for or solicitation of money, goods, or services for
              private gain, or any information posted primarily for advertising,
              promotional, or other commercial purposes;
            </li>
            <li>
              Disrupt or interfere with the security or use of the Digital
              Service or any websites or content linked to them;
            </li>
            <li>
              Interfere with or damage the Digital Service, including, without
              limitation, through the use of viruses, Trojan horses, harmful
              code, denial of service attacks, packet or IP spoofing, forged
              routing or email address information or similar methods or
              technology or disobey any requirements, procedures, policies, or
              regulations of networks connected to our Digital Service;
            </li>
            <li>
              Attempt to use another user’s account, impersonate another person
              or entity, misrepresent your affiliation with a person or entity,
              including (without limitation) Company, our any third party, or
              create or use a false identity;
            </li>
            <li>
              Attempt to obtain unauthorized access to the Digital Service or
              portions thereof that are restricted from general access;
            </li>
            <li>
              Use any meta tags or any other “hidden text” utilizing Company’s
              (or any third-party partner or sponsor’s) name, trademarks, or
              product names;
            </li>
            <li>
              Attempt to reverse engineer or otherwise derive or obtain the code
              in any form for any software used in the Digital Service;
            </li>
            <li>
              Engage in any activity that interferes with any third party’s
              ability to use or enjoy the Digital Service; or
            </li>
            <li>
              Assist any third party in engaging in any activity prohibited by
              these Terms of Use.
            </li>
          </ol>
          <p>Further, without our written consent, you may not:</p>
          <ul>
            <li>
              Reproduce, duplicate, copy, sell, resell, or exploit for any
              commercial purpose any Company content or any use of or access to
              the Digital Service;
            </li>
            <li>
              Use any high volume, automated, or electronic means (including,
              without limitation, robots, spiders, scripts, or other automated
              devices) to access the Digital Service or monitor or copy our web
              pages or the content contained thereon;
            </li>
            <li>
              Deep link to the Digital Service for any purpose; or frame the
              Digital Service, place pop-up windows over any content, or
              otherwise affect the display of the Digital Service.
            </li>
          </ul>
          <h3>INTELLECTUAL PROPERTY POLICY</h3>
          <p>
            We take claims of third-party intellectual property rights very
            seriously. In particular, we will respond to notices of alleged
            infringement that comply with applicable law. If you believe any
            materials accessible on or from this site (the “Website”) infringe
            your intellectual property, you may request removal of those
            materials (or access to them) from the Website by submitting written
            notification to our copyright agent designated below. In accordance
            with the Online Copyright Infringement Liability Limitation Act of
            the Digital Millennium Copyright Act (17 U.S.C. § 512) (“DMCA”), the
            written notice (the “DMCA Notice”) must include substantially the
            following:
          </p>
          <ul>
            <li>Your physical or electronic signature.</li>
            <li>
              Identification of the copyrighted work you believe to have been
              infringed or, if the claim involves multiple works on the Website,
              a representative list of such works.
            </li>
            <li>
              Identification of the material you believe to be infringing in a
              sufficiently precise manner to allow us to locate that material.
            </li>
            <li>
              Adequate information by which we can contact you (including your
              name, postal address, telephone number, and, if available, email
              address).
            </li>
            <li>
              A humanFriendlyTimeStatement that you have a good faith belief
              that use of the copyrighted material is not authorized by the
              copyright owner, its agent, or the law.
            </li>
            <li>
              A humanFriendlyTimeStatement that the information in the written
              notice is accurate.
            </li>
            <li>
              A humanFriendlyTimeStatement, under penalty of perjury, that you
              are authorized to act on behalf of the copyright owner.
            </li>
          </ul>
          <p>
            Our designated copyright agent to receive DMCA Notices can be reach
            at email:{" "}
            <a className="static-link" href="mailto:support@onteder.com">
              support@onteder.com{" "}
            </a>
          </p>
          <p>
            If you fail to comply with all of the requirements of Section
            512(c)(3) of the DMCA, your DMCA Notice may not be effective.
          </p>
          <p>
            Please be aware that if you knowingly materially misrepresent that
            material or activity on the Website is infringing your copyright,
            you may be held liable for damages (including costs and attorneys’
            fees) under Section 512(f) of the DMCA.
          </p>
          <p>
            In accordance with the DMCA, it is the policy of Company to
            terminate use of our Digital Service by repeat infringers in
            appropriate circumstances.
          </p>
          <h3>DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY</h3>
          <p>
            TO THE FULLEST EXTENT PERMITTED BY LAW, COMPANY, THEIR RELATED
            ENTITIES, SERVICE PROVIDERS, LICENSORS, AND RESPECTIVE OFFICERS,
            DIRECTORS, EMPLOYEES OR AGENTS (COLLECTIVELY “TEDER, LLC”) EXPRESSLY
            DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED OR
            STATUTORY, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND
            NON-INFRINGEMENT, RELATED TO OUR DIGITAL SERVICE.
          </p>
          <p>
            YOU UNDERSTAND AND AGREE THAT YOUR USE OF OUR DIGITAL SERVICE IS AT
            YOUR SOLE RISK. OUR DIGITAL SERVICE AND ALL CONTENT, PRODUCTS AND
            SERVICES OFFERED THROUGH THE DIGITAL SERVICE ARE PROVIDED ON AN “AS
            IS” AND “AS AVAILABLE” BASIS. TEDER, LLC IS NOT RESPONSIBLE FOR THE
            TIMELINESS OF DELIVERY OF CONTENT, ANY FAILURES OF DELIVERY,
            ERRONEOUS DELETION, OR ANY LOSS OR DAMAGE OF ANY KIND AS A RESULT OF
            THE USE OF ANY DIGITAL SERVICE. UNDER NO CIRCUMSTANCES, WILL ANY OF
            TEDER, LLC’S PARTIES BE LIABLE TO YOU OR TO ANY PERSON OR ENTITY
            CLAIMING THROUGH YOU FOR ANY LOSS, INJURY, LIABILITY OR DAMAGES
            ARISING OUT OF OR IN CONNECTION WITH YOUR ACCESS TO, USE OF,
            INABILITY TO USE, OR RELIANCE ON ANY OF OUR DIGITAL SERVICE OR ANY
            CONTENT, PRODUCT OR SERVICE PROVIDED TO YOU THROUGH OR IN CONNECTION
            WITH ANY OF OUR DIGITAL SERVICE. THIS IS A COMPREHENSIVE LIMITATION
            OF LIABILITY THAT APPLIES TO ALL LOSSES AND DAMAGES OF ANY KIND
            WHATSOEVER, WHETHER DIRECT OR INDIRECT, GENERAL, SPECIAL,
            INCIDENTAL, CONSEQUENTIAL, EXEMPLARY OR OTHERWISE, INCLUDING WITHOUT
            LIMITATION, LOSS OF DATA, GOODWILL, REVENUE OR PROFITS.
          </p>
          <h3>INDEMNIFICATION</h3>
          <p>
            By use of the Digital Service, you agree to indemnify and hold
            Company harmless from any claim or demand, including reasonable
            attorney’s fees and costs, made by any third party due to or arising
            out of your posting of any content on our Digital Service, or other
            use of our Digital Service in a manner not permitted by these Terms
            of Use, including without limitation your actual or alleged
            violation of these Terms of Use, or infringement of a third party’s
            intellectual property or other rights by you or another user of our
            Digital Service using your computer, mobile device or account.
          </p>
          <h3>USER-SUBMITTED CONTENT</h3>
          <p>
            Any content uploaded, posted, submitted, or otherwise made available
            by individual users of the Digital Service, including without
            limitation blog comments, message board posts, and any other content
            which does not originate with Company (“User Content”), is the sole
            responsibility of the person who made such User Content available on
            the Digital Service. Under no circumstances will Company be liable
            in any way for any User Content made available through this Digital
            Service by you or any third party.
          </p>
          <p>
            Since Company does not control the User Content posted on the
            Digital Service, it does not guarantee the truthfulness, integrity,
            suitability, or quality of that User Content, and it does not
            endorse such User Content. You also agree and understand that by
            accessing the Digital Service, you may encounter content that you
            may consider to be objectionable. Company has no responsibility for
            any User Content, including without limitation any errors or
            omissions therein. Company is not liable for any loss or damage of
            any kind incurred as a result of any User Content on the Digital
            Service.
          </p>
          <p>
            User Content is owned by the author thereof, and Company does not
            claim ownership of original works created and posted by individual
            visitors to this Digital Service. However, by uploading, posting,
            transmitting or otherwise making any User Content available on or
            through the Digital Service, you are granting Company, and its
            parent, subsidiaries, affiliates, and other related entities an
            irrevocable, nonexclusive, perpetual, royalty-free, transferrable,
            sublicensable, worldwide license to copy, reproduce, modify,
            publish, display, distribute publicly, perform, exploit, and prepare
            derivative works of such User Content (including your name, image,
            likeness, or information you have made publicly available in
            connection therewith) in any manner, media or format now existing or
            hereafter devised, without any obligation of notice, attribution or
            compensation to you.
          </p>
          <p>
            Company reserves the right (but has no obligation) in its sole
            discretion to pre-screen, edit, refuse, move or remove any User
            Content that is posted on the Digital Service. You agree that the
            exercise by Company of such discretion shall not convert or
            transform User Content to content owned or provided by Company, and
            the user who made such User Content available on the Digital Service
            will retain ownership thereof as described below.{" "}
          </p>
          <h3>ARTIST SUBMITTED CONTENT</h3>
          <p>
            Artist is responsible for providing and uploading content to the
            Digital Platform. By doing so, Artist hereby assigns, grants, or
            otherwise convey to Company all rights, title and interest in any
            Contribution submitted by Artist to the Company via the Digital
            Platform. Artist’s submissions shall be under the terms and
            conditions of this Agreement, without any additional consideration,
            terms or conditions. Notwithstanding the above, nothing herein shall
            supersede or modify the terms of any separate agreement you may have
            executed with Company regarding such Contributions.
          </p>
          <p>
            Artist warrants that you have the authority to submit all
            Contributions submitted by you to the Digital Platform and that your
            Contribution does not infringe or violate the rights of any third
            party. You hereby release, indemnify and hold harmless Company,
            including its officials, employees, representatives and agents,
            successors and assigns, and its licensees from any claims that may
            arise regarding the use of your Contribution(s).
          </p>
          <h3>COMPANY’S INTELLECTUAL PROPERTY</h3>
          <p>
            This Agreement does not grant permission for Artist to use the trade
            names, trademarks, service marks, or product names of the Company,
            except as required for reasonable and customary use in describing
            the origin of the Contribution and reproducing the attribution or
            copyright notice required under this Agreement. You shall not use
            the Company’s trademarks, any element thereof, or any confusingly
            similar expression without Company’s express written permission
            except as required under the attribution or copyright notice
            requirements of this Agreement.
          </p>
          <h3>PROVIDING FEEDBACK TO COMPANY</h3>
          <p>
            We welcome your comments and feedback about our Digital Service. All
            information and materials submitted to Company through the Digital
            Service or otherwise, such as any comments, feedback, ideas,
            questions, designs, data or the like regarding or relating to the
            Digital Service or the business of Company (collectively,
            “Feedback“), will be considered NON-CONFIDENTIAL and NON-PROPRIETARY
            with regard to you, but Company reserves the right to treat any such
            Feedback as the confidential information of Company.
          </p>
          <p>
            By submitting Feedback to Company, you assign to Company, free of
            charge, all worldwide rights, title and interest in all copyrights
            and other intellectual property rights in such Feedback. Company
            will be entitled to use any Feedback you submit, and any ideas,
            concepts, know-how or techniques contained in any such Feedback, for
            any purpose whatsoever, including but not limited to developing,
            manufacturing and marketing products and services using such
            Feedback without restriction and without compensating you in any
            way. You are responsible for the information and other content
            contained in any Feedback you submit to us, including, without
            limitation, its truthfulness and accuracy.
          </p>
          <h3>PRIVACY POLICY</h3>
          <p>
            Company’s Privacy Policy forms part of this Agreement and is
            incorporated herein by reference. Company’s Privacy Policy is
            located at:
            <Link className="static-link" to="/privacy-policy">
              {" "}
              Privacy Policy
            </Link>
            .
          </p>
          <p>
            Any questions, complaints or claims regarding the Digital Service
            can be directed to: support@onteder.com
          </p>
          <h3>USER COMMUNICATION</h3>
          <p>
            If you fail to make payment or materially breach any provision of
            the Agreement, and such breach is not caused by circumstances on the
            part of Company, Company may, taking the interest of the user in
            account, deny further access to the Digital Service.
          </p>
          <p>
            You are aware that Company may send Digital Service-related
            information to you in the form of e-mails and, where you have made
            your mobile number available and provided consent, by text messages,
            as detailed further in the Privacy Policy.
          </p>
          <h3>CHANGES TO THESE TERMS</h3>
          <p>
            We reserve the right, at any time, to modify, alter, or update these
            Terms of Use without prior notice. You are encouraged to check this
            page regularly for changes to the Terms of Use. Modifications will
            become effective immediately upon being posted to our Digital
            Service, without further notice to you. Your continued use of any of
            our Digital Service after such modifications are posted constitutes
            your acknowledgement and acceptance of such modifications, and you
            may not amend these Terms of Use.
          </p>
          <h3>ARTIST’S ADVERTISEMENTS & OFFERINGS</h3>
          <p>
            While using the Digital Platform, Artist may choose to offer, and
            charge a fee for merchandise, performances outside of the platform,
            acceptance of support, or any other similar requests. However, in
            accepting such obligations, Artist may act only on your own behalf
            and on your sole responsibility, not on behalf of any other Artist,
            and only if you agree to indemnify, defend, and hold Company
            harmless for any liability incurred by, or claims asserted against,
            such Company by reason of your accepting any such warranty or
            additional liability.
          </p>
          <p>
            If User chooses to accept an offer from Artist for the sale of
            merchandise, support (monetary or otherwise), performances outside
            of the platform, or any other similar requests, User does so at
            their own risk and will hold Company harmless for any liability
            incurred by, or claims asserted against, Artist by accepting their
            offer of goods or services.
          </p>
          <h3>TERMINATION</h3>
          <p>
            The license and rights granted herein will terminate automatically
            upon one of the following:
          </p>
          <ul>
            <li>
              User’s voluntary termination of any and all Digital Service;
            </li>
            <li>
              Company’s choice to no longer provide Digital Service to Users
              with reasonable notice to you; or
            </li>
            <li>Any breach by User of the terms of this Agreement. </li>
          </ul>
          <p>
            The obligations of the parties under this Agreement that by their
            nature would continue beyond expiration, termination or cancellation
            of this Agreement (including, without limitation, the warranties,
            indemnification obligations, confidentiality requirements and
            ownership and property rights) shall survive any such expiration,
            termination or cancellation.
          </p>
          <h3>GOVERNING LAW; ARBITRATION OF DISPUTES</h3>
          <p>
            All disputes arising under this agreement shall be governed by and
            interpreted in accordance with the laws of New York, without regard
            to principles of conflict of laws. The parties to this agreement
            will submit all disputes arising under this agreement to arbitration
            in New York City, New York, before a single arbitrator of the
            American Arbitration Association (“AAA”). The arbitrator shall be
            selected by application of the rules of the AAA, or by mutual
            agreement of the parties, except that such arbitrator shall be an
            attorney admitted to practice law in the State of New York. No party
            to this agreement will challenge the jurisdiction or venue
            provisions as provided in this section. No party to this agreement
            will challenge the jurisdiction or venue provisions as provided in
            this section. Nothing contained herein shall prevent Teder from
            obtaining an injunction to enforce its rights under this Terms of
            Use.{" "}
          </p>
          <p>
            Payment of all filing, administration and arbitrator fees will be
            governed by the AAA Consumer Arbitration Rules. You and Company will
            be individually responsible for all other costs and fees incurred in
            connection with the arbitration, including without limitation, all
            attorneys’ fees, unless recoverable under applicable law. However,
            in the event the arbitrator determines the claim(s) you assert in
            the arbitration to be frivolous, you agree to reimburse us for all
            fees and costs associated with the arbitration, including our
            attorneys’ fees, and fees and costs paid by us on your behalf that
            you otherwise would be obligated to pay under the AAA’s rules.
          </p>
          <h3>MISCELLANEOUS</h3>
          <p>
            This Agreement shall be construed under New York law. If any
            provision of this Agreement is deemed invalid or unenforceable under
            applicable law, it shall not affect the validity or enforceability
            of the remainder of the terms of this Agreement, and without further
            action by the parties to this agreement, such provision shall be
            reformed to the minimum extent necessary to make such provision
            valid and enforceable.
          </p>
          <p>
            No term or provision of this Agreement shall be deemed waived and no
            breach consented to unless such waiver or consent is in writing and
            signed by the party to be charged with such waiver or consent.
          </p>
          <p>
            This Agreement constitutes the entire agreement between the parties.
            There are no understandings, agreements or representations with
            respect to use of the Digital Platform not specified here. User
            shall not be bound by any additional provisions that may appear in
            any communication from Company. This Agreement may not be modified
            without the mutual written agreement of the parties.
          </p>
          <p>
            The section headings are provided merely for convenience and shall
            not be given any legal import. This Agreement will inure to the
            benefit of our successors, assigns, licensees, and sublicensees.
          </p>
        </div>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
};

export default TermsPage;
