import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { TableHead, TableRow, TableCell, Typography } from "@mui/material";

import { camelCaseToStr } from "../../../util/helpers";
import { HEADER_POSITION } from "../../../util/constants";

const TableHeaders = ({ headers, sortable }) => {
  const styles = {
    headerText: {
      fontFamily: "proximaNova",
      fontSize: "12px",
      color: "#1A1A4A",
    },
    headerRow: {
      height: "10px",
      lineHeight: "5px",
    },
  };

  return (
    <TableHead sx={styles.headerRow}>
      <TableRow>
        {headers.map((header, index) => (
          <TableCell
            sx={{ lineHeight: "1px", paddingBottom: 0 }}
            key={index}
            align={HEADER_POSITION[header]}
          >
            <Typography
              style={
                header === "name"
                  ? { marginLeft: sortable ? "60px" : "88px" }
                  : {}
              }
              sx={styles.headerText}
            >
              {camelCaseToStr(header)}
              {sortable && <ArrowDropDownIcon style={{ width: "16px" }} />}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableHeaders;
