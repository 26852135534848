export default function validateStepOne(values) {
  let errors = {};

  if (!values.firstName) {
    errors.firstName = "*Required field.";
  } else {
    errors.firstName = "";
  }

  if (!values.lastName) {
    errors.lastName = "*Required field.";
  } else {
    errors.lastName = "";
  }

  if (!values.address) {
    errors.address = "*Required field.";
  } else {
    errors.address = "";
  }

  if (!values.city) {
    errors.city = "*Required field.";
  } else {
    errors.city = "";
  }

  if (
    (values.state === "Select State" || values.state === "") &&
    values.country === "United States of America"
  ) {
    errors.state = "*Please select state.";
  } else {
    errors.state = "";
  }

  if (!values.zipCode) {
    errors.zipCode = "*Required field.";
  } else if (values.zipCode.length !== 5) {
    errors.zipCode = "*Enter valid zip code.";
  } else {
    errors.zipCode = "";
  }

  if (!values.email) {
    errors.email = "*Required field.";
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = "*Email address is invalid";
  } else {
    errors.email = "";
  }

  // if (!values.phoneNo || values.phoneNo.length === 0) {
  //   errors.phoneNo = "*Required field.";
  // } else if (values.phoneNo && values.phoneNo.replace(/[^0-9\.]+/g, "").length < 10) {
  //   errors.phoneNo = "*Enter a valid phone number";
  // } else {
  //   errors.phoneNo = ""
  // }

  return errors;
}
