import React, { useState } from "react";
import "../Styling/artistsignup.css";
import { UserType } from "../util/constants";
import { useDispatch } from "react-redux";
import { loginUserAction, setRegistrationStep1Action } from "../redux/actions";
import { useSelector } from "react-redux";
import { push } from "connected-react-router";
import { Link } from "react-router-dom";
import { login_1 } from "../Common/data";
import { DateTime } from "luxon";

const Login = (props) => {
  const styles = {
    form: {
      display: "flex",
      flexDirection: "column",
      padding: "36px 94px 36px 94px",
      textAlign: "center",
      "& input": {
        "&::focused": {
          color: "black",
        },
      },
    },
    input: {
      backgroundColor: "#f4f3ff",
      borderRadius: "6px",
      border: "none",
      marginBottom: "18px",
    },
    inputFocused: {
      color: "black",
    },
  };

  const currentState = useSelector((state) => state.TederReducerState);
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [wrongCreds, setWrongCreds] = useState(false);
  const [wrongCredsMsg, setWrongCredsMsg] = useState("");

  const handleEmailLogin = (event) => {
    event.preventDefault();

    const login_data = {
      username: email,
      password: password,
    };
    const loginTime = DateTime.now().ts;

    login_1(login_data)
      .then((res) => {
        // wrong creds
        if (res.code === "ErrorInitiateAuth") {
          setWrongCreds(true);
          setWrongCredsMsg(res.message);
          return;
        }
        // correct creds
        let registrationDetails = {
          ...currentState.userRegistrationStep1,
          userID: res.id_token,
          userType: res.userType,
          userRole: res.userRole,
          tederAccessToken: res.access_token,
          tederRefreshToken: res.refresh_token,
          timeLoggedIn: loginTime,
          isSignupCompleted: res.isSignupCompleted,
        };

        //Save id & refresh token to local storage
        localStorage.setItem("id_token", res.id_token);
        if (res.userType === UserType.Artist) {
          localStorage.setItem("refresh_token_artist", res.refresh_token);
        } else if (res.userType === UserType.BookingAgent) {
          localStorage.setItem("refresh_token_ba", res.refresh_token);
        }

        dispatch(loginUserAction(registrationDetails));
        dispatch(setRegistrationStep1Action(registrationDetails));

        const artistView = "/artist";
        const baView = "/agent-dashboard";
        const artistSignUp = "/signup/1";
        const baSignUp = "/basignup/1";

        // redirect
        if (res.userType === UserType.Artist) {
          return dispatch(
            push(res.isSignupCompleted ? artistView : artistSignUp)
          );
        }

        if (res.userType === UserType.BookingAgent) {
          return dispatch(push(res.isSignupCompleted ? baView : baSignUp));
        }

        if (res.userType === UserType.User) {
          alert("admin login not yet supported, contact admin or support");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const loginOptions = () => (
    <div className="container d-flex justify-content-center login-options">
      <div className="sign-in-container">
        <form className="sign-in-form" onSubmit={handleEmailLogin}>
          <div className="sign-in-text">
            <h3>Sign In</h3>
          </div>
          <p
            className="account-wrong-creds"
            style={
              wrongCreds === false ? { display: "none" } : { display: "block" }
            }
          >
            {wrongCredsMsg}
          </p>
          <label>Email</label>
          <input
            className="email-input"
            name="email"
            onChange={(event) => setEmail(event.target.value)}
          />
          {/* <OutlinedInput label="Email" sx={styles.input} /> */}
          <label>Password</label>
          <input
            type="password"
            name="password"
            onChange={(event) => setPassword(event.target.value)}
          />
          <div className="sign-in-additional">
            <div className="remember-me-btn">
              <input type="checkbox" name="remember" />
              <h3>Remember me</h3>
            </div>
            <div className="forgot-password">
              <h3>
                <Link
                  to="/forgot-password"
                  style={{
                    textDecoration: "none",
                    color: "white",
                    marginLeft: "5px",
                  }}
                >
                  Forgot your password?
                </Link>
              </h3>
            </div>
          </div>
          <button className="email-login-btn" type="submit">
            Log In
          </button>

          <div className="need-account">
            <h3>
              Need an account?
              <Link
                to="/signup/3"
                style={{
                  textDecoration: "none",
                  color: "white",
                  marginLeft: "5px",
                }}
              >
                Sign up here
              </Link>
            </h3>
          </div>
        </form>
        {/* <Box
          component="form"
          sx={styles.form}
          noValidate
          autoComplete="off"
          onSubmit={handleEmailLogin}
        >
          <div className="sign-in-text">
            <h3>Sign In</h3>
          </div>
          <p
            className="account-wrong-creds"
            style={
              wrongCreds === false ? { display: "none" } : { display: "block" }
            }
          >
            {wrongCredsMsg}
          </p>
          {/* <input
            className="email-input"
            placeholder="email"
            name="email"
            onChange={(event) => setEmail(event.target.value)}
          />

          <input
            type="password"
            placeholder="password"
            name="password"
            onChange={(event) => setPassword(event.target.value)}
          /> */
        /* <TextField
            label="Email"
            type="email"
            size="small"
            name="email"
            variant="outlined"
            // color="secondary"
            sx={styles.input}
            onChange={(event) => setEmail(event.target.value)}
          />
          <TextField
            label="Password"
            type="password"
            size="small"
            name="password"
            sx={styles.input}
            onChange={(event) => setPassword(event.target.value)}
          />
          <div className="sign-in-additional">
            <div className="remember-me-btn">
              <input type="checkbox" name="remember" />
              <h3>Remember me</h3>
            </div>
            <div className="forgot-password">
              <h3>
                <Link
                  to="/forgot-password"
                  style={{
                    textDecoration: "none",
                    color: "white",
                    marginLeft: "5px",
                  }}
                >
                  Forgot your password?
                </Link>
              </h3>
            </div>
          </div>
          <button className="email-login-btn" type="submit">
            Log In
          </button>

          <div className="need-account">
            <h3>
              Need an account?
              <Link
                to="/signup/3"
                style={{
                  textDecoration: "none",
                  color: "white",
                  marginLeft: "5px",
                }}
              >
                Sign up here
              </Link>
            </h3>
          </div>
        </Box> */}
      </div>
    </div>
  );

  return loginOptions();
};

export default Login;
