import * as React from "react";
import Config from "../../Common/config";
import { useState } from "react";
import axios from "axios";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import "./css/artistBankInfo.css";

const AdminAllShows = ({ allShows, stateUpdateForAdminShows }) => {
  const baseArtistURL = Config().ShowsPhotoUrl;
  const baseUrl = Config().Endpoint;

  const [artistEventName, setArtistShowName] = useState("");
  const [artistId, setArtistId] = useState("");
  const [startDate, setStartDate] = useState("");

  // const [filteValue, setFilterValue] = useState({
  //   artist_id: "",
  //   start_date: "",
  //   show_name: "",
  // });

  // const handleChangeFilter = (event) => {
  //   const value = event.target.value;
  //   setFilterValue({
  //     ...filteValue,
  //     [event.target.name]: value,
  //   });
  // };

  // const EnterHitFilter = (e) => {
  //   if (e.key === "Enter") {
  //     filter();
  //   }
  // };

  const filter = async (id, startDate, showName) => {
    let token = localStorage.getItem("id_token");
    let reqData = {
      artist_id: id,
      start_date: startDate,
      show_name: showName,
    };
    try {
      await axios
        .post(`${baseUrl}/admin_get_shows`, reqData, {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          stateUpdateForAdminShows(res.data.Show_List);
        });
    } catch (error) {
      console.error("admin_get_shows error", error);
    }
  };

  return (
    <div className="container-fluid">
      <div className="row adminRow">
        <div className="filter-block">
          <div>
            <label>Start Date</label>
            <input
              type="date"
              name="start_date"
              onChange={(e) => {
                setStartDate(e.target.value);
                filter(artistId, e.target.value, artistEventName);
              }}
              className="form-control"
            />
          </div>
          <div>
            <label>Event Name </label>
            <input
              type="text"
              name="artist_event_name"
              onChange={(e) => {
                setArtistShowName(e.target.value);
                filter(artistId, startDate, e.target.value);
              }}
              className="form-control"
            />
          </div>
          <div>
            <label>Artist id</label>
            <input
              type="text"
              name="artist_id"
              onChange={(e) => {
                setArtistId(e.target.value);
                filter(e.target.value, startDate, artistEventName);
              }}
              className="form-control"
            />
          </div>
        </div>
        <h3>Current pending Shows</h3>
        <div className="col-md-12">
          <table className="table tableWhite">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Artist ID</th>
                <th scope="col">Event Name</th>
                <th scope="col">Duration</th>
                <th scope="col">Ticket Price</th>
                <th scope="col">Total Tickets</th>
                <th scope="col">Tickets Sold</th>
                <th scope="col">Start Date</th>
                <th scope="col">Start Time</th>
                <th scope="col">Time Zone</th>
                <th scope="col">Status</th>
                <th scope="col">Event Page Link</th>
                <th scope="col">Vimeo Place Holder</th>
                <th scope="col">Created by</th>
              </tr>
            </thead>
            <tbody>
              {allShows.length > 0 ? (
                allShows.map((item, index) => (
                  <tr key={index}>
                    <td>{item.id}</td>
                    <td>{item.artist_id}</td>
                    <td>{item.event_name}</td>
                    <td>{item.duration}</td>
                    <td>{item.ticket_price}</td>
                    <td>{item.total_tickets}</td>
                    <td>{item.tickets_sold}</td>
                    <td>{item.start_date}</td>
                    <td>{item.start_time}</td>
                    <td>{item.time_zone}</td>
                    <td>{item.status}</td>
                    <td>{item.event_page_link}</td>
                    <td>{item.plceholder_vimeourl}</td>
                    <td>{item.created_by}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="14">
                    <Alert severity="error">
                      <b>{allShows.length === 0 ? "No Data Found" : ""}</b>
                    </Alert>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AdminAllShows;
