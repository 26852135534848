import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { DateTime } from "luxon";
import {
  Card,
  Grid,
  Avatar,
  Radio,
  Container,
  FormLabel,
  InputAdornment,
  IconButton,
  FormHelperText,
  RadioGroup,
  Typography,
  FormControl,
  CardContent,
  OutlinedInput,
  FormControlLabel,
  useTheme,
  Box,
  useMediaQuery,
} from "@mui/material";
import { useSelector } from "react-redux";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { convertDateString } from "../../../util/helpers";
import allBookingAgents from "../../../seedData/JSON/bookingAgents.json";
import {
  get_booking_agent_details,
  booking_agent_update_1,
} from "../../../Common/data";
import { bookingAgentDetailsAction } from "../../../redux/actions";

import stripe from "../assets/stripe.svg";
import paypal from "../assets/paypal.svg";

const SettingsBookingAgent = ({ agentDetails, renderInitials }) => {
  const theme = useTheme();
  const { pinkShadow } = theme.palette.layout.boxShadows;
  const mediaQueryPhone = theme.breakpoints.down("sm");
  const styles = {
    wrapper: {
      margin: 0,
      padding: 0,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      paddingTop: "20vh",
      background: "#f9f9fa",
      minHeight: "100vh",
      [mediaQueryPhone]: {
        paddingTop: "15%",
        overflow: "hidden",
        alignItems: "flex-start",
        width: "100vw",
      },
    },
    titleWrapper: {
      margin: "0",
      padding: 0,
      width: "600px",
    },
    titleWrapperSettings: {
      margin: "0 auto",
      padding: 0,
      width: "645px",
    },
    titleText: {
      margin: "0 auto",
      padding: 0,
      textAlign: "left",
      fontFamily: "titleFont",
      fontSize: "16px",
      color: "#1a1a4a",
    },
    profileWrapper: {
      margin: 0,
      paddingRight: "32px",
      borderRadius: "10px",
      display: "flex",
      justifyContent: "space-between",
      width: "600px",
      boxShadow: pinkShadow,
      [mediaQueryPhone]: {
        height: "50vh",
        justifyContent: "space-evenly",
        flexDirection: "column",
        width: "92vw",
      },
    },
    formWrapper: {
      margin: 0,
      padding: 0,
      display: "flex",
      width: "600px",
      borderRadius: "10px",
      boxShadow: pinkShadow,
      [mediaQueryPhone]: {
        width: "92vw",
        marginBottom: "5%",
      },
    },
    avatarAndNameWrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    avatar: {
      height: "100px",
      width: "100px",
      objectFit: "cover",
    },
    paymentMethodWrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "180px",
    },
    bookingAgentName: {
      fontSize: "16px",
      fontFamily: "titleFont",
      color: "#1A1A4A",
    },
    bookingAgentInfo: {
      fontSize: "14px",
      fontFamily: "proximaNova",
      color: "#1A1A4A",
    },
    depositMethodTitle: {
      fontSize: "14px",
      fontFamily: "titleFont",
      color: "#1A1A4A",
    },
    stripeAccBtn: {
      paddingTop: "15px",
      paddingBottom: "15px",
    },
    form: {
      m: 1,
      width: "100%",
      [mediaQueryPhone]: {
        width: "88vw",
      },
    },
    radioButtonLogoWrapper: {
      display: "flex",
    },
    stripeIcon: {
      height: "18px",
      marginLeft: "40px",
    },
    paypalIcon: {
      height: "25px",
      marginLeft: "40px",
    },
    input: {
      "&focus": {
        background: "black",
      },
    },
    radioFill: {
      color: "#DB3554",
      "&.Mui-checked": {
        color: "#DB3554",
      },
    },
  };

  const {
    firstName,
    lastName,
    email,
    address,
    phone,
    city,
    state,
    zipCode,
    country,
    dateJoined,
  } = agentDetails;

  const currentState = useSelector((state) => state.TederReducerState);
  const dispatch = useDispatch();
  const agent = allBookingAgents[0];
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  //Methods for friendly display of unix timestamp value from dateJoined

  // const timeStamptoSeconds = (milliSeconds) => {
  //   //Converts milliseconds to seconds
  //   return milliSeconds + parseInt("0000");
  // };

  // const getMemberSinceDisplay = (timeStamp) => {
  //   const memberSince = DateTime.fromSeconds(
  //     timeStamptoSeconds(timeStamp)
  //   ).toFormat("LLL yyyy");
  //   return `since ${memberSince}`;
  // };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const renderDate = (date) => {
    return new Date(date);
  };

  const [agenDetail, setAgentDetail] = useState("");

  useEffect(() => {
    get_booking_agent_details()
      .then((res) => {
        const details = res.booking_agent_settings[0];
        //For token refresh
        localStorage.setItem("AuthProviderId_ba", details.AuthProviderId);

        const formattedAgentDetails = {
          ...currentState.bookingAgentDetails,
          firstName: details.FirstName,
          lastName: details.LastName,
          stripeacct: details.StripeAcct,
        };
        setAgentDetail(details);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleBlur = (name, value) => {
    // calling the API - check validation first before calling the API
    // if(currentState.updateArtistErrors.isValid) {
    // console.log("check karo dddf", agenDetail);
    if (
      agenDetail.bankName !== "" &&
      agenDetail.routingNo !== "" &&
      agenDetail.accountNo !== ""
    ) {
      let updatedInfo = {
        update_field: name,
        update_value: value,
        teder_token: currentState.userContext.userID,
      };

      console.log("inside handleChange", updatedInfo);

      booking_agent_update_1(updatedInfo)
        .then((data) => {
          if (data.code === "Success") {
            // alert("Field Updated")
            name === "stripeCTA" && window.open(data.message, "_blank");
          } else {
            // alert("Error happened")
            console.log("Something went wrong upon update...");
          }
          return true;
        })
        .catch((error) => {
          //setIsOpen(false);
          console.log("Error caught in update api");
          console.error(error);
          return false;
        });
    }
  };

  return (
    <Grid container spacing={2} sx={styles.wrapper}>
      <Grid item xs={12}>
        <Container sx={styles.titleWrapperSettings}>
          <Typography sx={styles.titleText}>SETTINGS</Typography>
        </Container>
      </Grid>
      <Grid item xs={12}>
        <Card elevation={2} sx={styles.profileWrapper}>
          <CardContent style={styles.avatarAndNameWrapper}>
            <Avatar style={styles.avatar}>
              {renderInitials(firstName, lastName)}
            </Avatar>
            <Container>
              <Typography
                sx={styles.bookingAgentName}
              >{`${firstName} ${lastName}`}</Typography>
              <Typography sx={styles.bookingAgentInfo}>
                Booking Agent
              </Typography>
              <Typography
                sx={styles.bookingAgentInfo}
              >{`since ${convertDateString(dateJoined)}`}</Typography>
            </Container>
          </CardContent>
          {/* <CardContent sx={styles.paymentMethodWrapper}>
            <FormControl>
              <FormLabel sx={styles.depositMethodTitle}>BANK ACCOUNT</FormLabel>
              <RadioGroup defaultValue={agent.deposit_method}>
                <FormControlLabel
                  value="stripe"
                  control={<Radio checked sx={styles.radioFill} />}
                  label={
                    <div style={styles.radioButtonLogoWrapper}>
                      <Typography>Stripe</Typography>
                      <Box
                        component="img"
                        src={stripe}
                        alt="stripe"
                        style={styles.stripeIcon}
                      />
                    </div>
                  }
                ></FormControlLabel>
                <FormControlLabel
                  value="paypal"
                  control={<Radio checked={false} sx={styles.radioFill} />}
                  disabled={true}
                  label={
                    <div style={styles.radioButtonLogoWrapper}>
                      <Typography>PayPal</Typography>
                      <Box
                        component="img"
                        src={paypal}
                        alt="paypal"
                        style={styles.paypalIcon}
                      />
                    </div>
                  }
                ></FormControlLabel>
              </RadioGroup>
            </FormControl>
          </CardContent> */}
        </Card>
        <Card className="mt-3" sx={styles.stripeAccBtn}>
          {agenDetail && agenDetail.StripeAcct === null ? (
            <div className="container teder-form-contatiner" id="edit-bank">
              <Typography sx={styles.titleText}>BANK ACCOUNT</Typography>
              <form>
                <div className="d-flex">
                  <button
                    id="stripeCTA"
                    type="button"
                    className="green-teder-btn green-teder-btn-stripe"
                    name="stripeCTA"
                    onClick={() => handleBlur("stripeCTA", "")}
                  >
                    LOGIN TO STRIPE
                  </button>
                </div>
                <div className="text-left">
                  <small className="small-red-text">
                    *In order to get paid, please set up a Stripe account.
                  </small>
                </div>
                {/* <br />

          <small>
            NOTE: Artists will get 70% of their show’s net profits. Teder will
            get 30%. You'll receive payment within 5 business days via direct
            deposit.
          </small>
          <br /> */}
              </form>
            </div>
          ) : (
            <div className="container teder-form-contatiner" id="edit-bank">
              <Typography sx={styles.titleText}>Accounting</Typography>
              <form>
                <div className="d-flex">
                  <button
                    id="stripeCTA"
                    type="button"
                    className="green-teder-btn green-teder-btn-stripe"
                    name="stripeCTA"
                    onClick={() => handleBlur("stripeCTA", "")}
                  >
                    LOGIN TO STRIPE
                  </button>
                </div>
                <div className="text-left">
                  <small className="small-red-text">
                    *In order to get paid, please set up a Stripe account.
                  </small>
                </div>
                {/* <div className="note-text">
                  NOTE: Artists will get 70% of their show’s net profits. Teder
                  will get 30%. You'll receive payment within 5 business days
                  via direct deposit.
                </div> */}
              </form>
            </div>
          )}
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Container style={styles.titleWrapper}>
          <Typography sx={styles.titleText}>MY INFO</Typography>
        </Container>
      </Grid>
      <Grid item sx={12}>
        <Card elevation={2} sx={styles.formWrapper}>
          <Grid container spacing={2} component="form" sx={{ padding: "32px" }}>
            <Grid item xs={12} md={6}>
              <FormControl style={styles.form} variant="outlined">
                <FormHelperText>First Name</FormHelperText>
                <OutlinedInput
                  aria-describedby="outlined-weight-helper-text"
                  id="outlined-first-name"
                  value={firstName}
                  style={styles.input}
                  inputProps={{
                    "aria-label": "weight",
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl style={styles.form} variant="outlined">
                <FormHelperText>Last Name</FormHelperText>
                <OutlinedInput
                  aria-describedby="outlined-weight-helper-text"
                  id="outlined-last-name"
                  value={`${lastName}`}
                  inputProps={{
                    "aria-label": "weight",
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl style={{ m: 1, width: "100%" }} variant="outlined">
                <FormHelperText>Email</FormHelperText>
                <OutlinedInput
                  aria-describedby="outlined-weight-helper-text"
                  id="outlined-email"
                  defaultValue={email}
                  disabled
                  inputProps={{
                    "aria-label": "weight",
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl style={{ m: 1, width: "100%" }} variant="outlined">
                <FormHelperText>Address</FormHelperText>
                <OutlinedInput
                  aria-describedby="outlined-weight-helper-text"
                  id="outlined-address"
                  value={address}
                  inputProps={{
                    "aria-label": "weight",
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl style={{ m: 1, width: "100%" }} variant="outlined">
                <FormHelperText>Phone Number</FormHelperText>
                <OutlinedInput
                  aria-describedby="outlined-weight-helper-text"
                  id="outlined-phone-number"
                  value={phone}
                  inputProps={{
                    "aria-label": "weight",
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl style={{ m: 1, width: "100%" }} variant="outlined">
                <FormHelperText>City</FormHelperText>
                <OutlinedInput
                  aria-describedby="outlined-weight-helper-text"
                  id="outlined-first-name"
                  value={city}
                  inputProps={{
                    "aria-label": "weight",
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl style={{ m: 1, width: "100%" }} variant="outlined">
                <FormHelperText>State</FormHelperText>
                <OutlinedInput
                  aria-describedby="outlined-weight-helper-text"
                  id="outlined-last-name"
                  value={state}
                  inputProps={{
                    "aria-label": "weight",
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl style={{ m: 1, width: "100%" }} variant="outlined">
                <FormHelperText>Zip Code</FormHelperText>
                <OutlinedInput
                  aria-describedby="outlined-weight-helper-text"
                  id="outlined-first-name"
                  value={zipCode}
                  inputProps={{
                    "aria-label": "weight",
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl style={{ m: 1, width: "100%" }} variant="outlined">
                <FormHelperText>Country</FormHelperText>
                <OutlinedInput
                  aria-describedby="outlined-weight-helper-text"
                  id="outlined-last-name"
                  value={country}
                  inputProps={{
                    "aria-label": "weight",
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              {/* <FormControl style={{ m: 1, width: "100%" }} variant="outlined">
                <FormHelperText>Password</FormHelperText>
                <OutlinedInput
                  aria-describedby="outlined-weight-helper-text"
                  id="outlined-phone-number"
                  value={password}
                  inputProps={{
                    "aria-label": "weight",
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl> */}
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default SettingsBookingAgent;
