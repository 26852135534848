import React from "react";
import {
  CardContent,
  Container,
  ListItemAvatar,
  Avatar,
  Typography,
} from "@mui/material";

import Config from "../../../Common/config";

// import { parseURL } from "../../../util/helpers";
// import artistImages from "../../../Visuals/tempImages";

const UpcomingShowListItem = ({ showData }) => {
  const styles = {
    wrapper: {
      flexDirection: "column",
      height: "76px",
      padding: "0",
      borderBottom: "1px solid #dddddd",
    },
    content: {
      display: "flex",
      alignItems: "center",
      padding: "0 5px",
      height: "100%",
    },
    avatar: {
      height: "48px",
      width: "48px",
      objectFit: "cover",
      borderRadius: "100%",
    },
    artistName: {
      fontSize: "14px",
      fontFamily: "titleFont",
      color: "#1A1A4A",
    },
    dateDisplay: {
      fontFamily: "proximaNova2",
      fontSize: "13px",
      color: "#1A1A4A",
    },
  };
  const showURL = Config().ShowsPhotoUrl;
  return (
    <CardContent style={styles.wrapper}>
      <Container style={styles.content}>
        <ListItemAvatar>
          <Avatar
            src={`${showURL}${showData.artistImage}`}
            style={styles.avatar}
            alt={showData.artistName}
          />
        </ListItemAvatar>
        <Container style={{ padding: 0 }}>
          <Typography sx={styles.artistName}>{showData.artistName}</Typography>
          <Typography sx={styles.dateDisplay}>{showData.startDate}</Typography>
        </Container>
      </Container>
    </CardContent>
  );
};

export default UpcomingShowListItem;
