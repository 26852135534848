import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

import { Box } from "@mui/system";
import UploadPhoto from "../../HelperFunctions/UploadPhoto";
import GenreOptions from "../../HelperFunctions/GenreOptions";
import moment from "moment";
import Config from "../../Common/config";
import { createShowAction, createShowErrorsAction } from "../../redux/actions";
import ValidateShowForm from "../ShowPages/Schedule/ValidateShowForm";
import {
  ba_schedule_events,
  get_ba_schedule_user_list,
} from "../../Common/data";
import { BA_DASHBOARD_ROUTES } from "../../util/constants";
import Modal from "react-modal";
import "./css/baschedule.css";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { CircularProgress } from "@mui/material";
import TederTimePicker from "./TederTimePicker.js";

const BaSchedForm = ({ history, selectRoute }) => {
  const currentState = useSelector((state) => state.TederReducerState);
  const dispatch = useDispatch(); //to update our redux state

  const inputClassName = "form-control";
  const date_now = moment().add(1, "d").format("YYYY-MM-DD");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isScheduling, setIsScheduling] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [baartistList, setBaArtistList] = useState([]);
  const baseShowsURL = Config().ArtistPhotoUrl;

  useEffect(() => {
    const controller = new AbortController();
    setIsLoading(true);
    get_ba_schedule_user_list().then((res) => {
      const list = JSON.parse(res.ba_assigned_artists);
      const formattedList = list.map((option, index) => ({
        label: option.name,
        id: option.id,
        photo: option.photo,
        key: index + 1,
      }));
      setIsLoading(false);
      setBaArtistList(formattedList);
    });
    return () => {
      dispatch({ type: "CLEAR_CREATE_SHOW_STATE" });
      dispatch({ type: "CLEAR_SHOW_ERRORS" });

      controller.abort();
    };
  }, []);

  const styles = {
    wrapper: {
      margin: 0,
      padding: 0,
      display: "flex",
      flexDirection: "column",
      width: "100%",
      paddingTop: "12vh",
      background: "#f9f9fa",
      minHeight: "100vh",
    },
  };

  const [formValue, setFormValue] = useState({
    Artist_ID: "",
    showName: "",
    genres: "",
    showDate: "",
    showTime: "",
    showTimezone: "",
    showTicketPrice: "",
    showTicketMax: "",
    photoPath: "",
    showDuration: "",
    showDescription: "",
  });
  const [error, setError] = useState({
    Artist_ID: "This field is required",
    EventName: "This field is required",
    genres: "This field is required",
    StartDate: "This field is required",
    StartTime: "This field is required",
    TimeZone: "This field is required",
    TicketPrice: "This field is required",
    TotalTickets: "This field is required",
    PhotoPath: "Must include photo",
    showDuration: "This field is required",
    Description: "This field is required",
  });

  const handleClick = (event) => {
    const { myValue } = event.currentTarget.dataset;
  };

  const handleFormReset = () => {
    setFormValue({
      Artist_ID: "",
      showName: "",
      genres: "",
      showDate: "",
      showTime: "",
      showTimezone: "",
      showTicketPrice: "",
      showTicketMax: "",
      photoPath: "",
      showDuration: "",
      showDescription: "",
      //myValue: "",
    });
    //dispatch(push("/ba-dashboard"));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const showDetails = {
      ...currentState.createShowState,
      [name]: value,
    };

    dispatch(createShowAction(showDetails));

    //Update the error state so that the user get real-time validation feedback
    dispatch(createShowErrorsAction(ValidateShowForm(showDetails, true)));
  };

  const {
    Artist_ID,
    showName,
    genres,
    showDate,
    showTime,
    showTimeZone,
    showTicketPrice,
    showTicketMax,
    photoPath,
    showDuration,
    showDescription,
  } = currentState.createShowState;

  const handleScheduleEvent = () => {
    if (!currentState.createShowStateErrors.isValid)
      return alert("Enter Show Info");
    let reqdata = {
      Artist_ID: Artist_ID,
      EventName: showName,
      GenreList: genres,
      PhotoPath: photoPath,
      Description: showDescription,
      StartDate: showDate,
      StartTime: showTime + ":00",
      TimeZone: showTimeZone,
      Duration: showDuration.toString(),
      TicketPrice: showTicketPrice,
      TotalTickets: showTicketMax,
    };
    setIsScheduling(true);
    ba_schedule_events(reqdata)
      .then((res) => {
        setIsScheduling(false);
        setIsOpen(true);
      })
      .catch((err) => {
        setIsScheduling(false);
        console.error(err);
      });
  };

  const closeAndRedirect = () => {
    setIsOpen(false);
    handleFormReset();
    selectRoute(BA_DASHBOARD_ROUTES.Home);
  };

  const scheduleForm = () => (
    <Box component="div" sx={styles.wrapper}>
      <div className="ba-schedule-form-content">
        <h2>Schedule Event</h2>
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <form>
                <div className="form-group">
                  <label className="teder-form-label">Artist</label>
                  <Select
                    className={inputClassName}
                    placeholder="Artist"
                    name="Artist_ID"
                    value={Artist_ID}
                    onChange={handleChange}
                  >
                    {isLoading ? (
                      <CircularProgress />
                    ) : (
                      baartistList.map((caldata, index) => (
                        <MenuItem
                          key={index}
                          value={caldata.id}
                          data-my-value={caldata.label}
                          onClick={handleClick}
                        >
                          <div className="img-artist-block">
                            <div className="img-block">
                              <img src={baseShowsURL + caldata.photo} alt="" />
                              {/* <img src={caldata.photo} alt={caldata.photo} /> */}
                            </div>
                            <div className="artist-name">{caldata.label}</div>
                          </div>
                        </MenuItem>
                      ))
                    )}
                  </Select>

                  <small className="error-info">
                    {currentState.createShowStateErrors.Artist_ID}
                  </small>
                </div>
                <div className="form-group">
                  <label className="teder-form-label">Show Name</label>
                  <input
                    type="text"
                    className={inputClassName}
                    placeholder="Show Name"
                    name="showName"
                    value={showName}
                    onChange={handleChange}
                  />

                  <small className="error-info">
                    {currentState.createShowStateErrors.showName}
                  </small>
                </div>
                <div className="form-group">
                  <label className="teder-form-label">Music Genres</label>
                  <div className="form-groupdddddd">
                    <GenreOptions genres={genres} handleChange={handleChange} />
                    <small className="error-text-red">
                      {currentState.createShowStateErrors.genres}
                    </small>
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md">
                    <label className="teder-form-label">Date</label>
                    <input
                      type="date"
                      min={date_now}
                      className={inputClassName}
                      name="showDate"
                      value={showDate}
                      onChange={handleChange}
                      placeholder="mm/dd/yyyy"
                    />
                    {error.StartDate && (
                      <small className="error-info">
                        {currentState.createShowStateErrors.showDate}
                      </small>
                    )}
                  </div>
                  <div className="form-group col-md">
                    <label className="teder-form-label">Time</label>
                    {/* <input
                      type="time"
                      className={inputClassName}
                      name="showTime"
                      value={showTime}
                      onChange={handleChange}
                      placeholder="00:00 AM"
                      step="3600"
                    /> */}
                    <br />
                    <TederTimePicker />
                    {error.StartTime && (
                      <small className="error-info">
                        {currentState.createShowStateErrors.showTime}
                      </small>
                    )}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md">
                    <label className="teder-form-label">Time Zone</label>
                    <select
                      id="selectTimezone"
                      className="form-control artist-textbox-style teder-select"
                      name="showTimeZone"
                      value={showTimeZone}
                      onChange={handleChange}
                    >
                      <option value="">Select</option>
                      <option value="-12:00">(GMT -12:00) Baker Island</option>
                      <option value="-11:00">(GMT -11:00) Apia, Samoa</option>
                      <option value="-10:00">
                        (GMT -10:00) Honolulu, Hawaii
                      </option>
                      <option value="-09:50">
                        (GMT -9:30) Marquesas Islands
                      </option>
                      <option value="-09:00">(GMT -9:00) Juneau, Alaska</option>
                      <option value="-08:00">
                        (GMT -8:00) Pacific Time (US &amp; Canada), Los Angeles,
                        California{" "}
                      </option>
                      <option value="-07:00">
                        (GMT -7:00) Mountain Time (US &amp; Canada), Denver,
                        Colorado
                      </option>
                      <option value="-06:00">
                        (GMT -6:00) Central Time (US &amp; Canada), Chicago,
                        Illinois
                      </option>
                      <option value="-05:00">
                        (GMT -5:00) Eastern Time (US &amp; Canada), New York,
                        New York
                      </option>
                      <option value="-04:00">
                        (GMT -4:00) Caracas, Venezuela
                      </option>
                      <option value="-03:50">
                        (GMT -3:30) St. John's, Newfoundland
                      </option>
                      <option value="-03:00">
                        (GMT -3:00) Sao Paulo, Brazil
                      </option>
                      <option value="-02:00">
                        (GMT -2:00) South Georgia Island
                      </option>
                      <option value="-01:00">
                        (GMT -1:00) Azores, Portugal
                      </option>
                      <option value="+00:00">(GMT) London, England</option>
                      <option value="+01:00">(GMT +1:00) Paris, France</option>
                      <option value="+02:00">
                        (GMT +2:00) Kaliningrad, Russia
                      </option>
                      <option value="+03:00">
                        (GMT +3:00) Tel Aviv-Yafo, Israel
                      </option>
                      <option value="+03:50">(GMT +3:30) Tehran, Iran</option>
                      <option value="+04:00">(GMT +4:00) Muscat, Oman</option>
                      <option value="+04:50">
                        (GMT +4:30) Kabul, Afghanistan
                      </option>
                      <option value="+05:00">
                        (GMT +5:00) Tashkent, Uzbekistan
                      </option>
                      <option value="+05:50">(GMT +5:30) Mumbai, India</option>
                      <option value="+05:75">
                        (GMT +5:45) Kathmandu, Nepal
                      </option>
                      <option value="+06:00">
                        (GMT +6:00) Colombo, Sri Lanka
                      </option>
                      <option value="+06:50">
                        (GMT +6:30) Yangon, Myanmar
                      </option>
                      <option value="+07:00">
                        (GMT +7:00) Jakarta, Indonesia
                      </option>
                      <option value="+08:00">
                        (GMT +8:00) Singapore, Singapore
                      </option>
                      <option value="+08:75">
                        (GMT +8:45) Eucla, Western Australia
                      </option>
                      <option value="+09:00">(GMT +9:00) Tokyo, Japan</option>
                      <option value="+09:50">
                        (GMT +9:30) Darwin, Australia
                      </option>
                      <option value="+10:00">
                        (GMT +10:00) Sydney, Australia
                      </option>
                      <option value="+10:50">
                        (GMT +10:30) Lord Howe Island
                      </option>
                      <option value="+11:00">
                        (GMT +11:00) Solomon Islands
                      </option>
                      <option value="+11:50">
                        (GMT +11:30) Norfolk Island
                      </option>
                      <option value="+12:00">(GMT +12:00) Fiji, Oceania</option>
                      <option value="+12:75">
                        (GMT +12:45) Chatham Islands
                      </option>
                      <option value="+13:00">(GMT +13:00) Apia, Samoa</option>
                      <option value="+14:00">(GMT +14:00) Line Islands</option>
                    </select>
                    {error.TimeZone && (
                      <small className="error-info">
                        {currentState.createShowStateErrors.showTimezone}
                      </small>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-row">
                    <div className="form-group col-md">
                      <label className="teder-form-label">Shows Duration</label>
                      <input
                        type="number"
                        name="showDuration"
                        onChange={handleChange}
                        className={inputClassName}
                        value={showDuration}
                        placeholder="Minutes"
                      />
                      {error.showDuration && (
                        <small className="error-info">
                          {currentState.createShowStateErrors.showDuration}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md">
                      <label className="teder-form-label">Ticket Price</label>
                      <input
                        type="number"
                        name="showTicketPrice"
                        onChange={handleChange}
                        className={inputClassName}
                        value={showTicketPrice}
                        placeholder="USD"
                      />
                      {error.TicketPrice && (
                        <small className="error-info">
                          {currentState.createShowStateErrors.showTicketPrice}
                        </small>
                      )}
                    </div>
                    <div className="form-group col-md mob-mb-0">
                      <label className="teder-form-label">
                        Limit Number of Ticket
                      </label>
                      <input
                        type="number"
                        className={inputClassName}
                        placeholder="Max tickets for this show"
                        name="showTicketMax"
                        value={showTicketMax}
                        onChange={handleChange}
                      />
                      {error.TotalTickets && (
                        <small className="error-info">
                          {currentState.createShowStateErrors.showTicketMax}
                        </small>
                      )}
                    </div>
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-4">
                    <div className="mb-0">
                      <label className="teder-form-label">
                        Upload Cover Photo
                      </label>
                    </div>
                    <div className="upload-photo">
                      <UploadPhoto
                        photo={photoPath}
                        handleChange={handleChange}
                        message={"Upload Photo"}
                      />
                    </div>

                    <small className="error-info">
                      {currentState.createShowStateErrors.photoPath}
                    </small>
                  </div>
                  <div className="form-group col-md">
                    <div className="mb-0">
                      <label className="teder-form-label">Description</label>
                    </div>
                    <div className="upload-photo upload-photo-textarea">
                      <textarea
                        className={inputClassName}
                        id="inputAbout"
                        name="showDescription"
                        placeholder="Tell us more about your show."
                        value={showDescription}
                        onChange={handleChange}
                        maxLength="300"
                      />
                    </div>

                    <small className="error-info">
                      {currentState.createShowStateErrors.showDescription}
                    </small>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="d-flex btn-block">
          {/* <button
            //disabled={showName === ""}
            className="btn btn-secondry"
            onClick={() => handleFormReset()}
          >
            cancel
          </button> */}
          <button
            className="btn btn-primary"
            disabled={!currentState.createShowStateErrors.isValid}
            style={{
              cursor: !currentState.createShowStateErrors.isValid
                ? "not-allowed"
                : "pointer",
            }}
            onClick={handleScheduleEvent}
          >
            Schedule
          </button>
        </div>
      </div>
      <Modal
        isOpen={isScheduling}
        ariaHideApp={false}
        className="Modal"
        overlayClassName="Overlay"
        contentLabel="Loading Modal"
      >
        <div className="teder-modal-container">
          <div className="modal-body">
            <div className="teder-modal-title text-center">Scheduling...</div>
            <div className="teder-modal-body"></div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={modalIsOpen}
        ariaHideApp={false}
        className="Modal"
        overlayClassName="Overlay"
        contentLabel="Schedule Modal"
      >
        <div className="teder-modal-container">
          <div className="modal-body">
            <div className="teder-modal-subtitle text-center">
              Your show has been scheduled!
            </div>
            <br />
            <br />
            <div className="text-center">
              <button
                type="button"
                className="green-teder-btn"
                onClick={closeAndRedirect}
              >
                close
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </Box>
  );

  const backButton = () => history.goBack();

  const redirectUser = () => {
    dispatch(push("/login"));
    return "Please login";
  };

  return currentState.userContext.userLoggedIn
    ? scheduleForm()
    : redirectUser();
};

export default BaSchedForm;
