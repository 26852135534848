import React, { useState, useEffect, createRef } from "react";
import AvatarEditor from "react-avatar-editor";
import Dropzone from "react-dropzone";
import "../Styling/uploadPhoto.css";
// import { useSelector } from "react-redux";
import EditProfileIcon from "../Visuals/SignUpConfirmationAssets/edit-profile.png";
import Config from "../Common/config";
const UploadPhoto = ({ photo, handleChange, message, showType }) => {
  // const currentState = useSelector((state) => state.TederReducerState);
  const [file, setFile] = useState(null);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  //const [editedPhoto, setEditedPhoto] = useState(photo);
  const artistPhotoPath = Config().ArtistPhotoUrl;
  const showPhotoPath = Config().ShowsPhotoUrl;
  const [fileError, setFileError] = useState("");
  const [photoSize, setPhotoSize] = useState(window.innerWidth / 3.2);

  const photoRef = createRef();

  const getPath = () => {
    if (photo && !showType) return `${artistPhotoPath}${photo}`;
    else if (photo && showType) return `${showPhotoPath}${photo}`;
    return null;
  };
  const [editedPhoto, setEditedPhoto] = useState(getPath());

  // Dynamic photoSize
  useEffect(() => {
    window.addEventListener("resize", () => {
      setPhotoSize(window.innerWidth / 3.2);
    });
  });

  // Reset relevant State (except for editedPhoto)
  const resetEditor = () => {
    setFile(null);
    setScale(1);
    setRotate(0);
    setFileError("");
  };

  const validFile = (img) => {
    if (img) {
      const { type } = img;
      return (
        type.endsWith("jpeg") || type.endsWith("jpg") || type.endsWith("png")
      );
    }
  };

  const selectImage = (e) => {
    let selectedFile = e.target.files[0];
    if (validFile(selectedFile)) {
      resetEditor();
      setFile(selectedFile);
    }
    selectedFile = null;
  };

  const cropImage = (event) => {
    event.preventDefault();
    const imgURL = photoRef.current.getImageScaledToCanvas().toDataURL();
    setEditedPhoto(imgURL);
    handleChange({ target: { name: "photoPath", value: imgURL } });

    resetEditor();
  };

  const handleDrop = (dropped) => {
    let selectedFile = dropped[0];
    if (validFile(selectedFile)) {
      resetEditor();
      setFile(dropped[0]);
    } else {
      setFileError("Error: Choose a JPEG or PNG file.");
    }
    selectedFile = null;
  };

  return (
    <Dropzone
      className="img-drop-zone"
      onDrop={handleDrop}
      disableClick={false}
      multiple={false}
      noKeyboard
      style={{ width: "300px", height: "300px" }}
    >
      {({ getRootProps }) => (
        <div {...getRootProps()} className="img-editor-container">
          <div
            style={
              {
                // backgroundImage: "url(" + editedPhoto + ")",
                // backgroundRepeat: "no-repeat",
                // backgroundSize: "cover",
                // backgroundPosition: "center",
                // backgroundBlendMode: "multiply",
              }
            }
            className={editedPhoto ? "img-box-filled" : "img-box"}
          >
            {editedPhoto ? (
              <div className="img-box-wrapper">
                <div className="img-box-wrapper-child">
                  <img src={`${editedPhoto}`} alt="artist" />
                </div>
                <div
                  htmlFor="file-select-id"
                  accept="image/png, image/jpeg, image/jpg"
                  onClick={(e) => {
                    e.preventDefault();
                    resetEditor();
                    setEditedPhoto(null);
                  }}
                  className="img-box-button"
                >
                  <img
                    className="editIcon"
                    src={EditProfileIcon}
                    alt="edit profile icon"
                  />
                </div>
              </div>
            ) : (
              <div className="drag-n-drop-btn-container">
                <label
                  htmlFor="file-select-id"
                  className="edit-icon"
                  accept="image/png, image/jpeg, image/jpg"
                  onChange={(e) => selectImage(e)}
                >
                  <img
                    className="editIcon"
                    src={EditProfileIcon}
                    alt="edit profile icon"
                  />
                </label>
                <label className="upload-btn">
                  <div className={"drag-n-drop-btn custom-btn-text"}>
                    <p>{message}</p>
                  </div>
                  <input
                    id="file-select-id"
                    type="file"
                    className="file-select"
                    accept="image/png, image/jpeg, image/jpg"
                    onChange={(e) => selectImage(e)}
                  />
                  <span>{fileError}</span>
                </label>
                <div className={file ? "overlay" : "overlay-hidden"}>
                  <div className={file ? "img-modal" : "img-modal-hidden"}>
                    <div>
                      <span className="img-modal-title">CROP PHOTO</span>
                    </div>
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        resetEditor();
                      }}
                      className="close-btn"
                    >
                      ✕
                    </div>
                    <AvatarEditor
                      style={{ background: "#000" }}
                      image={file}
                      scale={scale}
                      width={photoSize}
                      height={photoSize}
                      color={[255, 255, 255]}
                      border={1}
                      rotate={rotate}
                      ref={photoRef}
                    />
                    <div className="img-ctrl-section">
                      <label className="slider-title">SCALE</label>
                      <input
                        type="range"
                        className="slider"
                        min={0.7}
                        max={10}
                        step={0.01}
                        value={scale}
                        onChange={(e) => setScale(parseFloat(e.target.value))}
                      />
                    </div>
                    <div className="img-ctrl-section">
                      <label className="slider-title">ROTATE</label>
                      <input
                        type="range"
                        className="slider"
                        min={-180}
                        max={180}
                        step={0.1}
                        value={rotate}
                        onChange={(e) => setRotate(parseFloat(e.target.value))}
                      />
                    </div>
                    <div className="img-modal-buttons">
                      <label
                        className="img-modal-button caution-button"
                        onClick={(e) => {
                          e.preventDefault();
                          resetEditor();
                        }}
                      >
                        Cancel
                      </label>
                      <label className="img-modal-button change-img-button">
                        Change Photo
                        <input
                          type="file"
                          className="file-select"
                          accept="image/png, image/jpeg, image/jpg"
                          onChange={(e) => selectImage(e)}
                        />
                      </label>
                      <label
                        className="img-modal-button"
                        onClick={(e) => cropImage(e)}
                      >
                        Save
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </Dropzone>
  );
};

export default UploadPhoto;
