import React, { useState } from "react";
import { Link } from "react-router-dom";
import Config from "../../Common/config";
import { truncateWithEllipses } from "../../util/helpers";
import moment from "moment";
import RenderTimeZone from "../../HelperFunctions/RenderTimeZone";
import { Box, Typography, Fade, useTheme } from "@mui/material";
import { useSelector } from "react-redux";

const ShowCard = ({ showListing }) => {
  const dayjs = require("dayjs");
  let startDate = dayjs(showListing.StartDate);
  const baseShowsURL = Config().ShowsPhotoUrl;
  const currentState = useSelector((state) => state.TederReducerState);

  const [showLink, setShowLink] = useState(false);

  //MUI Styles
  const theme = useTheme();
  const mediaQuery = theme.breakpoints.down("sm");
  const { darkRed } = theme.palette.tederColors;

  const styles = {
    wrapper: {
      width: "300px",
      display: "flex",
      flexDirection: "column",
      padding: "7%",
      background: "white",
      borderRadius: "16px",
      position: "relative",
      [mediaQuery]: {
        width: "270px",
      },
    },
    infoBox: {
      borderRadius: "15px",
      marginTop: "6%",
      padding: "5px 45px",
    },
    infoText: {
      fontFamily: "titleFont",
      color: "black",
      whiteSpace: "nowrap",
    },
    infoLink: {
      position: "absolute",
      top: "30%",
      left: "20%",
      border: "solid 2px white",
      width: "60%",
      color: "white",
      borderRadius: "15px",
    },
    image: {
      boxShadow: "5px 4px 4px rgba(0, 0, 0, 0.25)",
      borderRadius: "15px",
      maxWidth: "300px",
      margin: "5%",
    },
  };

  return (
    <Box
      component="div"
      style={styles.wrapper}
      onMouseEnter={() => setShowLink(true)}
      onMouseLeave={() => setShowLink(false)}
    >
      <Box
        component="img"
        src={`${baseShowsURL}${showListing.PhotoPath}`}
        alt="placeholder"
        loading="lazy"
        style={{ ...styles.image, filter: showLink ? "invert(65%)" : "none" }}
      />
      <Fade in={showLink} timeout={1000}>
        <Link
          to={
            currentState.userContext.userLoggedIn
              ? `/shows/${showListing.ID}`
              : `/shows/${showListing.ID}/fan`
          }
        >
          <Box component="div" style={styles.infoLink}>
            SHOW INFO
          </Box>
        </Link>
      </Fade>
      <Box component="div" sx={styles.infoBox}>
        <Typography variant="h5" style={{ ...styles.infoText, color: darkRed }}>
          {truncateWithEllipses(showListing.EventName, 12)}
        </Typography>
        <Typography variant="p" style={styles.infoText}>
          {startDate.format("MMM D YYYY")}
        </Typography>
        <br />
        <Box component="div" style={styles.infoText}>
          {moment(showListing.StartTime, "HH:mm").format("hh:mm a")}
          <br />
          <Typography variant="small" style={styles.infoText}>
            {RenderTimeZone(showListing.TimeZone)}
          </Typography>
        </Box>
        <Box component="small" style={styles.infoText}>
          {showListing.Genres}
        </Box>
      </Box>
    </Box>
  );
};

export default ShowCard;
