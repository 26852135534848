import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const FAQPage = () => {
  const currentState = useSelector((state) => {
    return state.TederReducerState;
  });

  const artistFAQ = () => (
    <div>
      <h2>Artist support</h2>
      <p>
        Below you’ll find some of our FAQs on how to set up the best live stream
        of your life. Have another question not answered below? Contact us at{" "}
        <a className="static-link" href="mailto:support@onteder.com">
          support@onteder.com
        </a>
        .
      </p>
      <br />

      <h5>What necessary equipment do I need to host a live stream?</h5>
      <p>
        See a step-by-step guide in the following link:{" "}
        <Link to="/obs/instructions" className="static-link">
          OBS instructions
        </Link>
      </p>
      <br />

      <h5>How do I set up my stream?</h5>
      <p>
        All you need is your laptop or PC, a webcam + microphone, music
        production equipment (if needed), and a stable internet connection.
      </p>
      <br />

      <h5>What cameras are best for capturing my live stream?</h5>
      <p>
        The Logitech C920 is considered to be one of the best - all around
        cameras for streaming. Any camera similar to this should work.
      </p>
      <br />

      <h5>How do I let my audience know I’ve cancelled or postponed a show?</h5>
      <p>
        Cancel the show. We will email anyone who purchased a ticket and refund
        them.
      </p>
      <br />

      <h5>How can I get featured on Teder?</h5>
      <p>
        Great question. Contact us with your show details at{" "}
        <a className="static-link" href="mailto:support@onteder.com">
          support@onteder.com
        </a>
        .
      </p>
      <br />
      <br />
      <br />
    </div>
  );

  return (
    <div className="container static-page">
      {currentState.userContext.userLoggedIn && artistFAQ()}
      <h2>FAQs</h2>
      <br />

      <h5>What is Teder?</h5>
      <p>
        Teder is a live streaming platform that allows artists to get paid for
        doing what they love.
      </p>
      <br />

      <h5>
        How do my Teder ticket purchases directly support my favorite artists?
      </h5>
      <p>
        This question gets at our raison d’etre—we created Teder to help artists
        easily schedule shows, perform for fans and get paid immediately after
        each performance. After operational expenses from our partners, our
        share is 30% of net ticket purchases per show. Artists receive 70% of
        net ticket purchases per show.
      </p>
      <br />

      <h5>
        How do my Teder merch purchases directly support my favorite artists?
      </h5>
      <p>
        Artists are completely in control of their merch sales and therefore
        receive all proceeds.
      </p>
      <br />

      <h5>How can I help artists make more money?</h5>
      <p>
        Simple! Share their Teder shows and merch on all social channels. Invite
        your friends to attend. If you have more than one person watching,
        purchase tickets based on the number of people watching in your
        household. Instead of shopping at big online retailers, choose to
        purchase albums and other merch directly from the artists themselves or
        here on Teder.{" "}
      </p>
      <br />

      {/* <h5>How do I create an account?</h5>
            <p>On desktop and mobile, sign-up is the same. Click Fan Sign-up in the upper right hand drop down and you’ll be directed through our sign-up flow. The only thing you’ll need on-hand is a credit or debit card for purchasing tickets. Need any additional help with signing up? Contact us at <a className="static-link" href="mailto:support@onteder.com">support@onteder.com</a>.</p>
            <br/> */}

      <h5>How is my data used?</h5>
      <p>
        Please review our{" "}
        <a className="static-link" href="/privacy-policy">
          Privacy Policy
        </a>{" "}
        and{" "}
        <a className="static-link" href="/terms">
          Terms and Conditions
        </a>
        .
      </p>
      <br />

      <p>
        <h5> LEARN MORE ABOUT TEDER </h5>
        <a className="static-link" href="/terms">
          Terms and Conditions
        </a>
        <br />
        <a className="static-link" href="/privacy-policy">
          Privacy Policy
        </a>
        <br />
      </p>
      <br />
      <p>
        <h5>CONTACT SUPPORT</h5>
        <a className="static-link" href="mailto:support@onteder.com">
          support@onteder.com{" "}
        </a>
      </p>
      <br />
    </div>
  );
};

export default FAQPage;
