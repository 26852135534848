import React, { useState, useEffect } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  useTheme,
} from "@mui/material";

import { TableHeaders, RowItem } from ".";

import {
  getTotalTicketSales,
  getLastShow,
  getTimeOnTeder,
  getTotalEarnings,
} from "../../../util/helpers";

import { allShows, allArtists, allBookingAgents } from "../../../seedData";

const ArtistTable = ({ headers, rankedByEarnings, artistList }) => {
  const [tableData, setTableData] = useState([]);
  const theme = useTheme();
  const mediaQueryPhone = theme.breakpoints.down("sm");

  const styles = {
    tableContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      background: "transparent",
      position: "relative",
      maxWidth: "830px",
      [mediaQueryPhone]: {
        width: "100vw",
        flexWrap: "wrap",
      },
    },
    allArtistsText: {
      width: "100%",
      textAlign: "left",
      paddingLeft: "80px",
      fontFamily: "titleFont",
      fontSize: "16px",
      color: "#1a1a4a",
    },
  };
  // useEffect(() => {
  //   const agent = allBookingAgents[0];
  //   const artists = allArtists.filter(
  //     (artist) => artist.booking_agent_id === agent.id
  //   );
  //   const artistsShows = artists.flatMap((artist) => artist.shows);
  //   const bookingAgentShows = allShows.filter(
  //     (show) =>
  //       show.booking_agent_id === agent.id && artistsShows.includes(show.id)
  //   );

  //   let dataForTable = artists.map((artist) => {
  //     const showsByThisArtist = bookingAgentShows.filter((show) =>
  //       artist.shows.includes(show.id)
  //     );

  //     const possibleRowData = {
  //       totalShows: showsByThisArtist.length,
  //       timeOnTeder: getTimeOnTeder(artist),
  //       totalTicketSales: getTotalTicketSales(showsByThisArtist),
  //       totalEarnings: getTotalEarnings(showsByThisArtist),
  //       lastShow: getLastShow(showsByThisArtist),
  //     };

  //     const rowData = headers.reduce((row, header) => {
  //       row[header] = possibleRowData[header];
  //       return row;
  //     }, {});

  //     return { ...rowData, ...artist };
  //   });

  //   if (rankedByEarnings) {
  //     dataForTable.sort((a, b) => b.totalEarnings - a.totalEarnings);
  //   }

  //   setTableData(dataForTable);
  // }, [headers, rankedByEarnings]);

  return (
    <TableContainer sx={styles.tableContainer} elevation={0} component={Paper}>
      <Table style={{ width: "830px" }}>
        <TableHeaders headers={headers} sortable={!rankedByEarnings} />
        <TableBody>
          {artistList &&
            artistList.map((data, index) => (
              <RowItem
                rank={rankedByEarnings && index + 1}
                key={data.id}
                data={data}
                headers={headers}
              />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ArtistTable;
