import React, { useState, useEffect } from "react";
import "../Styling/signup-3.css";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { push } from "react-router-redux";
import { sign_up_1 } from "../Common/data";
import { setUserRegistration1 } from "../redux/actions";
import { TextField, Box, useTheme } from "@mui/material";

const SignUp = () => {
  //styles
  const theme = useTheme();
  const mediaQueryPhone = theme.breakpoints.down("sm");

  const styles = {
    form: {
      display: "flex",
      flexDirection: "column",
      padding: "36px 94px 36px 94px",
      textAlign: "center",
      [mediaQueryPhone]: {
        alignItems: "center",
        margin: "0 auto",
      },
      // "& > :not(style)": { m: 1, width: "25ch" },
    },
    input: {
      backgroundColor: "#f4f3ff",
      borderRadius: "6px",
      border: "none",
      marginBottom: "18px",
      // "&:placeholder": {
      //   color: "black",
      // },
      [mediaQueryPhone]: {
        height: "35px",
        position: "relative",
        width: "75vw",
        backgroundColor: "#f4f3ff",
        borderRadius: "6px",
        border: "none",
        marginBottom: "18px",
      },
    },
  };
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [memberType, setMemberType] = useState("");
  const [alreadyRegisteredError, setAlreadyRegisteredError] = useState(false);
  const [alreadyRegisteredErrorMsg, setAlreadyRegisteredErrorMsg] =
    useState("");
  const [artistStyle, setArtistStyle] = useState("artist-selector");
  const [agentStyle, setAgentStyle] = useState("online-booking-agent-selector");
  const [passwordErrorText, setPasswordErrorText] = useState("");
  const [passwordErrorTextColor, setPasswordErrorTextColor] = useState("");

  const [currentPasswordLength, setCurrentPasswordLength] = useState(1);

  const dispatch = useDispatch();

  const capitalLetterRegex = new RegExp("^(?=.*[A-Z])");
  const numberRegex = new RegExp("^(?=.*[0-9])");
  const specialCharRegex = new RegExp("^(?=.*[!@#$%^&*])");
  const sixDigitRegex = new RegExp("^(?=.{6,})");

  const handlePasswordChange = (e) => {
    if (e.target.value.length === 0) {
      setCurrentPasswordLength(0);
      setPasswordErrorTextColor("default-color");
    } else {
      if (!sixDigitRegex.test(e.target.value)) {
        setPasswordErrorText("sign-up-password-wrong");
        setPasswordErrorTextColor("red");
      } else if (!capitalLetterRegex.test(e.target.value)) {
        setPasswordErrorText("sign-up-password-wrong");
        setPasswordErrorTextColor("red");
      } else if (!numberRegex.test(e.target.value)) {
        setPasswordErrorText("sign-up-password-wrong");
        setPasswordErrorTextColor("red");
      } else if (!specialCharRegex.test(e.target.value)) {
        setPasswordErrorText("sign-up-password-wrong");
        setPasswordErrorTextColor("red");
      } else {
        setPassword(e.target.value);
        setPasswordErrorText("sign-up-password-correct");
        setPasswordErrorTextColor("green");
      }
      setCurrentPasswordLength(1);
    }
  };

  const handleArtistMember = (e) => {
    setMemberType("1");
    setArtistStyle("artist-selector2");
    setAgentStyle("online-booking-agent-selector");
  };

  const handleBookingAgentMember = (e) => {
    setMemberType("4");
    setAgentStyle("online-booking-agent-selector2");
    setArtistStyle("artist-selector");
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = {
      email: email,
      password: password,
      usertype: memberType,
    };

    const dispatch_data = {
      username: email,
      password: password,
      usertype: memberType,
    };

    dispatch(setUserRegistration1(dispatch_data));

    sign_up_1(data)
      .then((data) => {
        if (data.code === 201) {
          dispatch(push("/signup/confirmation"));
        }
        if (data.code === "AlreadyRegistered") {
          setAlreadyRegisteredError(true);
          setAlreadyRegisteredErrorMsg(data.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });

    // dispatch(push("/signup/1"));
  };

  return (
    <div className="container d-flex justify-content-center">
      <div className="sign-up-container">
        <div className="member-type-container">
          <h1>WELCOME TO TEDER</h1>
          <p>Which type of account are you signing up for?</p>
          <div className="account-type-container">
            <div onClick={handleBookingAgentMember} className={agentStyle}>
              <p>ONLINE</p>
              <p>BOOKING</p>
              <p>AGENT</p>
            </div>
            <div onClick={handleArtistMember} className={artistStyle}>
              <p>ARTIST</p>
            </div>
          </div>
        </div>
        <div className="half-border"></div>
        <form className="sign-up-form" onSubmit={handleSubmit}>
          <div className="sign-up-text">
            <h3>Sign Up</h3>
          </div>

          <p
            className="account-exists-text"
            style={
              alreadyRegisteredError === false
                ? { display: "none" }
                : { display: "block" }
            }
          >
            {alreadyRegisteredErrorMsg}
          </p>

          <label className="email-text">Email</label>
          <input
            name="email"
            onChange={(event) => setEmail(event.target.value)}
          ></input>

          <label className="password-text">Password</label>
          <input
            className={
              currentPasswordLength === 0
                ? "sign-up-password-default"
                : passwordErrorText
            }
            style={{ margin: 0 }}
            type="password"
            name="password"
            onChange={handlePasswordChange}
          ></input>

          <p
            className="password-rules-text"
            style={
              passwordErrorTextColor === "default-color"
                ? { color: "rgba(244, 243, 255, 0.65)" }
                : { color: passwordErrorTextColor }
            }
          >
            Must have at least 6 characters including 1 uppercase letter and 1
            special character
          </p>

          <button
            disabled={!email || !password || !memberType}
            className="create-account-btn"
            style={
              !email || !password || !memberType
                ? {
                    backgroundColor: "rgba(237, 27, 80, 0.5 )",
                    color: "rgba(255, 255, 255, 0.5",
                  }
                : {
                    backgroundColor: "rgba(237, 27, 80, 1 )",
                    color: "rgba(255, 255, 255, 1)",
                  }
            }
          >
            CREATE ACCOUNT
          </button>

          <div className="sign-in-account">
            <h3>
              Already have an account?
              <Link
                to="/login"
                style={{
                  color: "white",
                  marginLeft: "5px",
                }}
              >
                Sign in here
              </Link>
            </h3>
          </div>
        </form>
        {/* <Box component="form" sx={styles.form} onSubmit={handleSubmit}>
          <div className="sign-up-text">
            <h3>Sign Up</h3>

            <p
              className="account-exists-text"
              style={
                alreadyRegisteredError === false
                  ? { display: "none" }
                  : { display: "block" }
              }
            >
              {alreadyRegisteredErrorMsg}
            </p>
          </div>
          <TextField
            label="Email"
            size="small"
            name="email"
            type="email"
            sx={styles.input}
            onChange={(event) => setEmail(event.target.value)}
          />
          <TextField
            error={currentPasswordLength === 0 ? true : false}
            label="Password"
            size="small"
            name="password"
            type="password"
            sx={styles.input}
            onChange={handlePasswordChange}
          />
          <p
            className="password-rules-text"
            style={
              passwordErrorTextColor === "default-color"
                ? { color: "rgba(244, 243, 255, 0.65)" }
                : { color: passwordErrorTextColor }
            }
          >
            Must have at least 6 characters including 1 uppercase letter and 1
            special character
          </p>

          <button
            disabled={!email || !password || !memberType}
            className="create-account-btn"
            style={
              !email || !password || !memberType
                ? {
                    backgroundColor: "rgba(237, 27, 80, 0.5 )",
                    color: "rgba(255, 255, 255, 0.5",
                  }
                : {
                    backgroundColor: "rgba(237, 27, 80, 1 )",
                    color: "rgba(255, 255, 255, 1)",
                  }
            }
          >
            CREATE ACCOUNT
          </button>

          <div className="sign-in-account">
            <h3>
              Already have an account?
              <Link
                to="/login"
                style={{
                  color: "white",
                  marginLeft: "5px",
                }}
              >
                Sign in here
              </Link>
            </h3>
          </div>
        </Box> */}
      </div>
    </div>
  );
};
export default SignUp;
