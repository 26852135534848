const RenderTimeZone = (timeZone) => {
switch (timeZone) {
    case '-12:00':
        return 'Baker Island'
    
    case '-11:00':
        return 'Apia, Samoa'
    
    case '-10:00':
        return 'Honolulu, Hawaii'
    
    case '-9:30':
        return 'Marquesas Islands'
    
    case '-9:00':
        return 'Juneau, Alaska'
    
    case '-08:00':
        return 'Los Angeles, California'
    
    case '-07:00':
        return 'Denver, Colorado'
    
    case '-06:00':
        return 'Chicago, Illinois'
    
    case '-05:00':
        return 'New York, New York'

    case '-04:00':
        return 'Caracas, Venezuela'
    
    case '-03:50':
        return "St. John's, Newfoundland"
    
    case '-03:00':
        return 'Sao Paulo, Brazil'
    
    case '-02:00':
        return 'South Georgia Island'
    
    case '-01:00':
        return 'Azores, Portugal'
    
    case '+00:00':
        return 'London, England'
    
    case '+01:00':
        return 'Paris, France'
    
    case '+02:00':
        return 'Kaliningrad, Russia'
    
    case '+03:00':
        return 'Tel Aviv-Yafo, Israel'
    
    case '+03:50':
        return 'Tehran, Iran'
    
    case '+04:00':
        return 'Muscat, Oman'
    
    case '+04:50':
        return 'Kabul, Afghanistan'
    
    case '+05:00':
        return 'Tashkent, Uzbekistan'
    
    case '+05:50':
        return 'Mumbai, India'
    
    case '+05:75':
        return 'Kathmandu, Nepal'
    
    case '+06:00':
        return 'Colombo, Sri Lanka'
    
    case '+06:50':
        return 'Yangon, Myanmar'
    
    case '+07:00':
        return 'Jakarta, Indonesia'
    
    case '+08:00':
        return 'Singapore, Singapore'
    
    case '+08:75':
        return 'Eucla, Western Australia'
    
    case '+09:00':
        return 'Tokyo, Japan'
    
    case '+09:50':
        return 'Darwin, Australia'
    
    case '+10:00':
        return 'Sydney, Australia'
    
    case '+10:50':
        return 'Lord Howe Island'
    
    case '+11:00':
        return 'Solomon Islands'
    
    case '+11:50':
        return 'Norfolk Island'
    
    case '+12:00':
        return 'Fiji, Oceania'
    
    case '+12:75':
        return 'Chatham Islands'
    
    case '+13:00':
        return 'Apia, Samoa'
    
    case '+14:00':
        return 'Line Islands'
    
    default:
        break;
    }
}

export default RenderTimeZone
