import React from "react";
import "./Styling/styles.css";
import "./Styling/fonts.css";
import "./Styling/common.css";
import { Route, Switch } from "react-router-dom";
import HomePage from "./Containers/HomePage";
import FooterContainer from "./Components/Footer/FooterContainer";
import WebFont from "webfontloader";
import ShowPageContainer from "./Containers/ShowPageContainer";
import BillingPage from "./Containers/BillingPage";
import Login from "./Containers/Login";
import AdminPage from "./Containers/AdminPage";
import SchedShowForm from "./Components/ShowPages/Schedule/SchedShowForm";
import ContactUsPage from "./Components/StaticPages/ContactUsPage";
// import JobsPage from './Components/StaticPages/JobsPage';
// import HelpPage from './Components/StaticPages/HelpPage';
import FAQPage from "./Components/StaticPages/FAQPage";
import TermsPage from "./Components/StaticPages/Terms";
import PrivacyPage from "./Components/StaticPages/PrivacyPolicy";
import ViewShowPage from "./Components/ShowPages/Schedule/ViewShowPage";
import StreamShowPage from "./Components/ShowPages/Stream/StreamShowPage";
// import StreamBrowser from './Components/ShowPages/Stream/StreamBrowser';
import StreamOBS from "./Components/ShowPages/Stream/StreamOBS";
// import StepThreeForm from './Components/ArtistSignUpPage/ArtistSignupForms/StepThreeForm';
import EditProfile from "./Components/ArtistPages/EditProfile/EditProfile";
import ViewShowDetails from "./Components/ShowPages/Schedule/ViewShowDetails";
import EditShowDetails from "./Components/ShowPages/Schedule/EditShowDetails";
import MyShows from "./Containers/MyShows";
// import StepOne from "./Components/ArtistPages/Registration/StepOne";
// import StepTwo from "./Components/ArtistPages/Registration/StepTwo";
import AboutUs from "./Components/StaticPages/AboutUs";
import Affiliate from "./Components/StaticPages/Affiliate";
import StreamInstructions from "./Containers/StreamInstructions";
import SignUp from "./Containers/SignUp";
import SignUpConfirmation from "./Containers/SignUpConfirmation";
import SignUpFormOne from "./Components/ArtistPages/Registration/SignUpFormOne";
import SignUpFormTwo from "./Components/ArtistPages/Registration/SignUpFormTwo";
import ForgotPasswordPage from "./Containers/ForgotPasswordPage";
import ForgotPasswordConfirmPage from "./Containers/ForgotPasswordConfirmPage";
import CreateNewPasswordPage from "./Containers/CreateNewPasswordPage";
import BADashboard from "./Components/BookingAgentPages/BADashboard";
import BASignUpFormOne from "./Components/ArtistPages/Registration/BASignUpFormOne";
import ArtistProfileDashboard from "./Components/BookingAgentPages/ArtistProfileDashboard";
import ExploreShows from "./Components/ShowPages/Explore/ExploreShows";
import ProtectedRoute from "./ProtectedRoute";
import ConfirmationComplete from "./Containers/ConfirmationComplete";
import { useSelector } from "react-redux";

WebFont.load({
  custom: {
    families: ["ITCAvantGardeStdBold"],
    urls: ["./Styling/fonts.css"],
  },
});

const App = (props) => {
  const currentState = useSelector((state) => state.TederReducerState);
  return (
    <>
      <div className="content">
        <Switch>
          <Route path="/about-us" component={AboutUs} />
          <Route path="/affiliate" component={Affiliate} />
          <Route path="/contact" component={ContactUsPage} />
          {/* <Route path='/jobs' component={JobsPage}/> */}
          {/* <Route path='/help' component={HelpPage}/> */}
          <Route path="/faq" component={FAQPage} />
          <Route path="/terms" component={TermsPage} />
          <Route path="/privacy-policy" component={PrivacyPage} />
          <Route path="/login" component={Login} />
          <ProtectedRoute path="/admin" component={AdminPage} />
          <Route exact path="/shows" component={ShowPageContainer} />
          <ProtectedRoute
            exact
            path="/agent-dashboard"
            component={BADashboard}
          />
          <ProtectedRoute exact path="/my-shows" component={MyShows} />
          <ProtectedRoute path="/shows/create" component={SchedShowForm} />
          <Route exact path="/shows/:id" component={ViewShowDetails} />
          <ProtectedRoute
            exact
            path="/shows/edit/:id"
            component={EditShowDetails}
          />
          <Route exact path="/shows/:id/fan" component={ViewShowDetails} />
          <Route exact path="/shows/view/:id" component={ViewShowPage} />
          <Route exact path="/shows/stream/:id" component={StreamShowPage} />
          {/* <Route exact path='/shows/stream/browser/:id' component={StreamBrowser}/> */}
          <Route exact path="/shows/stream/obs/:id" component={StreamOBS} />
          <Route
            exact
            path="/obs/instructions"
            component={StreamInstructions}
          />
          <ProtectedRoute exact path="/signup/1" component={SignUpFormOne} />
          <ProtectedRoute exact path="/signup/2" component={SignUpFormTwo} />
          <ProtectedRoute
            exact
            path="/basignup/1"
            component={BASignUpFormOne}
          />
          <ProtectedRoute
            exact
            path="/artist"
            component={ArtistProfileDashboard}
          />

          <ProtectedRoute
            exact
            path="/artist/my-account"
            component={EditProfile}
          />
          <ProtectedRoute
            exact
            path="/artist/billing"
            component={BillingPage}
          />
          <Route exact path="/signup/3" component={SignUp} />
          <Route
            exact
            path="/signup/confirmation"
            component={SignUpConfirmation}
          />
          <Route
            exact
            path="/signup/confirmation-complete"
            component={ConfirmationComplete}
          />
          <Route exact path="/forgot-password" component={ForgotPasswordPage} />
          <Route
            exact
            path="/forgot-password/confirmation"
            component={ForgotPasswordConfirmPage}
          />
          <Route
            exact
            path="/forgot-password/create-new-password"
            component={CreateNewPasswordPage}
          />

          <Route exact path="/explore-shows" component={ExploreShows} />
          <Route path="/" component={HomePage} />
        </Switch>
      </div>
      <div
        id={
          currentState.globalRoute === "/agent-dashboard"
            ? "footer-baDashboard"
            : "footer"
        }
      >
        <FooterContainer />
      </div>
    </>
  );
};

export default App;
