import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { display_show_list } from "../Common/data";
import { updatHomepageShowsAction } from "../redux/actions";
import ShowGrid from "../Components/HomePage/ShowGrid";
const ShowPageContainer = () => {
  const [loadShows, setLoadShows] = useState(false);

  const dispatch = useDispatch();
  var currentState = useSelector((state) => {
    return state.TederReducerState;
  });

  if (!loadShows) {
    display_show_list
      .then((result) => {
        dispatch(updatHomepageShowsAction(result.Recent_Show_list));
      })
      .catch((error) => {
        console.log("Error caught in update api");
        console.log(error);
        return false;
      });

    setLoadShows(true);
  }

  return (
    <div>
      <h2>UPCOMING SHOWS</h2>
      <br />
      <ShowGrid showsList={currentState.homepageShows} />
    </div>
  );
};

export default ShowPageContainer;
