import React from "react";
import "./topnavbar.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { logoutUserAction } from "../../redux/actions";
import { AuthRoles } from "../../Common/global";
import { artist_logout } from "../../Common/data";
import { setGlobalRouteAction } from "../../redux/actions";
import { UserType, BA_DASHBOARD_ROUTES } from "../../util/constants";

const SideBar = ({
  toggleSideBar,
  setShowSideBar,
  sideBarStyle,
  getCorrectRoute,
}) => {
  const dispatch = useDispatch(); //to update our redux state
  const currentState = useSelector((state) => state.TederReducerState);

  const HandleLogout = () => {
    artist_logout(currentState.userContext.userID);

    var tederLogoutDetails = {
      userID: "",
      userFirstName: "",
      userLastName: "",
      userEmail: "",
      pendingShows: 0,
      userLoggedIn: false,
      userType: AuthRoles.ANONYMOUS,
    };
    dispatch({ type: "CLEAR_DATA" });
    dispatch(logoutUserAction(tederLogoutDetails));
  };

  const handleScheduleRoute = () => {
    /* Workaround to direct user to schedule show component on VBA dashboard.
    We need to figure out why the nested routes don't work */
    if (currentState.userContext.userType === UserType.BookingAgent) {
      dispatch(setGlobalRouteAction(BA_DASHBOARD_ROUTES.BA_Schedule));
      setShowSideBar(false);
    }
    setShowSideBar(false);
  };

  const viewerSideBar = () => (
    <>
      <div className="p-2">
        <Link
          to="/"
          className="sidebar-link"
          onClick={() => setShowSideBar(false)}
        >
          Home
        </Link>
      </div>
      <div className="p-2">
        <Link
          to="/explore-shows"
          className="sidebar-link"
          onClick={() => setShowSideBar(false)}
        >
          Explore Shows
        </Link>
      </div>
      <div className="p-2">
        {/* <Link to="/artists" className="sidebar-link" onClick={() => setShowSideBar(false)}>Artist list</Link> */}
      </div>
      <br />
      <br />
      <div className="p-2">
        <Link
          to="/login"
          className="sidebar-link login-text"
          onClick={() => setShowSideBar(false)}
        >
          Login
        </Link>
      </div>
    </>
  );

  const artistSideBar = () => (
    <>
      <div className="p-2">
        <Link
          to={getCorrectRoute()}
          className="sidebar-link"
          onClick={() => setShowSideBar(false)}
        >
          Profile
        </Link>
      </div>
      <div className="p-2">
        <Link
          to="/my-shows"
          className="sidebar-link"
          onClick={() => setShowSideBar(false)}
        >
          My Shows
        </Link>
      </div>
      <div className="p-2">
        {/* {!currentState.artistContext.bankName ? 
                <Link to="/artist/my-account/" className="sidebar-link" onClick={() => setShowSideBar(false)}>Set up bank acct</Link>
                : */}
        <Link
          to={
            currentState.userContext.userType === UserType.Artist
              ? "/shows/create"
              : currentState.userContext.userType === UserType.BookingAgent
              ? "/agent-dashboard"
              : null
          }
          className="sidebar-link"
          onClick={handleScheduleRoute}
        >
          Schedule a Show
        </Link>
        {/* } */}
      </div>
      <div className="p-2">
        <Link
          to="/artist/billing"
          className="sidebar-link"
          onClick={() => setShowSideBar(false)}
        >
          View ticket sales
        </Link>
      </div>
      {/* <div className="p-2">
        <Link
          to="/artist/my-account"
          className="sidebar-link"
          onClick={() => setShowSideBar(false)}
        >
          My Account
        </Link>
      </div> */}
      {currentState.userContext.userRole === AuthRoles.ADMIN && (
        <div className="p-2">
          <Link
            to="/admin"
            className="sidebar-link"
            onClick={() => setShowSideBar(false)}
          >
            Admin
          </Link>
        </div>
      )}
      <br />
      <br />
      <div className="p-2">
        <Link
          to="/"
          className="sidebar-link login-text"
          onClick={() => {
            HandleLogout();
            setShowSideBar(false);
          }}
        >
          Log Out
        </Link>
      </div>
    </>
  );

  const renderNav = () =>
    !currentState.userContext.userLoggedIn ? viewerSideBar() : artistSideBar();

  return (
    <div className={sideBarStyle}>
      <div className="container">
        <div className="hide-btn">
          <span onClick={() => toggleSideBar()}>✕</span>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center sidebar-content">
          {renderNav()}
        </div>
      </div>
    </div>
  );
};

export default SideBar;
