import React from "react";
import {
  Box,
  Typography,
  Card,
  Divider,
  List,
  Tooltip,
  useTheme,
  Button,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { trimUnderscores } from "../../../util/helpers";

import GoalListItem from "./GoalListItem";
import { CardHeading } from "../SharedComponents";
import { RedTederButton } from "../SharedComponents/Buttons";

const GoalsCard = ({ data }) => {
  const theme = useTheme();
  const { pinkShadow } = theme.palette.layout.boxShadows;
  const { darkRed, darkBlue } = theme.palette.tederColors;
  const styles = {
    wrapper: {
      height: "234px",
      padding: "0 11px",
      marginBottom: "5%",
      boxShadow: pinkShadow,
    },
    infoIcon: {
      width: "20px",
      height: "20px",
    },
    noGoalsText: {
      color: darkRed,
      textTransform: "uppercase",
      fontWeight: "bolder",
      fontFamily: "titleFont, sans-serif",
    },
    noGoalsWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      boxShadow: pinkShadow,
      borderRadius: "15px",
      marginTop: "10%",
      padding: "10%",
      background: "white",
      "&hover": {
        cursor: "pointer",
        background: darkBlue,
        color: "white",
      },
    },
    goalsList: {
      padding: "0",
      display: "flex",
      height: "calc(100% - 44px)",
      flexDirection: "column",
      justifyContent: "space-evenly",
    },
  };
  // if (!data.length) return null;
  return (
    <Card elevation={2} style={styles.wrapper}>
      <CardHeading
        title="GOALS"
        component={
          <Tooltip title="More Info Goes Here" sx={styles.infoIcon}>
            <InfoOutlinedIcon sx={{ color: "#505071" }} />
          </Tooltip>
        }
      />
      <Divider />
      {!data.length ? (
        <Box component="div" style={styles.noGoalsWrapper}>
          <Button style={styles.noGoalsText}>Set Your Goals!</Button>
        </Box>
      ) : (
        <List style={styles.goalsList}>
          {data.slice(0, 2).map((goal, index) => (
            <GoalListItem
              key={index}
              title={trimUnderscores(goal.category)}
              current={goal.current}
              target={goal.target}
            />
          ))}
        </List>
      )}
    </Card>
  );
};

export default GoalsCard;
