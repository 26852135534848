import React from "react";
import { Card, Divider, useTheme } from "@mui/material";
import { CardHeading, IncomeChart } from "../SharedComponents";
import { displayLastFewMonths } from "../../../util/helpers";
import { BA_DASHBOARD_ROUTES } from "../../../util/constants";

const IncomeOverviewCard = ({ data, selectRoute }) => {
  const theme = useTheme();
  const { pinkShadow } = theme.palette.layout.boxShadows;
  const styles = {
    wrapper: {
      padding: "0 11px",
      boxShadow: pinkShadow,
    },
    chartWrapper: {
      border: "1px solid transparent",
      boxShadow: "none",
    },
  };
  if (!data.length) return null;
  return (
    <Card elevation={2} style={styles.wrapper}>
      <CardHeading
        title="INCOME OVERVIEWS"
        subTitle="SEE MORE"
        selectRoute={selectRoute}
        routeSelection={BA_DASHBOARD_ROUTES.Income}
      />
      <Divider />
      <IncomeChart height={295} data={displayLastFewMonths(data)} />
    </Card>
  );
};

export default IncomeOverviewCard;
