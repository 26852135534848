import React, { useState } from "react";
import "./viewShowDetails.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import PurchaseTicket from "../Tickets/PurchaseTicket";
// import sampleArtist from "../../../Visuals/ArtistProfileAssets/sample-artist.jpg";
// import sampleShow6 from '../../../Visuals/ArtistProfileAssets/sample-show6.jpg';
import ReactLogo from "../../../Visuals/logo.svg";
import { display_show_details, cancel_show } from "../../../Common/data";
import { updateShowAction } from "../../../redux/actions";
import Config from "../../../Common/config";
import moment from "moment";
import RenderTimeZone from "../../../HelperFunctions/RenderTimeZone";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Modal from "react-modal";
import youTube from "../../../Visuals/ArtistProfileAssets/youtube.svg";
import soundCloud from "../../../Visuals/ArtistProfileAssets/soundcloud.svg";
import instagram from "../../../Visuals/ArtistProfileAssets/instagram.svg";
import tiktok from "../../../Visuals/ArtistProfileAssets/tiktok.svg";
import website from "../../../Visuals/ArtistProfileAssets/globe.svg";

const ViewShowDetails = ({ match }) => {
  const dispatch = useDispatch();
  const currentState = useSelector((state) => state.TederReducerState);

  const showId = match.params.id;
  const [loadShow, setLoadShow] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const baseShowsURL = Config().ShowsPhotoUrl;
  const baseArtistURL = Config().ArtistPhotoUrl;

  if (!loadShow) {
    display_show_details(showId)
      .then((result) => {
        console.log("INSIDE VIEW SHOW DETAILS", result.Show_Details);

        const show = result.Show_Details[0];

        const showDetails = {
          showID: show.ID,
          artistID: show.ArtistID,
          artistStageName: show.StageName,
          artistPhotoPath: show.ArtistPhotoPath,
          artistSC: show.SoundcloudHandle,
          artistIG: show.InstagramHandle,
          artistYT: show.YoutubeHandle,
          artistTT: show.TikTokHandle,
          artistWebsite: show.Website,
          createdDate: show.CreatedDate,
          showStreamKey: show.EventBroadcastLink,
          showPageLink: show.EventPageLink,
          showName: show.EventName,
          genres: show.Genres,
          genreIDList: show.GenreIDList,
          showDate: show.StartDate,
          showTime: show.StartTime,
          showTimezone: show.TimeZone,
          showTicketPrice: show.TicketPrice,
          showDuration: show.Duration,
          showTicketMax: show.TotalTickets,
          showDescription: show.EventDescription,
          photoPath: show.PhotoPath,
          featured: show.Featured,
          status: show.Status,
          ticketsSold: show.TicketsSold,
        };
        dispatch(updateShowAction(showDetails));
      })
      .catch((error) => {
        console.log("Error caught in update api");
        console.log(error);
        return false;
      });
    setLoadShow(true);
  }

  // const fakeShow = {
  //     StartDate: '12/24/2020',
  //     StartTime: new Date().toLocaleTimeString(),
  //     PhotoPath: sampleShow6,
  //     EventID: 1,
  //     EventName: "The Slow Rush",
  //     ArtistName: "Tame Impala",
  //     EventLocation: "New York City",
  //     ArtistPhoto: sampleArtist,
  //     Genres: "Rock, Indie, Alternative",
  //     TicketsSold: "2,873",
  //     TicketPrice: "10.00"
  // }
  const show = currentState.viewShowDetails;

  const dayjs = require("dayjs");
  let startDate = dayjs(show.showDate);

  const renderArtistOptions = () => (
    <>
      <br />
      <div className="show-details-col">
        <label className="show-details-category">TICKETS SOLD</label>
        <span className="show-details-value">{show.ticketsSold}</span>
      </div>
      <label className="show-details-category">your show options</label>
      <Link to={`/shows/stream/${showId}`}>
        <button className="artist-profile-main-btn">Set Up Stream</button>
      </Link>
      <div className="show-details-artist-buttons-container">
        <Link
          to={`/shows/edit/${showId}`}
          className="show-details-artist-option-button"
        >
          Edit
        </Link>{" "}
        <span
          className="show-details-artist-option-button"
          onClick={() => setIsOpen(true)}
        >
          Cancel
        </span>
      </div>
    </>
  );

  const yesCancel = () => {
    setIsOpen(false);
    handleCancel();
  };

  const handleCancel = () => {
    const cancelled = {
      ShowID: currentState.viewShowDetails.showID,
    };

    cancel_show(cancelled)
      .then((data) => {
        if (data.code === "Success") {
          alert("This show has been cancelled.");
          dispatch(push(`/artist`));
        } else {
          alert(
            "Could not cancel show, please contact our support team. Thank you!"
          );
          console.log("Something went wrong upon update...");
        }
        return true;
      })
      .catch((error) => {
        console.log("Error caught in update api");
        console.log(error);
        return false;
      });
  };

  return (
    <div className="container">
      <div className="show-details-container">
        <a href="#/" style={{ color: "white" }}>
          <div className="show-details-banner">
            <img
              src={baseShowsURL + show.photoPath}
              className="show-details-banner-img"
              alt="artist-banner"
            />
            <h2 className="show-details-title">
              {show.showName}{" "}
              <span className="show-details-live-on">LIVE ON</span>
              <img src={ReactLogo} className="show-details-logo" alt="teder" />
            </h2>
          </div>
        </a>

        <div className="row">
          <div className="col-sm">
            <div className="show-details-flier-container">
              <img
                src={baseShowsURL + show.photoPath}
                alt="Upload Images"
                className="show-details-flier"
              />
              <br />
              <br />
            </div>
          </div>
          <div className="show-details-info-container col-sm">
            <div className="show-details-info-header text-center">
              <img
                src={baseArtistURL + show.artistPhotoPath}
                className="show-details-artist-img"
                alt="artist"
              />
              <h3 className="show-details-artist-name text-center">
                {show.artistStageName}{" "}
              </h3>
              <div className="text-center">
                <small className="small-green-text">
                  {show.genres.split(",").map((genre, i) => {
                    let length = show.genres.split(",").length;
                    if (i === length - 1) {
                      return genre;
                    } else {
                      return genre + " • ";
                    }
                  })}
                </small>
              </div>
              {(show.artistSC ||
                show.artistIG ||
                show.artistYT ||
                showId.artistWebsite) && (
                <div className="shows-socials-container">
                  {show.artistSC && (
                    <small>
                      <a
                        href={`https://soundcloud.com/${show.artistSC}`}
                        target="blank"
                        className="socials-link"
                      >
                        <img
                          src={soundCloud}
                          alt="Sound Cloud"
                          className="shows-socials-icon"
                        />
                        {show.artistSC}
                      </a>
                    </small>
                  )}
                  {show.artistIG && (
                    <small>
                      <a
                        href={`https://www.instagram.com/${show.artistIG}`}
                        target="blank"
                        className="socials-link"
                      >
                        <img
                          src={instagram}
                          alt="Instagram"
                          className="shows-socials-icon"
                        />
                        {show.artistIG}
                      </a>
                    </small>
                  )}
                  <br />
                  {show.artistYT && (
                    <small>
                      <a
                        href={`https://www.youtube.com/${show.artistYT}`}
                        target="blank"
                        className="socials-link"
                      >
                        <img
                          src={youTube}
                          alt="YouTube"
                          className="shows-socials-icon"
                        />
                        {show.artistYT}
                      </a>
                    </small>
                  )}
                  {show.artistTT && (
                    <small>
                      <a
                        href={`https://www.youtube.com/${show.artistTT}`}
                        target="blank"
                        className="socials-link"
                      >
                        <img
                          src={tiktok}
                          alt="TikTok"
                          className="shows-socials-icon"
                        />
                        {show.artistTT}
                      </a>
                    </small>
                  )}
                  {show.artistWebsite && (
                    <small>
                      <a
                        href={`https://www.${show.artistWebsite}`}
                        target="blank"
                        className="socials-link"
                      >
                        <img
                          src={website}
                          alt="Website"
                          className="shows-socials-icon"
                        />
                        {show.artistWebsite}
                      </a>
                    </small>
                  )}
                </div>
              )}
            </div>
            <div className="show-details-info-box">
              <div className="show-details-row">
                <div className="show-details-col">
                  <label className="show-details-category">TICKET PRICE</label>
                  <span className="show-details-value">
                    ${show.showTicketPrice}
                  </span>
                </div>
                <div className="show-details-col">
                  <label className="show-details-category">
                    STREAMING TIME ZONE
                  </label>
                  <span className="show-details-value">
                    {RenderTimeZone(show.showTimezone)}
                  </span>
                </div>
              </div>
              <div className="show-details-row">
                <div className="show-details-col">
                  <label className="show-details-category">DATE</label>
                  <span className="show-details-value">
                    {startDate.format("MMM D YYYY")}
                  </span>
                </div>
                <div className="show-details-col">
                  <label className="show-details-category">TIME</label>
                  <span className="show-details-value">
                    {moment(show.showTime, "HH:mm").format("hh:mm a")}
                  </span>
                  {/* <span className="show-details-value">{moment(show.showTime, 'HH:mm').add(show.showTimezone, 'h' ).format('hh:mm a') || fakeShow.StartTime}</span> */}
                </div>
              </div>
              <div className="show-details-col">
                <label className="show-details-category">DESCRIPTION</label>
                <span className="show-details-description">
                  {show.showDescription}
                </span>
                {currentState.artistContext.stageName ===
                  show.artistStageName && renderArtistOptions()}
              </div>
            </div>
          </div>
        </div>
        <br />

        <div className="show-details-artist-buttons-container">
          <CopyToClipboard text={`https://www.tederlive.com/#${match.url}`}>
            <span
              className="red-teder-btn"
              data-toggle="modal"
              data-target="#shareShow"
            >
              Share Show
            </span>
          </CopyToClipboard>{" "}
          <Link to={`/shows/view/${showId}`} className="red-teder-btn">
            Watch Show
          </Link>
        </div>

        {/* <!-- Modal --> */}
        <div
          className="modal fade"
          id="shareShow"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="shareShowLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-body text-center">
                <br />
                <br />
                <h5>Show link copied!</h5>
              </div>
              <div className="modal-body d-flex justify-content-center">
                <span
                  type="button"
                  className="green-teder-btn"
                  data-dismiss="modal"
                >
                  close
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Modal --> */}

        <Modal
          isOpen={modalIsOpen}
          ariaHideApp={false}
          className="Modal"
          overlayClassName="Overlay"
          contentLabel="Bank Modal"
        >
          <div className="teder-modal-container">
            <div className="modal-body">
              <div className="teder-modal-title text-center">Careful!</div>
              <div className="teder-modal-body">
                <div className="text-center">
                  Cancelling this show will permanently delete it.
                  <br />
                  <br />
                  <br />
                  Do you still want to cancel?
                </div>
              </div>
              <div className="text-center">
                <button
                  type="button"
                  className="red-teder-btn"
                  onClick={yesCancel}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="green-teder-btn"
                  onClick={() => setIsOpen(false)}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </Modal>

        <br />
        <br />
        <br />
        <div className="row">
          <PurchaseTicket />
        </div>
        <br />
        <br />
      </div>
    </div>
  );
};

export default ViewShowDetails;
