export const ABBREVIATED_MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const THIS_OR_NEXT = ["This", "Next"];

export const WEEKDAYS = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const TIME_METRICS = [
  "years",
  "months",
  "days",
  "hours",
  "minutes",
  "seconds",
];

export const HEADER_POSITION = {
  name: "left",
  totalShows: "center",
  totalTicketSales: "center",
  totalEarnings: "center",
  lastShow: "left",
  timeOnTeder: "left",
};

export const UserType = {
  Anonymous: "-1",
  User: "0",
  Artist: "1",
  Admin: "2",
  Affiliate: "3",
  BookingAgent: "4",
};

export const BA_DASHBOARD_ROUTES = {
  Home: "home",
  Artists: "artists",
  Income: "income",
  Settings: "settings",
  BA_Schedule: "ba-schedule",
};

export const ARTIST_DASHBOARD_ROUTES = {
  Home: "home",
  ScheduleShow: "schedule",
  TicketSales: "sales",
  EditAccount: "edit",
};

export const ARTIST_DASHBOARD_LINKS = [
  {
    id: 0,
    name: ARTIST_DASHBOARD_ROUTES.Home,
    page: "Home",
  },
  {
    id: 1,
    name: ARTIST_DASHBOARD_ROUTES.ScheduleShow,
    page: "Schedule Show",
  },
  {
    id: 2,
    name: ARTIST_DASHBOARD_ROUTES.TicketSales,
    page: "View Ticket Sales",
  },
  {
    id: 3,
    name: ARTIST_DASHBOARD_ROUTES.EditAccount,
    page: "My Settings",
  },
];

export const GET_IP_API = "https://geolocation-db.com/json/";

export const HUBSPOT_PROPERTIES = {
  ARTIST: "artist",
  VBA: "VBA",
  REFERRED_BY: "referred_by",
  USER_TYPE: "user_type",
  DATE_JOINED: "date_joined",
  GENDER: "gender",
  AGE: "age",
  EMAIL: "email",
};
