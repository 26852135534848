import React, { useState, useEffect } from "react";
import { Button, useTheme } from "@mui/material";

const ArtistPageButton = ({ children, ...props }) => {
  const styles = {
    buttonLink: {
      backgroundColor: "#ffffff",
      border: "2px solid #1A1A4A",
      color: "#1A1A4A",
      width: "160px",
      borderRadius: "10px",
      fontFamily: "inherit",
      fontSize: "0.9rem",
      fontWeight: "bolder",
      padding: "5px 10px",
      marginBottom: "3%",
      textDecoration: "none",
      transition: "all 0.2s ease-in-out",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#1A1A4A",
        textDecoration: "none",
        color: "#fff",
        transition: "all 0.2s ease-in-out",
      },
    },
  };
  return (
    <Button hover={true} sx={styles.buttonLink}>
      {children}
    </Button>
  );
};

const RedTederButton = ({ children, onClick, ...props }) => {
  const theme = useTheme();
  const { darkRed, darkBlue } = theme.palette.tederColors;
  const styles = {
    redTederButton: {
      padding: "5% 13%",
      fontFamily: "inherit",
      fontStyle: "normal",
      fontWeight: "700",
      marginTop: "30%",
      fontSize: "14px",
      lineHeight: "17px",
      letterSpacing: "0.01em",
      background: darkRed,
      textTransform: "uppercase",
      boxShadow: "5px 4px 10px rgba(0, 0, 0, 0.1)",
      color: "#ffffff",
      [theme.breakpoints.down("md")]: {
        marginTop: "15%",
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: "20%",
      },
      "&:hover": {
        background: darkBlue,
      },
    },
  };
  return (
    <Button hover={true} sx={styles.redTederButton} onClick={onClick}>
      {children}
    </Button>
  );
};

const GreenTederButton = ({ children, onClick, ...props }) => {
  const theme = useTheme();
  const { green01, darkBlue } = theme.palette.tederColors;
  const styles = {
    greenButton: {
      color: darkBlue,
      backgroundColor: green01,
      border: "none",
      padding: "10px 10px",
      margin: "8px 8px",
      maxWidth: "200px",
      width: "200px",
      fontFamily: "titlefont serif",
      fontSize: "16px",
      textAlign: "center",
      textDecoration: "none",
      textTransform: "uppercase",
      letterSpacing: "1px",
      transition: "all 0.3s ease-in-out",
      appearance: "none",
    },
  };

  return (
    <Button hover={true} sx={styles.greenButton} onClick={onClick} {...props}>
      {children}
    </Button>
  );
};

export { ArtistPageButton, GreenTederButton, RedTederButton };
