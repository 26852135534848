import React, { useState, useEffect } from "react";
import "./css/StepOne.css";
import { useSelector } from "react-redux";
import { booking_agent_update_1 } from "../../../Common/data";
import { UserType } from "../../../util/constants";
import { useDispatch } from "react-redux";
import {
  setRegistrationStep1Action,
  artistDetailsAction,
  loadContriesAction,
  loadStatesAction,
  loginUserAction,
} from "../../../redux/actions";
import {
  show_country_list,
  show_state_list,
  get_artist_details,
  get_genders_list,
} from "../../../Common/data";
import { push } from "connected-react-router";
import { HUBSPOT_PROPERTIES } from "../../../util/constants";
import customHubspotAPI from "../../../HelperFunctions/CustomHubspotAPI";

function BASignUpFormOne() {
  const todaysDate = "1900-01-01";
  const dispatch = useDispatch();
  const currentState = useSelector((state) => state.TederReducerState);
  const [countries, setCountries] = useState([]);
  const [countryLoad, setCountryLoad] = useState(false);
  const [states, setStates] = useState("");
  const [statesLoad, setStatesLoad] = useState(false);
  const [genders, setGenders] = useState([]);

  useEffect(() => {
    get_artist_details()
      .then((res) => {
        const artistDetails = {
          ...currentState.artistDetails,
          authProviderId: res.artist_details[0].AuthProviderId,
          authProvider: res.artist_details[0].AuthProvider,
          authID: res.artist_details[0].AuthID,
          artistId: res.artist_details[0].ArtistID,
          firstName: res.artist_details[0].FirstName,
          lastName: res.artist_details[0].LastName,
          address: res.artist_details[0].Address,
          city: res.artist_details[0].City,
          email: res.artist_details[0].Email,
          state: res.artist_details[0].State,
          country: res.artist_details[0].Country,
          zipCode: res.artist_details[0].ZipCode,
          phoneNo: res.artist_details[0].PhoneNo,
          bankName: res.artist_details[0].BankName,
          routingNo: res.artist_details[0].RoutingNo,
          accID: res.artist_details[0].AccID,
          accNo: res.artist_details[0].AccNo,
          referralCode: res.artist_details[0].ReferralCode,
          userType: res.artist_details[0].UserType,
        };
        dispatch(artistDetailsAction(artistDetails));
        return artistDetails;
      })
      .catch((err) => console.error(err));
    get_genders_list()
      .then((res) => {
        setGenders(res.gendersList);
      })
      .catch((error) => console.error(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFormJson = () => {
    const BASignupJson = {
      FirstName: currentState.userRegistrationStep1.firstName,
      LastName: currentState.userRegistrationStep1.lastName,
      Address: currentState.userRegistrationStep1.address,
      City: currentState.userRegistrationStep1.city,
      State: currentState.userRegistrationStep1.state,
      Country: currentState.userRegistrationStep1.country,
      ZipCode: currentState.userRegistrationStep1.zipCode,
      PhoneNo: currentState.userRegistrationStep1.phoneNo,
      UserType: currentState.userRegistrationStep1.userType,
    };
    return BASignupJson;
  };

  // fetching artist details
  // checking state

  const get_country_list = async () => {
    const response = await show_country_list
      .then((result) => {
        return JSON.parse(result.countryList);
      })
      .catch((err) => console.error(err));
    setCountries(response);
    dispatch(loadContriesAction(response));
  };

  const get_states_list = async () => {
    const response = await show_state_list
      .then((result) => {
        return JSON.parse(result.stateList);
      })
      .catch((err) => console.error(err));
    setStates(response);
    dispatch(loadStatesAction(response));
  };

  useEffect(() => {
    // get countries if not in state
    if (currentState.countries.length === 0) {
      get_country_list();
      setCountryLoad(true);
    } else {
      setCountries(currentState.countries);
      setCountryLoad(true);
    }

    // get states if not in state
    if (currentState.states.length === 0) {
      get_states_list();
      setStatesLoad(true);
    } else {
      setStates(currentState.states);
      setStatesLoad(true);
    }
    const registrationDetails = {
      ...currentState.userRegistrationStep1,
      userType: UserType.BookingAgent,
    };
    dispatch(setRegistrationStep1Action(registrationDetails));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const registrationDetails = {
      ...currentState.userRegistrationStep1,
      [name]: value,
    };

    dispatch(setRegistrationStep1Action(registrationDetails));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = {
      ...currentState.userRegistrationStep1,
      userType: UserType.BookingAgent,
    };

    dispatch(setRegistrationStep1Action(data));
    let details = {
      ...currentState.userContext,
      isSignupCompleted: 1,
    };
    dispatch(loginUserAction(details));

    const formJson = getFormJson();

    const hubspotData = [
      {
        name: HUBSPOT_PROPERTIES.EMAIL,
        value: currentState.baContext.email,
      },
      {
        name: HUBSPOT_PROPERTIES.USER_TYPE,
        value: HUBSPOT_PROPERTIES.VBA,
      },
    ];

    try {
      customHubspotAPI(hubspotData);
    } catch (error) {
      console.error("vba form api error", error);
    }

    booking_agent_update_1(formJson)
      .then((res) => {
        dispatch(push("/agent-dashboard"));
      })
      .catch((err) => console.error(err));
  };

  const signUpForm = () => (
    <div className="sign-up-form-1-container">
      <div className="sign-up-form-1-content">
        <form className="sign-up-form-1-form" onSubmit={handleSubmit}>
          <div className="sign-up-title">
            <p>BOOKING AGENT SIGN UP FORM</p>
          </div>
          <div className="first-last-input">
            <div className="name-input">
              <label>First Name</label>
              <input
                name="firstName"
                value={currentState.userRegistrationStep1.firstName}
                onChange={handleChange}
              ></input>
              <p>*Required Field</p>
            </div>
            <div className="last-input">
              <label>Last Name</label>
              <input
                name="lastName"
                value={currentState.userRegistrationStep1.lastName}
                onChange={handleChange}
              ></input>
            </div>
          </div>
          <div className="state-input">
            <label>Date of Birth</label>
            <input
              type="date"
              style={{ borderRadius: "3px", border: "none", height: "33px" }}
              min={todaysDate}
              name="dateOfBirth"
              value={currentState.userRegistrationStep1.dateOfBirth}
              onChange={handleChange}
              placeholder="mm/dd/yyyy"
            />
            <p>*Must be 18 or older</p>
            <label>Gender</label>
            <select
              labelId="select-gender"
              id="select-gender"
              name="gender"
              onChange={handleChange}
            >
              {genders &&
                genders.map((gender) => (
                  <option key={gender.DisplayOrder} value={gender.GenderDesc}>
                    {gender.GenderDesc}
                  </option>
                ))}
            </select>
          </div>
          <div className="address-input">
            <label>Address</label>
            <input
              name="address"
              value={currentState.userRegistrationStep1.address}
              onChange={handleChange}
            ></input>
          </div>
          <div className="city-state-zip-input">
            <div className="city-input">
              <label>City</label>
              <input
                name="city"
                value={currentState.userRegistrationStep1.city}
                onChange={handleChange}
              ></input>
            </div>
            <div className="state-input">
              <label>State</label>
              <select
                value={currentState.userRegistrationStep1.state}
                name="state"
                onChange={handleChange}
              >
                <option>Select State</option>
                <option>AK</option>
                <option>AL</option>
                <option>AR</option>
                <option>AZ</option>
                <option>CA</option>
                <option>CO</option>
                <option>CT</option>
                <option>DE</option>
                <option>FL</option>
                <option>GA</option>
                <option>HI</option>
                <option>IA</option>
                <option>ID</option>
                <option>IL</option>
                <option>IN</option>
                <option>KS</option>
                <option>KY</option>
                <option>LA</option>
                <option>MA</option>
                <option>MD</option>
                <option>ME</option>
                <option>MI</option>
                <option>MN</option>
                <option>MO</option>
                <option>MS</option>
                <option>MT</option>
                <option>NC</option>
                <option>ND</option>
                <option>NE</option>
                <option>NH</option>
                <option>NJ</option>
                <option>NM</option>
                <option>NV</option>
                <option>NY</option>
                <option>OH</option>
                <option>OK</option>
                <option>OR</option>
                <option>PA</option>
                <option>RI</option>
                <option>SC</option>
                <option>SD</option>
                <option>TN</option>
                <option>TX</option>
                <option>UT</option>
                <option>VA</option>
                <option>VT</option>
                <option>WA</option>
                <option>WI</option>
                <option>WV</option>
                <option>WY</option>
              </select>
            </div>
            <div className="zip-input">
              <label>Zip Code</label>
              <input
                name="zipCode"
                value={currentState.userRegistrationStep1.zipCode}
                onChange={handleChange}
              ></input>
            </div>
          </div>
          <div className="country-email-input">
            <div className="country-input">
              <label>Country</label>
              <select
                value={currentState.userRegistrationStep1.country}
                name="country"
                onChange={handleChange}
              >
                <option>Select Country</option>
                {countries &&
                  countries.map((country) => (
                    <option key={country.ID}>{country.CountryName}</option>
                  ))}
              </select>
              <span style={{ color: "red", fontSize: ".65em", margin: "2%" }}>
                *Required Field
              </span>
            </div>
            <div className="phone-referral-input">
              <div className="phone-input">
                <label>Phone Number</label>
                <input
                  name="phoneNo"
                  value={currentState.userRegistrationStep1.phoneNo}
                  onChange={handleChange}
                ></input>
              </div>
            </div>
          </div>
          <div className="next-button-container">
            <button
              type="submit"
              className={
                currentState.userRegistrationStep1.country === "Select Country"
                  ? "disabled"
                  : "next-button"
              }
              disabled={
                currentState.userRegistrationStep1.country === "Select Country"
              }
            >
              <p>NEXT</p>
            </button>
            {/* </Link> */}
          </div>
        </form>
      </div>
    </div>
  );
  return countryLoad && statesLoad ? signUpForm() : null;
}

export default BASignUpFormOne;
