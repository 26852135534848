import React, { useEffect } from "react";
import "../../../Styling/artistsignup.css";
import GenreOptions from "../../../HelperFunctions/GenreOptions";
import UploadPhoto from "../../../HelperFunctions/UploadPhoto";
import { create_show } from "../../../Common/data";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import {
  createShowAction,
  createShowErrorsAction,
  resetShowAction,
} from "../../../redux/actions";
import { Box } from "@mui/system";
import ValidateShowForm from "./ValidateShowForm";
import moment from "moment";
import NumberFormat from "react-number-format";
import Modal from "react-modal";
import TederTimePicker from "../../BookingAgentPages/TederTimePicker";

const SchedShowForm = ({ history }) => {
  const currentState = useSelector((state) => state.TederReducerState);
  const dispatch = useDispatch(); //to update our redux state

  const inputClassName = "form-control artist-textbox-style";
  const date_now = moment().add(1, "d").format("YYYY-MM-DD");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const styles = {
    wrapper: {
      // marginLeft: "6%",
      // marginBottom: "5%",
      margin: "3% 0 0 0",
      height: "100vh",
      padding: "3%",
      overflow: "auto",
    },
  };
  useEffect(
    () => () => {
      dispatch({ type: "CLEAR_CREATE_SHOW_STATE" });
      dispatch({ type: "CLEAR_SHOW_ERRORS" });
    },
    []
  );
  const handleChange = (e) => {
    let { name, value } = e.target;

    let showDetails;
    showDetails = {
      ...currentState.createShowState,
      Artist_ID: currentState.artistContext.stageName,
      [name]: value,
    };

    console.log("show details", showDetails);

    dispatch(createShowAction(showDetails));

    //update the error state so that the user get real-time validation feedback
    dispatch(createShowErrorsAction(ValidateShowForm(showDetails)));
  };

  const handleCreateShow = () => {
    // setIsLoading(true) ;
    // if (checkCreateShowProperties(currentState.createShowState)) {
    //   return;
    // } else {
    const createShowJson = {
      EventName: currentState.createShowState.showName,
      GenreList: currentState.createShowState.genres,
      PhotoPath: currentState.createShowState.photoPath,
      Description: currentState.createShowState.showDescription,
      StartDate: currentState.createShowState.showDate,
      StartTime: currentState.createShowState.showTime + ":00",
      TimeZone: currentState.createShowState.showTimeZone,
      Duration: currentState.createShowState.showDuration.toString(),
      TicketPrice: currentState.createShowState.showTicketPrice,
      TotalTickets: currentState.createShowState.showTicketMax,
    };
    create_show(createShowJson)
      .then((data) => {
        //update state here with the new show that was created
        dispatch(resetShowAction());
        setIsOpen(true);
      })
      .catch((error) => {
        console.log("Error in create_show");
        console.log(error);
      });
    // }
  };

  function checkCreateShowProperties(CreateShowJson) {
    for (var key in CreateShowJson) {
      if (key !== "PhotoPath") {
        if (CreateShowJson[key] === "") return true;
      }
    }
    return false;
  }

  let disabledata = checkCreateShowProperties(currentState.createShowState);

  const closeAndRedirect = () => {
    setIsOpen(false);
    dispatch(push("/"));
  };

  const scheduleForm = () => (
    <Box component="div" sx={styles.wrapper}>
      <div className="text-center">
        {/* <button className="green-teder-btn" onClick={backButton}>
          Back
        </button> */}
      </div>
      <br />
      <br />
      <h2>SCHEDULE A SHOW </h2>
      <br />
      <div className="row">
        <div className="col">
          <UploadPhoto
            photo={currentState.createShowState.photoPath || null}
            handleChange={handleChange}
            message={"Upload Event Photo"}
          />
          <small className="error-text-red">
            {currentState.createShowStateErrors.photoPath}
          </small>
          <div className="form-group">
            <label className="teder-form-label">Description</label>
            <br />
            <small className="small-green-text">
              Character Limit:{" "}
              {300 - currentState.createShowState.showDescription.length}
            </small>
            <textarea
              className={inputClassName}
              id="inputAbout"
              name="showDescription"
              placeholder="Tell us more about your show."
              value={currentState.createShowState.showDescription}
              onChange={handleChange}
              maxLength="300"
            />
            <small className="error-text-red">
              {currentState.createShowStateErrors.showDescription}
            </small>
          </div>
        </div>
        <div className="col">
          <form>
            <div className="form-group">
              <label className="teder-form-label">Show Name</label>
              <input
                type="text"
                className={inputClassName}
                placeholder="Show Name"
                name="showName"
                value={currentState.createShowState.showName}
                onChange={handleChange}
              />
              <small className="error-text-red">
                {currentState.createShowStateErrors.showName}
              </small>
            </div>
            <div className="form-group">
              <GenreOptions
                genres={currentState.createShowState.genres}
                handleChange={handleChange}
              />
              <small className="error-text-red">
                {currentState.createShowStateErrors.genres}
              </small>
            </div>
            <div className="form-row">
              <div className="form-group col-md">
                <label className="teder-form-label">Date</label>
                <input
                  type="date"
                  min={date_now}
                  className={inputClassName}
                  name="showDate"
                  value={currentState.createShowState.showDate}
                  onChange={handleChange}
                  placeholder="mm/dd/yyyy"
                />
                <small className="error-text-red">
                  {currentState.createShowStateErrors.showDate}
                </small>
              </div>
              <div className="form-group col-md">
                <label className="teder-form-label">Time</label>
                {/* <input
                  type="time"
                  className={inputClassName}
                  step={1800}
                  name="showTime"
                  value={currentState.createShowState.showTime}
                  onChange={handleChange}
                  placeholder="00:00 AM"
                /> */}
                <br />
                <TederTimePicker primary />

                <small className="error-text-red">
                  {currentState.createShowStateErrors.showTime}
                </small>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md">
                <label className="teder-form-label">
                  Time Zone/ Streaming Location
                </label>
                <select
                  id="selectTimezone"
                  className="form-control artist-textbox-style teder-select"
                  name="showTimeZone"
                  value={currentState.createShowState.showTimeZone}
                  onChange={handleChange}
                >
                  <option value="-12:00">(GMT -12:00) Baker Island</option>
                  <option value="-11:00">(GMT -11:00) Apia, Samoa</option>
                  <option value="-10:00">(GMT -10:00) Honolulu, Hawaii</option>
                  <option value="-09:50">(GMT -9:30) Marquesas Islands</option>
                  <option value="-09:00">(GMT -9:00) Juneau, Alaska</option>
                  <option value="-08:00">
                    (GMT -8:00) Pacific Time (US &amp; Canada), Los Angeles,
                    California{" "}
                  </option>
                  <option value="-07:00">
                    (GMT -7:00) Mountain Time (US &amp; Canada), Denver,
                    Colorado
                  </option>
                  <option value="-06:00">
                    (GMT -6:00) Central Time (US &amp; Canada), Chicago,
                    Illinois
                  </option>
                  <option value="-05:00">
                    (GMT -5:00) Eastern Time (US &amp; Canada), New York, New
                    York
                  </option>
                  <option value="-04:00">(GMT -4:00) Caracas, Venezuela</option>
                  <option value="-03:50">
                    (GMT -3:30) St. John's, Newfoundland
                  </option>
                  <option value="-03:00">(GMT -3:00) Sao Paulo, Brazil</option>
                  <option value="-02:00">
                    (GMT -2:00) South Georgia Island
                  </option>
                  <option value="-01:00">(GMT -1:00) Azores, Portugal</option>
                  <option value="+00:00">(GMT) London, England</option>
                  <option value="+01:00">(GMT +1:00) Paris, France</option>
                  <option value="+02:00">
                    (GMT +2:00) Kaliningrad, Russia
                  </option>
                  <option value="+03:00">
                    (GMT +3:00) Tel Aviv-Yafo, Israel
                  </option>
                  <option value="+03:50">(GMT +3:30) Tehran, Iran</option>
                  <option value="+04:00">(GMT +4:00) Muscat, Oman</option>
                  <option value="+04:50">(GMT +4:30) Kabul, Afghanistan</option>
                  <option value="+05:00">
                    (GMT +5:00) Tashkent, Uzbekistan
                  </option>
                  <option value="+05:50">(GMT +5:30) Mumbai, India</option>
                  <option value="+05:75">(GMT +5:45) Kathmandu, Nepal</option>
                  <option value="+06:00">(GMT +6:00) Colombo, Sri Lanka</option>
                  <option value="+06:50">(GMT +6:30) Yangon, Myanmar</option>
                  <option value="+07:00">(GMT +7:00) Jakarta, Indonesia</option>
                  <option value="+08:00">
                    (GMT +8:00) Singapore, Singapore
                  </option>
                  <option value="+08:75">
                    (GMT +8:45) Eucla, Western Australia
                  </option>
                  <option value="+09:00">(GMT +9:00) Tokyo, Japan</option>
                  <option value="+09:50">(GMT +9:30) Darwin, Australia</option>
                  <option value="+10:00">(GMT +10:00) Sydney, Australia</option>
                  <option value="+10:50">(GMT +10:30) Lord Howe Island</option>
                  <option value="+11:00">(GMT +11:00) Solomon Islands</option>
                  <option value="+11:50">(GMT +11:30) Norfolk Island</option>
                  <option value="+12:00">(GMT +12:00) Fiji, Oceania</option>
                  <option value="+12:75">(GMT +12:45) Chatham Islands</option>
                  <option value="+13:00">(GMT +13:00) Apia, Samoa</option>
                  <option value="+14:00">(GMT +14:00) Line Islands</option>
                </select>
              </div>
            </div>
            <div className="form-group">
              <div className="form-row">
                <div className="form-group col-md">
                  <label className="teder-form-label">Duration</label>
                  <input
                    type="range"
                    name="showDuration"
                    className="custom-range durationRange "
                    value={currentState.createShowState.showDuration}
                    min="30"
                    max="300"
                    step="30"
                    id="durationRange"
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col-md">
                  <label className="teder-form-label">Minutes</label>
                  <input
                    type="text"
                    name="showDuration"
                    onChange={handleChange}
                    className={inputClassName}
                    value={currentState.createShowState.showDuration}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md">
                  <label className="teder-form-label">Ticket Price</label>
                  <input
                    type="range"
                    className="custom-range durationRange "
                    min="3"
                    max="1000"
                    step="1"
                    name="showTicketPrice"
                    value={currentState.createShowState.showTicketPrice}
                    onChange={handleChange}
                  />
                  <small className="error-text-red">
                    {currentState.createShowStateErrors.showTicketPrice}
                  </small>
                </div>
                <div className="form-group col-md">
                  <label className="teder-form-label">Price $</label>
                  <input
                    type="text"
                    placeholder="$"
                    name="showTicketPrice"
                    onChange={handleChange}
                    className={inputClassName}
                    value={currentState.createShowState.showTicketPrice}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md">
                  <label className="teder-form-label">Limit # Of Tickets</label>
                  <input
                    type="number"
                    className={inputClassName}
                    placeholder="Max tickets for this show"
                    name="showTicketMax"
                    value={currentState.createShowState.showTicketMax}
                    onChange={handleChange}
                  />
                  <small className="error-text-red">
                    {currentState.createShowStateErrors.showTicketMax}
                  </small>
                </div>
              </div>
              <div className="form-row">
                <table className="table tableWhite">
                  <thead>
                    <tr>
                      <th scope="col">Tickets Sold</th>
                      <th scope="col">Show Income</th>
                      <th scope="col">Expected Revenue</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>25</td>
                      <td>
                        {
                          <NumberFormat
                            value={
                              parseFloat(
                                currentState.createShowState.showTicketPrice
                              ) * 25
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                            decimalScale={2}
                          />
                        }
                      </td>
                      <td>
                        {
                          <NumberFormat
                            value={
                              parseFloat(
                                currentState.createShowState.showTicketPrice
                              ) *
                              25 *
                              0.7
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                            decimalScale={2}
                          />
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>100</td>
                      <td>
                        {
                          <NumberFormat
                            value={
                              parseFloat(
                                currentState.createShowState.showTicketPrice
                              ) * 100
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                            decimalScale={2}
                          />
                        }
                      </td>
                      <td>
                        {
                          <NumberFormat
                            value={
                              parseFloat(
                                currentState.createShowState.showTicketPrice
                              ) *
                              100 *
                              0.7
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                            decimalScale={2}
                          />
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>250</td>
                      <td>
                        {
                          <NumberFormat
                            value={
                              parseFloat(
                                currentState.createShowState.showTicketPrice
                              ) * 250
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                            decimalScale={2}
                          />
                        }
                      </td>
                      <td>
                        {
                          <NumberFormat
                            value={
                              parseFloat(
                                currentState.createShowState.showTicketPrice
                              ) *
                              250 *
                              0.7
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                            decimalScale={2}
                          />
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>1000</td>
                      <td>
                        {
                          <NumberFormat
                            value={
                              parseFloat(
                                currentState.createShowState.showTicketPrice
                              ) * 1000
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                            decimalScale={2}
                          />
                        }
                      </td>
                      <td>
                        {
                          <NumberFormat
                            value={
                              parseFloat(
                                currentState.createShowState.showTicketPrice
                              ) *
                              1000 *
                              0.7
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                            decimalScale={2}
                          />
                        }
                      </td>
                    </tr>
                  </tbody>
                </table>
                <small className="error-text-red">
                  *The numbers shown are estimation. <br />
                  actual revenue may be different
                </small>
              </div>
            </div>
          </form>
        </div>
      </div>
      <br />
      <br />

      <div className="d-flex justify-content-center">
        <button
          disabled={!currentState.createShowStateErrors.isValid}
          //disabled={false}
          className="red-teder-btn"
          onClick={handleCreateShow}
        >
          Create Show
        </button>
      </div>

      <Modal
        isOpen={modalIsOpen}
        ariaHideApp={false}
        className="Modal"
        overlayClassName="Overlay"
        contentLabel="Schedule Modal"
      >
        <div className="teder-modal-container">
          <div className="modal-body">
            <div className="teder-modal-subtitle text-center">
              Your show has been scheduled!
            </div>
            <br />
            <br />
            <div className="text-center">
              <button
                type="button"
                className="green-teder-btn"
                onClick={closeAndRedirect}
              >
                close
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </Box>
  );

  const backButton = () => history.goBack();

  const redirectUser = () => {
    dispatch(push("/login"));
    return "Please login";
  };

  return currentState.userContext.userLoggedIn
    ? scheduleForm()
    : redirectUser();
};

export default SchedShowForm;
