import React, {useState, useEffect} from 'react'
import "../Styling/forgotpasswordpage.css";


function CreateNewPasswordPage() {
        const [btnClass, setBtnClass] = useState("forgot-password-unconfirm-btn");

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    useEffect(() => {

        if(password.length > 0 && confirmPassword.length > 0){
            setBtnClass("forgot-password-confirm-btn");
        }
        else{
            setBtnClass("forgot-password-unconfirm-btn");
        }

    }, [password, confirmPassword]);
    return (
        <div className="forgot-password-container">
            <div className="forgot-password-content">
                <form className="forgot-password-form">
                    <p className="forgot-password-title">CREATE NEW PASSWORD</p>
                    <p className="forgot-password-text">
Your new password must be different from previous passwords.
                    </p>
                    <label className="forgot-password-label">New Password</label>
                    <input onChange={e => setPassword(e.target.value)} className="change-password-input"/>
                    <p className="password-rules-text">Must have at least 6 characters including 1 uppercase letter and 1 special character
</p>
                                        <label className="forgot-password-label">Confirm New Password</label>
                    <input onChange={e => setConfirmPassword(e.target.value)} className="confirm-password-input"/>
                    <button className={btnClass} disabled={confirmPassword.length === 0}>CONFIRM</button>
                </form>
            </div>
            
        </div>
    )
}

export default CreateNewPasswordPage
